import { action, computed, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { formatters } from '../../helpers/Formatters';
import { Expense } from '../../models/Expense';
import { ExpenseModal } from '../Modals/ExpenseModal';
export const ExpensesTable = observer(class ExpensesTable extends Component {
  filter
  _addModalOpen
  _selectedExpense

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  idFormatter = (cell, row) => (
    <><a
      href={`#${row.id}`}
      key={row.id}
      onClick={
        () => {
          runInAction(() => {
            row.amount = row.amount.cents / 100;
            this._selectedExpense = row;
            this._addModalOpen = true;
          });
        }
      }
    >
      {cell.split('-')[0]}
    </a><ClipboardLink text={cell} /></>
  )

  driverFormatter = (cell, row) => {
    if (!row.driver) {
      return;
    }

    const url = `/driver/${row.driver.data.id}`;

    return (
      <NavLink strict={+true} to={url}>{row.driver.data.id}</NavLink>
    );
  }

  tripFormatter = (cell, row) => {
    if (!row.booking) return (<span />);

    const url = `/trips/${row.booking.data.id}`;

    return (
      <NavLink strict={+true} to={url}>{row.booking.data.id}</NavLink>
    );
  }

  typeFormatter = (cell, _row) => {
    switch(cell) {
      case 'food':
        return <>
          <i className="bx bxs-pizza text-primary pizza-icon mr-2" />
          { cell }
        </>
      case 'gas': case 'jeevz-gas':
        return <>
          <i className="bx bxs-gas-pump text-primary mr-2" />
          { cell }
        </>
      case 'parking':
        return <>
          <i className="bx bxs-parking text-primary mr-2" />
          { cell }
        </>
      default:
        return <>
          <i className="bx bx-question-mark text-primary mr-2" />
          { cell }
        </>
    }
  }

  statusFormatter = (cell, _row) => {
    switch (cell) {
      case 'submitted':
        return <Badge color="warning" className="mr-1">{ cell.toUpperCase() }</Badge>;
      case 'approved':
        return <Badge className="badge-soft-success mr-1">{ cell.toUpperCase() }</Badge>;
      case 'rejected':
        return <Badge className="badge-soft-danger mr-1">{ cell.toUpperCase() }</Badge>;
      case 'disputed':
        return <Badge color="danger" className="mr-1">{ cell.toUpperCase() }</Badge>;
      default:
        return <Badge color="danger" className="mr-1">unknown</Badge>;
    }
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'amount',
      name: 'Amount',
      priority: 1,
      formatter: formatters.currencyFormatter,
      sort: true,
      sortKey: 'amount_cents',
    },
    {
      dataField: 'expenseType',
      name: 'Type',
      priority: 1,
      sort: true,
      formatter: this.typeFormatter,
    },
    {
      dataField: 'status',
      name: 'Status',
      priority: 1,
      sort: true,
      formatter: this.statusFormatter,
    },
    {
      dataField: 'description',
      name: 'Description',
      priority: 1,
    },
    {
      dataField: 'driver',
      name: 'Driver',
      priority: 1,
      formatter: this.driverFormatter,
    },
    {
      dataField: 'createdAt',
      name: 'Created',
      priority: 1,
      formatter: this.createdAtFormatter,
      sort: true,
    },
  ]

  addSubmitHandler = async (e, values) => {
    const data = {
      expense: {
        amount_cents: Math.round(values.amount * 100),
        description: values.description,
        expense_type: values.expenseType,
        bento: values.bento,
      },
    }

    if (this._selectedExpense) {
      await client.makeApiCall(
        'put', `/expenses/${this._selectedExpense.id}`, data,
        'Expense updated successfully',
        'Unable to update expense',
        (response) => {
          this._selectedExpense.updateFromJson(response.data)
          this._closeModals()
        }
      )
    } else {
      await client.makeApiCall(
        'post', this.props.dataUrl, data,
        'Expense added successfully',
        'Unable to add expense',
        this._postSubmitCallback
      )
    }
  }

  _postSubmitCallback = () => {
    this._tableRef.current.fetchData()
    this._closeModals()
  }

  _closeModals = () => {
    this._addModalOpen = false
  }

  get modalTitle() {
    if (this._selectedExpense) {
      return this._selectedExpense.paid ? 'View Expense' : 'Edit Expense'
    } else {
      return 'New Expense'
    }
  }

  render() {
    return (
      <>
        <ExpenseModal
          isOpen={this._addModalOpen}
          toggle={() => {
            runInAction(() => {
              this._selectedExpense = null;
              this._addModalOpen = !this._addModalOpen;
            });
          }}
          title={this.modalTitle}
          submitHandler={this.addSubmitHandler}
          expense={this._selectedExpense}
        />
        <MyTable
          model={Expense}
          dataUrl={this.props.dataUrl}
          breadcrumbBaseTitle={"Expenses"}
          dataColumns={this.dataColumns}
          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}
          singleActions={this.props.addEnabled && {
            'Add +': () => runInAction(() => {
              this._selectedExpense = null;
              this._addModalOpen = !this._addModalOpen;
            })
          }}
          embedded
          ref={this._tableRef}
        />
      </>
    );
  }
});

decorate(ExpensesTable, {
  _addModalOpen: observable,
  filter: observable,
  _selectedExpense: observable,
  modalTitle: computed,
  _closeModals: action,
});
