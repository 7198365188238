import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE } from './actionTypes';

export const editProfile = (user) => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = (msg) => ({
  type: PROFILE_SUCCESS,
  payload: msg,
});

export const profileError = (error) => ({
  type: PROFILE_ERROR,
  payload: error,
});
