// @ts-nocheck
import { action, computed, observable } from "mobx"
import { apiCall } from "../constants/apiCall"


export class Offer {
    /**
     * unique id of this Offer, immutable.
     */
    id = null

    @observable status
    @observable driverId
    @observable driver
    @observable bookingId
    @observable cancelledBy
    @observable manuallyOffered
    @observable expiresAt
    @observable expiredAt
    @observable acceptedAt
    @observable rejectedAt
    @observable terminatedAt
    @observable cancelledAt
    @observable createdAt
    @observable updatedAt
    @observable booking
    @observable customMessage
    @observable bonus

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id: this.id,
        status: this.status,
        driverId: this.driverId,
        driver: this.driver,
        bookingId: this.bookingId,
        cancelledBy: this.cancelledBy,
        manuallyOffered: this.manuallyOffered,
        expiresAt: this.expiresAt,
        expiredAt: this.expiredAt,
        acceptedAt: this.acceptedAt,
        rejectedAt: this.rejectedAt,
        terminatedAt: this.terminatedAt,
        cancelledAt: this.cancelledAt,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
        booking: this.booking,
        customMessage: this.customMessage,
        bonus: this.bonus,
      }
    }

    @action async accept() {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/dispatch_offers/${this.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          dispatch_offer: {
            status: 'accepted',
          }
        }),
      })

      if (response.data?.type === 'dispatch_offer') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action async reject() {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/dispatch_offers/${this.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          dispatch_offer: {
            status: 'rejected',
          }
        }),
      })

      if (response.data?.type === 'dispatch_offer') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.status = data.attributes.status
      this.driverId = data.attributes.driverId
      this.bookingId = data.attributes.bookingId
      this.cancelledBy = data.attributes.cancelledBy
      this.manuallyOffered = data.attributes.manuallyOffered
      this.expiresAt = data.attributes.expiresAt
      this.expiredAt = data.attributes.expiredAt
      this.acceptedAt = data.attributes.acceptedAt
      this.rejectedAt = data.attributes.rejectedAt
      this.terminatedAt = data.attributes.terminatedAt
      this.cancelledAt = data.attributes.cancelledAt
      this.createdAt = data.attributes.createdAt
      this.updatedAt = data.attributes.updatedAt
      this.booking = data.attributes.booking
      this.customMessage = data.attributes.customMessage
      this.bonus = data.attributes.bonus
      this.driver = data.attributes.driver

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
