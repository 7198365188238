import { client } from './client';

const URL = "https://route.ls.hereapi.com/routing/7.2/calculateroute.json"

export default new class HereEstimate {
  calculate = async (addresses, departureTime) => {
    let travelTimeSeconds
    let departure = departureTime

    if (typeof(departure) !== "string") {
      departure = departure.toISOString()
    }

    const waypoints = []
    let count = 0

    for (const address of addresses) {
      console.dir(address)

      waypoints.push(`waypoint${ count }=geo!${ address.latitude },${ address.longitude }`)
      count++
    }

    const query = `${URL}?apiKey=${ process.env.REACT_APP_HERE_API_KEY }&\
departure=${ departure }&\
${ waypoints.join("&") }&\
mode=car;balanced;traffic:enabled&\
routeAttributes=sm&\
representation=overview`

    await client.makeApiCall(
      'get', query, {},
      null,
      null,
      async (response) => {
        travelTimeSeconds = response.response.route[0].summary.travelTime
      }
    )

    return travelTimeSeconds
  }
}
