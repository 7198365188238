// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Audit {
    /**
     * unique id of this Audit, immutable.
     */
    id = null

    @observable createdAt
    @observable auditableId
    @observable auditableType
    @observable userId
    @observable userType
    @observable action
    @observable auditedChanges
    @observable user

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:             this.id,
        createdAt:      this.createdAt,
        auditableId:    this.auditableId,
        auditableType:  this.auditableType,
        userId:         this.userId,
        userType:       this.userType,
        action:         this.action,
        auditedChanges: this.auditedChanges,
        user:           this.user,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.createdAt      = data.attributes.createdAt
      this.auditableId    = data.attributes.auditableId
      this.auditableType  = data.attributes.auditableType
      this.userId         = data.attributes.userId
      this.userType       = data.attributes.userType
      this.action         = data.attributes.action
      this.auditedChanges = data.attributes.auditedChanges
      this.user           = data.attributes.user

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
