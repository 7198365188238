import { action, decorate } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Button, Modal
} from 'reactstrap';

export const BookingStopsModal = observer(class BookingStopsModal extends Component {
  onDragEnd = (result) => {
    // dropped outside the list or no movement
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    this.reorder(this.props.stops, result.source.index, result.destination.index)
  }

  reorder = (array, a, b) => {
    [array[a], array[b]] = [array[b], array[a]]
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Reorder Waypoints
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="stops">
              { (provided) => (
                <ul
                  className="stops list-group"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {this.props.stops.map((stop, index) => {
                    return (
                      <Draggable key={stop.id} draggableId={stop.id} index={index}>
                        { (provided) => (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
                            style={{ cursor: "pointer" }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <span className="badge badge-primary badge-pill">{index+1}</span>
                            {stop.label} - {stop.line1}
                            <i className="fa fa-fw fa-bars" />
                          </li>
                        ) }
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              ) }
            </Droppable>
          </DragDropContext>

          <p className="mt-4 text-sm">Note: If you don't Save it will not update the position even though the table will show the order you modified.</p>

          <Button color="primary" type="submit" className="mt-2" onClick={this.props.submitHandler}>Save Order</Button>
        </div>

      </Modal>
    );
  }
});

BookingStopsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  stops: PropTypes.arrayOf(PropTypes.object),
  submitHandler: PropTypes.func,
}

decorate(BookingStopsModal, {
  reorder: action,
});
