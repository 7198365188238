import { action, observable } from "mobx"
import { apiCall } from "../constants/apiCall"
import { InsurancePolicy } from "../models/InsurancePolicy"

export class insurancePoliciesStore {
	@observable insurancePolicies = new Map()

  @action clear() {
    this.insurancePolicies.clear()
  }

  @action create = async (url: string, data: object) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: "POST",
      body: JSON.stringify(data),
    })

    if (response.error) {
      return response
    } else {
      const insurancePolicy = new InsurancePolicy(response.data)
      this.insurancePolicies.set(insurancePolicy.id, insurancePolicy)
      return insurancePolicy
    }
  }

  @action update = async(id: string, data: object) => {
    const insurancePolicy = this.insurancePolicies.get(id)

    if (!insurancePolicy) {
      return "error"
    }

    // clients/:client_id/addresses/:id OR /addresses/:id
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/insurance_policies/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    })

    if (response.error) {
      return response
    } else {
      insurancePolicy.updateFromJson(response.data)
      return insurancePolicy
    }
  }

  @action destroy = async(id: string) => {
    const insurancePolicy = this.insurancePolicies.get(id)

    if (!insurancePolicy) {
      return "error"
    }

    await apiCall(`${process.env.REACT_APP_API_URL}/insurance_policies/${id}`, {
      method: "DELETE",
    })

    this.insurancePolicies.delete(id)
    return true
  }

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: "GET",
    })

    if (response.error) {
      return response
    } else {
      for (const insurancePolicyData of response.data) {
        this.insurancePolicies.set(insurancePolicyData.id, new InsurancePolicy(insurancePolicyData))
      }
      return this.insurancePolicies
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/insurance_policies/${id}`, {
      method: "GET",
    })

    if (response.error) {
      return response
    } else {
      const insurancePolicy = new InsurancePolicy(response.data)
      this.insurancePolicies.set(response.data?.id, insurancePolicy)
      return insurancePolicy
    }
  }
}
