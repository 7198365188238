import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Badge, Button } from 'reactstrap';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { AlertModal } from '../../components/Modals/AlertModal';
import { ConfirmationModal } from '../../components/Modals/ConfirmationModal';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { formatters } from '../../helpers/Formatters';
import { Card } from '../../models/Card';
import { CardModal } from '../Modals/CardModal';
export const CreditCardsTable = observer(class CreditCardsTable extends Component {
  filter

  addModalOpen = false
  _confirmationModalOpen = false
  _alertModalOpen = false

  componentDidMount() {
    this.tableRef = React.createRef();
  }

  idFormatter = (cell, _row) => (
    <>
      <span>{cell.split('-')[0]}</span>
      <ClipboardLink text={cell} />
    </>
  )

  expiryFormatter = (_cell, row) => {
    return `${row.expMonth}/${row.expYear}`
  }

  defaultFormatter = (cell, _row) => {
    return cell ? 'Default' : ''
  }

  makeDefault = async (row) => {
    const data = {
      card: {
        default: true,
      },
    };

    client.makeApiCall(
      'put', `/cards/${row.id}`, data,
      'Card set as default payment',
      'Unable to set card as default payment',
      () => runInAction(() => row.isDefault = true)
    )
  }

  defaultButton = (_cell, row) => {
    if (!row.default) {
      return (<Button color="success" onClick={() => { this.makeDefault(row); }}>Make Default</Button>);
    }
    return null;
  }

  statusFormatter = (cell, _row) => {
    switch (cell) {
      case 'active':
        return <Badge color="success" className="mr-1">{ cell.toUpperCase() }</Badge>;
      case 'removed': case 'expired':
        return <Badge className="badge-soft-info mr-1">{ cell.toUpperCase() }</Badge>;
      case 'flagged':
        return <Badge color="danger" className="mr-1">{ cell.toUpperCase() }</Badge>;
      default:
        return <Badge color="danger" className="mr-1">unknown</Badge>;
    }
  }

  _toggleConfirmationModalOn = async (selectedRows) => {
    if (selectedRows.length === 0) {
      this._toggleAlertModal()
    } else {
      this._selectedCards = selectedRows
      this._confirmationModalOpen = !this._confirmationModalOpen
    }
  }

  _toggleAlertModal = () => this._alertModalOpen = !this._alertModalOpen
  _toggleConfirmationModal = () => {
    this._confirmationModalOpen = !this._confirmationModalOpen
    if (!this._confirmationModalOpen) { this._selectedCards = null }
  }

  _removeCards = async () => {
    await Promise.allSettled(this._selectedCards.map(async (row) => {
      await client.makeApiCall(
        'delete', `/cards/${row.id}`, null,
        'Card removed',
        `Unable to remove card ${ row.id }`,
        this.tableRef.current.fetchData
      )
    }))
    this._toggleConfirmationModal()
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'label',
      name: 'Label',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'stripeId',
      name: 'Stripe ID',
      priority: 1,
    },
    {
      dataField: 'brand',
      name: 'Brad',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'expMonth',
      name: 'Expiry',
      priority: 1,
      formatter: this.expiryFormatter,
      sort: true,
    },
    {
      dataField: 'last4',
      name: 'Last 4 Digits',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'isDefault',
      name: 'Default',
      priority: 1,
      formatter: formatters.defaultFormatter,
      sort: true,
      sortKey: 'default',
    },
    {
      dataField: 'status',
      name: 'Status',
      priority: 1,
      formatter: this.statusFormatter,
      sort: true,
    },
    {
      name: 'Removed?',
      priority: 1,
      dataField: 'removed',
      formatter: formatters.removedFormatter,
    },
    {
      dataField: '',
      name: '',
      priority: 1,
      formatter: this.defaultButton
    },
  ]

  render() {
    return (
      <>
        <ConfirmationModal
          isOpen={ this._confirmationModalOpen }
          toggle={ this._toggleConfirmationModal }
          header={ 'Remove Cards?' }
          body={ 'Are you sure you want to remove this/these card(s)?' }
          confirm={ this._removeCards }
          confirmButtonText={ 'Remove' }
          closeButtonText={ 'Cancel' }
        />

        <AlertModal
          isOpen={ this._alertModalOpen }
          toggle={ this._toggleAlertModal }
          header={ 'Error' }
          body={ 'No cards selected.' }
        />

        <CardModal
          isOpen={this.addModalOpen}
          toggle={() => { runInAction(() => { this.addModalOpen = !this.addModalOpen; }); }}
          title={`Add New Credit Card`}
          dataUrl={this.props.dataUrl}
          client={this.props.client}
        />

        <MyTable
          model={Card}
          dataUrl={this.props.dataUrl}

          breadcrumbBaseTitle={this.props.baseTitle || 'Credit Cards'}
          dataColumns={this.dataColumns}
          embedded
          searchable
          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}
          defaultLimit={this.props.defaultLimit}

          singleActions={this.props.addEnabled && {
            'Add +': () => runInAction(() => { this.addModalOpen = !this.addModalOpen; })
          }}

          multiActions={{
            'Remove from Account': (rows) => {
              this._toggleConfirmationModalOn(rows)
            }
          }}
          ref={this.tableRef}
        />
      </>
    );
  }
});

decorate(CreditCardsTable, {
  addModalOpen: observable,
  _toggleConfirmationModalOn: action,
  _toggleConfirmationModal: action,
  _confirmationModalOpen: observable,
  _alertModalOpen: observable,
  _toggleAlertModal: action,
  _removeCards: action,
});
