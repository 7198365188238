import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  Row,
} from 'reactstrap'

export const DriverModal = observer(
  class DriverModal extends Component {
    render() {
      return (
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {this.props.title}
            </h5>

            <button
              type="button"
              onClick={this.props.toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.props.submitHandler}
              model={
                this.props.driver || {
                  status: 'applied',
                  talkingType: 'average',
                  drivingType: 'average',
                }
              }
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <AvField
                      name="name"
                      placeholder="Name"
                      type="text"
                      className="form-control"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Name is required',
                        },
                      }}
                      id="name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <AvField
                      name="email"
                      placeholder="Email"
                      type="email"
                      className="form-control"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Email is required',
                        },
                      }}
                      id="email"
                    />
                  </FormGroup>
                  <AvGroup>
                    <AvField
                      type="select"
                      name="qualificationTier"
                      label="Qualified For"
                      id="qualificationTier"
                    >
                      <option>dryver</option>
                      <option>jeevz</option>
                    </AvField>
                  </AvGroup>
                  <AvGroup>
                    <AvField
                      type="select"
                      name="gender"
                      label="Gender"
                      id="gender"
                    >
                      <option>undefined</option>
                      <option>male</option>
                      <option>female</option>
                    </AvField>
                  </AvGroup>
                  <FormGroup>
                    <Label htmlFor="phoneNumber">Phone Number</Label>
                    <AvField
                      name="phoneNumber"
                      placeholder="Phone Number"
                      type="tel"
                      className="form-control"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Phone number is required',
                        },
                      }}
                      id="phoneNumber"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="zipCode">Zip Code</Label>
                    <AvField
                      name="zipCode"
                      placeholder="Zip Code"
                      type="text"
                      className="form-control"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Zip code is required',
                        },
                        pattern: {
                          value: /^\d{5}(?:[-\s]\d{4})?$/,
                          errorMessage:
                            'Zip code must be XXXXX or XXXXX-XXXX format',
                        },
                      }}
                      id="zipCode"
                    />
                  </FormGroup>
                  <AvGroup>
                    <AvField
                      type="select"
                      name="status"
                      label="Status"
                      id="driverStatus"
                    >
                      <option>applied</option>
                      <option>rejected</option>
                      <option>probationary</option>
                      <option>active</option>
                      <option>removed</option>
                      <option>banned</option>
                    </AvField>
                  </AvGroup>
                  <FormGroup>
                    <Label htmlFor="hourlyRate">Hourly Pay Rate $</Label>
                    <AvField
                      name="hourlyRate"
                      placeholder="Hourly Pay Rate $"
                      validate={{ required: { value: true }, number: true }}
                      type="text"
                      className="form-control"
                      id="hourlyRate"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="talkingType">Talking Type</Label>
                    <AvField type="select" name="talkingType" id="talkingType">
                      <option>quiet</option>
                      <option>average</option>
                      <option>talkative</option>
                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="drivingType">Driving Type</Label>
                    <AvField type="select" name="drivingType" id="drivingType">
                      <option>slow</option>
                      <option>average</option>
                      <option>fast</option>
                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    <AvInput
                      type="checkbox"
                      label="Is driver a smoker?"
                      id="smokes"
                      name="smokes"
                      tag={CustomInput}
                    />
                  </FormGroup>

                  <FormGroup>
                    <AvInput
                      type="checkbox"
                      label="Does driver wear cologne?"
                      id="wearsCologne"
                      name="wearsCologne"
                      tag={CustomInput}
                    />
                  </FormGroup>

                  <FormGroup>
                    <AvInput
                      type="checkbox"
                      label="Can driver drive stick/manual transmission?"
                      id="drivesManual"
                      name="drivesManual"
                      tag={CustomInput}
                    />
                  </FormGroup>

                  <FormGroup>
                    <AvInput
                      type="checkbox"
                      label="Can driver drive large vehicles?"
                      id="largeVehicles"
                      name="largeVehicles"
                      tag={CustomInput}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="password">Password</Label>
                    <AvField
                      name="password"
                      placeholder="Password"
                      type="password"
                      className="form-control"
                      validate={{
                        required: {
                          value: !this.props.driver,
                          errorMessage: 'Password is required for new drivers',
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            'Password must be at least 6 characters',
                        },
                      }}
                      id="password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="passwordConfirmation">
                      Password Confirmation
                    </Label>
                    <AvField
                      name="passwordConfirmation"
                      placeholder="Confirm Password"
                      type="password"
                      validate={{
                        required: {
                          value: !this.props.driver,
                          errorMessage: 'Please enter password confirmation',
                        },
                        match: {
                          value: 'password',
                          errorMessage:
                            'Password confirmation must match Password',
                        },
                      }}
                      id="passwordConfirmation"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" type="submit">
                Submit form
              </Button>
            </AvForm>
          </div>
        </Modal>
      )
    }
  },
)

decorate(DriverModal, {})
