// Import Breadcrumb
import { timeAgoInWords } from '@bluemarblepayroll/time-ago-in-words'
import { AvatarModal } from '@components/Modals/AvatarModal'
import { DriverModal } from '@components/Modals/DriverModal'
import { AdminNotesTable } from '@components/Tables/AdminNotesTable'
import { BankAccountsTable } from '@components/Tables/BankAccountsTable'
import { EarningsTable } from '@components/Tables/EarningsTable'
import { PayoutsTable } from '@components/Tables/PayoutsTable'
import { RatingsTable } from '@components/Tables/RatingsTable'
import { TipsTable } from '@components/Tables/TipsTable'
import { Driver } from '@models/Driver'
import { action, computed, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import Avatar from 'react-avatar'
import {
  Badge,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap'
import strftime from 'strftime'
import Breadcrumbs from '~/components/Common/Breadcrumb'
import { client } from '~/helpers/client'
import { titleize } from '~/helpers/titleize'
import { BookingsTable } from '~/pages/Bookings/BookingsTable'
import { ClientsTable } from '~/pages/Clients/ClientsTable'
// Import mini card widgets
import MiniCards from '../Contacts/mini-card'
import ChartMiniCard from './ChartMiniCard'
import dryverLogo from '../../assets/images/dryver-icon.png'
import jeevzLogo from '../../assets/images/jeevz-icon.png'

const TRIP_TYPES = ['Round Trip', 'One Way', 'Airport', 'Overnighter', 'Other']
const OFFER_TYPES = [
  'Pending',
  'Expired',
  'Accepted',
  'Rejected',
  'Terminated',
  'Cancelled',
]

export const DriverShow = observer(
  class DriverShow extends Component {
    driver

    showActionDropdown = false

    showSubscriptionModal = false

    constructor(props) {
      super(props)
      this.driverId = this.props.location.pathname.replace('/driver/', '')
      this.fetchData()
    }

    fetchData = async () => {
      let result

      try {
        result = await client.get(`/drivers/${this.driverId}`)
        this.driver = new Driver(result.data)
        this.loaded = true
      } catch {
        this.errorVisible = true
        this.errorMessage = 'Unable to fetch driver data. Please reload page.'
      }
    }

    updateDriver = async (_e, values) => {
      const data = {
        driver: {
          name: values.name,
          email: values.email,
          gender: values.gender,
          phone_number: values.phoneNumber,
          hourly_pay_rate_cents: values.hourlyRate && values.hourlyRate * 100,
          zip_code: values.zipCode,
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          status: values.status,
          qualification_tier: values.qualificationTier,
          preferences_attributes: {
            talking_type: values.talkingType,
            driving_type: values.drivingType,
            smokes: values.smokes,
            wears_cologne: values.wearsCologne,
            drives_manual: values.drivesManual,
            large_vehicles: values.largeVehicles,
          },
        },
      }

      Object.keys(data).forEach((key) => data[key] === null && delete data[key])

      const result = await client.makeApiCall(
        'put',
        `/drivers/${this.driver.id}`,
        data,
        'Driver updated successfully',
        'Unable to update driver',
      )

      if (result.data.type !== 'errors') {
        this.driver = new Driver(result.data)
        this.toggleDriverModal()
      }
    }

    toggleDriverModal = () => {
      this.showDriverModal = !this.showDriverModal
    }

    toggleAvatarModal = () => {
      this.showAvatarModal = !this.showAvatarModal
    }

    get driverFor() {
      if (this.driver?.createdAt) {
        return timeAgoInWords(new Date(this.driver.createdAt)).replace(
          ' ago',
          '',
        )
      } else {
        return 'Unknown'
      }
    }

    get lastTripDate() {
      if (this.driver?.lastTripDate) {
        const date = new Date(this.driver.lastTripDate)
        const timeAgo = timeAgoInWords(date)
        return `${strftime('%B %d, %Y', date)} (${timeAgo})`
      } else {
        return 'never'
      }
    }

    get driverRideBreakDown() {
      return this.driver?.rideBreakdown
        ? [
            this.driver.rideBreakdown.roundTrip || 0,
            this.driver.rideBreakdown.oneWay || 0,
            this.driver.rideBreakdown.airport || 0,
            this.driver.rideBreakdown.overnighter || 0,
            this.driver.rideBreakdown.other || 0,
          ]
        : []
    }

    get driverOfferBreakDown() {
      return this.driver?.offerBreakdown
        ? [
            this.driver.offerBreakdown.pending || 0,
            this.driver.offerBreakdown.expired || 0,
            this.driver.offerBreakdown.accepted || 0,
            this.driver.offerBreakdown.rejected || 0,
            this.driver.offerBreakdown.terminated || 0,
            this.driver.offerBreakdown.cancelled || 0,
          ]
        : []
    }

    renderChart = (chart) => {
      if (this.driver) {
        let { title, series, labels } =
          chart === 'rideBreakdown'
            ? {
                title: 'Ride Breakdown',
                series: this.driverRideBreakDown,
                labels: TRIP_TYPES,
              }
            : {
                title: 'Offer Breakdown',
                series: this.driverOfferBreakDown,
                labels: OFFER_TYPES,
              }
        if (series.reduce((accu, actualValue) => accu + actualValue, 0) <= 1) {
          return (
            <MiniCards
              title={title}
              text="Not enough data"
              iconClass="bx-analyse"
              key={`_no_data_${chart}`}
            />
          )
        }
        return (
          <Col xl="4">
            <ChartMiniCard
              title={title}
              series={series}
              labels={labels}
              iconClass="bx-analyse"
              key={`_chart_ob_${chart}`}
            />
          </Col>
        )
      }
    }

    render() {
      return (
        <>
          {this.driver && (
            <>
              <DriverModal
                driver={this.driver}
                isOpen={this.showDriverModal}
                toggle={this.toggleDriverModal}
                title="Edit Driver"
                submitHandler={this.updateDriver}
              />
              <AvatarModal
                driver={this.driver}
                isOpen={this.showAvatarModal}
                toggle={this.toggleAvatarModal}
                title="Upload Photo"
                dataUrl={`/drivers/${this.driver.id}`}
              />
            </>
          )}

          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Drivers" breadcrumbItem="Profile" />

              <Row>
                <Col xl="4">
                  <Card className="overflow-hidden">
                    <div className="bg-soft-primary">
                      <Row>
                        <Col xs="12" className="align-self-end">
                          <div className="text-primary p-3 float-right">
                            <ButtonDropdown
                              isOpen={this.showActionDropdown}
                              toggle={() =>
                                runInAction(() => {
                                  this.showActionDropdown =
                                    !this.showActionDropdown
                                })
                              }
                            >
                              <DropdownToggle
                                caret
                                color="secondary"
                                className="btn btn-secondary btn-sm"
                              >
                                Actions &nbsp;{' '}
                                <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-right">
                                <DropdownItem
                                  key="editDriverAction"
                                  onClick={this.toggleDriverModal}
                                >
                                  Edit Driver Data
                                </DropdownItem>
                                {this.driver?.stripeId && (
                                  <DropdownItem
                                    key="viewStripeAction"
                                    onClick={() =>
                                      window.open(
                                        `https://dashboard.stripe.com/accounts/${this.driver.stripeId}`,
                                        '_blank',
                                      )
                                    }
                                  >
                                    View Stripe Data
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </ButtonDropdown>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="6">
                          <div className="avatar-md profile-user-wid mb-4">
                            <Avatar
                              onClick={this.toggleAvatarModal}
                              name={this.driver?.name}
                              src={this.driver?.avatarUrl}
                              size={64}
                              className="object-fit-cover"
                              round
                            />
                          </div>
                          <h5 className="font-size-15 text-truncate">
                            {this.driver?.name}
                          </h5>
                          <p className="text-primary font-weight-semibold font-size-14 mb-0 mt-1 ">
                            Avg Rating:{' '}
                            {this.driver?.rating
                              ? `${this.driver.rating}/5`
                              : ''}
                          </p>
                          <p className="text-muted mb-0 text-truncate">
                            {this.driver?.status
                              ? titleize(this.driver.status)
                              : ''}
                          </p>
                          {this.driver?.qualificationTier && (
                            <div className="mt-4">
                              <p className="font-weight-semibold font-size-14 mb-0 mt-1 ">
                                Qualified for:
                              </p>

                              <div className="d-flex ">
                                <img
                                  src={
                                    this.driver?.qualificationTier === 'dryver'
                                      ? dryverLogo
                                      : jeevzLogo
                                  }
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                  }}
                                />
                                {titleize(this.driver?.qualificationTier)}
                              </div>
                            </div>
                          )}
                        </Col>

                        <Col sm={6}>
                          <div className="pt-4">
                            <Row>
                              <Col xs="6">
                                <h5 className="font-size-15">
                                  {this.driver?.tripsCount || '0'}
                                </h5>
                                <p className="text-muted mb-0">Rides</p>
                              </Col>
                              <Col xs="6">
                                <h5 className="font-size-15">
                                  {this.driver?.totalEarnings?.pretty || '$0'}
                                </h5>
                                <p className="text-muted mb-0">Earnings</p>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col xs="6">
                                <h5 className="font-size-15">
                                  {this.driver?.hourlyPayRate?.pretty || '$0'}
                                </h5>
                                <p className="text-muted mb-0">Hourly Rate</p>
                              </Col>
                              <Col xs="6" />
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="4">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        Personal Information
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Full Name :</th>
                              <td>{this.driver?.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Email :</th>
                              <td>
                                <a href={`mailto:${this.driver?.email}`}>
                                  {this.driver?.email}
                                </a>
                              </td>
                            </tr>

                            {this.driver?.phoneNumber && (
                              <tr>
                                <th scope="row">Phone :</th>
                                <td>
                                  <a href={`tel:${this.driver?.phoneNumber}`}>
                                    {this.driver?.phoneNumber}
                                  </a>
                                </td>
                              </tr>
                            )}

                            {this.driver?.zipCode && (
                              <tr>
                                <th scope="row">Location :</th>
                                <td>{this.driver?.zipCode}</td>
                              </tr>
                            )}

                            {this.driver?.birthdate && (
                              <tr>
                                <th scope="row">Birthdate :</th>
                                <td>{this.driver?.birthdate}</td>
                              </tr>
                            )}

                            {this.driver?.acceptedTosAt && (
                              <tr>
                                <th scope="row">Accepted ToS At :</th>
                                <td>
                                  {strftime(
                                    '%c',
                                    new Date(this.driver.acceptedTosAt),
                                  )}
                                </td>
                              </tr>
                            )}

                            {this.driver?.deviceData && (
                              <tr>
                                <th scope="row">Device Info :</th>
                                <td>
                                  {Object.keys(this.driver.deviceData).map(
                                    (key) => (
                                      <div>
                                        <strong>{key}:</strong>{' '}
                                        {JSON.stringify(
                                          this.driver.deviceData[key],
                                        )}
                                      </div>
                                    ),
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="4">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Driver Preferences</CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Talking :</th>
                              <td>
                                {titleize(
                                  this.driver?.preferences?.data?.attributes
                                    ?.talkingType || '',
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Driving :</th>
                              <td>
                                {titleize(
                                  this.driver?.preferences?.data?.attributes
                                    ?.drivingType || '',
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Smokes? :</th>
                              <td>
                                {this.driver?.preferences?.data?.attributes
                                  ?.smokes ? (
                                  <Badge color="success" className="mr-1">
                                    Yes
                                  </Badge>
                                ) : (
                                  <Badge color="danger" className="mr-1">
                                    No
                                  </Badge>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Cologne? :</th>
                              <td>
                                {this.driver?.preferences?.data?.attributes
                                  ?.wearsCologne ? (
                                  <Badge color="success" className="mr-1">
                                    Yes
                                  </Badge>
                                ) : (
                                  <Badge color="danger" className="mr-1">
                                    No
                                  </Badge>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Can Drive Manual? :</th>
                              <td>
                                {this.driver?.preferences?.data?.attributes
                                  ?.drivesManual ? (
                                  <Badge color="success" className="mr-1">
                                    Yes
                                  </Badge>
                                ) : (
                                  <Badge color="danger" className="mr-1">
                                    No
                                  </Badge>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Can Driver Large Vehicles? :</th>
                              <td>
                                {this.driver?.preferences?.data?.attributes
                                  ?.largeVehicles ? (
                                  <Badge color="success" className="mr-1">
                                    Yes
                                  </Badge>
                                ) : (
                                  <Badge color="danger" className="mr-1">
                                    No
                                  </Badge>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Row>
                    <MiniCards
                      title="Zip Code"
                      text={this.driver?.zipCode || 'Unknown'}
                      iconClass="bx-map"
                      key="_card_ltv"
                    />
                    <MiniCards
                      title="Driver For"
                      text={this.driverFor}
                      iconClass="bx-calendar"
                      key="_card_memberSince"
                    />
                    <MiniCards
                      title="Last Ride"
                      text={this.lastTripDate}
                      iconClass="bx-car"
                      key="_card_lastTrip"
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <CardTitle className="col-md-4 my-4">Driver Stats</CardTitle>
              </Row>
              <Row>
                <MiniCards
                  title="Month trips average"
                  text={this.driver?.monthlyRideAverage || 'Unknown'}
                  iconClass="bx-map"
                  key="_card_mta"
                />
                <MiniCards
                  title="Response time average"
                  text={this.driver?.avgResponseTime}
                  iconClass="bx-map"
                  key="_card_art"
                />
              </Row>
              <Row>
                {this.renderChart('rideBreakdown')}
                {this.renderChart('offerBreakdown')}
              </Row>
              <Row>
                <Col xl="12">
                  {this.driver && (
                    <BookingsTable
                      dataUrl={`/drivers/${this.driver.id}/bookings?sort_column=requested_start_time&sort_dir=desc`}
                      embedded
                      defaultLimit={10}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.driver && (
                    <AdminNotesTable
                      dataUrl={`/drivers/${this.driver.id}/admin_notes`}
                      embedded
                      defaultLimit={10}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.driver && (
                    <PayoutsTable
                      dataUrl={`/users/${this.driver.id}/stripe_transfers`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Payouts"
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.driver && (
                    <EarningsTable
                      dataUrl={`/drivers/${this.driver.id}/earnings`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Earnings"
                      // actions={{
                      //   Remove: (rows) => {
                      //     console.log(rows);
                      //   },
                      // }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.driver && (
                    <TipsTable
                      dataUrl={`/drivers/${this.driver.id}/tips`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Tips"
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.driver && (
                    <RatingsTable
                      dataUrl={`/drivers/${this.driver.id}/ratings?type=received`}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.driver && (
                    <BankAccountsTable
                      dataUrl={`/users/${this.driver.id}/bank_accounts`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Bank Accounts (Payout dest)"
                      addEnabled={true}
                      driver={this.driver}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.driver && (
                    <ClientsTable
                      dataUrl={`/drivers/${this.driver.id}/favorite_clients`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Favorite Clients"
                      // actions={{
                      //   Remove: (rows) => {
                      //     console.log(rows);
                      //   },
                      // }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.driver && (
                    <ClientsTable
                      dataUrl={`/drivers/${this.driver.id}/blocked_clients`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Blocked Clients"
                      // actions={{
                      //   Remove: (rows) => {
                      //     console.log(rows);
                      //   },
                      // }}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )
    }
  },
)

decorate(DriverShow, {
  driver: observable,
  fetchData: action,
  showActionDropdown: observable,
  showSubscriptionModal: observable,
  updateDriver: action,
  toggleDriverModal: action,
  showDriverModal: observable,
  toggleAvatarModal: action,
  showAvatarModal: observable,
  lastTripDate: computed,
})
