import { AvField, AvForm } from 'availity-reactstrap-validation'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Button, Col, FormGroup, Label, Modal, Row } from 'reactstrap'

export const PermanentNoteModal = observer(
  class PermanentNoteModal extends Component {
    render() {
      return (
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {this.props.title}
            </h5>

            <button
              type="button"
              onClick={this.props.toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.props.submitHandler}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="body">Note</Label>
                    <AvField
                      name="body"
                      placeholder="Note"
                      type="textarea"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="body"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" type="submit">
                Submit form
              </Button>
            </AvForm>
          </div>
        </Modal>
      )
    }
  },
)

decorate(PermanentNoteModal, {})
