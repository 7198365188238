import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { titleize } from '../../helpers/titleize';
import { ShortUrlsTable } from '../../components/Tables/ShortUrlsTable';

export const ShortUrlsIndex = observer(class ShortUrlsIndex extends Component {
  filter

  constructor(props) {
    super(props);

    switch (this.props.location.pathname) {
      case '/short_urls':
        this.filter = null;
        break;
      default:
        this.filter = this.props.location.pathname.replace('/short_urls/', '');
    }
  }

  get title() {
    return titleize(this.filter || 'All');
  }

  render() {
    return (
      <ShortUrlsTable
        filter={this.filter}
        dataUrl="/short_urls"
        baseTitle="Short Urls"
        title={this.title}
        addEnabled={true}
      />
    );
  }
});

decorate(ShortUrlsIndex, {
  filter: observable,
  title: computed,
});
