// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Vehicle {
    /**
     * unique id of this Vehicle, immutable.
     */
    id = null

    @observable make
    @observable model
    @observable vin
    @observable color
    @observable year
    @observable manualTransmission
    @observable licensePlateNumber
    @observable availableForP2P
    @observable fuelType
    @observable classification
    @observable insurancePolicy
    @observable address
    @observable dailyRate
    @observable hourlyRate
    @observable isDefault
    @observable createdAt
    @observable updatedAt

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:                 this.id,
        make:               this.make,
        model:              this.model,
        vin:                this.vin,
        color:              this.color,
        year:               this.year,
        manualTransmission: this.manualTransmission,
        licensePlateNumber: this.licensePlateNumber,
        availableForP2P:    this.availableForP2P,
        fuelType:           this.fuelType,
        classification:     this.classification,
        dailyRate:          this.dailyRate,
        hourlyRate:         this.hourlyRate,
        insurancePolicy:    this.insurancePolicy,
        address:            this.address,
        isDefault:          this.isDefault,
        createdAt:          this.createdAt,
        updatedAt:          this.updatedAt,
      }
    }

    @computed get hourlyRateFormatted() {
      return this.hourlyRate.cents / 100
    }

    @computed get dailyRateFormatted() {
      return this.dailyRate.cents / 100
    }

    @computed get insurancePolicyId() {
      return this.insurancePolicy?.id
    }

    @computed get addressId() {
      return this.address?.id
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.make               = data.attributes.make
      this.model              = data.attributes.model
      this.vin                = data.attributes.vin
      this.color              = data.attributes.color
      this.year               = data.attributes.year
      this.manualTransmission = data.attributes.manualTransmission
      this.licensePlateNumber = data.attributes.licensePlateNumber
      this.availableForP2P    = data.attributes.availableForP2P
      this.fuelType           = data.attributes.fuelType
      this.classification     = data.attributes.classification
      this.dailyRate          = data.attributes.dailyRate
      this.hourlyRate         = data.attributes.hourlyRate
      this.insurancePolicy    = data.attributes.insurancePolicy
      this.address            = data.attributes.address
      this.isDefault          = data.attributes.default
      this.createdAt          = data.attributes.createdAt
      this.updatedAt          = data.attributes.updatedAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
