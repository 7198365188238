export async function apiCall(url, opts = {}) {
  const currentUser = JSON.parse(localStorage.getItem('authUser'));
  const { jwt } = currentUser;

  const response = await fetch(url, {
    ...opts,
    headers: {
      Accept: 'application/json',
      'Content-Type': opts.contentType || 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }); const payload = await response.json();

  return payload;
}
