import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { postJwtProfile } from '../../../helpers/fakebackend_helper';
import { profileError } from './actions';
// Login Redux States
import { EDIT_PROFILE } from './actionTypes';

function* editProfile({ payload: { user } }) {
  const payload = { admin: {} };
  const currentUser = JSON.parse(localStorage.getItem('authUser'));

  if (user.name) { payload.admin.name = user.name; }
  if (user.password) {
    payload.admin.password = user.password;
    payload.admin.password_confirmation = user.passwordConfirmation;
  }
  if (user.phoneNumber) { payload.admin.phone_number = user.phoneNumber; }

  try {
    const response = yield call(postJwtProfile, `${process.env.REACT_APP_API_URL}/admins/${currentUser.id}`, payload);
    currentUser.name = response.data.attributes.name;
    localStorage.setItem('authUser', JSON.stringify(currentUser));
    window.location.reload();
  } catch (error) {
    yield put(profileError(error));
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

function* ProfileSaga() {
  yield all([
    fork(watchProfile),
  ]);
}

export default ProfileSaga;
