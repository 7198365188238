import { AvField, AvForm } from 'availity-reactstrap-validation';
import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';

export const EarningModal = observer(class EarningModal extends Component {
  get editable() {
    return !['paid', 'cancelled'].includes(this.props?.earning?.status)
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.earning}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="amount">Earning Amount $</Label>
                  <AvField
                    disabled={!this.editable}
                    name="amount"
                    placeholder="Earning Amount $"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="amount"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="notes">Notes</Label>
                  <AvField
                    disabled={!this.editable}
                    name="notes"
                    placeholder="Notes (admin/internal only)"
                    type="text"
                    className="form-control"
                    id="notes"
                  />
                </FormGroup>
              </Col>
            </Row>
            { this.editable && <Button color="primary" type="submit">Submit form</Button> }
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(EarningModal, {
  editable: computed,
});
