import _ from 'lodash';
import { myToast } from '../components/Common/MyToast';
import * as Sentry from "@sentry/react";

let API_BASE_URL;

API_BASE_URL = process.env.REACT_APP_API_URL;

const convertKeysToCamelCase = (obj) => {
  if (!obj || typeof obj !== 'object') return obj;

  if (obj instanceof Array) {
    return _.map(obj, (value) => convertKeysToCamelCase(value));
  }

  const newObj = {};
  _.mapKeys(obj, (value, key) => {
    newObj[_.camelCase(key)] = convertKeysToCamelCase(value);
  });

  return newObj;
};

const constructQueryString = (data) => _.map(data, (v, k) => {
  if (v instanceof Array) {
    return _.map(v, (value) => `${encodeURIComponent(k)}=${encodeURIComponent(value)}`).join('&');
  }

  return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
}).join('&');

class apiClient {
  constructor(options = {}) {
    this.baseUrl = API_BASE_URL;
  }

  fetch = (path, method, params, headersData) => {
    let body;
    let modifiedPath;
    let contentType;

    if (method === 'GET' || method === 'DELETE') {
      modifiedPath = `${path}${
        path.includes('?') ? '&' : '?'
      }${constructQueryString(params)}`;
    } else if (method === 'POST' || method === 'PUT') {
      if (params instanceof FormData) {
        body = params;
      } else {
        body = JSON.stringify(params);
        contentType = 'application/json';
      }
    }

    const headers = new Headers({
      Accept: 'application/json',
      ...headersData,
    });

    if (contentType) {
      headers.set('Content-Type', contentType);
    }

    const currentUser = JSON.parse(localStorage.getItem('authUser'));

    // only include the JWT auth for calls to base API
    if (currentUser && !(modifiedPath || path).includes("http")) {
      const { jwt } = currentUser;
      headers.set('Authorization', `Bearer ${jwt}`);
    }

    let statusCode = null;
    return new Promise((resolve, reject) => {
      let fullUrl

      if ((modifiedPath || path).includes("http")) {
        fullUrl = (modifiedPath || path)
      } else {
        fullUrl = this.baseUrl + (modifiedPath || path)
      }

      fetch(fullUrl, {
        method,
        body,
        headers,
        redirect: 'follow',
      })
        .then((response) => {
          if (response.status === 204) {
            return resolve();
          }

          let parsedResponse;
          statusCode = response.status;

          if (response.status >= 200 && response.status < 300) {
            if (response.headers.get('Content-Type') === 'image/jpg') {
              parsedResponse = response.blob();
              return parsedResponse.then((data) => {
                resolve(data);
              });
            }
            parsedResponse = response.json();
            return parsedResponse.then((data) => {
              resolve(convertKeysToCamelCase(data));
            });
          }

          parsedResponse = response.json();
          return parsedResponse.then(Promise.reject.bind(Promise));
        })
        .catch((err) => {
          let error;

          if (err.error === 'invalid_jwt') {
            window.location = '/login'
          }

          if (err?.data?.type === 'errors') {
            let message;

            message = err.data.attributes.messages.join(', ')
            error = {
              message,
              response: err,
              statusCode,
            };
          } else {
            console.log('-----------')
            console.dir(err)
            error = {
              message: 'Something went wrong.',
              response: null,
              statusCode,
            };
          }

          reject(error);
        });
    });
  };

  post = (path, data, headers = {}) => this.fetch(path, 'POST', data, headers);

  put = (path, data, headers = {}) => this.fetch(path, 'PUT', data, headers);

  get = (path, data, headers = {}) => this.fetch(path, 'GET', data, headers);

  delete = (path, data, headers = {}) => this.fetch(path, 'DELETE', data, headers);

  patch = (path, data, headers = {}) => this.fetch(path, 'PATCH', data, headers);

  makeApiCall = async (method, url, data = {}, successMessage, errorMessage, callback) => {
    let response

    try {
      if (['post', 'put', 'patch'].includes(method)) {
        response = await this[method](url, data)
      } else {
        response = await this[method](url)
      }

      if (callback) { callback(response) }

      // myToast.showToast('success', successMessage, 'Success', () => {
      //   if (callback) { callback() }
      // })
      if (successMessage) {
        myToast.showToast('success', successMessage, 'Success')
      }
    } catch(err) {
      console.log(err)
      Sentry.captureException(err)
      if (errorMessage) {
        myToast.showToast('error', `${errorMessage}: ${err.message}`, 'Error')
      }
    }

    return response
  }
}

export default apiClient;
