// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Card {
  /**
   * unique id of this Card, immutable.
   */
  id = null

  @observable label
  @observable stripeId
  @observable addressCity
  @observable addressCountry
  @observable line1
  @observable line1Check
  @observable line2
  @observable state
  @observable postalCode
  @observable postalCodeCheck
  @observable brand
  @observable country
  @observable currency
  @observable cvcCheck
  @observable dynamicLast4
  @observable expMonth
  @observable expYear
  @observable funding
  @observable last4
  @observable name
  @observable isDefault
  @observable status
  @observable createdAt
  @observable updatedAt
  @observable removed

  /**
   * Indicates whether changes in this object
   * should be submitted to the server
   */
  autoSave = true

  /**
   * Disposer for the side effect that automatically
   * stores this Card, see @dispose.
   */
  saveHandler = null

  constructor(data) {
    this.id = data?.id

    this.updateFromJson(data)
  }

  @computed get formattedExpiry() {
    const paddedMonth = this.expMonth.toString().padStart(2, '0')
    const shortenedYear = this.expYear.toString().slice(-2)

    return `${paddedMonth}/${shortenedYear}`
  }

  @computed get asJson() {
    return {
      id:                 this.id,
      label:              this.label,
      stripeId:           this.stripeId,
      addressCity:        this.addressCity,
      addressCountry:     this.addressCountry,
      line1:              this.line1,
      line1Check:         this.line1Check,
      line2:              this.line2,
      state:              this.state,
      postalCode:         this.postalCode,
      postalCodeCheck:    this.postalCodeCheck,
      brand:              this.brand,
      country:            this.country,
      currency:           this.currency,
      cvcCheck:           this.cvcCheck,
      dynamicLast4:       this.dynamicLast4,
      expMonth:           this.expMonth,
      expYear:            this.expYear,
      funding:            this.funding,
      last4:              this.last4,
      name:               this.name,
      status:             this.status,
      removed:            this.removed,
      isDefault:          this.isDefault,
      createdAt:          this.createdAt,
      updatedAt:          this.updatedAt,
    }
  }

  /**
   * Update this object with information from the server
   */
  @action updateFromJson(data) {
    // make sure our changes aren't sent back to the server
    this.autoSave = false

    // seeing issues where some API calls for cards are not camel cased, hence the hacky ||'s
    this.label              = data.attributes.label
    this.stripeId           = data.attributes.stripe_id || data.attributes.stripeId
    this.addressCity        = data.attributes.address_city || data.attributes.addressCity
    this.addressCountry     = data.attributes.address_country || data.attributes.addressCountry
    this.line1              = data.attributes.address_line1 || data.attributes.address_Line1
    this.line1Check         = data.attributes.address_line1_check || data.attributes.addressLine1Check
    this.line2              = data.attributes.address_line2 || data.attributes.addressLine2
    this.state              = data.attributes.address_state || data.attributes.addressState
    this.postalCode         = data.attributes.address_zip || data.attributes.addressZip
    this.postalCodeCheck    = data.attributes.address_zip_check || data.attributes.addressZipCheck
    this.brand              = data.attributes.brand
    this.country            = data.attributes.country
    this.currency           = data.attributes.currency
    this.cvcCheck           = data.attributes.cvc_check || data.attributes.cvcCheck
    this.dynamicLast4       = data.attributes.dynamic_last4 || data.attributes.dynamicLast4
    this.expMonth           = data.attributes.exp_month || data.attributes.expMonth
    this.expYear            = data.attributes.exp_year || data.attributes.expYear
    this.funding            = data.attributes.funding
    this.last4              = data.attributes.last4
    this.name               = data.attributes.name
    this.status             = data.attributes.status
    this.removed            = data.attributes.removed
    this.isDefault          = data.attributes.default
    this.createdAt          = data.attributes.created_at || data.attributes.createdAt
    this.updatedAt          = data.attributes.updated_at || data.attributes.updatedAt

    this.autoSave = true
  }

  dispose() {
      // clean up the observer
      this.saveHandler()
  }
}
