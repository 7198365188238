import { decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { BankAccount } from '../../models/BankAccount';
import { BankAccountModal } from '../Modals/BankAccountModal';
export const BankAccountsTable = observer(class BankAccountsTable extends Component {
  filter

  _addModalOpen = false

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  idFormatter = (cell, _row) => (
    <>
      <NavLink strict={+true} to={`/bank_account/${cell}`} key={cell}>{cell.split('-')[0]}</NavLink>
      <ClipboardLink text={cell} />
    </>
  )

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'bankName',
      name: 'Bank Name',
      priority: 1,
    },
    {
      dataField: 'last4',
      name: 'Account Num Last 4',
      priority: 1,
    },
    {
      dataField: 'accountHolderName',
      name: 'Account Holder Name',
      priority: 1,
    },
    {
      dataField: 'stripeId',
      name: 'Stripe ID',
      priority: 1,
    },
  ]

  _postSubmitCallback = () => {
    this._tableRef.current.fetchData()
    runInAction(() => this._addModalOpen = false)
  }

  render() {
    return <>
      <BankAccountModal
        isOpen={this._addModalOpen}
        toggle={() => { runInAction(() => { this._addModalOpen = !this._addModalOpen; }); }}
        title={`Add New Payout Account`}
        dataUrl={this.props.dataUrl}
        driver={this.props.driver}
        submitCallback={this.postSubmitCallback}
      />

      <MyTable
        model={BankAccount}
        dataUrl={this.props.dataUrl}
        breadcrumbBaseTitle={this.props.baseTitle || 'Bank Accounts'}
        dataColumns={this.dataColumns}
        embedded
        dateFilterColumn={'createdAt'}
        dateFilterLabel={'Created At'}
        defaultLimit={this.props.defaultLimit}
        filter={this.props.filter}
        searchable
        singleActions={this.props.addEnabled && {
          'Add +': () => runInAction(() => {
            this._addModalOpen = !this._addModalOpen
          }),
        }}
        ref={this._tableRef}
      />
    </>
  }
});

decorate(BankAccountsTable, {
  _addModalOpen: observable,
});
