import React, { useEffect, useState } from 'react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter, Link } from 'react-router-dom'

// i18n
import { withNamespaces } from 'react-i18next'

import { client } from '../../helpers/client'

import { User } from '../../helpers/CurrentUser'

const SidebarContent = (props) => {
  const [counts, setCounts] = useState(0)

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
    setSidebarCounts()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show')

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') // a
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add('mm-active')
          }
        }
      }
      return false
    }
    return false
  }

  async function setSidebarCounts() {
    const result = await client.get('/admin/dashboard_stats/sidebar_counts')
    setCounts(result.awaitingCloseCount + result.dispatchingCount)
  }

  return (
    <>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t('Menu')} </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <span>{props.t('Dashboard')}</span>
            </Link>
          </li>

          {User.canViewBookings && (
            <>
              <Link to="/bookings/">
                <li className="menu-title">{props.t('Bookings')}</li>
              </Link>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-timer" />
                  <span>{props.t('Requiring Action')}</span>
                  {counts > 0 && (
                    <span className="badge badge-pill badge-danger ml-2">
                      {counts}
                    </span>
                  )}
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/bookings/dispatching">
                      {props.t('Dispatching')}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/bookings/dispatching/jeevz">
                      {props.t('Dispatching (Jeevz only)')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/dispatching/dryver">
                      {props.t('Dispatching (Dryver only)')}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/bookings/pending-p2p">
                      {props.t('Pending P2P Response')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/awaiting-review">
                      {props.t('Awaiting Review')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-timer" />
                  <span>{props.t('Internal Trips')}</span>
                </Link>

                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/bookings/dispatching/internal">
                      {props.t('Dispatching')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/pending-p2p/internal">
                      {props.t('Pending P2P Response')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/awaiting-review/internal">
                      {props.t('Awaiting Review')}
                    </Link>
                  </li>

                  <li>
                    <Link to="/bookings/upcoming/internal">
                      {props.t('Upcoming')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/confirmed/internal">
                      {props.t('Driver Assigned')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/driver-en-route/internal">
                      {props.t('Driver on Way')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/driver-arrived/internal">
                      {props.t('Driver Arrived')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/driver-returning/internal">
                      {props.t('Driver Returning')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/in-progress/internal">
                      {props.t('In Progress')}
                    </Link>
                  </li>

                  <li>
                    <Link to="/bookings/active/internal">
                      {props.t('All Active')}
                    </Link>
                  </li>

                  <li>
                    <Link to="/bookings/finished/internal">
                      {props.t('All Finished')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/closed/internal">
                      {props.t('Closed Out')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/cancelled/internal">
                      {props.t('Cancelled')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/failed/internal">
                      {props.t('Failed')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-hourglass" />
                  <span>{props.t('Active')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/bookings/active">{props.t('All Active')}</Link>
                  </li>
                  {/* <li>
                    <Link to="/bookings/active/jeevz">
                      {props.t('Jeevz Only')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/active/dryver">
                      {props.t('Dryver Only')}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/bookings/upcoming">{props.t('Upcoming')}</Link>
                  </li>
                  <li>
                    <Link to="/bookings/upcoming-first">
                      {props.t('Upcoming 1st Timers')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/confirmed">
                      {props.t('Driver Assigned')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/driver-en-route">
                      {props.t('Driver on Way')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/driver-arrived">
                      {props.t('Driver Arrived')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/driver-returning">
                      {props.t('Driver Returning')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/in-progress">
                      {props.t('In Progress')}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-badge-check" />
                  <span>{props.t('Finished')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/bookings/finished">
                      {props.t('All Finished')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/closed">{props.t('Closed Out')}</Link>
                  </li>
                  <li>
                    <Link to="/bookings/awaiting-review">
                      {props.t('Awaiting Close')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bookings/cancelled">{props.t('Cancelled')}</Link>
                  </li>
                  <li>
                    <Link to="/bookings/failed">{props.t('Failed')}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/booking-import" className="waves-effect">
                  <i className="bx bx-user" />
                  <span>{props.t('Booking Import')}</span>
                </Link>
              </li>
            </>
          )}

          <li className="menu-title">{props.t('Users')}</li>
          {User.canViewClients && (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-user" />
                <span>{props.t('Clients')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/clients">{props.t('All')}</Link>
                </li>
                <li>
                  <Link to="/clients/active">{props.t('Active')}</Link>
                </li>
                <li>
                  <Link to="/clients/trial">{props.t('Trial')}</Link>
                </li>
                <li>
                  <Link to="/clients/awaiting_booking">
                    {props.t('Awaiting First Booking')}
                  </Link>
                </li>
                <li>
                  <Link to="/clients/prospects">{props.t('Prospects')}</Link>
                </li>
                <li>
                  <Link to="/clients/leads">{props.t('Leads')}</Link>
                </li>
                <li>
                  <Link to="/clients/suspended">{props.t('Suspended')}</Link>
                </li>
                {/* <li><Link to="/clients/awaiting-call">{props.t('Awaiting Call') }</Link></li> */}
                <li>
                  <Link to="/clients/cancelled">{props.t('Cancelled')}</Link>
                </li>
                <li>
                  <Link to="/clients/removed">{props.t('Removed')}</Link>
                </li>
              </ul>
            </li>
          )}

          {User.canViewDrivers && (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-car" />
                <span>{props.t('Drivers')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/drivers">{props.t('All')}</Link>
                </li>
                <li>
                  <Link to="/drivers/active">{props.t('Active')}</Link>
                </li>
                <li>
                  <Link to="/drivers/probationary">
                    {props.t('Probationary')}
                  </Link>
                </li>
                <li>
                  <Link to="/drivers/applied">{props.t('Applied')}</Link>
                </li>
                <li>
                  <Link to="/drivers/rejected">{props.t('Rejected')}</Link>
                </li>
                <li>
                  <Link to="/drivers/removed">{props.t('Removed')}</Link>
                </li>
                <li>
                  <Link to="/drivers/banned">{props.t('Banned')}</Link>
                </li>
                <li>
                  <Link to="/drivers/map">{props.t('Map')}</Link>
                </li>
              </ul>
            </li>
          )}

          <li className="menu-title">{props.t('Cashflow')}</li>
          {User.canViewBillings && (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-line-chart" />
                <span>{props.t('Billings')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/billings">{props.t('All')}</Link>
                </li>
                <li>
                  <Link to="/billings/pending">{props.t('Pending')}</Link>
                </li>
                <li>
                  <Link to="/billings/charged">{props.t('Charged')}</Link>
                </li>
                <li>
                  <Link to="/billings/refunded">{props.t('Refunded')}</Link>
                </li>
                <li>
                  <Link to="/billings/failed">{props.t('Failed')}</Link>
                </li>
                <li>
                  <Link to="/billings/deleted">{props.t('Deleted')}</Link>
                </li>
              </ul>
            </li>
          )}

          {User.canViewEarnings && (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-line-chart-down" />
                <span>{props.t('Driver Earnings')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/earnings">{props.t('All')}</Link>
                </li>
                <li>
                  <Link to="/earnings/pending">{props.t('Pending')}</Link>
                </li>
                <li>
                  <Link to="/earnings/paid">{props.t('Paid')}</Link>
                </li>
                <li>
                  <Link to="/earnings/failed">{props.t('Failed')}</Link>
                </li>
                <li>
                  <Link to="/earnings/cancelled">{props.t('Cancelled')}</Link>
                </li>
              </ul>
            </li>
          )}

          <li className="menu-title">{props.t('P2P')}</li>
          {User.canViewClients && (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-line-chart" />
                <span>{props.t('P2P')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/vehicles/p2p">{props.t('Vehicles')}</Link>
                </li>
              </ul>
            </li>
          )}

          {User.canViewPromoCodes && (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-barcode" />
                <span>{props.t('Promo Codes')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/promo_codes">{props.t('All')}</Link>
                </li>
              </ul>
            </li>
          )}

          {/* We need access to see like canViePromoCodes but with Coupons??? */}
          {User.canViewCoupons && (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-barcode" />
                <span>{props.t('Coupons')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/coupons">{props.t('All')}</Link>
                </li>
              </ul>
            </li>
          )}

          <li className="menu-title">{props.t('Marketing')}</li>
          {User.canViewShortUrls && (
            <li>
              <Link to="/short_urls" className="waves-effect">
                <i className="bx bx-link-alt" />
                <span>{props.t('Short Urls')}</span>
              </Link>
            </li>
          )}

          {User.canViewLeadImport && (
            <li>
              <Link to="/marketing/lead-import" className="waves-effect">
                <i className="bx bx-user" />
                <span>{props.t('Lead Import')}</span>
              </Link>
            </li>
          )}

          <li className="menu-title">{props.t('Communications')}</li>
          {User.canViewTexting && (
            <li>
              <Link to="/communication/texting-tool" className="waves-effect">
                <i className="bx bx-message-alt-edit" />
                <span>{props.t('Texting Tool')}</span>
              </Link>
            </li>
          )}

          <li className="menu-title">{props.t('Settings')}</li>

          {User.canViewDispatch && (
            <li>
              <Link to="/dispatch-settings" className="waves-effect">
                <i className="bx bx-cog" />
                <span>{props.t('Dispatch')}</span>
              </Link>
            </li>
          )}

          {User.canViewZips && (
            <li>
              <Link to="/zip-codes" className="waves-effect">
                <i className="bx bx-map-pin" />
                <span>{props.t('Zip Codes')}</span>
              </Link>
            </li>
          )}

          {User.canViewEvents && (
            <li>
              <Link to="/events" className="waves-effect">
                <i className="bx bx-calendar" />
                <span>{props.t('Events')}</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}

export default withRouter(withNamespaces()(SidebarContent))
