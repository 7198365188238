import {
  action, decorate, observable, runInAction
} from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import RelativeTime from 'react-relative-time';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { AdminNote } from '../../models/AdminNote';
import { AdminNoteModal } from '../Modals/AdminNoteModal';

export const AdminNotesTable = observer(class AdminNotesTable extends Component {
  filter

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  createdAtFormatter = (cell, row) => {
    if (cell) {
      return (<RelativeTime value={cell} />);
    }
  }

  dataColumns = [
    {
      dataField: 'body',
      name: 'Note',
      priority: 1,
    },
    {
      dataField: 'authorName',
      name: 'Author',
      priority: 1,
    },
    {
      dataField: 'createdAt',
      name: 'Created At',
      priority: 1,
      formatter: this.createdAtFormatter,
      sort: true,
    },
  ]

  addSubmitHandler = async (e, values) => {
    client.makeApiCall(
      'post', this.props.dataUrl, { admin_note: { body: values.body } },
      'Note added successfully',
      'Unable to add note',
      this._postSubmitCallback
    )
  }

  _postSubmitCallback = () => {
    this._tableRef.current.fetchData()
    runInAction(() => this._addModalOpen = false)
  }

  render() {
    return (
      <>
        <AdminNoteModal
          isOpen={this.addModalOpen}
          toggle={() => { runInAction(() => { this.addModalOpen = !this.addModalOpen; }); }}
          title="Add New Note"
          submitHandler={this.addSubmitHandler}
        />

        <MyTable
          model={AdminNote}
          dataUrl={this.props.dataUrl}
          breadcrumbBaseTitle={this.props.baseTitle || 'Admin Only Notes'}
          dataColumns={this.dataColumns}
          embedded
          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}
          defaultLimit={this.props.defaultLimit}
          singleActions={{
            'Add +': () => runInAction(() => { this.addModalOpen = !this.addModalOpen; })
          }}
          ref={this._tableRef}
        />
      </>
    );
  }
});

decorate(AdminNotesTable, {
  addModalOpen: observable,
  addSubmitHandler: action,
});
