// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Charge {
    /**
     * unique id of this Charge, immutable.
     */
    id = null

    @observable stripeId              = null
    @observable failureCode           = null
    @observable paymentMethodStripeId = null
    @observable receiptEmail          = null
    @observable receiptUrl            = null
    @observable statementDescriptor   = null
    @observable description           = null
    @observable outcome               = null
    @observable fullyRefunded         = null
    @observable disputed              = null
    @observable captured              = null
    @observable amount                = null
    @observable tax                   = null
    @observable amountRefunded        = null
    @observable applicationFee        = null
    @observable createdAt             = null
    @observable updatedAt             = null

    // @observable email = ""

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:                     this.id,
        stripeId:               this.stripeId,
        failureCode:            this.failureCode,
        paymentMethodStripeId:  this.paymentMethodStripeId,
        receiptEmail:           this.receiptEmail,
        receiptUrl:             this.receiptUrl,
        statementDescriptor:    this.statementDescriptor,
        description:            this.description,
        outcome:                this.outcome,
        fullyRefunded:          this.fullyRefunded,
        disputed:               this.disputed,
        captured:               this.captured,
        amount:                 this.amount,
        tax:                    this.tax,
        amountRefunded:         this.amountRefunded,
        applicationFee:         this.applicationFee,
        createdAt:              this.createdAt,
        updatedAt:              this.updatedAt,
      }
    }

    @computed get formattedAmount() {
      return this.amount.cents / 100
    }

    @computed get amountRefundable() {
      return (this.amount.cents - this.amountRefunded.cents) / 100
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.stripeId              = data.attributes.stripeId
      this.failureCode           = data.attributes.failureCode
      this.paymentMethodStripeId = data.attributes.paymentMethodStripeId
      this.receiptEmail          = data.attributes.receiptEmail
      this.receiptUrl            = data.attributes.receiptUrl
      this.statementDescriptor   = data.attributes.statementDescriptor
      this.description           = data.attributes.description
      this.outcome               = data.attributes.outcome
      this.fullyRefunded         = data.attributes.fullyRefunded
      this.disputed              = data.attributes.disputed
      this.captured              = data.attributes.captured
      this.amount                = data.attributes.amount
      this.tax                   = data.attributes.tax
      this.amountRefunded        = data.attributes.amountRefunded
      this.applicationFee        = data.attributes.applicationFee
      this.createdAt             = data.attributes.createdAt
      this.updatedAt             = data.attributes.updatedAt

      this.autoSave = true
    }

    dispose() {
        // clean up the observer
        this.saveHandler()
    }
}
