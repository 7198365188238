import { decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import strftime from 'strftime';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { ShortUrlModal } from '../../components/Modals/ShortUrlModal';
import { formatters } from '../../helpers/Formatters';
import { ShortUrl } from '../../models/ShortUrl';
import { MyTable } from "../../components/MyTable/MyTable"

export const ShortUrlsTable = observer(class ShortUrlsTable extends Component {
  filter

  addModalOpen

  selectedUrl

  idFormatter = (cell, row) => (
    <>
      <a
        href={`#${row.id}`}
        key={row.id}
        onClick={
          () => {
            runInAction(() => {
              this.addModalOpen = true;
              this.selectedUrl = row;
            });
          }
        }
      >
        { cell.split('-')[0] }
      </a>

      <ClipboardLink text={cell} />
    </>
  )

  createdAtFormatter = (cell, row) => {
    const time = new Date(cell);

    return (
      <div>
        { strftime('%B %d, %Y', time) }
        <br />
        { strftime('%l:%M%P', time) }
        <br />
        { strftime('(%Z)', time) }
      </div>
    );
  }

  urlFormatter = (cell, _row) => {
    return(
      <a href={cell} target="_blank" rel="noopener noreferrer">{ cell }</a>
    )
  }

  shortFormatter = (cell, _row) => {
    return(
      <a href={`https://jvz.app/${cell}`}>{ `jvz.app/${cell}` }</a>
    )
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      formatter: this.idFormatter,
      sort: true,
      priority: 1,
    },
    {
      dataField: 'url',
      name: 'Links To',
      formatter: this.urlFormatter,
      sort: true,
      priority: 1,
    },
    {
      dataField: 'uniqueKey',
      name: 'Short Url',
      formatter: this.shortFormatter,
      sort: true,
      priority: 1,
    },
    {
      dataField: 'category',
      name: 'Category',
      sort: true,
      priority: 2,
    },
    {
      dataField: 'useCount',
      name: 'Clicks',
      sort: true,
      priority: 2,
    },
    {
      dataField: 'expiresAt',
      name: 'Expires At',
      formatter: formatters.fullTimeFormatter,
      sort: true,
      priority: 2,
    },
  ]

  render() {
    return (
        <>
          <ShortUrlModal
            isOpen={this.addModalOpen}
            toggle={() => {
              runInAction(() => {
                this.addModalOpen = !this.addModalOpen
                this.selectedUrl = null
              });
            }}
            title={this.modalTitle}
            submitHandler={this.submitEarningHandler}
            url={this.selectedUrl}
          />

          <MyTable
            model={ShortUrl}
            dataUrl={this.props.dataUrl}
            dataColumns={this.dataColumns}
            breadcrumbTitle={this.props.filter || 'All'}
            breadcrumbBaseTitle={this.props.baseTitle || 'Short Urls'}
            subheading={"A list of all of our homebaked Shortened URLs using jvz.app"}
            singleActions={{
              'Add +': () => {
                runInAction(() => {
                  this.selectedUrl = null;
                  this.addModalOpen = !this.addModalOpen;
                });
              }
            }}

            dateFilterColumn={'createdAt'}
            dateFilterLabel={'Created At'}

            embedded={this.props.embedded}
            defaultLimit={this.limit}
            filter={this.props.filter}
            searchable
          />
        </>
    );
  }
});

decorate(ShortUrlsTable, {
  addModalOpen: observable,
  selectedUrl: observable,
});
