import { observer } from 'mobx-react';
import React, { Component, createRef, useRef, useEffect } from 'react';
import {
  GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs,
} from 'react-google-maps';
import {
  Card, CardBody, CardHeader, NavLink,
} from 'reactstrap';
import { retry } from '../../helpers/retry'

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

class MarkerList extends Component {
  render() {
    return this.props.locations.map((location, index) => (
      <MarkerWithInfoWindow key={index.toString()} location={location} />
    ));
  }
}

class MarkerWithInfoWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { location } = this.props;

    return (
      <Marker
        icon={location.icon}
        onClick={this.toggle}
        position={location}
        title={location.title}
        label={location.label}
      >
        {this.state.isOpen
        && (
        <InfoWindow onCloseClick={this.toggle}>
          <NavLink href={location.www} target="_blank">{location.title}</NavLink>
        </InfoWindow>
        )}
      </Marker>
    );
  }
}

const GoogleMapsComponent = withScriptjs(withGoogleMap((props) => {
  const mapRef = useRef();

  useEffect(() => {
    props.onLoad && props.onLoad(mapRef.current);
  }, []);

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={props.defaultZoom}
      zoom={props.zoom}
      center={props.center}
      style={props.style}
    >
      <MarkerList locations={props.locations} />
    </GoogleMap>
  );
}));

export const ReactGoogleMap = observer(class ReactGoogleMap extends Component {
  defaultZoom = 11

  constructor(props) {
    super(props);
    this.title = props.title || 'Map';
    this.map = createRef()
  }

  fitBounds() {
    retry(() => {
      if (!window.google) {
        return false;
      }

      const bounds = new google.maps.LatLngBounds();

      this.props.mapLocations.forEach(coord => {
        bounds.extend(new google.maps.LatLng(coord));
      });

      this.map.current.fitBounds(bounds);

      return true;
    }, 10, 500);
  }

  onLoad = (map) => {
    this.map.current = map;
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <i className="icon-map" />
          {' '}
          {this.title}
          {' '}
          {this.props.eta}
        </CardHeader>
        <CardBody>
          <GoogleMapsComponent
            key="map"
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '400px', ...(this.props.style || {}) }} />}
            mapElement={<div style={{ height: '100%' }} />}
            defaultZoom={this.defaultZoom}
            zoom={this.props.zoom || this.defaultZoom}
            center={this.props.center}
            locations={this.props.mapLocations}
            onLoad={this.onLoad}
          />
        </CardBody>
      </Card>
    );
  }
});
