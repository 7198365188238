import { action, computed, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Badge,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
} from 'reactstrap'
import strftime from 'strftime'
import { BookingModal } from '../../components/Modals/BookingModal'
import { ConfirmationModal } from '../../components/Modals/ConfirmationModal'
import { PromptModal } from '../../components/Modals/PromptModal'
import { client } from '../../helpers/client'
import { DateTime } from 'luxon'
import dryverLogo from '../../assets/images/dryver-icon.png'
import jeevzLogo from '../../assets/images/jeevz-icon.png'

export const RequestCard = observer(
  class RequestCard extends Component {
    showActionDropdown = false
    showBookingModal = false
    closeOutModalOpen = false
    cancelModalOpen = false
    _promptModalOpen = false

    formattedTime = (time) => {
      const date = new Date(time)
      const zone = `${strftime('%Z', date)
        .match(/\b(\w)/g)
        .join('')
        .toUpperCase()}`

      return (
        <>
          {strftime('%b %d, %Y', date)}
          <br />
          {strftime('%l:%M%P', date)} ({zone})
          <br />
        </>
      )
    }

    localizedFormattedTime = (time) => {
      const tz = this.props.booking?.timeZone || 'America/New_York'
      const dt = DateTime.fromISO(time, { zone: tz })
      return (
        <>
          {dt.toFormat('DD')}
          <br />
          {dt.toFormat('t')}
          <strong> ({dt.toFormat('ZZZZ')})</strong>
        </>
      )
    }

    toggleBookingModal = () => (this.showBookingModal = !this.showBookingModal)

    _toggleCloseOutModal = async () =>
      (this.closeOutModalOpen = !this.closeOutModalOpen)

    _closeOut = async () => {
      client.makeApiCall(
        'put',
        `/bookings/${this.props.booking.id}`,
        { booking: { status: 'closed' } },
        'Booking closed successfully',
        'Unable to close booking',
        () => window.location.reload(),
      )
    }

    _toggleCancelModal = async () =>
      (this.cancelModalOpen = !this.cancelModalOpen)

    _cancel = async () => {
      client.makeApiCall(
        'put',
        `/bookings/${this.props.booking.id}`,
        { booking: { status: 'cancelled' } },
        'Booking cancelled successfully',
        'Unable to cancel booking',
        () => window.location.reload(),
      )
    }

    get statusBadge() {
      const status = this.props.booking.status
      switch (status) {
        case 'requested':
        case 'dispatching':
          return (
            <Badge color="warning" className="mr-1">
              {status.toUpperCase()}
            </Badge>
          )
        case 'confirmed':
          return (
            <Badge className="badge-soft-success mr-1">
              {status.toUpperCase()}
            </Badge>
          )
        case 'completed':
          if (this.props.booking.closedOutAt) {
            return <Badge className="badge-soft-success mr-1">Completed</Badge>
          }
          return (
            <Badge color="warning" className="mr-1">
              AWAITING CLOSE OUT
            </Badge>
          )
        case 'driver_returning':
          return (
            <Badge className="badge-soft-info mr-1">
              Driver Returning to Origin
            </Badge>
          )
        case 'driver_en_route':
        case 'driver_arrived':
        case 'in_progress':
          return (
            <Badge className="badge-soft-info mr-1">
              {status.toUpperCase()}
            </Badge>
          )
        case 'cancelled':
        case 'failed':
          return (
            <Badge className="badge-soft-danger mr-1">
              {status.toUpperCase()}
            </Badge>
          )
        case 'pending_p2p':
          return (
            <Badge color="warning" className="mr-1">
              PENDING P2P RESP
            </Badge>
          )
        default:
          return (
            <Badge color="danger" className="mr-1">
              unknown
            </Badge>
          )
      }
    }

    _togglePromptModal = () => (this._promptModalOpen = !this._promptModalOpen)

    _grantBonus = async (value) => {
      if (value && parseFloat(value) > 0) {
        client.makeApiCall(
          'post',
          `/drivers/${this.props.booking.driver.id}/bonuses`,
          {
            bonus: {
              amount_cents: parseFloat(value) * 100,
              booking_id: this.props.booking.id,
            },
          },
          'Bonus added successfully',
          'Unable to grant bonus too driver',
          () => window.location.reload(),
        )
      }
    }

    get _pickupTimeLabel() {
      if (this._isAirportPickup) {
        return 'Driver Pick up Vehicle at'
      } else {
        return 'Pickup Time'
      }
    }

    get _isAirportPickup() {
      return (
        this.props.booking.bookingType === 'airport' &&
        this.props.booking.airportTripType === 'arriving'
      )
    }

    get _clientPickupTime() {
      const vehiclePickupTime = new Date(this.props.booking.requestedStartTime)
      const leadTimeMinutes =
        this.props.booking.estimatedVehiclePickupLeadTimeMinutes
      const pickupTime = new Date(
        vehiclePickupTime.getTime() + leadTimeMinutes * 60000,
      )

      return (
        <>
          <strong>In booking TZ:</strong> <br />
          {this.localizedFormattedTime(pickupTime.toISOString())}
          <br />
          <br />
          <strong>In your TZ:</strong> <br />
          {this.formattedTime(pickupTime.toISOString())}
          <br />
        </>
      )
    }

    showCouponForm = () => {
      const coupon = window.prompt('Enter Coupon Code', 'Coupon Code')

      client.makeApiCall(
        'post',
        `/bookings/${this.props.booking.id}/admin_apply_coupon`,
        { booking: { coupon_code: coupon } },
        'Coupon code added',
        'Unable to add coupon code',
        () => window.location.reload(),
      )
    }

    render() {
      return (
        <>
          <ConfirmationModal
            isOpen={this.cancelModalOpen}
            toggle={this._toggleCancelModal}
            header={'Cancel Trip?'}
            body={'Are you sure you want to cancel this trip?'}
            confirm={this._cancel}
            confirmButtonText={'Yes'}
            closeButtonText={'No'}
          />

          <ConfirmationModal
            isOpen={this.closeOutModalOpen}
            toggle={this._toggleCloseOutModal}
            header={'Close Trip?'}
            body={
              "Are you sure we're ready to close the trip and charge the client?  Make sure you've reviewed all the expenses and details."
            }
            confirm={this._closeOut}
            confirmButtonText={'Close Out'}
          />

          {this.props.booking.driver && (
            <PromptModal
              isOpen={this._promptModalOpen}
              toggle={this._togglePromptModal}
              header={'Grant Bonus'}
              body={`Enter the bonus amount for ${this.props.booking.driver.name} in whole $.`}
              confirm={this._grantBonus}
              confirmButtonText={'Submit'}
            />
          )}

          <BookingModal
            title={'Edit Booking'}
            isOpen={this.showBookingModal}
            client={this.props.client}
            booking={this.props.booking}
            toggle={this.toggleBookingModal}
          />
          <Card>
            <CardBody>
              <CardTitle className="mb-4">
                <span>Booking Request Data</span>
                {(this.props.booking.editable ||
                  this.props.booking.cancellable ||
                  this.props.booking.closeable) && (
                  <div className="text-primary float-right">
                    <ButtonDropdown
                      isOpen={this.showActionDropdown}
                      toggle={() =>
                        runInAction(() => {
                          this.showActionDropdown = !this.showActionDropdown
                        })
                      }
                    >
                      <DropdownToggle
                        caret
                        color="secondary"
                        className="btn btn-secondary btn-sm"
                      >
                        Actions &nbsp; <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-menu-right">
                        {this.props.booking.editable && (
                          <DropdownItem
                            key="editBookingAction"
                            onClick={this.toggleBookingModal}
                          >
                            Edit Booking
                          </DropdownItem>
                        )}

                        {this.props.booking.cancellable && (
                          <DropdownItem
                            key="cancelBookingAction"
                            onClick={this._toggleCancelModal}
                          >
                            Cancel Booking
                          </DropdownItem>
                        )}

                        {this.props.booking.closeable && (
                          <DropdownItem
                            key="closeBookingAction"
                            onClick={this._toggleCloseOutModal}
                          >
                            Close Out Booking
                          </DropdownItem>
                        )}

                        {this.props.booking.driver && (
                          <DropdownItem
                            key="grantBonusAction"
                            onClick={this._togglePromptModal}
                          >
                            Grant Bonus to Driver
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                )}
              </CardTitle>

              <div className="table-responsive">
                <Table className="table mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Booked on</th>
                      <td>
                        {this.props.booking?.dryver ? (
                          <div className="d-flex ">
                            <img
                              src={dryverLogo}
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                              }}
                            />
                            Dryver{' '}
                            {this.props.client.currentSubscription
                              ? ' Plus'
                              : ''}
                          </div>
                        ) : (
                          <div className="d-flex ">
                            <img
                              src={jeevzLogo}
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                              }}
                            />
                            Jeevz
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td>{this.statusBadge}</td>
                    </tr>
                    <tr>
                      <th scope="row">Trip Type</th>
                      <td>{this.props.booking.typeLabel}</td>
                    </tr>
                    {this.props.booking.vehicleTransport && (
                      <>
                        <tr>
                          <th scope="row">Vehicle Transport?</th>
                          <td>
                            <Badge color="success" className="mr-1">
                              Yes
                            </Badge>
                          </td>
                        </tr>
                      </>
                    )}
                    {this.props.booking.airportCode && (
                      <>
                        <tr>
                          <th scope="row">Airport</th>
                          <td>{this.props.booking.airportCode}</td>
                        </tr>
                        {this.props.booking.airline && (
                          <tr>
                            <th scope="row">Airline</th>
                            <td>{this.props.booking.airline}</td>
                          </tr>
                        )}
                        {this.props.booking.flightNumber && (
                          <tr>
                            <th scope="row">Flight Number</th>
                            <td>{this.props.booking.flightNumber}</td>
                          </tr>
                        )}
                        <tr>
                          <th scope="row">Arriving/Departing?</th>
                          <td>{this.props.booking.airportTripType}</td>
                        </tr>
                      </>
                    )}

                    <tr>
                      <th scope="row">{this._pickupTimeLabel}</th>
                      {this.props.booking.requestedStartTime && (
                        <td>
                          <strong>In booking TZ:</strong> <br />
                          {this.localizedFormattedTime(
                            this.props.booking.requestedStartTime,
                          )}
                          <br />
                          <br />
                          <strong>In your TZ:</strong> <br />
                          {this.formattedTime(
                            this.props.booking.requestedStartTime,
                          )}
                          <br />
                        </td>
                      )}
                    </tr>

                    {this._isAirportPickup && (
                      <tr>
                        <th scope="row">Picking up Client at Airport at</th>
                        <td>{this._clientPickupTime}</td>
                      </tr>
                    )}
                    <tr>
                      <th scope="row">Estimated Duration</th>
                      <td>
                        {this.props.booking.estimatedDurationMinutes} minutes
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Estimated Price</th>
                      <td>{this.props.booking.estimatedPrice?.pretty}</td>
                    </tr>

                    {this.props.booking.bookingStartedAt && (
                      <tr>
                        <th scope="row">Trip Actually Started At</th>
                        <td>
                          {this.formattedTime(
                            this.props.booking.bookingStartedAt,
                          )}
                        </td>
                      </tr>
                    )}

                    {this.props.booking.bookingEndedAt && (
                      <tr>
                        <th scope="row">Trip Actually Ended At</th>
                        <td>
                          {this.formattedTime(
                            this.props.booking.bookingEndedAt,
                          )}
                        </td>
                      </tr>
                    )}

                    {this.props.booking.actualDurationMinutes && (
                      <tr>
                        <th scope="row">Actual Length Min</th>
                        <td>{this.props.booking.actualDurationMinutes}</td>
                      </tr>
                    )}

                    {this.props.booking.minutesApplied > 0 && (
                      <tr>
                        <th scope="row">Minutes Credited</th>
                        <td>{this.props.booking.minutesApplied}</td>
                      </tr>
                    )}

                    {this.props.booking.creditsApplied?.cents > 0 && (
                      <tr>
                        <th scope="row">Credits Applied</th>
                        <td>{this.props.booking.creditsApplied.pretty}</td>
                      </tr>
                    )}

                    {this.props.booking.actualPrice &&
                      this.props.booking.done && (
                        <tr>
                          <th scope="row">Actual Price</th>
                          <td>{this.props.booking.actualPrice.pretty}</td>
                        </tr>
                      )}

                    {this.props.booking.couponCodeUsed && (
                      <tr>
                        <th scope="row">Coupon Used</th>
                        <td>{this.props.booking.couponCodeUsed}</td>
                      </tr>
                    )}

                    {!this.props.booking.couponCodeUsed && (
                      <tr>
                        <th scope="row">Coupon Code :</th>
                        <td>
                          <a href="#" onClick={this.showCouponForm}>
                            Add One
                          </a>
                        </td>
                      </tr>
                    )}

                    {this.props.booking.couponDiscountPercent > 0 && (
                      <tr>
                        <th scope="row">Coupon % off</th>
                        <td>{this.props.booking.couponDiscountPercent}%</td>
                      </tr>
                    )}

                    {this.props.booking.estimatedCouponDiscountCents > 0 && (
                      <tr>
                        <th scope="row">Coupon $ Off (est)</th>
                        <td>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            this.props.booking.estimatedCouponDiscountCents /
                              100,
                          )}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th scope="row">Pickup Address</th>
                      <td>
                        {this.props.booking.pickupAddress && (
                          <a
                            href={`https://maps.google.com/?q=${this.props.booking.pickupAddress.latitude},${this.props.booking.pickupAddress.longitude}`}
                          >
                            <div>
                              {this.props.booking.pickupAddress.line1}
                              <br />
                              {this.props.booking.pickupAddress.line2}{' '}
                              {this.props.booking.pickupAddress.line2 && <br />}
                              {this.props.booking.pickupAddress.city},{' '}
                              {this.props.booking.pickupAddress.state}{' '}
                              {this.props.booking.pickupAddress.postalCode}
                            </div>
                          </a>
                        )}
                      </td>
                    </tr>

                    {this.props.booking.dropoffAddress && (
                      <tr>
                        <th scope="row">Dropoff Address</th>
                        <td>
                          {this.props.booking.dropoffAddress && (
                            <a
                              href={`https://maps.google.com/?q=${this.props.booking.dropoffAddress.latitude},${this.props.booking.dropoffAddress.longitude}`}
                            >
                              <div>
                                {this.props.booking.dropoffAddress.line1}
                                <br />
                                {this.props.booking.dropoffAddress.line2}{' '}
                                {this.props.booking.dropoffAddress.line2 && (
                                  <br />
                                )}
                                {this.props.booking.dropoffAddress.city},{' '}
                                {this.props.booking.dropoffAddress.state}{' '}
                                {this.props.booking.dropoffAddress.postalCode}
                              </div>
                            </a>
                          )}
                        </td>
                      </tr>
                    )}

                    {this.props.booking.assignedPhoneNumber && (
                      <tr>
                        <th scope="row">Assigned Phone Number</th>
                        <td>{this.props.booking.assignedPhoneNumber}</td>
                      </tr>
                    )}

                    {this.props.booking.preferredDriver && (
                      <tr>
                        <th scope="row">Preferred Driver</th>
                        <td>
                          <Link
                            to={`/driver/${this.props.booking.preferredDriver.id}`}
                          >
                            {this.props.booking.preferredDriver.name}
                          </Link>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th scope="row">Client Notes</th>
                      <td>{this.props.booking.notes}</td>
                    </tr>

                    {this.props.booking.dispatchNotes && (
                      <tr>
                        <th scope="row">Notes from Client for Dispatch Only</th>
                        <td>{this.props.booking.dispatchNotes}</td>
                      </tr>
                    )}

                    <tr>
                      <th scope="row">Admin Notes for Driver</th>
                      <td>{this.props.booking.adminDriverNotes}</td>
                    </tr>

                    {this.props.booking.vehiclePickupNotes && (
                      <tr>
                        <th scope="row">P2P Vehicle Pickup Notes</th>
                        <td>{this.props.booking.vehiclePickupNotes}</td>
                      </tr>
                    )}

                    {this.props.booking.vehicle && (
                      <tr>
                        <th scope="row">Vehicle</th>
                        <td>
                          {this.props.booking.vehicle.year}{' '}
                          {this.props.booking.vehicle.make}{' '}
                          {this.props.booking.vehicle.model}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </>
      )
    }
  },
)

decorate(RequestCard, {
  showActionDropdown: observable,
  toggleBookingModal: action,
  showBookingModal: observable,
  statusBadge: computed,
  _toggleCloseOutModal: action,
  closeOutModalOpen: observable,
  _toggleCancelModal: action,
  cancelModalOpen: observable,
  _promptModalOpen: observable,
  _togglePromptModal: action,
  _pickupTimeLabel: computed,
  _isAirportPickup: computed,
  _clientPickupTime: computed,
})
