import { action, computed, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import Avatar from 'react-avatar'
import { NavLink } from 'react-router-dom'
import strftime from 'strftime'
import ClipboardLink from '../../components/Common/ClipboardLink'
import { MyTable } from '../../components/MyTable/MyTable'
import { client } from '../../helpers/client'
import { formatters } from '../../helpers/Formatters'
import { Tip } from '../../models/Tip'
import { TipModal } from '../Modals/TipModal'

export const TipsTable = observer(
  class TipsTable extends Component {
    filter
    _addModalOpen
    _selectedTip

    componentDidMount() {
      this._tableRef = React.createRef()
    }

    idFormatter = (cell, row) => (
      <>
        <a
          href={`#${row.id}`}
          key={row.id}
          onClick={() => {
            runInAction(() => {
              row.amount = row.amount.cents / 100
              this._selectedTip = row
              this._addModalOpen = true
            })
          }}
        >
          {cell.split('-')[0]}
        </a>
        <ClipboardLink text={cell} />
      </>
    )

    rateableFormatter = (cell, row) => {
      if (cell.type === 'Driver') {
        return (
          <NavLink strict={+true} to={`/driver/${cell.id}`}>
            {cell.name || 'unknown'}
          </NavLink>
        )
      }
      return (
        <NavLink strict={+true} to={`/client/${cell.id}`}>
          {cell.name || 'unknown'}
        </NavLink>
      )
    }

    createdAtFormatter = (cell, row) => {
      const time = new Date(cell)

      return (
        <div>
          {strftime('%B %d, %Y', time)}
          <br />
          {strftime('%l:%M%P', time)}
          <br />
          {strftime('(%Z)', time)}
        </div>
      )
    }

    clientFormatter = (cell, row) => {
      if (cell) {
        const url = `/client/${cell.id}`

        return (
          <NavLink strict={+true} to={url}>
            <Avatar
              name={cell.name}
              src={cell.avatarUrl}
              size={32}
              round
              style={{ marginRight: '10px' }}
            />
            {cell.name}
          </NavLink>
        )
      } else {
        return <div>Unknown</div>
      }
    }

    dataColumns = [
      {
        dataField: 'id',
        name: 'ID',
        priority: 1,
        formatter: this.idFormatter,
      },
      {
        dataField: 'amount',
        name: 'Amount',
        priority: 1,
        formatter: formatters.currencyFormatter,
        sort: true,
      },
      {
        dataField: 'client',
        name: 'Client',
        priority: 1,
        formatter: this.clientFormatter,
      },
      {
        dataField: 'createdAt',
        name: 'Created At',
        priority: 1,
        formatter: this.createdAtFormatter,
        sort: true,
      },
    ]

    addSubmitHandler = async (e, values) => {
      const data = {
        tip: {
          amount_cents: Math.round(values.amount * 100),
        },
      }

      if (this._selectedTip) {
        client.makeApiCall(
          'put',
          `/tips/${this._selectedTip.id}`,
          data,
          'Tip updated successfully',
          'Unable to update tip',
          (response) => {
            this._selectedTip.updateFromJson(response.data)
            this._closeModals()
          },
        )
      } else {
        client.makeApiCall(
          'post',
          this.props.dataUrl,
          data,
          'Tip added successfully',
          'Unable to add tip',
          this._postSubmitCallback,
        )
      }
    }

    _postSubmitCallback = () => {
      this._tableRef.current.fetchData()
      this._closeModals()
    }

    _closeModals = () => {
      this._addModalOpen = false
    }

    get modalTitle() {
      if (this._selectedTip) {
        return this._selectedTip.paid ? 'View Tip' : 'Edit Tip'
      } else {
        return 'New Tip'
      }
    }

    render() {
      return (
        <>
          <TipModal
            isOpen={this._addModalOpen}
            toggle={() => {
              runInAction(() => {
                this._selectedTip = null
                this._addModalOpen = !this._addModalOpen
              })
            }}
            title={this.modalTitle}
            submitHandler={this.addSubmitHandler}
            tip={this._selectedTip}
          />

          <MyTable
            model={Tip}
            dataUrl={this.props.dataUrl}
            dataColumns={this.dataColumns}
            breadcrumbTitle={this.props.filter || 'All'}
            breadcrumbBaseTitle={this.props.baseTitle || 'Tips'}
            dateFilterColumn={'createdAt'}
            dateFilterLabel={'Created At'}
            singleActions={
              this.props.addEnabled && {
                'Add +': () => {
                  runInAction(() => {
                    this._selectedTip = null
                    this._addModalOpen = !this._addModalOpen
                  })
                },
              }
            }
            embedded
            defaultLimit={this.props.defaultLimit}
            filter={this.props.filter}
            ref={this._tableRef}
          />
        </>
      )
    }
  },
)

decorate(TipsTable, {
  _addModalOpen: observable,
  filter: observable,
  _selectedTip: observable,
  modalTitle: computed,
  _closeModals: action,
})
