import {
  action, decorate, observable, runInAction
} from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { Address } from "../../models/Address";
import { AddressModal } from '../Modals/AddressModal';
import { ConfirmationModal } from '../Modals/ConfirmationModal';

export const AddressesTable = observer(class AddressesTable extends Component {
  filter

  _addModalOpen = false
  reload = false
  _currentStopId
  _currentAddresses
  _removeStopModalOpen = false
  _removeAddressesModalOpen = false

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  idFormatter = (cell, row) => (
    <><a
      href={`#${row.id}`}
      key={row.id}
      onClick={
        () => {
          runInAction(() => {
            this._addModalOpen = true;
            this.selectedAddress = row;
          });
        }
      }
    >
      {cell.split('-')[0]}
    </a><ClipboardLink text={cell} /></>
  )

  removeStopButton = (cell, row) => {
    if (this.props.removable) {
      return (<Button color="danger" onClick={() => { this._toggleRemoveStopModal(row); }}>Remove Waypoint</Button>);
    }
    return null;
  }

  defaultButton = (_cell, row) => {
    if (!row.isDefault) {
      return (<Button color="success" onClick={() => { this.makeDefault(row); }}>Make Default</Button>);
    } else {
      return (
        <div className='text-center'>
          <i className="fa fa-house-user" />
        </div>)
    }
  }

  makeDefault = async (row) => {
    const data = {
      address: {
        default: true,
      },
    };

    client.makeApiCall(
      'put', `/addresses/${row.id}`, data,
      'Address set as default',
      'Unable to set address as default',
      () => runInAction(() => {
        row.isDefault = true
        this._tableRef.current.fetchData()
      })
    )
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'label',
      name: 'Label',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'line1',
      name: 'Line 1',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'line2',
      name: 'Line 2',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'city',
      name: 'City',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'state',
      name: 'State',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'postalCode',
      name: 'Postal Code',
      priority: 1,
      sort: true,
    },
    {
      dataField: '',
      name: 'Actions',
      priority: 1,
      formatter: this.removeStopButton,
    },
    {
      dataField: '',
      name: '',
      priority: 1,
      formatter: this.defaultButton
    },
  ]

  submitHandler = async (e, values) => {
    const data = {
      address: {
        label: values.label,
        line1: values.line1,
        line2: values.line2,
        city: values.city,
        state: values.state,
        postal_code: values.postalCode,
        building_code: values.buildingCode,
        neighborhood_code: values.neighborhoodCode,
      },
    }

    if (this.selectedAddress) {
      client.makeApiCall(
        'put', `${this.props.dataUrl}/${this.selectedAddress.id}`, data,
        'Address updated successfully',
        'Unable to update address',
        this._postSubmitCallback
      )
    } else {
      client.makeApiCall(
        'post', this.props.dataUrl, data,
        'Address added successfully',
        'Unable to add address',
        this._postSubmitCallback
      )
    }
  }

  _postSubmitCallback = () => {
    this._tableRef.current.fetchData()

    runInAction(() => {
      this._addModalOpen = false
      this._removeStopModalOpen = false
      this._removeAddressesModalOpen = false
    })
  }

  _toggleRemoveStopModal = (row) => {
    this._currentStopId = row?.id
    this._removeStopModalOpen = !this._removeStopModalOpen
  }

  _removeStop = async () => {
    await client.makeApiCall(
      'delete', `${this.props.dataUrl}/${this._currentStopId}`, null,
      'Stop removed successfully',
      'Unable to remove stop',
      this._postSubmitCallback
    )
  }

  _toggleRemoveAddressesModal = (addresses) => {
    this._removeAddressesModalOpen = !this._removeAddressesModalOpen
    this._currentAddresses = addresses
  }

  _removeAddresses = async () => {
    await Promise.allSettled(this._currentAddresses.map(async (address) => {
      await client.delete(`${this.props.dataUrl}/${address.id}`);
    }))
    this._postSubmitCallback()
  }

  render() {
    return (
      <>
        <ConfirmationModal
          isOpen={ this._removeStopModalOpen }
          toggle={ this._toggleRemoveStopModal }
          header={ `Remove ${ this.props.type === 'waypoint' ? 'Waypoint' : 'Address' }?` }
          body={
            this.props.type === 'waypoint' ? "Are you sure you want to remove this waypoint from the trip?"  : "Are you sure you want to remove this address?"
          }
          confirm={ this._removeStop }
          confirmButtonText={ 'Remove' }
          closeButtonText={ 'Cancel' }
        />

        <ConfirmationModal
          isOpen={ this._removeAddressesModalOpen }
          toggle={ this._toggleRemoveAddressesModal }
          header={ `Remove ${ this.props.type === 'waypoint' ? 'Waypoints' : 'Addresses' }?` }
          body={ "Are you sure you want to remove selected addresses?" }
          confirm={ this._removeAddresses }
          confirmButtonText={ 'Remove' }
          closeButtonText={ 'Cancel' }
        />

        <AddressModal
          isOpen={this._addModalOpen}
          toggle={() => { runInAction(() => {
            this._addModalOpen = !this._addModalOpen;
            this.selectedAddress = null
          }); }}
          title={this.selectedAddress ? 'Update Address' : `Add New ${this.props.baseTitle}`}
          submitHandler={this.submitHandler}
          address={this.selectedAddress}
        />

        <MyTable
          model={Address}
          dataUrl={this.props.dataUrl}
          breadcrumbBaseTitle={this.props.baseTitle || 'Addresses'}
          dataColumns={this.dataColumns}
          embedded
          searchable
          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}
          defaultLimit={this.props.defaultLimit}
          singleActions={this.props.addEnabled && {
            'Add +': () => runInAction(() => { this._addModalOpen = !this._addModalOpen; })
          }}
          multiActions={{
            'Remove Addresses/Waypoints': (rows) => {
              this._toggleRemoveAddressesModal(rows)
            }
          }}
          ref={this._tableRef}
        />
      </>
    );
  }
});

decorate(AddressesTable, {
  _addModalOpen: observable,
  submitHandler: action,
  _toggleRemoveStopModal: action,
  _removeStopModalOpen: observable,
  _toggleRemoveAddressesModal: action,
  _removeAddressesModalOpen: observable,
  _tableRef: observable,
});
