// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Earning {
    /**
     * unique id of this Earning, immutable.
     */
    id = null

    @observable label
    @observable status
    @observable stripeTransferId
    @observable amount
    @observable expectedPayDate
    @observable driver
    @observable user
    @observable earnableType
    @observable earnableId
    @observable bookingId
    @observable issuedAt
    @observable createdAt
    @observable updatedAt
    @observable admin
    @observable paid = false

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:               this.id,
        label:            this.label,
        status:           this.status,
        stripeTransferId: this.stripeTransferId,
        expectedPayDate:  this.expectedPayDate,
        amount:           this.amount,
        driver:           this.driver,
        user:             this.user,
        admin:            this.admin,
        earnableType:     this.earnableType,
        earnableId:       this.earnableId,
        bookingId:        this.bookingId,
        issuedAt:         this.issuedAt,
        createdAt:        this.createdAt,
        updatedAt:        this.updatedAt,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.id               = data.id
      this.label            = data.attributes.label
      this.status           = data.attributes.status
      this.stripeTransferId = data.attributes.stripeTransferId
      this.amount           = data.attributes.amount
      this.driver           = data.attributes.driver
      this.user             = data.attributes.user
      this.admin            = data.attributes.admin
      this.earnableType     = data.attributes.earnableType
      this.earnableId       = data.attributes.earnableId
      this.bookingId        = data.attributes.bookingId
      this.issuedAt         = data.attributes.issuedAt
      this.expectedPayDate  = data.attributes.expectedPayDate
      this.createdAt        = data.attributes.createdAt
      this.updatedAt        = data.attributes.updatedAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
