import { timeAgoInWords } from '@bluemarblepayroll/time-ago-in-words'
// Import Breadcrumb
import { action, computed, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import Avatar from 'react-avatar'
import { NavLink } from 'react-router-dom'
import {
  Badge,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap'
import strftime from 'strftime'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { AvatarModal } from '../../components/Modals/AvatarModal'
import { BookingModal } from '../../components/Modals/BookingModal'
import { CancelSubscriptionModal } from '../../components/Modals/CancelSubscriptionModal'
import { ClientModal } from '../../components/Modals/ClientModal'
import { FavoritesBlockedModal } from '../../components/Modals/FavoritesBlockedModal'
import { PauseSubscriptionModal } from '../../components/Modals/PauseSubscriptionModal'
import { PromptModal } from '../../components/Modals/PromptModal'
import { SubaccountModal } from '../../components/Modals/SubaccountModal'
import { SubscriptionModal } from '../../components/Modals/SubscriptionModal'
import { AddressesTable } from '../../components/Tables/AddressesTable'
import { AdminNotesTable } from '../../components/Tables/AdminNotesTable'
import { BankAccountsTable } from '../../components/Tables/BankAccountsTable'
import { BillingsTable } from '../../components/Tables/BillingsTable'
import { ChargesTable } from '../../components/Tables/ChargesTable'
import { CreditCardsTable } from '../../components/Tables/CreditCardsTable'
import { InsurancePoliciesTable } from '../../components/Tables/InsurancePoliciesTable'
import { SubaccountsTable } from '../../components/Tables/SubaccountsTable'
import { SubscriptionsTable } from '../../components/Tables/SubscriptionsTable'
import { UtmsTable } from '../../components/Tables/UtmsTable'
import { VehiclesTable } from '../../components/Tables/VehiclesTable'
import { client } from '../../helpers/client'
import { titleize } from '../../helpers/titleize'
import { Client } from '../../models/Client'
import { BookingsTable } from '../Bookings/BookingsTable'
// Import mini card widgets
import humanizeString from 'humanize-string'
import dryverLogo from '../../assets/images/dryver-icon.png'
import jeevzLogo from '../../assets/images/jeevz-icon.png'
import { PermanentNotesTable } from '../../components/Tables/PermanentNotesTable'
import MiniCards from '../Contacts/mini-card'
import { DriversTable } from '../Drivers/DriversTable'
import { ClientsTable } from './ClientsTable'

export const ClientShow = observer(
  class ClientShow extends Component {
    client

    showActionDropdown = false
    _showjumpDropdown = false

    showSubscriptionModal = false

    showBookingModal = false

    showFavoriteBlockedModal = false

    favoriteBlockedTitle = ''
    favoriteBlockedType = ''
    favoriteBlockedUrl = ''
    favoriteBlockedSubmitHandler
    showAvatarModal
    _confirmationModalOpen = false
    _confirmationPauseModalOpen = false
    _promptModalOpen = false
    _subaccountModalOpen = false

    constructor(props) {
      super(props)
      this.clientId = this.props.location.pathname.replace('/client/', '')
      this.fetchData()
    }

    componentDidMount() {
      this._faveDriversTableRef = React.createRef()
    }

    fetchData = async () => {
      let result

      try {
        result = await client.get(`/clients/${this.clientId}`)
        this.client = new Client(result.data)
        this.loaded = true
      } catch {
        this.errorVisible = true
        this.errorMessage = 'Unable to fetch client data. Please reload page.'
      }
    }

    get signedUpViaIcon() {
      switch (this.client?.signedUpVia) {
        case 'email':
          return 'envelope'
        case 'facebook':
          return 'facebook-circle'
        case 'google':
          return 'google'
        case 'apple':
          return 'apple'
        default:
          return null
      }
    }

    updateClient = async (_e, values) => {
      let hourlyRateOverride
      let billingRateOverride

      if (
        values.hourlyRateOverride === 0 ||
        values.hourlyRateOverride === '0' ||
        values.hourlyRateOverride
      ) {
        hourlyRateOverride = values.hourlyRateOverride * 100
      }

      if (
        values.billingRateOverride === 0 ||
        values.billingRateOverride === '0' ||
        values.billingRateOverride
      ) {
        billingRateOverride = values.billingRateOverride * 100
      }

      const data = {
        client: {
          name: values.name,
          ambassador: values.ambassador,
          email: values.email,
          gender: values.gender,
          phone_number: values.phoneNumber,
          invite_code: values.inviteCode,
          zip_code: values.zipCode,
          hourly_rate_cents_override: hourlyRateOverride,
          billing_rate_cents_override: billingRateOverride,
          available_minutes: values.availableMinutes,
          available_credits_cents: values.availableCreditsFormatted * 100,
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          internal_account: values.internalAccount,
          account_type: values.accountType,
          dryver: values.dryver,
          preferences_attributes: {
            auto_tip_percentage: values.autoTipPercentage,
            driver_talking_type: values.driverTalkingType,
            driver_driving_type: values.driverDrivingType,
          },
        },
      }

      Object.keys(data).forEach(
        (key) =>
          ![
            'hourly_rate_cents_override',
            'billing_rate_cents_override',
          ].includes(key) &&
          data[key] === null &&
          delete data[key],
      )

      const result = await client.makeApiCall(
        'put',
        `/clients/${this.client.id}`,
        data,
        'Client updated successfully',
        'Unable to update client data',
        () => {
          this._toggleClientModal()
        },
      )

      if (result.data) {
        this.client = new Client(result.data)
      }
    }

    _toggleClientModal = () => {
      this.showClientModal = !this.showClientModal
    }

    _toggleSubaccountModal = () => {
      this._subaccountModalOpen = !this._subaccountModalOpen
    }

    subscriptionSubmitHandler = async (_e, values) => {
      const data = {
        subscription: {
          name: values.displayName,
          product_id: values.productId,
          hourly_rate_cents: values.hourlyRate * 100,
          billing_rate_cents: values.billingRate * 100,
          billing_frequency: values.billingFrequency,
          one_way_enabled: values.oneWayEnabled,
          preferred_drivers_enabled: values.preferredDriversEnabled,
          lock_box_enabled: values.lockBoxEnabled,
          charge_now: values.chargeNow,
          suspended: values.suspended,
        },
      }

      Object.keys(data).forEach((key) => data[key] === null && delete data[key])

      if (this.currentSubscription) {
        client.makeApiCall(
          'put',
          `/subscriptions/${this.currentSubscription.id}`,
          data,
          'Subscription updated successfully',
          'Unable to update subscription',
          () => window.location.reload(),
        )
      } else {
        client.makeApiCall(
          'post',
          `/clients/${this.client.id}/subscriptions`,
          data,
          'Subscription updated successfully',
          'Unable to update subscription',
          () => window.location.reload(),
        )
      }
    }

    get currentSubscription() {
      if (this.client?.currentSubscription) {
        this.client.currentSubscription.hourlyRate =
          this.client.currentSubscription.hourlyRateCents / 100
        this.client.currentSubscription.billingRate =
          this.client.currentSubscription.billingRateCents / 100

        return this.client.currentSubscription
      }
      return null
    }

    get lastTripDate() {
      if (this.client?.lastTripDate) {
        const date = new Date(this.client.lastTripDate)
        const timeAgo = timeAgoInWords(date)

        return `${strftime('%B %d, %Y', date)} (${timeAgo})`
      }

      return 'never'
    }

    favoriteSubmitHandler = async (options) => {
      for (const option of options) {
        await client.post(`/clients/${this.client.id}/favorite_drivers`, {
          driver: {
            id: option.value,
          },
        })
      }

      window.location.reload()
    }

    blockedSubmitHandler = async (options) => {
      for (const option of options) {
        await client.post(`/clients/${this.client.id}/blocked_drivers`, {
          driver: {
            id: option.value,
          },
        })
      }

      window.location.reload()
    }

    toggleAvatarModal = () => {
      this.showAvatarModal = !this.showAvatarModal
    }

    get memberFor() {
      if (this.client?.createdAt) {
        return timeAgoInWords(new Date(this.client.createdAt)).replace(
          ' ago',
          '',
        )
      } else {
        return 'Unknown'
      }
    }

    _cancelSubscription = async (_e, values) => {
      let url

      switch (values.cancelType) {
        case 'continueNoRefund':
          url = `/subscriptions/${this.client.currentSubscription.id}`
          break
        case 'endNowNoRefund':
          url = `/subscriptions/${this.client.currentSubscription.id}?cancel_now=true`
          break
        case 'endNowWithRefund':
          url = `/subscriptions/${this.client.currentSubscription.id}?cancel_now=true&refund=true`
          break
        default:
          url = `/subscriptions/${this.client.currentSubscription.id}`
      }
      await client.makeApiCall(
        'delete',
        url,
        null,
        'Subscription cancelled successfully',
        'Unable to cancel subscription',
        () => window.location.reload(),
      )
    }

    _pauseSubscription = async (_e, values) => {
      await client.makeApiCall(
        'put',
        `/subscriptions/${this.client.currentSubscription.id}`,
        { pause: true, is_admin: true },
        'Subscription updated successfully',
        'Unable to update subscription',
        () => window.location.reload(),
      )
    }

    _toggleConfirmationModal = () =>
      (this._confirmationModalOpen = !this._confirmationModalOpen)
    _togglePauseConfirmationModal = () =>
      (this._confirmationPauseModalOpen = !this._confirmationPauseModalOpen)
    _deleteClient = async () => {
      await client.makeApiCall(
        'delete',
        `/clients/${this.client.id}`,
        null,
        'Client deleted successfully',
        'Unable to delete client',
        () => (window.location = '/clients'),
      )
    }

    _togglePromptModal = () => (this._promptModalOpen = !this._promptModalOpen)

    _deletePromptEval = (answer) => {
      if (answer === 'DELETE') {
        this._deleteClient()
      }
    }

    accountTypeIcon = (type) => {
      switch (type) {
        case 'individual':
          return 'bx bx-user'
        case 'corporate':
          return 'bx bx-buildings'
        case 'partnership':
          return 'fa fa-users'
        case 'event_partnership':
          return 'bx bx-calendar'
        default:
          return ''
      }
    }

    showPromoForm = () => {
      const promo = window.prompt('Enter Promo Code', 'Promo Code')

      console.dir(promo)

      client.makeApiCall(
        'put',
        `/clients/${this.client.id}`,
        { client: { promo_code_used: promo } },
        'Promo Code Updated',
        'Unable to update promo code',
        () => window.location.reload(),
      )
    }

    render() {
      return (
        <>
          <CancelSubscriptionModal
            isOpen={this._confirmationModalOpen}
            toggle={this._toggleConfirmationModal}
            header={'Cancel Membership?'}
            confirm={this._cancelSubscription}
            confirmButtonText={'Confirm'}
            closeButtonText={'Cancel'}
          />

          <PauseSubscriptionModal
            isOpen={this._confirmationPauseModalOpen}
            toggle={this._togglePauseConfirmationModal}
            header={'Pause Membership?'}
            confirm={this._pauseSubscription}
            confirmButtonText={'Confirm'}
            closeButtonText={'Cancel'}
          />

          <PromptModal
            body={
              'If you actually wish to delete this client, please type DELETE below.  This action is not easily reversible and should be done w/ caution.'
            }
            isOpen={this._promptModalOpen}
            toggle={this._togglePromptModal}
            header={'Delete Client?'}
            confirm={this._deletePromptEval}
            confirmButtonText={'Submit'}
          />

          {this.client && (
            <SubaccountModal
              isOpen={this._subaccountModalOpen}
              toggle={this._toggleSubaccountModal}
              title={'Add Subaccount'}
              clientId={this.client.id}
            />
          )}

          {this.client && (
            <>
              <ClientModal
                client={this.client}
                isOpen={this.showClientModal}
                toggle={this._toggleClientModal}
                title="Edit Client"
                submitHandler={this.updateClient}
              />
              <AvatarModal
                driver={this.client}
                isOpen={this.showAvatarModal}
                toggle={this.toggleAvatarModal}
                title="Upload Photo"
                dataUrl={`/clients/${this.client.id}`}
              />
              <BookingModal
                title="New Booking"
                isOpen={this.showBookingModal}
                toggle={() => {
                  runInAction(() => {
                    this.showBookingModal = !this.showBookingModal
                  })
                }}
                client={this.client}
              />
              <SubscriptionModal
                title={
                  this.client.currentSubscription
                    ? 'Edit Subscription'
                    : 'New Subscription'
                }
                isOpen={this.showSubscriptionModal}
                toggle={() => {
                  runInAction(() => {
                    this.showSubscriptionModal = !this.showSubscriptionModal
                  })
                }}
                client={this.client}
                submitHandler={this.subscriptionSubmitHandler}
                subscription={this.currentSubscription}
              />
            </>
          )}

          <FavoritesBlockedModal
            isOpen={this.showFavoriteBlockedModal}
            toggle={() => {
              runInAction(() => {
                this.showFavoriteBlockedModal = !this.showFavoriteBlockedModal
              })
            }}
            title={this.favoriteBlockedTitle}
            type={this.favoriteBlockedType}
            dataUrl={this.favoriteBlockedUrl}
            submitHandler={this.favoriteBlockedSubmitHandler}
          />

          <div className="page-content">
            <Container fluid>
              <div className="mb-4">
                <ButtonDropdown
                  isOpen={this._showjumpDropdown}
                  toggle={() =>
                    runInAction(() => {
                      this._showjumpDropdown = !this._showjumpDropdown
                    })
                  }
                >
                  <DropdownToggle
                    caret
                    color="secondary"
                    className="btn btn-secondary btn-sm"
                  >
                    Jump To &nbsp; <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-left">
                    {[
                      ['bookings', 'Bookings'],
                      ['notes', 'Notes'],
                      ['subscriptions', 'Subscriptions'],
                      ['billings', 'Billings'],
                      ['charges', 'Charges'],
                      ['drivers', 'Favorite Drivers'],
                      ['blocked-drivers', 'Blocked Drivers'],
                      ['addresses', 'Addresses'],
                      ['cards', 'Credit  Cards'],
                      ['bankAccounts', 'Bank Accounts'],
                      ['vehicles', 'Vehicles'],
                      ['insurance', 'Insurance'],
                      ['subaccounts', 'Subaccounts'],
                      ['invitees', 'Invitees'],
                      ['utms', 'Utms'],
                    ].map((item) => (
                      <DropdownItem
                        key={item[0]}
                        onClick={() =>
                          document.getElementById(item[0]).scrollIntoView()
                        }
                      >
                        {item[1]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Clients" breadcrumbItem="Profile" />

              <Row>
                <Col xl="4">
                  <Card>
                    <div className="bg-soft-primary">
                      <Row>
                        <Col xs="12" className="align-self-end">
                          <div className="text-primary p-3 float-right">
                            <ButtonDropdown
                              isOpen={this.showActionDropdown}
                              toggle={() =>
                                runInAction(() => {
                                  this.showActionDropdown =
                                    !this.showActionDropdown
                                })
                              }
                            >
                              <DropdownToggle
                                caret
                                color="secondary"
                                className="btn btn-secondary btn-sm"
                              >
                                Actions &nbsp;{' '}
                                <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-right">
                                <DropdownItem
                                  key="editClientAction"
                                  onClick={this._toggleClientModal}
                                >
                                  Edit Client Data
                                </DropdownItem>

                                {!this.client?.isChildAccount && (
                                  <DropdownItem
                                    key="editSubAction"
                                    onClick={() => {
                                      runInAction(() => {
                                        this.showSubscriptionModal = true
                                      })
                                    }}
                                  >
                                    {this.client?.currentSubscription
                                      ? 'Edit'
                                      : 'Create'}{' '}
                                    Subscription
                                  </DropdownItem>
                                )}

                                {!this.client?.isChildAccount &&
                                  this.client?.isElPrez && (
                                    <DropdownItem
                                      key="addSubaccountAction"
                                      onClick={() => {
                                        runInAction(() => {
                                          this._subaccountModalOpen = true
                                        })
                                      }}
                                    >
                                      Add Subaccount
                                    </DropdownItem>
                                  )}

                                <DropdownItem
                                  key="pauseSubscriptionAction"
                                  onClick={this._togglePauseConfirmationModal}
                                >
                                  Pause subscription
                                </DropdownItem>

                                {!this.client?.isChildAccount &&
                                  this.client?.currentSubscription && (
                                    <DropdownItem
                                      key="cancelSubAction"
                                      onClick={this._toggleConfirmationModal}
                                    >
                                      Cancel Subscription
                                    </DropdownItem>
                                  )}

                                {this.client?.stripeId && (
                                  <DropdownItem
                                    key="viewStripeAction"
                                    onClick={() =>
                                      window.open(
                                        `https://dashboard.stripe.com/customers/${this.client.stripeId}`,
                                        '_blank',
                                      )
                                    }
                                  >
                                    View Stripe Data
                                  </DropdownItem>
                                )}
                                <DropdownItem
                                  key="bookRideAction"
                                  onClick={() => {
                                    runInAction(() => {
                                      this.showBookingModal =
                                        !this.showBookingModal
                                    })
                                  }}
                                >
                                  Book a Ride
                                </DropdownItem>

                                <DropdownItem
                                  key="deleteClientAction"
                                  onClick={this._showDeletePrompt}
                                >
                                  Delete Client
                                </DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="6">
                          <div className="avatar-md profile-user-wid mb-4">
                            <Avatar
                              name={this.client?.name}
                              src={this.client?.avatarUrl}
                              size={64}
                              onClick={this.toggleAvatarModal}
                              round
                            />
                          </div>
                          <h5 className="font-size-15 text-truncate">
                            {this.client?.name}
                          </h5>

                          <p className="text-muted mb-0 text-truncate">
                            {this.client?.status
                              ? titleize(this.client.status)
                              : ''}
                            {this.client?.currentSubscription &&
                              ` - ${this.client.currentSubscription.displayName}`}
                          </p>

                          <div className="my-1 flex flex-row items-center">
                            <div className="d-flex ">
                              <img
                                src={
                                  this.client?.dryver ? dryverLogo : jeevzLogo
                                }
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                }}
                              />
                              {this.client?.dryver
                                ? this.client.currentSubscription
                                  ? 'Dryver Plus'
                                  : 'Dryver'
                                : 'Jeevz'}
                            </div>
                          </div>

                          {this.client?.accountType && (
                            <p className="font-weight-bold text-info">
                              <i
                                className={`${this.accountTypeIcon(
                                  this.client.accountType,
                                )}`}
                              />{' '}
                              {humanizeString(this.client?.accountType)}
                            </p>
                          )}
                          {this.client?.internalAccount && (
                            <p className="font-weight-bold text-info">
                              *INTERNAL ACCOUNT
                            </p>
                          )}
                          {this.client?.ambassador && (
                            <h5 className="flex flex-row items-center">
                              <i className="bx bx-crown mr-2 text-info" />
                              <Badge color="info">Ambassador</Badge>
                            </h5>
                          )}
                          {this.client?.activeGroup && (
                            <h5
                              className="flex flex-row items-center"
                              style={{ textWrap: 'nowrap' }}
                            >
                              <i className="bx bx-crown mr-2 text-info" />
                              <Badge color="info">
                                {this.client.activeGroup.displayName}
                              </Badge>
                            </h5>
                          )}
                        </Col>

                        <Col sm={6}>
                          <div className="pt-4">
                            <Row>
                              <Col xs="6">
                                <h5 className="font-size-15">
                                  {this.client?.tripsCount}
                                </h5>
                                <p className="text-muted mb-0">Rides</p>
                              </Col>
                              <Col xs="6">
                                <h5 className="font-size-15">
                                  {this.client?.totalSpend || '$0'}
                                </h5>
                                <p className="text-muted mb-0">Spend</p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {this.client?.currentSubscription && (
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Subscription Information
                        </CardTitle>
                        {!this.client.isChildAccount && (
                          <div className="table-responsive">
                            <Table className="table-nowrap mb-0">
                              <tbody>
                                {this.client.currentSubscription.suspended && (
                                  <tr>
                                    <th scope="row">Status :</th>
                                    <td>
                                      <Badge color="danger" className="mr-1">
                                        SUSPENDED
                                      </Badge>
                                    </td>
                                  </tr>
                                )}

                                {this.client.currentSubscription.paused && (
                                  <tr>
                                    <th scope="row">Status :</th>
                                    <td>
                                      <Badge color="warning" className="mr-1">
                                        PAUSED
                                      </Badge>
                                      <div>
                                        Until{' '}
                                        {strftime(
                                          '%B %d, %Y',
                                          new Date(
                                            this.client.currentSubscription.pauseEndsAt,
                                          ),
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}

                                {this.client.pendingChurnOn && (
                                  <tr>
                                    <th scope="row">Status :</th>
                                    <td>
                                      <Badge color="warning" className="mr-1">
                                        PENDING CHURN
                                      </Badge>
                                      <div>
                                        On{' '}
                                        {strftime(
                                          '%B %d, %Y',
                                          new Date(this.client.pendingChurnOn),
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}

                                {this.client.currentSubscription
                                  .trialPeriodDays && (
                                  <tr>
                                    <th scope="row">Trial Length :</th>
                                    <td>
                                      <div>
                                        {
                                          this.client.currentSubscription
                                            .trialPeriodDays
                                        }{' '}
                                        days
                                      </div>
                                    </td>
                                  </tr>
                                )}

                                <tr>
                                  <th scope="row">Subscription Name :</th>
                                  <td>
                                    {
                                      this.client.currentSubscription
                                        .displayName
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Membership Billing Rate :</th>
                                  <td>
                                    $
                                    {this.client.currentSubscription
                                      .billingRateCents / 100}{' '}
                                    {
                                      this.client.currentSubscription
                                        .billingFrequency
                                    }
                                    {this.client.currentSubscription
                                      .currentCancelOffer && (
                                      <div>
                                        <br />
                                        <div className="font-weight-bold">
                                          Cancel offer
                                        </div>
                                        <div>
                                          {
                                            this.client.currentSubscription
                                              .currentCancelOffer
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row">One Way Trips? :</th>
                                  <td>
                                    {this.client.currentSubscription
                                      .oneWayEnabled ? (
                                      <Badge color="success" className="mr-1">
                                        Yes
                                      </Badge>
                                    ) : (
                                      <Badge color="danger" className="mr-1">
                                        No
                                      </Badge>
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row">Preferred Drivers? :</th>
                                  <td>
                                    {this.client.currentSubscription
                                      .preferredDriversEnabled ? (
                                      <Badge color="success" className="mr-1">
                                        Yes
                                      </Badge>
                                    ) : (
                                      <Badge color="danger" className="mr-1">
                                        No
                                      </Badge>
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row">Lock Box? :</th>
                                  <td>
                                    {this.client.currentSubscription
                                      .lockBoxEnabled ? (
                                      <Badge color="success" className="mr-1">
                                        Yes
                                      </Badge>
                                    ) : (
                                      <Badge color="danger" className="mr-1">
                                        No
                                      </Badge>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        )}

                        {this.client?.isChildAccount && (
                          <div>
                            Subaccount Of:{' '}
                            <NavLink
                              strict={+true}
                              to={`/client/${this.client.parentAccount.id}`}
                            >
                              {this.client.parentAccount.name}
                            </NavLink>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  )}
                </Col>
                <Col xl="4">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        Personal Information
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Full Name :</th>
                              <td>{this.client?.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Email :</th>
                              <td>
                                <a href={`mailto:${this.client?.email}`}>
                                  {this.client?.email}
                                </a>
                              </td>
                            </tr>
                            {this.client?.phoneNumber && (
                              <tr>
                                <th scope="row">Phone :</th>
                                <td>
                                  <a href={`tel:${this.client?.phoneNumber}`}>
                                    {this.client?.phoneNumber}
                                  </a>
                                </td>
                              </tr>
                            )}
                            {this.client?.zipCode && (
                              <tr>
                                <th scope="row">Location :</th>
                                <td>{this.client?.zipCode}</td>
                              </tr>
                            )}
                            {this.client?.birthdate && (
                              <tr>
                                <th scope="row">Birthdate :</th>
                                <td>{this.client?.birthdate}</td>
                              </tr>
                            )}
                            {this.client?.signedUpVia && (
                              <tr>
                                <th scope="row">Signed up With :</th>
                                <td>
                                  <i
                                    className={`bx bx-${this.signedUpViaIcon}`}
                                  />{' '}
                                  {this.client?.signedUpVia}
                                </td>
                              </tr>
                            )}
                            {this.client?.leadSource && (
                              <tr>
                                <th scope="row">Lead Source :</th>
                                <td>
                                  <i
                                    className={`bx bx-${this.leadSourceIcon}`}
                                  />{' '}
                                  {this.client?.leadSource}
                                </td>
                              </tr>
                            )}
                            {typeof this.client?.hourlyRateCentsOverride ===
                              'number' && (
                              <tr>
                                <th scope="row">Hourly Rate Override :</th>
                                <td>{this.client?.hourlyRateCentsOverride}</td>
                              </tr>
                            )}
                            {typeof this.client?.billingRateCentsOverride ===
                              'number' && (
                              <tr>
                                <th scope="row">Billing Rate Override :</th>
                                <td>{this.client?.billingRateCentsOverride}</td>
                              </tr>
                            )}

                            {this.client && (
                              <tr>
                                <th scope="row">Personal Invite Code :</th>
                                <td>{this.client.inviteCode || 'n/a'}</td>
                              </tr>
                            )}

                            {this.client?.ambassadorInviteCode && (
                              <tr>
                                <th scope="row">Ambassador Invite Code :</th>
                                <td>
                                  {this.client.ambassadorInviteCode || 'n/a'}
                                </td>
                              </tr>
                            )}

                            {this.client && (
                              <tr>
                                <th scope="row">Promo Code Used :</th>
                                <td>
                                  {this.client.promoCodeUsed || (
                                    <a href="#" onClick={this.showPromoForm}>
                                      Add One
                                    </a>
                                  )}
                                </td>
                              </tr>
                            )}

                            {this.client?.invitedBy && (
                              <tr>
                                <th scope="row">Invited By :</th>
                                <td>
                                  <NavLink
                                    strict={+true}
                                    to={`/client/${this.client.invitedBy.id}`}
                                  >
                                    {this.client.invitedBy.name}
                                  </NavLink>
                                </td>
                              </tr>
                            )}

                            {this.client && (
                              <tr>
                                <th scope="row">Available Credits :</th>
                                <td>{this.client.availableCredits.pretty}</td>
                              </tr>
                            )}

                            {this.client && (
                              <tr>
                                <th scope="row">Available Minutes :</th>
                                <td>{this.client.availableMinutes} min</td>
                              </tr>
                            )}

                            {this.client?.acceptedTosAt && (
                              <tr>
                                <th scope="row">Accepted ToS At :</th>
                                <td>
                                  {strftime(
                                    '%c',
                                    new Date(this.client.acceptedTosAt),
                                  )}
                                </td>
                              </tr>
                            )}

                            {this.client?.deviceData && (
                              <tr>
                                <th scope="row">Device Info :</th>
                                <td>
                                  {Object.keys(this.client.deviceData).map(
                                    (key) => (
                                      <div key={`deviceData-${key}`}>
                                        <strong>{key}:</strong>{' '}
                                        {JSON.stringify(
                                          this.client.deviceData[key],
                                        )}
                                      </div>
                                    ),
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="4">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Preferences</CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Talking :</th>
                              <td>
                                {titleize(
                                  this.client?.preferences?.driverTalkingType ||
                                    '',
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Driving :</th>
                              <td>
                                {titleize(
                                  this.client?.preferences?.driverDrivingType ||
                                    '',
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Auto Tip :</th>
                              <td>
                                {this.client?.preferences?.autoTipPercentage
                                  ? `${this.client?.preferences?.autoTipPercentage}%`
                                  : 'None'}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Row>
                    <MiniCards
                      title="City"
                      text={this.client?.city || 'Unknown'}
                      iconClass="bx-map"
                      key="_card_ltv"
                    />
                    <MiniCards
                      title="Member For"
                      text={this.memberFor || 'Never'}
                      iconClass="bx-calendar"
                      key="_card_memberSince"
                    />
                    <MiniCards
                      title="Last Ride"
                      text={this.lastTripDate || 'Never'}
                      iconClass="bx-car"
                      key="_card_lastTrip"
                    />
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="bookings">
                  {this.client && (
                    <BookingsTable
                      dataUrl={`/clients/${this.client.id}/bookings?sort_column=requested_start_time&sort_dir=desc`}
                      embedded
                      title="Bookings"
                      defaultLimit={10}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="notes">
                  {this.client && (
                    <AdminNotesTable
                      dataUrl={`/clients/${this.client.id}/admin_notes`}
                      embedded
                      defaultLimit={10}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="permanent-notes">
                  {this.client && (
                    <PermanentNotesTable
                      dataUrl={`/clients/${this.client.id}/permanent_notes`}
                      embedded
                      defaultLimit={50}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="subscriptions">
                  {this.client && (
                    <SubscriptionsTable
                      dataUrl={`/clients/${this.client.id}/subscriptions`}
                      embedded
                      defaultLimit={10}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="billings">
                  {this.client && (
                    <BillingsTable
                      dataUrl={`/users/${this.client.id}/billings`}
                      embedded={true}
                      defaultLimit={10}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="charges">
                  {this.client && (
                    <ChargesTable
                      dataUrl={`/clients/${this.client.id}/charges`}
                      embedded
                      defaultLimit={10}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="drivers">
                  {this.client && (
                    <DriversTable
                      ref={this._faveDriversTableRef}
                      dataUrl={`/clients/${this.client.id}/favorite_drivers`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Favorite Drivers"
                      favorite={true}
                      actions={{
                        Remove: async (rows) => {
                          for (const row of rows) {
                            await client.makeApiCall(
                              'delete',
                              `/clients/${this.client.id}/favorite_drivers/${row.id}`,
                              null,
                              'Favorite driver removed successfully',
                              'Unable to remove favorite driver',
                            )
                          }
                          this._faveDriversTableRef?.current?.refreshData()
                        },
                      }}
                      singleAction={{
                        'Add +': () => {
                          this.showFavoriteBlockedModal = true
                          this.favoriteBlockedTitle = 'Add Favorite Drivers'
                          this.favoriteBlockedType = 'Drivers'
                          this.favoriteBlockedUrl = '/drivers'
                          this.favoriteBlockedSubmitHandler =
                            this.favoriteSubmitHandler
                        },
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="blocked-drivers">
                  {this.client && (
                    <DriversTable
                      dataUrl={`/clients/${this.client.id}/blocked_drivers`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Blocked Drivers"
                      actions={{
                        Remove: async (rows) => {
                          for (const row of rows) {
                            await client.delete(
                              `/clients/${this.client.id}/blocked_drivers/${row.id}`,
                            )
                          }

                          window.location.reload()
                        },
                      }}
                      singleAction={{
                        'Add +': () => {
                          this.showFavoriteBlockedModal = true
                          this.favoriteBlockedTitle = 'Add Blocked Drivers'
                          this.favoriteBlockedType = 'Drivers'
                          this.favoriteBlockedUrl = '/drivers'
                          this.favoriteBlockedSubmitHandler =
                            this.blockedSubmitHandler
                        },
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="addresses">
                  {this.client && (
                    <AddressesTable
                      dataUrl={`/clients/${this.client.id}/addresses`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Addresses"
                      addEnabled={true}
                      type={'address'}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                {this.client && (
                  <Col xl="12" id="cards">
                    {!this.client.isChildAccount && (
                      <CreditCardsTable
                        dataUrl={`/clients/${this.client.id}/cards`}
                        embedded
                        defaultLimit={10}
                        baseTitle={'Credit Cards'}
                        addEnabled={true}
                        client={this.client}
                      />
                    )}

                    {this.client.isChildAccount && (
                      <div className="mb-4">
                        &gt; Credit Cards managed by:{' '}
                        <NavLink
                          strict={+true}
                          to={`/client/${this.client.parentAccount.id}`}
                        >
                          {this.client.parentAccount.name}
                        </NavLink>
                      </div>
                    )}
                  </Col>
                )}
              </Row>

              <Row>
                <Col xl="12" id="bankAccounts">
                  {this.client && (
                    <BankAccountsTable
                      dataUrl={`/users/${this.client.id}/bank_accounts`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Bank Accounts (for P2P)"
                      addEnabled={true}
                      driver={this.client}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                {this.client && (
                  <Col xl="12" id="vehicles">
                    {!this.client.isChildAccount && (
                      <VehiclesTable
                        dataUrl={`/clients/${this.client.id}/vehicles`}
                        embedded={true}
                        defaultLimit={10}
                        baseTitle="Vehicles"
                        addEnabled={true}
                        client={this.client}
                      />
                    )}

                    {this.client.isChildAccount && (
                      <div className="mb-4">
                        &gt; Vehicles managed by:{' '}
                        <NavLink
                          strict={+true}
                          to={`/client/${this.client.parentAccount.id}`}
                        >
                          {this.client.parentAccount.name}
                        </NavLink>
                      </div>
                    )}
                  </Col>
                )}
              </Row>

              <Row>
                <Col xl="12" id="insurance">
                  {this.client && (
                    <InsurancePoliciesTable
                      dataUrl={`/users/${this.client.id}/insurance_policies`}
                      embedded
                      defaultLimit={10}
                      baseTitle={'Insurance Policies'}
                      addEnabled={true}
                    />
                  )}
                </Col>
              </Row>

              {this.client?.isParent && (
                <Row>
                  <Col xl="12" id="subaccounts">
                    <SubaccountsTable
                      dataUrl={`/subaccounts?parent_id=${this.client.id}`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Subaccounts"
                      client={this.client}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col xl="12" id="invitees">
                  {this.client && (
                    <ClientsTable
                      dataUrl={`/clients?invited_by=${this.client.id}`}
                      embedded
                      defaultLimit={10}
                      baseTitle="Invitees"
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="12" id="utms">
                  {this.client && (
                    <UtmsTable
                      dataUrl={`/users/${this.client.id}/utms`}
                      embedded
                      defaultLimit={10}
                      baseTitle="UTMs"
                      client={this.client}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )
    }
  },
)

decorate(ClientShow, {
  client: observable,
  fetchData: action,
  showActionDropdown: observable,
  showSubscriptionModal: observable,
  signedUpViaIcon: computed,
  updateClient: action,
  _toggleClientModal: action,
  _toggleSubaccountModal: action,
  _subaccountModalOpen: observable,
  showClientModal: observable,
  showBookingModal: observable,
  currentSubscription: computed,
  showFavoriteBlockedModal: observable,
  favoriteBlockedTitle: observable,
  favoriteBlockedType: observable,
  favoriteBlockedUrl: observable,
  favoriteBlockedSubmitHandler: observable,
  showAvatarModal: observable,
  toggleAvatarModal: action,
  memberFor: computed,
  _toggleConfirmationModal: action,
  _togglePauseConfirmationModal: action,
  _confirmationModalOpen: observable,
  _confirmationPauseModalOpen: observable,
  _promptModalOpen: observable,
  _togglePromptModal: action,
  _showjumpDropdown: observable,
})
