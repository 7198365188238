import { decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import Avatar from 'react-avatar'
import RelativeTime from 'react-relative-time'
import { NavLink } from 'react-router-dom'
import { Badge } from 'reactstrap'
import ClipboardLink from '../../components/Common/ClipboardLink'
import { ClientModal } from '../../components/Modals/ClientModal'
import { MyTable } from '../../components/MyTable/MyTable'
import { client } from '../../helpers/client'
import { Client } from '../../models/Client'
import humanizeString from 'humanize-string'

export const ClientsTable = observer(
  class ClientsTable extends Component {
    addModalOpen

    ambassadorFormatter = (isAmbassador) => {
      if (isAmbassador) {
        return <Badge className="ml-1 badge-soft-success">Ambassador</Badge>
      } else {
        return <></>
      }
    }

    clientFormatter = (cell, row) => {
      const url = `/client/${row.id}`

      return (
        <NavLink strict={+true} to={url}>
          <Avatar
            name={row.name}
            src={row.avatarUrl}
            size={32}
            round
            style={{ marginRight: '10px' }}
          />
          {cell}
          <br />
          {this.ambassadorFormatter(row.ambassador)}
        </NavLink>
      )
    }

    idFormatter = (cell, _row) => (
      <>
        <NavLink strict={+true} to={`/client/${cell}`} key={cell}>
          {cell.split('-')[0]}
        </NavLink>
        <ClipboardLink text={cell} />
      </>
    )

    createdAtFormatter = (cell, row) => {
      if (cell) {
        return <RelativeTime value={cell} />
      }
    }

    emailFormatter = (cell, row) => {
      if (cell) {
        return <a href={`mailto:${cell}`}>{cell}</a>
      }
    }

    phoneFormatter = (cell, _row) => {
      if (cell) {
        return <a href={`tel:${cell}`}>{cell}</a>
      }
    }

    statusFormatter = (cell, _row) => {
      switch (cell) {
        case 'lead':
        case 'prospect':
          return (
            <Badge color="warning" className="mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        case 'trial':
        case 'active':
          return (
            <Badge className="badge-soft-success mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        case 'cancelled':
        case 'removed':
        case 'suspended':
          return (
            <Badge color="danger" className="mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        case 'awaiting_booking':
          return (
            <Badge color="warning" className="mr-1">
              Awaiting Booking
            </Badge>
          )
        default:
          return (
            <Badge color="danger" className="mr-1">
              unknown
            </Badge>
          )
      }
    }

    subscriptionFormatter = (cell, _row) => cell?.displayName || 'none'

    accountTypeIcon = (type) => {
      switch (type) {
        case 'individual':
          return 'bx bx-user'
        case 'corporate':
          return 'bx bx-buildings'
        case 'partnership':
          return 'fa fa-users'
        case 'event_partnership':
          return 'bx bx-calendar'
        default:
          return ''
      }
    }
    accountTypeFormatter = (cell, _row) =>
      cell ? (
        <>
          <i className={`${this.accountTypeIcon(cell)}`} />{' '}
          {humanizeString(cell)}
        </>
      ) : (
        ''
      )

    dataColumns = [
      {
        dataField: 'id',
        name: 'ID',
        priority: 1,
        formatter: this.idFormatter,
        sort: true,
      },
      {
        dataField: 'name',
        name: 'Name',
        priority: 1,
        formatter: this.clientFormatter,
        sort: true,
      },
      {
        dataField: 'email',
        name: 'Email',
        priority: 1,
        formatter: this.emailFormatter,
        sort: true,
      },
      {
        dataField: 'phoneNumber',
        name: 'Phone',
        priority: 1,
        formatter: this.phoneFormatter,
        sort: true,
      },
      {
        dataField: 'zipCode',
        name: 'Zip',
        priority: 1,
        sort: true,
      },
      {
        dataField: 'status',
        name: 'Status',
        priority: 1,
        sort: true,
        formatter: this.statusFormatter,
      },
      {
        dataField: 'accountType',
        name: 'Account Type',
        priority: 1,
        sort: true,
        formatter: this.accountTypeFormatter,
      },
      {
        dataField: 'tripsCount',
        name: 'Rides',
        priority: 1,
        sort: true,
      },
      {
        dataField: 'currentSubscription',
        name: 'Subscription',
        priority: 1,
        formatter: this.subscriptionFormatter,
      },
      {
        dataField: 'createdAt',
        name: 'Joined At',
        priority: 1,
        formatter: this.createdAtFormatter,
        sort: true,
      },
    ]

    createClient = async (_e, values) => {
      let hourlyRateOverride
      let billingRateOverride

      if (
        values.hourlyRateOverride === 0 ||
        values.hourlyRateOverride === '0' ||
        values.hourlyRateOverride
      ) {
        hourlyRateOverride = values.hourlyRateOverride * 100
      }

      if (
        values.billingRateOverride === 0 ||
        values.billingRateOverride === '0' ||
        values.billingRateOverride
      ) {
        billingRateOverride = values.billingRateOverride * 100
      }

      const data = {
        client: {
          name: values.name,
          email: values.email,
          gender: values.gender,
          phone_number: values.phoneNumber,
          zip_code: values.zipCode,
          invite_code: values.inviteCode,
          hourly_rate_cents_override: hourlyRateOverride,
          billing_rate_cents_override: billingRateOverride,
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          internal_account: values.internalAccount,
          account_type: values.accountType,
          dryver: values.dryver,
          preferences_attributes: {
            auto_tip_percentage: values.autoTipPercentage,
            driver_talking_type: values.driverTalkingType,
            driver_driving_type: values.driverDrivingType,
          },
          ambassador: values.ambassador,
        },
      }

      Object.keys(data).forEach(
        (key) =>
          ![
            'hourly_rate_cents_override',
            'billing_rate_cents_override',
          ].includes(key) &&
          data[key] === null &&
          delete data[key],
      )

      client.makeApiCall(
        'post',
        '/clients',
        data,
        'Client created successfully',
        'Unable to create new client',
        (response) => (window.location = `/client/${response.data.id}`),
      )
    }

    render() {
      return (
        <>
          <ClientModal
            isOpen={this.addModalOpen}
            toggle={() => {
              runInAction(() => {
                this.addModalOpen = !this.addModalOpen
              })
            }}
            title="New Client"
            submitHandler={this.createClient}
          />

          <MyTable
            model={Client}
            filter={this.props.filter}
            dataUrl={this.props.dataUrl || '/clients'}
            breadcrumbBaseTitle={this.props.baseTitle || 'Clients'}
            dataColumns={this.dataColumns}
            embedded={this.props.embedded}
            dateFilterColumn={'createdAt'}
            dateFilterLabel={'Created At'}
            defaultLimit={this.props.defaultLimit}
            searchable
            singleActions={
              this.props.addEnabled && {
                'Add +': () =>
                  runInAction(() => {
                    this.addModalOpen = !this.addModalOpen
                  }),
              }
            }
          />
        </>
      )
    }
  },
)

decorate(ClientsTable, {
  addModalOpen: observable,
})
