import AvSelect from '@availity/reactstrap-validation-select';
import '@availity/reactstrap-validation-select/styles.scss';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import humps from 'humps';
import debounce from 'lodash.debounce';
import { DateTime } from 'luxon';
import { action, computed, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Col, FormGroup, Label, Modal, Row } from 'reactstrap';
import { titleize } from '../../helpers/titleize';
import { client } from '../../helpers/client';
import HereEstimate from "../../helpers/HereEstimate";
import { Address } from '../../models/Address';
import { Trip } from '../../models/Trip';
import { AddressModal } from './AddressModal';
import { CardModal } from './CardModal';
import { VehicleModal } from './VehicleModal';

export const BookingModal = observer(class BookingModal extends Component {
  oneWay = false

  airport = false

  addressModalOpen = false

  cardModalOpen = false

  vehicleModalOpen = false

  clientPreferredDrivers = []

  showEmbarkInput
  showArriveInput
  showStartInput
  showEndInput
  showVehiclePickupEmbarkInput
  showDropoffClientInput

  selectedAirport

  _possibleAirports = []
  _bookingStops = []

  constructor(props) {
    super(props);

    this.loadAssociationData();

    if (this.props.booking) {
      this.loadBooking();
    }

    this._debouncedAirportSearch = debounce(
      this._fetchAirports,
      1000
    );
  }

  _setDefaults = () => {
    const defaultAddress = this.props.client.addressesStore.default
    const defaultCard = this.props.client.cardsStore.default
    const defaultVehicle = this.props.client.vehiclesStore.default

    if (defaultVehicle) {
      this.booking.vehicle = defaultVehicle.asJson
    }

    if (defaultAddress) {
      this.booking.pickupAddress = defaultAddress.asJson
    }

    if (defaultCard) {
      this.booking.card = defaultCard.asJson
    }
  }

  loadBooking = async () => {
    if (this.props.booking?.bookingType === 'one_way' || this.props.booking?.bookingType === 'scheduled_one_way') {
      this.oneWay = true
    }

    if (this.props.booking?.bookingType === 'airport') {
      this.airport = true

      await this._fetchAirports(this.props.booking.airportCode)

      this.selectedAirport = this._possibleAirports.find(airport => {
        const a1 = airport.title.toLowerCase()
        const a2 = this.props.booking.airportCode.toLowerCase()

        return a1.includes(a2) || a2.includes(a1)
      })

      this._bookingStops = this.props.booking.tripStops
      this.props.booking.airport = this.selectedAirport.id
    }
  }

  loadAssociationData = async () => {
    await this.props.client.fetchAddresses(50);
    await this.props.client.fetchCards(50);
    await this.props.client.fetchVehicles(50);

    if (!this.props.booking) {
      this.booking = new Trip({ id: "new", attributes: {
        requestedStartTime: (new Date()).toISOString()
      } })
      this._setDefaults()
    }
  }

  handleChange = (_e, v) => {
    switch (v) {
      case 'displayNewCardModal':
        this.cardModalOpen = true;
        break;
      case 'displayNewPickupForm':
        this.addressModalOpen = true;
        break;
      case 'displayNewDropoffForm':
        this.addressModalOpen = true;
        break;
      case 'displayNewVehicleForm':
        this.vehicleModalOpen = true;
        break;
      default:
    }
  }

  vehicleSubmitHandler = async (_e, values) => {
    const data = {
      vehicle: {
        year: values.year,
        make: values.make,
        model: values.model,
        color: values.color,
        vin: values.vin,
        license_plate_number: values.licensePlateNumber,
        manual_transmission: values.manualTransmission,
      },
    };

    Object.keys(data).forEach((key) => (data[key] === null) && delete data[key]);

    client.makeApiCall(
      'post', `/clients/${this.props.client.id}/vehicles`, data,
      'Vehicle added successfully',
      'Unable to add new vehicle',
      async () => {
        runInAction(() => this.vehicleModalOpen = false);
        await this.props.client.fetchVehicles(50);
      }
    )
  }

  addressSubmitHandler = async (_e, values) => {
    const data = {
      address: {
        label: values.label,
        line1: values.line1,
        line2: values.line2,
        city: values.city,
        state: values.state,
        postal_code: values.postalCode,
      },
    };

    Object.keys(data).forEach((key) => (data[key] === null) && delete data[key]);

    const address = await client.makeApiCall(
      'post', `/clients/${this.props.client.id}/addresses`, data,
      'Address added successfully',
      'Unable to add new address',
      async (addressResponse) => {
        const address = new Address(addressResponse.data)

        runInAction(() => this.addressModalOpen = false);
        await this.props.client.fetchAddresses(50);

        return address
      }
    )

    return address
  }

  _changeRideType = (_e, v) => {
    if (v === 'one_way' || v === 'scheduled_one_way') {
      this.oneWay = true
    } else {
      this.oneWay = false
    }

    if (v === 'airport') {
      this.airport = true
    } else {
      this.airport = false
    }
  }

  _handleAirportChange = (v) => {
    const query = encodeURIComponent(v)
    this._debouncedAirportSearch(query)
  }

  _fetchAirports = async (airportQuery) => {
    if (!airportQuery || airportQuery.length === 0) { return }

    const encodedQuery = `${ airportQuery }%20airport`
    const scope = "39.8283,98.5795"
    const hereApiUrl = "https://discover.search.hereapi.com/v1/discover"

    let url = `${hereApiUrl}?at=${scope}&in=countryCode:USA&q=${encodedQuery}&lang=en-US&apiKey=${process.env.REACT_APP_HERE_API_KEY}&show=tz`

    await fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then((responseJSON) => {
        this._possibleAirports = responseJSON.items
      }).catch((err) => {
        console.dir(err)
      })
  }

  get _airportOptions() {
    return this._possibleAirports.map(airport => ({ label: airport.title, value: airport.id }))
  }

  _findAirport = (id) => {
    return this._possibleAirports.find(airport => airport.id === id)
  }

  _findAddress = (id) => {
    return this.props.client.addresses.find(address => address.id === id)
  }

  _handleStopChange = (_e, v) => {
    if (v === 'displayNewPickupForm') {
      this.addressModalOpen = true
      return
     }
    const stop = this.props.client.addresses.find(address => address.id === v)
    this._bookingStops.push(stop)
  }

  _removeStop = (stopToRemove) => {
    this._bookingStops = this._bookingStops.filter(stop => stop.id !== stopToRemove.id)
  }

  _selectAirport = (airportId) => {
    const airport = this._findAirport(airportId)
    const airportAddress = airport.address

    airportAddress.id = airport.id

    this._bookingStops.push(airportAddress)
  }

  onDragEnd = (result) => {
    // dropped outside the list or no movement
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    this._reorder(this._bookingStops, result.source.index, result.destination.index)
  }

  _reorder = (array, a, b) => {
    [array[a], array[b]] = [array[b], array[a]]
  }

  formatTimeParamsToLocal(timeString) {
    if (!timeString) {
      return null
    }

    const hourOffset = (new Date(timeString).getTimezoneOffset() / 60)
    let hourPadded = "0" + hourOffset.toString()

    return `${timeString} -${hourPadded.substr(hourPadded.length-2)}00`
  }

  _submit = async (e, values) => {
    if (this.props.booking) {
      await this.editBooking(e, values)
    } else {
      await this.createBooking(e, values)
    }
  }

  // TODO: de-dupe these next 2 functions
  editBooking = async (_e, values) => {
    const data = {
      booking: {
        status: values.status,
        requested_start_time: this.formatTimeParamsToLocal(values.requestedStartTimeLocalizedString),
        booking_started_at: this.formatTimeParamsToLocal(values.bookingStartedAtLocalizedString),
        booking_ended_at: this.formatTimeParamsToLocal(values.bookingEndedAtLocalizedString),
        driver_embarked_at: this.formatTimeParamsToLocal(values.driverEmbarkedAtLocalizedString),
        driver_arrived_at: this.formatTimeParamsToLocal(values.driverArrivedAtLocalizedString),
        driver_picking_up_vehicle_at: this.formatTimeParamsToLocal(values.driverPickingUpVehicleAtLocalizedString),
        driver_dropped_off_client_at: this.formatTimeParamsToLocal(values.driverDroppedOffClientAtLocalizedString),
        estimated_duration_minutes: values.estimatedDurationMinutes,
        card_id: values.cardId,
        address_id: values.addressId,
        round_trip: !values.oneWayTrip,
        vehicle_transport: values.vehicleTransport,
        dropoff_address_id: values.dropoffAddressId,
        vehicle_id: values.vehicleId,
        notes: values.notes,
        admin_driver_notes: values.adminDriverNotes,
        vehicle_pickup_notes: values.vehiclePickupNotes,
        booking_type: values.bookingType,
        airport_trip_type: values.airportTripType,
        airport_code: values.airport ? this._findAirport(values.airport).label : null,
        airline: values.airline,
        flight_number: values.flightNumber,
        estimated_price_cents: values.estimatedPriceFormatted ? values.estimatedPriceFormatted * 100 : null,
        booking_stop_ids: this._bookingStops.map(address => address.addressId || address.id),
      },
    };

    if (data.booking.booking_type === 'airport') {
      const airport = this._findAirport(values.airport)
      const address = this._findAddress(values.addressId)

      // create or get airport address in database
      let airportAddress = await this.addressSubmitHandler(null, {
        label: airport.title,
        line1: `${airport.address.houseNumber} ${airport.address.street}`.trim(),
        city: airport.address.city,
        state: airport.address.stateCode,
        postalCode: airport.address.postalCode,
      })

      airportAddress = new Address(airportAddress.data)

      data.booking.airport_code = airportAddress.label

      if (values.airportTripType === "arriving") {
        const pickupTime = new Date(values.requestedStartTimeLocalizedString)
        const leadTime = await HereEstimate.calculate([address, airportAddress], pickupTime)

        data.booking.estimated_vehicle_pickup_lead_time_minutes = (leadTime / 60) + 15

        let dateTime = DateTime.fromISO(values.requestedStartTimeLocalizedString)
        dateTime = dateTime.minus({ minutes: data.booking.estimated_vehicle_pickup_lead_time_minutes })

        data.booking.requested_start_time = dateTime.toISO()
      }

      // swap out address id for the one in our database
      data.booking.booking_stop_ids = this._bookingStops.map(
        stop => { return stop.id === airport.id ? (airportAddress.addressId || airportAddress.id) : (stop.addressId || stop.id) }
      )
    }

    Object.keys(data.booking).forEach((key) => (data.booking[key] === null) && delete data.booking[key]);
    Object.keys(data.booking).forEach((key) => (data.booking[key] === this.props.booking[humps.camelize(key)]) && delete data.booking[key]);

    client.makeApiCall(
      'put', `/bookings/${this.props.booking.id}`, data,
      'Booking updated successfully',
      'Unable to update booking',
      () => window.location.reload()
    )
  }

  createBooking = async (_e, values) => {
    const data = {
      booking: {
        requested_start_time: this.formatTimeParamsToLocal(values.requestedStartTimeLocalizedString),
        booking_started_at: this.formatTimeParamsToLocal(values.bookingStartedAtLocalizedString),
        booking_ended_at: this.formatTimeParamsToLocal(values.bookingEndedAtLocalizedString),
        estimated_duration_minutes: values.estimatedDurationMinutes,
        card_id: values.cardId,
        address_id: values.addressId,
        round_trip: !values.oneWayTrip,
        vehicle_transport: values.vehicleTransport,
        dropoff_address_id: values.dropoffAddressId,
        vehicle_id: values.vehicleId,
        notes: values.notes,
        admin_driver_notes: values.adminDriverNotes,
        vehicle_pickup_notes: values.vehiclePickupNotes,
        booking_type: values.bookingType,
        airport_trip_type: values.airportTripType,
        airport_code: values.airport ? this._findAirport(values.airport).label : null,
        airline: values.airline,
        flight_number: values.flightNumber,
        estimated_price_cents: values.estimatedPriceFormatted ? values.estimatedPriceFormatted * 100 : null,
        booking_stop_ids: this._bookingStops.map(address => address.addressId || address.id),
      }
    };

    if (data.booking.booking_type === 'airport') {
      const airport = this._findAirport(values.airport)
      const address = this._findAddress(values.addressId)

      // create or get airport address in database
      let airportAddress = await this.addressSubmitHandler(null, {
        label: airport.title,
        line1: `${airport.address.houseNumber} ${airport.address.street}`.trim(),
        city: airport.address.city,
        state: airport.address.stateCode,
        postalCode: airport.address.postalCode,
      })

      airportAddress = new Address(airportAddress.data)

      data.booking.airport_code = airportAddress.label

      if (values.airportTripType === "arriving") {
        const pickupTime = new Date(values.requestedStartTimeLocalizedString)
        const leadTime = await HereEstimate.calculate([address, airportAddress], pickupTime)

        data.booking.estimated_vehicle_pickup_lead_time_minutes = (leadTime / 60) + 15

        let dateTime = DateTime.fromISO(values.requestedStartTimeLocalizedString)
        dateTime = dateTime.minus({ minutes: data.booking.estimated_vehicle_pickup_lead_time_minutes })

        data.booking.requested_start_time = dateTime.toISO()
      }

      // swap out address id for the one in our database
      data.booking.booking_stop_ids = this._bookingStops.map(
        stop => { return stop.id === airport.id ? (airportAddress.id || airportAddress.addressId) : (stop.id || stop.addressId) }
      )
    }

    Object.keys(data.booking).forEach((key) => (data.booking[key] === null) && delete data.booking[key]);

    client.makeApiCall(
      'post', `/clients/${this.props.client.id}/bookings`, data,
      'Booking created successfully',
      'Unable to create booking',
      () => window.location.reload()
    )
  }

  render() {
    return (
      <>
        <AddressModal
          title="Add New Address"
          isOpen={this.addressModalOpen}
          toggle={() => { runInAction(() => { this.addressModalOpen = !this.addressModalOpen; }); }}
          submitHandler={this.addressSubmitHandler}
        />

        <VehicleModal
          title="Add New Vehicle"
          isOpen={this.vehicleModalOpen}
          toggle={() => { runInAction(() => { this.vehicleModalOpen = !this.vehicleModalOpen; }); }}
          submitHandler={this.vehicleSubmitHandler}
        />

        <CardModal
          isOpen={this.cardModalOpen}
          toggle={() => { runInAction(() => { this.cardModalOpen = !this.cardModalOpen; }); }}
          title={`Add New Credit Card`}
          dataUrl={`/clients/${this.props.client.id}/cards`}
          callbackFunction={() => {
            runInAction(() => { this.cardModalOpen = false })
            this.props.client.fetchCards(50)
          }}
          client={this.props.client}
        />

        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              { this.props.title }
            </h5>

            <button
              type="button"
              onClick={this.props.toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <AvForm className="needs-validation" onValidSubmit={this._submit} model={this.props.booking || this.booking}>
              <Row>
                <Col>
                  { this.props.booking && <FormGroup>
                    <Label htmlFor="status">Status (try not to change this - it will not send notifications, etc)</Label>
                    <AvField type="select" name="status" id="statusSelect" onChange={this.handleChange}>
                      <option />
                      <option key={'requested'} value={'requested'}>Requested</option>
                      <option key={'dispatching'} value={'dispatching'}>Dispatching</option>
                      <option key={'confirmed'} value={'confirmed'}>Confirmed</option>
                      <option key={'driver_en_route'} value={'driver_en_route'}>Driver on Way</option>
                      <option key={'driver_arrived'} value={'driver_arrived'}>Driver Arrived</option>
                      <option key={'driver_returning'} value={'driver_returning'}>Driver Returning to Origin</option>
                      <option key={'in_progress'} value={'in_progress'}>In Progress</option>
                      <option key={'completed'} value={'completed'}>Completed</option>
                      <option key={'cancelled'} value={'cancelled'}>Cancelled</option>
                      <option key={'failed'} value={'failed'}>Failed</option>
                    </AvField>
                  </FormGroup> }

                  <FormGroup>
                    <Label htmlFor="bookingType">Ride Type</Label>
                    <AvField type="select" name="bookingType" id="bookingTypeSelect" onChange={this._changeRideType}>
                      <option />
                      <option key={'roundTrip'} value={'round_trip'}>Round Trip</option>
                      <option key={'oneWay'} value={'one_way'}>One Way</option>
                      {/* <option key={'scheduledOneWay'} value={'scheduled_one_way'}>Scheduled One Way</option> */}
                      <option key={'airport'} value={'airport'}>Airport</option>
                      <option key={'overnighter'} value={'overnighter'}>Overnighter</option>
                      <option key={'other'} value={'other'}>Other</option>
                    </AvField>
                  </FormGroup>

                  { this.airport && <>
                      <FormGroup>
                        <Label htmlFor="airportTripType">Departing/Arriving?</Label>
                        <AvField type="select" name="airportTripType" id="airportTripTypeSelect">
                          <option />
                          <option key={'departing'} value={'departing'}>Departing</option>
                          <option key={'arriving'} value={'arriving'}>Arriving</option>
                        </AvField>
                      </FormGroup>

                      <AvGroup>
                        <Label for="airport">Airport</Label>
                        <AvSelect name="airport" options={this._airportOptions} required onInputChange={this._handleAirportChange} onChange={this._selectAirport}/>
                        <AvFeedback>Some error message</AvFeedback>
                      </AvGroup>

                      <FormGroup>
                        <Label htmlFor="airline">Airline</Label>
                        <AvField
                          name="airline"
                          placeholder="Airline Name"
                          type="text"
                          className="form-control"
                          id="airline"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="flightNumber">Flight Number</Label>
                        <AvField
                          name="flightNumber"
                          placeholder="Flight Number"
                          type="text"
                          className="form-control"
                          id="flightNumber"
                        />
                      </FormGroup>
                    </>
                  }

                  <FormGroup>
                    <Label htmlFor="requestedStartTimeLocalizedString">Pickup Time</Label>
                    { this.airport && <div className="text-muted mb-2" style={{ fontSize: "10px" }}>(for airport arrivals, this is when the client should be picked up at the airport)</div> }
                    <AvField
                      name="requestedStartTimeLocalizedString"
                      placeholder="Label (optional)"
                      type="datetime-local"
                      className="form-control"
                      id="requestedStartTimeLocalizedString"
                      validate={{ required: { value: true } }}
                    />
                  </FormGroup>

                  { <FormGroup>
                    <Label htmlFor="estimatedDurationMinutes">Estimated Trip Duration (minutes)</Label>
                    <AvField
                      name="estimatedDurationMinutes"
                      placeholder="120"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      id="estimatedDurationMinutes"
                      validate={{ required: { value: true }, number: true }}
                    />
                  </FormGroup> }

                  { <FormGroup>
                    <Label htmlFor="estimatedPriceFormatted">Estimated Price</Label>
                    <AvField
                      name="estimatedPriceFormatted"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      id="estimatedPriceFormatted"
                      validate={{ required: { value: false }, number: true }}
                    />
                  </FormGroup> }

                  { this.props.booking && this.props.booking.p2p && <FormGroup>
                    <Label htmlFor="driverPickingUpVehicleAtLocalizedString">Driver Picking Up Vehicle At <span onClick={
                      () => {runInAction(() => {this.showVehiclePickupEmbarkInput = !this.showVehiclePickupEmbarkInput})}
                    }>&#8681;</span></Label>
                    { (this.props.booking.driverPickingUpVehicleAtLocalizedString || this.showVehiclePickupEmbarkInput) && <AvField
                      name="driverPickingUpVehicleAtLocalizedString"
                      type="datetime-local"
                      className="form-control"
                      id="driverPickingUpVehicleAtLocalizedString"
                    /> }
                  </FormGroup> }

                  { this.props.booking && <FormGroup>
                    <Label htmlFor="driverEmbarkedAtLocalizedString">Driver Embarked to Client At <span onClick={
                      () => {runInAction(() => {this.showEmbarkInput = !this.showEmbarkInput})}
                    }>&#8681;</span></Label>
                    { (this.props.booking.driverEmbarkedAtLocalizedString || this.showEmbarkInput) && <AvField
                      name="driverEmbarkedAtLocalizedString"
                      type="datetime-local"
                      className="form-control"
                      id="driverEmbarkedAtLocalizedString"
                    /> }
                  </FormGroup> }

                  { this.props.booking && <FormGroup>
                    <Label htmlFor="driverArrivedAtLocalizedString">Driver Arrived At <span onClick={
                      () => {runInAction(() => {this.showArriveInput = !this.showArriveInput})}
                    }>&#8681;</span></Label>
                    { (this.props.booking.driverArrivedAtLocalizedString || this.showArriveInput) && <AvField
                      name="driverArrivedAtLocalizedString"
                      type="datetime-local"
                      className="form-control"
                      id="driverArrivedAtLocalizedString"
                    /> }
                  </FormGroup> }

                  { this.props.booking && <FormGroup>
                    <Label htmlFor="bookingStartedAtLocalizedString">Trip Actually Started At <span onClick={
                      () => {runInAction(() => {this.showStartInput = !this.showStartInput})}
                    }>&#8681;</span></Label>
                    { (this.props.booking.bookingStartedAtLocalizedString || this.showStartInput) && <AvField
                      name="bookingStartedAtLocalizedString"
                      type="datetime-local"
                      className="form-control"
                      id="bookingStartedAtLocalizedString"
                      validate={{ required: { value: true } }}
                    /> }
                  </FormGroup> }

                  { this.props.booking && this.props.booking.p2p && <FormGroup>
                    <Label htmlFor="driverDroppedOffClientAtLocalizedString">Driver Picking Up Vehicle At <span onClick={
                      () => {runInAction(() => {this.showDropoffClientInput = !this.showDropoffClientInput})}
                    }>&#8681;</span></Label>
                    { (this.props.booking.driverDroppedOffClientAtLocalizedString || this.showDropoffClientInput) && <AvField
                      name="driverDroppedOffClientAtLocalizedString"
                      type="datetime-local"
                      className="form-control"
                      id="driverDroppedOffClientAtLocalizedString"
                    /> }
                  </FormGroup> }

                  { this.props.booking && <FormGroup>
                    <Label htmlFor="bookingEndedAtLocalizedString">Trip Actually Ended At <span onClick={
                      () => {runInAction(() => {this.showEndInput = !this.showEndInput})}
                    }>&#8681;</span></Label>
                    { (this.props.booking.bookingEndedAtLocalizedString || this.showEndInput) && <AvField
                      name="bookingEndedAtLocalizedString"
                      type="datetime-local"
                      className="form-control"
                      id="bookingEndedAtLocalizedString"
                      validate={{ required: { value: true } }}
                    /> }
                  </FormGroup> }

                  <FormGroup>
                    <AvField type="select" name="cardId" label="Credit Card" onChange={this.handleChange}>
                      <option />
                      { this.props.client.cards.map((card) => (
                        <option key={card.id} value={card.id}>
                          {
                            [card.label && titleize(card.label), card.brand && titleize(card.brand), card.last4, `${card.expMonth}/${card.expYear}`, card.isDefault ? '(default)' : null].filter((el) => el != null).join(' - ')
                          }
                        </option>
                      ))}

                      <optgroup>
                        <option key="newCardOpt" value="displayNewCardModal">New Card</option>
                      </optgroup>
                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    <AvField type="select" name="addressId" label="Pickup Address" onChange={this.handleChange}>
                      <option />
                      { this.props.client.addresses.map((address) => (
                        <option key={address.id} value={address.id}>
                          {
                            [address.label && `[${address.label}]`, address.line1, address.city, address.state, address.isDefault ? '(default)' : null].filter((el) => el != null).join(', ')
                          }
                        </option>
                      ))}
                      <optgroup>
                        <option key="newPickupOpt" value="displayNewPickupForm">New Address</option>
                      </optgroup>
                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    <h6>Booking Stops</h6>

                    {/* dragdropcontext not re-rendering without this */}
                    { console.log(this._bookingStops) }

                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="stops">
                        { (provided) => (
                          <ul
                            className="stops list-group container mb-4"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {this._bookingStops.filter(stop => stop?.id).map((stop, index) => {
                              return (
                                <Draggable key={stop.id} draggableId={stop.id} index={index}>
                                  { (provided) => (
                                    <li
                                      className="list-group-item list-group-item-action row ml-1 pr-0 pl-0 list-group-item-draggable"
                                      style={{ cursor: "pointer" }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="col-1"><div className="badge badge-primary badge-pill">{index+1}</div></div>
                                      <div className="col">{stop.label ? stop.label : stop.street}{stop.line1 ? ` - ${stop.line1}` : ''}</div>
                                      <div className="col-1"><i className="fa fa-fw fa-bars" /></div>
                                      <div className="col-1 text-danger"><i onClick={() => this._removeStop(stop)} className="bx bx-x-circle" style={{ cursor: "pointer" }} /></div>
                                    </li>
                                  ) }
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </ul>
                        ) }
                      </Droppable>
                    </DragDropContext>

                    <AvField type="select" name={'bookingStop'} label={null} onChange={this._handleStopChange}>
                      <option />
                      { this.props.client.addresses.map((address) => (
                        <option key={address.id} value={address.id}>
                          {
                            [address.label && `[${address.label}]`, address.line1, address.city, address.state, address.isDefault ? '(default)' : null].filter((el) => el != null).join(', ')
                          }
                        </option>
                      ))}
                      <optgroup>
                        <option key={'newStopOpt'} value="displayNewPickupForm">New Address</option>
                      </optgroup>
                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    <AvGroup check>
                      <Label check>
                        <AvInput type="checkbox" name="vehicleTransport" /> Vehicle Transport Only?
                      </Label>
                    </AvGroup>
                      {/* <AvCheckboxGroup name="vehicleTransport" label="">
                        <AvCheckbox customInput label="Vehicle Transport Only?" value="true" name="vehicleTransport" />
                      </AvCheckboxGroup> */}
                  </FormGroup>

                  { this.oneWay && (
                    <FormGroup>
                      <AvField type="select" name="dropoffAddressId" label="Dropoff Address" onChange={this.handleChange}>
                        <option />
                        { this.props.client.addresses.map((address) => (
                          <option key={address.id} value={address.id}>
                            {
                                [
                                  address.label && `[${address.label}]`,
                                  address.line1,
                                  address.city,
                                  address.state,
                                  address.isDefault ? '(default)' : null,
                                ].filter((el) => el != null).join(', ')
                              }
                          </option>
                        ))}
                        <optgroup label="Action">
                          <option key="newDropoffOpt" value="displayNewDropoffForm">New Address</option>
                        </optgroup>
                      </AvField>
                    </FormGroup>
                  ) }

                  <FormGroup>
                    <AvField type="select" name="vehicleId" label="Vehicle" onChange={this.handleChange}>
                      <option />
                      { this.props.client.vehicles.map((vehicle) => (
                        <option key={vehicle.id} value={vehicle.id}>
                          {
                                [
                                  vehicle.color && titleize(vehicle.color),
                                  vehicle.year,
                                  vehicle.make && titleize(vehicle.make),
                                  vehicle.model && titleize(vehicle.model),
                                  vehicle.isDefault ? '(default)' : null,
                                ].filter((el) => el != null).join(', ')
                              }
                        </option>
                      ))}
                      <optgroup label="Action">
                        <option key="newVehicleOpt" value="displayNewVehicleForm">New Vehicle</option>
                      </optgroup>

                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="notes">Notes for Driver (from and visible to Client)</Label>
                    <AvField
                      name="notes"
                      placeholder="Notes for driver about the trip"
                      type="textarea"
                      className="form-control"
                      id="notes"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="adminDriverNotes">Notes for Driver (from Admin)</Label>
                    <AvField
                      name="adminDriverNotes"
                      placeholder="Notes for driver about the trip from admin (not visible to client)"
                      type="textarea"
                      className="form-control"
                      id="adminDriverNotes"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="vehiclePickupNotes">P2P Vehicle Pickup Notes</Label>
                    <AvField
                      name="vehiclePickupNotes"
                      placeholder="Notes for driver about retrieving the P2P vehicle"
                      type="textarea"
                      className="form-control"
                      id="vehiclePickupNotes"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" type="submit">{ this.props.booking ? 'Save' : 'Book' }</Button>
            </AvForm>
          </div>
        </Modal>
      </>
    );
  }
});

decorate(BookingModal, {
  oneWay: observable,
  addressModalOpen: observable,
  cardModalOpen: observable,
  vehicleModalOpen: observable,
  handleChange: action,
  loadAssociationData: action,
  vehicleSubmitHandler: action,
  addressSubmitHandler: action,
  showArriveInput: observable,
  showEmbarkInput: observable,
  showVehiclePickupEmbarkInput: observable,
  showDropoffClientInput: observable,
  showEndInput: observable,
  showStartInput: observable,
  loadBooking: action,
  airport: observable,
  selectedAirport: observable,
  _possibleAirports: observable,
  _fetchAirports: action,
  _airportOptions: computed,
  _bookingStops: observable,
  _handleStopChange: action,
  _removeStop: action,
  _selectAirport: action,
  _reorder: action,
  _setDefaults: action,
});
