import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';

import { decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';


export const ZipCodeModal = observer(class ZipCodeModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.zipCode}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="zipCode">Zip Code (5 or 9 digit)</Label>
                  <AvField
                    value={this.props.zipCode?.code}
                    name="code"
                    placeholder="code"
                    type="text"
                    className="form-control"
                    id="code"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="regionName">Region Name</Label>
                  <AvField
                    value={this.props.zipCode?.regionName}
                    name="regionName"
                    placeholder="regionName"
                    type="text"
                    className="form-control"
                    id="regionName"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="cityName">City Name</Label>
                  <AvField
                    value={this.props.zipCode?.cityName}
                    name="cityName"
                    placeholder="cityName"
                    type="text"
                    className="form-control"
                    id="cityName"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="stateAbbr">State Abbr</Label>
                  <AvField
                    value={this.props.zipCode?.stateAbbr}
                    name="stateAbbr"
                    placeholder="stateAbbr"
                    type="text"
                    className="form-control"
                    id="stateAbbr"
                    maxLength="2"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="status">Status</Label>
                  <AvGroup>
                    <AvField type="select" name="status" id="statuses" value={this.props.zipCode?.status || 'launched'}>
                      <option key={'launched'} value={'launched'}>Launched</option>
                      <option key={'new'} value={'new'}>New (cannot book)</option>
                      <option key={'soft'} value={'soft'}>Soft-Launched</option>
                      <option key={'closed'} value={'closed'}>Closed (cannot book)</option>
                    </AvField>
                  </AvGroup>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(ZipCodeModal, {
});
