import { decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { myToast } from '../../components/Common/MyToast';
import { client } from '../../helpers/client';

export const BookingImport = observer(class BookingImport extends Component {
  handleSubmit = async (values, rowNum) => {
    if (values.user_id.toUpperCase().includes("CLIENT")) {
      return
    }

    const data = {
      user_id: values.user_id,
      booking_type: values.booking_type,
      start_date: values.start_date,
      start_time: values.start_time,
      address_id: values.address_id,
      dropoff_address_id: values.dropoff_address_id,
      card_id: values.card_id,
      vehicle_id: values.vehicle_id,
      estimated_duration_minutes: parseInt(values.estimated_duration_minutes),
      driver_id: values.driver_id,
      preferred_driver_id: values.preferred_driver_id,
      admin_driver_notes: values.admin_driver_notes,
      notes: values.notes,
      airport_trip_type: values.airport_trip_type,
      airport_code: values.airport_code,
      airline: values.airline,
      flight_number: values.flight_number,
      vehicle_transport: values.vehicle_transport,
      vehicle_pickup_notes: values.vehicle_pickup_notes,

      address_label: values.address_label,
      address_line1: values.address_line1,
      address_city: values.address_city,
      address_state: values.address_state,
      address_postal_code: values.address_postal_code,

      dropoff_address_label: values.dropoff_address_label,
      dropoff_address_line1: values.dropoff_address_line1,
      dropoff_address_city: values.dropoff_address_city,
      dropoff_address_state: values.dropoff_address_state,
      dropoff_address_postal_code: values.dropoff_address_postal_code,

      vehicle_year: values.vehicle_year,
      vehicle_make: values.vehicle_make,
      vehicle_model: values.vehicle_model,
    }

    Object.keys(data).forEach((key) => (
      data[key] === null || data[key] === undefined || data[key].length === 0
    ) && delete data[key]);

    try {
      await this.createBooking(data);
      myToast.showToast('success', 'Booking created successfully', 'Success')
    } catch(err) {
      myToast.showToast('error', `Unable to create booking from row #${rowNum}: ${err.message}`, 'Error')
    }
  }

  createBooking = async (data) => {
    return await client.post(`/bookings`, {
      booking: data,
    });
  }

  validate = (rows) => {
    let pass = true
    let rowNum = 0

    for (const row of rows) {
      rowNum++

      if (row.user_id.toUpperCase().includes("CLIENT")) {
        continue
      }

      if (!['round_trip', 'one_way', 'overnighter', 'airport'].includes(row.booking_type)) {
        myToast.showToast('error', `Booking type must be: round_trip, one_way, overnighter, or airport.  Was: ${row.booking_type} in row ${rowNum}`, 'Error')
        pass = false
      }

      if (!(/\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])/.test(row.start_date))) {
        myToast.showToast('error', `All Dates must be of the format YYYY-MM-DD. Was: ${row.start_date} in row ${rowNum}`, 'Error')
        pass = false
      }

      if (!(/(?:\d|[01]\d|2[0-3]):[0-5]\d (AM|PM|am|pm)/.test(row.start_time))) {
        myToast.showToast('error', `All Times must be of the format HH:MM AM/PM. Was: ${row.start_time} in row ${rowNum}`, 'Error')
        pass = false
      }

      if (!row.address_id && (!row.address_line1 || !row.address_city || !row.address_state || !row.address_postal_code)) {
        myToast.showToast('error', `All bookings must have either an existing Pickup Address ID or a new full address included in row ${rowNum}`, 'Error')
        pass = false
      }

      if (!row.vehicle_id && (!row.vehicle_year || !row.vehicle_make || !row.vehicle_model)) {
        myToast.showToast('error', `All bookings must have either an existing Vehicle ID belonging to the User or a new full vehicle detail included in row ${rowNum}`, 'Error')
        pass = false
      }
    }

    return pass
  }

  render() {
    return(
      <>
        <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="Booking Import" breadcrumbItem="Bookings" />

              <Row>
                <Col xl="12">
                  <Card>
                    <CardHeader className="mt-0 h5 bg-transparent border-bottom text-uppercase card-header">
                      CSV Import
                    </CardHeader>
                    <CardBody>
                      <CardTitle className="mt-0">Drag/drop or click to import a list of bookings to create</CardTitle>
                      <CardText>
                        Supported columns include: Client ID*, Booking Type*, Start Date* (YYYY-MM-DD), Start Time* (HH:MM PM), Pickup Address ID* OR Full Pickup Address (Line1, City, State, Zip), Dropoff Address ID OR Full Dropoff Address (Line1, City, State, Zip), Card ID*, Vehicle ID*, Estimated Length Minutes*, Driver ID, Preferred Driver ID, Admin Driver Notes, Client Driver Notes, Airport Trip Type (departing, arriving), Airport Code, Airline, Flight Number, Vehicle Transport (true, false), Vehicle Pickup Notes (for P2P)<br/>
                        <br/>* required
                      </CardText>

                      <Importer
                          // chunkSize={10000} // optional, internal parsing chunk size in bytes
                          assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                          restartable={true} // optional, lets user choose to upload another file when import is complete
                          onStart={async () => {
                            // const message = window.prompt("If you'd like to auto send a text message to all users in this CSV, input the message now.  Leave blank if you do not wish to message these leads.", "");
                            // await this.updateTextMessage(message)
                          }}
                          processChunk={async (rows) => {
                            // TODO: Add in a confirmation dialog
                            let rowNum = 1
                            if (this.validate(rows)) {
                              await Promise.allSettled(rows.map(async (row) => {
                                await this.handleSubmit(row, rowNum)
                                rowNum++
                              }));
                            }
                          }}
                          onComplete={({ file, fields }) => {
                            // this.updateTextMessage(null)
                            console.log("finished import of file", file, "with fields", fields);
                          }}
                          onClose={() => {
                            // this.updateTextMessage(null)
                            window.location.reload()
                          }}
                        >
                          <ImporterField name="user_id" label="Client ID" />
                          <ImporterField name="booking_type" label="Booking Type" />
                          <ImporterField name="start_date" label="Start Date" />
                          <ImporterField name="start_time" label="Start Time (ET)" />

                          <ImporterField name="address_id" label="Pickup Address ID" optional />
                          <ImporterField name="address_label" label="Pickup Address Label" optional />
                          <ImporterField name="address_line1" label="Pickup Address Line 1" optional />
                          <ImporterField name="address_city" label="Pickup Address City" optional />
                          <ImporterField name="address_state" label="Pickup Address State" optional />
                          <ImporterField name="address_postal_code" label="Pickup Address Zip Code" optional />

                          <ImporterField name="dropoff_address_id" label="Dropoff Address ID" optional />
                          <ImporterField name="dropoff_address_label" label="Dropoff Address Label" optional />
                          <ImporterField name="dropoff_address_line1" label="Dropoff Address Line 1" optional />
                          <ImporterField name="dropoff_address_city" label="Dropoff Address City" optional />
                          <ImporterField name="dropoff_address_state" label="Dropoff Address State" optional />
                          <ImporterField name="dropoff_address_postal_code" label="Dropoff Address Zip Code" optional />

                          <ImporterField name="card_id" label="Credit Card ID" />

                          <ImporterField name="vehicle_id" label="Vehicle ID" optional />
                          <ImporterField name="vehicle_year" label="Vehicle Year" optional />
                          <ImporterField name="vehicle_make" label="Vehicle Make" optional />
                          <ImporterField name="vehicle_model" label="Vehicle Model" optional />

                          <ImporterField name="estimated_duration_minutes" label="Est Length (min)" />
                          <ImporterField name="driver_id" label="Assigned Driver ID" optional />
                          <ImporterField name="preferred_driver_id" label="Preferred Driver ID" optional />
                          <ImporterField name="admin_driver_notes" label="Notes to Driver from Admin" optional />
                          <ImporterField name="notes" label="Notes to Driver from Client" optional />
                          <ImporterField name="airport_trip_type" label="Airport Trip Type (departing/arriving)" optional />
                          <ImporterField name="airport_code" label="Airport Code (ABC)" optional />
                          <ImporterField name="airline" label="airline" optional />
                          <ImporterField name="flight_number" label="Flight Number (AA1234)" optional />
                          <ImporterField name="vehicle_transport" label="Vehicle Only? (true/false)" optional />
                          <ImporterField name="vehicle_pickup_notes" label="Vehicle Pickup Notes (P2P Only)" optional />
                        </Importer>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      </>
    )
  }
});

decorate(BookingImport, {
});
