import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

class MyToast {
  showToast = (toastType, message, title, callback) => {
    toastr.options = {
      positionClass: 'toast-top-center',
      closeButton: true,
      progressBar: true,
      showEasing: 'swing',
      hideEasing: 'swing',
      onHidden: callback
    };

    if (toastType === 'info') toastr.info(message, title);
    else if (toastType === 'warning') toastr.warning(message, title);
    else if (toastType === 'error') toastr.error(message, title);
    else toastr.success(message, title);
  };
}

const myToast = new MyToast();

export { myToast };
