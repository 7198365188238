import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { action, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row, Spinner
} from 'reactstrap';
import { myToast } from '../../components/Common/MyToast';
import { client } from '../../helpers/client';

const stateAbbreviations = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
  'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
  'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
  'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
];

export const CardModal = observer(class CardModal extends Component {
  loading = false

  handleSubmit = async (event, values, elements, stripe) => {
    this.loading = true

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: this.props.client.email,
        name: this.props.client.name,
        phone: this.props.client.phoneNumber,
        address: {
          city: values.city,
          line1: values.line1,
          line2: values.line2,
          postal_code: values.postalCode,
          state: values.state,
        }
      }
    });

    if (error) {
      myToast.showToast('error', error.message, 'Unable to add card')
    } else {
      const cardData = {
        card: {
          stripe_id: paymentMethod.id,
          address_city: paymentMethod.billing_details.address.city,
          address_country: paymentMethod.billing_details.address.country,
          address_line1: paymentMethod.billing_details.address.line1,
          address_line2: paymentMethod.billing_details.address.line2,
          address_zip: paymentMethod.billing_details.address.postal_code,
          address_state: paymentMethod.billing_details.address.state,
          name: paymentMethod.billing_details.name,
          address_line1_check: paymentMethod.card.checks.address_line1_check,
          address_zip_check: paymentMethod.card.checks.address_postal_code_check,
          cvc_check: paymentMethod.card.checks.cvc_check,
          brand: paymentMethod.card.brand,
          country: paymentMethod.card.country,
          exp_month: paymentMethod.card.exp_month,
          exp_year: paymentMethod.card.exp_year,
          fingerprint: paymentMethod.card.fingerprint,
          funding: paymentMethod.card.funding,
          last4: paymentMethod.card.last4,
          metadata: paymentMethod.metadata,
        }
      }

      client.makeApiCall(
        'post', this.props.dataUrl, cardData,
        'Card added successfully',
        'Unable to add card',
        this.props.callbackFunction || (() => window.location.reload())
      )
    }
  };

  render() {
    return (
      <ElementsConsumer>
      {({elements, stripe}) => (
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              { this.props.title }
            </h5>

            <button
              type="button"
              onClick={this.props.toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <AvForm className="needs-validation" onValidSubmit={(e, values) => {
              this.handleSubmit(e, values, elements, stripe)
            }}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="status">{ this.props.type }</Label>
                    <CardElement
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="line1">Address Line 1</Label>
                        <AvField
                          name="line1"
                          placeholder="Address Line 1"
                          type="text"
                          errorMessage="Address Line 1 required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="line1"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="line1">Address Line 2</Label>
                        <AvField
                          name="line2"
                          placeholder="Address Line 2"
                          type="text"
                          className="form-control"
                          id="line2"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="city">City</Label>
                        <AvField
                          name="city"
                          placeholder="City"
                          type="text"
                          errorMessage=" Please provide a valid city"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="city"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="state"
                          label="State"
                          validate={{ required: { value: true } }}
                          id="state"
                          className="form-control"
                        >
                          {
                            stateAbbreviations.map((opt) => <option key={opt} value={opt}>{opt}</option>)
                          }
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="postalCode">Zip</Label>
                        <AvField
                          name="postalCode"
                          placeholder="Zip Code"
                          type="text"
                          errorMessage="Please provide a valid zip"
                          className="form-control"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: '^[0-9]{5}$',
                              errorMessage: 'Please provide a valid zip code',
                            },
                          }}
                          id="postalCode"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Button color="primary" type="submit" disabled={this.loading}>Submit {this.loading && <Spinner className="mr-2" color="primary" />}</Button>
            </AvForm>
          </div>
        </Modal>
      )}
      </ElementsConsumer>
    );
  }
});

decorate(CardModal, {
  loading: observable,
  handleSubmit: action,
});
