import { AvField, AvForm } from 'availity-reactstrap-validation';
import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';

export const DispatchOfferModal = observer(class DispatchOfferModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div>
            <strong>Making dispatch offer to:</strong><br/>
            { this.props.drivers.map((driver) => driver.name).join(', ') }
          </div>
          <br />
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="customMessage">Custom Message (optional)</Label>
                  <AvField
                    name="customMessage"
                    placeholder="Custom message to send when making offer"
                    type="textarea"
                    className="form-control"
                    id="customMessage"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="bonus">Bonus (optional)</Label>
                  <AvField
                    name="bonus"
                    placeholder="Bonus to include in payout $"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    id="bonus"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(DispatchOfferModal, {
  editable: computed,
});
