import React from 'react';
import { NavLink } from 'react-router-dom';
import strftime from 'strftime';
import { Badge } from 'reactstrap';
import RelativeTime from 'react-relative-time';
import ClipboardLink from '../components/Common/ClipboardLink';

class Formatters {
  currencyFormatter = (cell, row) => {
   return <div>{ cell.pretty }</div>
  }

  createdAtRelativeFormatter = (cell, _row) => {
    if (cell) {
      return (<RelativeTime value={cell} />);
    }
  }

  createdAtFormatter = (cell, _row) => {
    const time = new Date(cell);

    return (
      <div>
        { strftime('%B %d, %Y', time) }
        <br />
        { strftime('%l:%M%P', time) }
        <br />
        { strftime('(%Z)', time) }
      </div>
    );
  }

  removedFormatter = (cell, _row) => {
    if (cell) {
      return <Badge className="badge-soft-danger mr-1">DELETED</Badge>
    }
  }

  boolFormatter = (cell, _row) => {
    if (cell) {
      return <Badge className="badge-soft-success mr-1">Yes</Badge>
    } else {
      return <Badge className="badge-soft-danger mr-1">No</Badge>
    }
  }

  localizedDateFormatter = (date) => {
    if (date) {
      const newDate = new Date(date)
      const offset = newDate / 60

      newDate.setTime(newDate.getTime() - offset * 60 * 60 * 1000)

      return newDate.toISOString().split('T')[0]
    }
  }

  idFormatter = (cell, row) => (
    <>
      <NavLink strict={+true} to={`/charges/${cell}`}>{cell}</NavLink>
      <ClipboardLink text={cell} />
    </>
  )

  fullTimeFormatter = (cell, row) => {
    if (!cell) {
      return 'n/a'
    }

    const time = new Date(cell);

    return (
      <div>
        { strftime('%B %d, %Y', time) }
        <br />
        { strftime('%l:%M%P', time) }
        { ` (${strftime('%Z', time).split(' ').map((token) => token.split('')[0]).join('')})` }
      </div>
    );
  }

  bookingFormatter = (cell, row) => {
    const url = `/bookings/${cell}`;

    return (
      <NavLink strict={+true} to={url}>{cell}</NavLink>
    );
  }

  clientFormatter = (cell, row) => {
    const url = `/clients/${cell}`;

    return (
      <NavLink strict={+true} to={url}>{cell}</NavLink>
    );
  }

  cardFormatter = (cell, row) => {
    const url = `/cards/${cell}`;

    return (
      <NavLink strict={+true} to={url}>{cell}</NavLink>
    );
  }

  defaultFormatter = (cell, row) => {
    if (cell) {
      return <Badge className="badge-soft-info mr-1">Default</Badge>
    }
  }
}

const formatters = new Formatters();

export { formatters };
