import { computed, observable } from "mobx"

export class BookingStop {
    /**
     * unique id of this Address, immutable.
     */
    id = null

    @observable addressId
    @observable bookingId
    @observable position
    @observable label
    @observable line1
    @observable line2
    @observable secondaryNumber
    @observable city
    @observable state
    @observable postalCode
    @observable buildingCode
    @observable neighborhoodCode
    @observable isDefault
    @observable latitude
    @observable longitude
    @observable createdAt
    @observable updatedAt
    @observable timezone

    // @observable email = ""

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:               this.id,
        position:         this.position,
        addressId:        this.addressId,
        bookingId:        this.bookingId,
        label:            this.label,
        line1:            this.line1,
        line2:            this.line2,
        secondaryNumber:  this.secondaryNumber,
        city:             this.city,
        state:            this.state,
        postalCode:       this.postalCode,
        buildingCode:     this.buildingCode,
        neighborhoodCode: this.neighborhoodCode,
        isDefault:        this.isDefault,
        latitude:         this.latitude,
        longitude:        this.longitude,
        createdAt:        this.createdAt,
        updatedAt:        this.updatedAt,
        timezone:         this.timezone,
      }
    }

    /**
     * Update this object with information from the server
     */
    updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.position         = data.attributes.position
      this.bookingId        = data.attributes.bookingId
      this.addressId        = data.attributes.addressId
      this.label            = data.attributes.label
      this.line1            = data.attributes.line1
      this.line2            = data.attributes.line2
      this.secondaryNumber  = data.attributes.secondaryNumber
      this.city             = data.attributes.city
      this.state            = data.attributes.state
      this.postalCode       = data.attributes.postalCode
      this.buildingCode     = data.attributes.buildingCode
      this.neighborhoodCode = data.attributes.neighborhoodCode
      this.isDefault        = data.attributes.default
      this.latitude         = data.attributes.latitude
      this.longitude        = data.attributes.longitude
      this.createdAt        = data.attributes.createdAt
      this.updatedAt        = data.attributes.updatedAt
      this.timezone         = data.attributes.timezone

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
