import React, { useState } from 'react';
import {
  Row, Col, Alert, Card, CardBody, CardText, CardTitle, Container,
} from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// action
import { userForgetPassword } from '../../store/actions';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/J.png';

import { client } from '../../helpers/client';

const ForgetPasswordPage = (props) => {
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  async function handleValidSubmit(_event, values) {
    try {
      await client.post(`/passwords`, {
        password: {
          email: values.email,
        }
      })

      setSuccess(true)
    } catch {
      setFailure(true)
    }
  }

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark"><i className="fas fa-home h2" /></Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              { !success && !failure && <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Jeevz.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="profile" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="avatar" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">

                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: '13px' }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card> }

              { success &&
                <Card color="success" className="text-white-50">
                  <CardBody>
                    <CardTitle className="mb-4 text-white">
                      <i className="mdi mdi-check-all mr-3" />
                      {' '}
                      Password Reset Submitted
                    </CardTitle>
                    <CardText>Please check your email for password reset link.</CardText>
                  </CardBody>
                </Card>
              }

              { failure &&
                <Card color="danger" className="text-white-50">
                  <CardBody>
                    <CardTitle className="mb-4 text-white">
                      <i className="mdi mdi-check-all mr-3" />
                      {' '}
                      Error
                    </CardTitle>
                    <CardText>Unable to reset your password.  Please refresh and try again.</CardText>
                  </CardBody>
                </Card>
              }
              <div className="mt-5 text-center">
                <p>
                  ©
                  {new Date().getFullYear()}
                  {' '}
                  Jeevz
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage),
);
