import { decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import strftime from 'strftime';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { InsurancePolicyModal } from '../../components/Modals/InsurancePolicyModal';
import { MyTable } from '../../components/MyTable/MyTable';
import { formatters } from '../../helpers/Formatters';
import { InsurancePolicy } from '../../models/InsurancePolicy';

export const InsurancePoliciesTable = observer(class InsurancePoliciesTable extends Component {
  filter

  _addModalOpen

  _selectedInsurancePolicy

  idFormatter = (cell, row) => (
    <>
      <a
        href={`#${row.id}`}
        key={row.id}
        onClick={
          () => {
            runInAction(() => {
              this._addModalOpen = true;
              this._selectedInsurancePolicy = row;
            });
          }
        }
      >
        { cell.split('-')[0] }
      </a>

      <ClipboardLink text={cell} />
    </>
  )

  createdAtFormatter = (cell, row) => {
    const time = new Date(cell);

    return (
      <div>
        { strftime('%B %d, %Y', time) }
        <br />
        { strftime('%l:%M%P', time) }
        <br />
        { strftime('(%Z)', time) }
      </div>
    );
  }

  urlFormatter = (cell, _row) => {
    return(
      <a href={cell} target="_blank" rel="noopener noreferrer">{ cell }</a>
    )
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'policyNumber',
      name: 'Policy Number',
      priority: 1,
    },
    {
      dataField: 'provider',
      name: 'Provider',
      priority: 1,
    },
    {
      dataField: 'endDate',
      name: 'End Date',
      priority: 1,
    },
    {
      dataField: 'createdAt',
      name: 'Created',
      priority: 1,
      formatter: formatters.createdAtRelativeFormatter,
    }
  ]

  render() {
    return (
        <>
          <InsurancePolicyModal
            isOpen={this._addModalOpen}
            toggle={() => {
              runInAction(() => {
                this._addModalOpen = !this._addModalOpen
                this._selectedInsurancePolicy = null
              });
            }}
            title={this.modalTitle}
            // submitHandler={this.submitEarningHandler}
            insurancePolicy={this._selectedInsurancePolicy}
            dataUrl={ this.props.dataUrl }
          />
          <MyTable
            model={InsurancePolicy}
            dataUrl={this.props.dataUrl}
            title={'Insurance Policies'}
            breadcrumbBaseTitile={this.props.baseTitle || 'Insurance Policies'}
            dataColumns={this.dataColumns}
            embedded={this.props.embedded}
            defaultLimit={this.limit}
            filter={this.props.filter}
            singleActions={this.props.addEnabled && {
              'Add +': () => runInAction(() => {
                this._selectedInsurancePolicy = null;
                this._addModalOpen = !this._addModalOpen;
              })
            }}
          />
        </>
    );
  }
});

decorate(InsurancePoliciesTable, {
  _addModalOpen: observable,
  _selectedInsurancePolicy: observable,
});
