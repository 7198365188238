// @ts-nocheck
import { action, computed, observable } from "mobx"

export class ZipCode {
    /**
     * unique id of this ZipCode, immutable.
     */
    id = null

    @observable code: string
    @observable status: string
    @observable regionName: string
    @observable driverCount: number
    @observable clientCount: number
    @observable addressCount: number
    @observable rideCount: number
    @observable cityName: string
    @observable stateAbbr: string
    @observable createdAt: string
    @observable updatedAt: string
    @observable hourlyRate: any
    @observable dryverHourlyRate: any


    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:           this.id,
        code:         this.code,
        status:       this.status,
        regionName:   this.regionName,
        driverCount:  this.driverCount,
        clientCount:  this.clientCount,
        addressCount: this.addressCount,
        cityName:     this.cityName,
        stateAbbr:    this.stateAbbr,
        rideCount:    this.rideCount,
        createdAt:    this.createdAt,
        updatedAt: this.updatedAt,
        hourlyRate: this.hourlyRate,
        dryverHourlyRate: this.dryverHourlyRate
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.code = data.attributes.code
      this.status = data.attributes.status
      this.regionName = data.attributes.regionName
      this.driverCount = data.attributes.driverCount
      this.clientCount = data.attributes.clientCount
      this.addressCount = data.attributes.addressCount
      this.rideCount = data.attributes.rideCount
      this.cityName = data.attributes.cityName
      this.stateAbbr = data.attributes.stateAbbr
      this.createdAt = data.attributes.createdAt
      this.updatedAt = data.attributes.updatedAt
      this.hourlyRate = data.attributes.hourlyRate
      this.dryverHourlyRate = data.attributes.dryverHourlyRate

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
