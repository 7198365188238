// @ts-nocheck
import { action, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { Offer } from "../models/Offer"

export class offersStore {
  @observable offers = new Map()

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.offers.clear()
      for (const offerData of response.data) {
        this.offers.set(offerData.id, new Offer(offerData))
      }
      return this.offers
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/dispatch_offers/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const offer = new Offer(response.data)
      this.offers.set(response.data?.id, offer)
      return offer
    }
  }

  @action accept = async () => {
    await apiCall(`${process.env.REACT_APP_API_URL}/dispatch_offers/${id}`, {
      method: 'PUT',
    })
  }

  @action reject = async () => {
    await apiCall(`${process.env.REACT_APP_API_URL}/dispatch_offers/${id}`, {
      method: 'PUT',
    })
  }
}
