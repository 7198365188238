import React, { Component } from 'react'

import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Link } from 'react-router-dom'

import { action, computed, observable, decorate } from 'mobx'
import { observer } from 'mobx-react'
import ApexRadial from './ApexRadial'

import { client } from '../../helpers/client'

export const MonthlyEarning = observer(
  class MonthlyEarning extends Component {
    data = {}

    componentDidMount() {
      this.fetchData()
    }

    fetchData = async () => {
      this.data = await client.get('/admin/dashboard_stats/monthly_earnings')
    }

    get monthlyTotal() {
      if (this.data.billedThisPeriod) {
        return `${this.data.billedThisPeriod}`
      }
      return '...'
    }

    get pctChange() {
      if (this.data.percentChange) {
        return `${this.data.percentChange}%`
      }
      return '...'
    }

    render() {
      return (
        <>
          {' '}
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Monthly Total Revenue</CardTitle>
              <Row>
                <Col sm="12">
                  <p className="text-muted">This month</p>
                  <h3>{this.monthlyTotal}</h3>
                  <p className="text-muted">
                    <span
                      className={`text-${
                        this.data.percentChange > 0 ? 'success' : 'danger'
                      } mr-2`}
                    >
                      {' '}
                      {parseInt(this.pctChange)}%{' '}
                      <i
                        className={`mdi mdi-arrow-${
                          this.data.percentChange >= 0 ? 'up' : 'down'
                        }`}
                      />{' '}
                    </span>{' '}
                    From previous period
                  </p>
                  <div className="mt-4">
                    <Link
                      to="/billings"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      View More
                      <i className="mdi mdi-arrow-right ml-1" />
                    </Link>
                  </div>
                </Col>
              </Row>
              <p className="text-muted mt-4 mb-3">
                "Monthly" earnings is trailing 30 days, not calendar month.
              </p>
            </CardBody>
          </Card>
        </>
      )
    }
  },
)

decorate(MonthlyEarning, {
  fetchData: action,
  data: observable,
  monthlyTotal: computed,
})
