// @ts-nocheck
import { action, computed, observable } from "mobx"
import { DateTime } from "luxon"

export class Message {
    /**
     * unique id of this Message, immutable.
     */
    id = null

    @observable body: string
    @observable createdAt: string
    @observable updatedAt: string
    @observable readAt?: string
    @observable userId: string
    @observable messageableId: string
    @observable messageableType: string
    @observable user: {
      id: string
      name: string
      initials: string
      avatar?: string
    }

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id: this.id,
        body: this.body,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
        readAt: this.readAt,
        userId: this.userId,
        messageableId: this.messageableId,
        messageableType: this.messageableType,
        user: this.user,
      }
    }

  @computed get createdAtObj() {
    console.log('cat', this.createdAt)
    if (this.createdAt) {
      return DateTime.fromISO(this.createdAt)
    }
    return null
  }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.body = data.body
      this.createdAt = data.createdAt
      this.updatedAt = data.updatedAt
      this.readAt = data.readAt
      this.userId = data.userId
      this.messageableId = data.messageableId
      this.messageableType = data.messageableType
      this.user = data.user

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
