// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Rating {
    /**
     * unique id of this Rating, immutable.
     */
    id = null

    @observable value
    @observable rater
    @observable ratee
    @observable createdAt
    @observable updatedAt

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:         this.id,
        value:      this.value,
        rater:      this.rater,
        ratee:      this.ratee,
        createdAt:  this.createdAt,
        updatedAt:  this.updatedAt,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.value      = data.attributes.value
      this.rater      = data.attributes.rater
      this.ratee      = data.attributes.ratee
      this.createdAt  = data.attributes.createdAt
      this.updatedAt  = data.attributes.updatedAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
