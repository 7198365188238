// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import logo from '../../assets/images/J.png';
import { client } from '../../helpers/client';

const ResetPasswordPage = (props) => {
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  async function resetPassword(_e, values, search) {
    if (!search) {
      setFailure(true)
    }

    const token = search.replace('?reset_password_token=', '')

    if (token.length === 0) {
      setFailure(true)
    }

    try {
      await client.put(`/passwords/${token}`, {
        password: {
          password: values.password,
          password_confirmation: values.passwordConfirmation,
        }
      })
      setSuccess(true)
    } catch {
      setFailure(true)
    }
  }

  return(<>
    <div className="home-btn d-none d-sm-block">
      <Link to="/" className="text-dark"><i className="fas fa-home h2" /></Link>
    </div>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            { !success && !failure && <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col className="col-12">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Password Reset !</h5>
                      <p>Please input your new password.</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    {/* <img src={profile} alt="" className="img-fluid" /> */}
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to="/">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} height="34" alt={"User Avatar"} />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">

                  <AvForm
                    className="form-horizontal mt-4"
                    onValidSubmit={(e,v) => resetPassword(e, v, props.location.search)}
                  >
                    <div className="form-group">
                      <AvField
                        name="password"
                        label="Password"
                        className="form-control"
                        placeholder="Password"
                        type="password"
                        validate={{
                          required: { value: true, errorMessage: 'Password is required' },
                          minLength: { value: 6, errorMessage: 'Password must be at least 6 characters' },
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <AvField
                        name="passwordConfirmation"
                        label="Password Confirmation"
                        className="form-control"
                        placeholder="Confirm Password"
                        type="password"
                        validate={{
                          required: { value: true, errorMessage: 'Please enter password confirmation' },
                          match: { value: 'password', errorMessage: 'Password confirmation must match Password' },
                        }}
                      />
                    </div>

                    <Row className="form-group">
                      <Col className="text-right">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Reset
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </CardBody>
            </Card> }

            { success &&
              <Card color="success" className="text-white-50">
                <CardBody>
                  <CardTitle className="mb-4 text-white">
                    <i className="mdi mdi-check-all mr-3" />
                    {' '}
                    Successfuly Reset Password
                  </CardTitle>
                  <CardText>Please return to the app to log in.</CardText>
                </CardBody>
              </Card>
            }

            { failure &&
              <Card color="danger" className="text-white-50">
                <CardBody>
                  <CardTitle className="mb-4 text-white">
                    <i className="mdi mdi-check-all mr-3" />
                    {' '}
                    Error
                  </CardTitle>
                  <CardText>Unable to reset your password.  Please refresh and try again.</CardText>
                </CardBody>
              </Card>
            }

            <div className="mt-5 text-center">
              <p>
                ©
                {new Date().getFullYear()}
                {' '}
                Jeevz.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>)
};

export default ResetPasswordPage;
