import { AvField, AvForm } from 'availity-reactstrap-validation';
import { decorate, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';
import { client } from '../../helpers/client';

export const InsurancePolicyModal = observer(class InsurancePolicyModal extends Component {
  submitHandler = async (e, values) => {
    const data = {
      insurance_policy: {
        policy_number: values.policyNumber,
        provider: values.provider,
        end_date: values.endDate,
      },
    }

    if (this.props.insurancePolicy) {
      await client.makeApiCall(
        'put', `${this.props.dataUrl}/${this.props.insurancePolicy.id}`, data,
        'Insurance Policy updated',
        'Unable to update insurance policy',
        () => {
          runInAction(() => this.addModalOpen = false)
          window.location.reload()
        }
      )
    } else {
      await client.makeApiCall(
        'post', this.props.dataUrl, data,
        'Insurance Policy created',
        'Unable to create insurance policy',
        () => {
          runInAction(() => this.addModalOpen = false)
          window.location.reload()
        }
      )
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.submitHandler} model={this.props.insurancePolicy}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="provider">Provider</Label>
                  <AvField
                    name={"provider"}
                    className={"form-control"}
                    validate={{ required: true }}
                    placeholder={"Geico/Progressive/Etc"}
                    id={"provider"}
                    type={"text"}
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="policyNumber">Policy Number</Label>
                  <AvField
                    name="policyNumber"
                    placeholder="12345678"
                    className="form-control"
                    validate={{ required: true }}
                    id="policyNumber"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="endDate">End of Coverage Date</Label>
                  <AvField
                    name="endDate"
                    placeholder="Date Coverage Ends"
                    type="date"
                    className="form-control"
                    validate={{ required: true }}
                    id="endDate"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Save</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(InsurancePolicyModal, {
});
