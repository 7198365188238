// Import Breadcrumb
import {
  action, decorate, observable, runInAction
} from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Switch from 'react-switch';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { client } from '../../helpers/client';

const Offsymbol = (props) => (
  <div
    style={{
					  display: 'flex',
					  justifyContent: 'center',
					  alignItems: 'center',
					  height: '100%',
					  fontSize: 12,
					  color: '#fff',
					  paddingRight: 2,
    }}
  >
    {' '}
    No
  </div>
);

const OnSymbol = (props) => (
  <div
    style={{
					  display: 'flex',
					  justifyContent: 'center',
					  alignItems: 'center',
					  height: '100%',
					  fontSize: 12,
					  color: '#fff',
					  paddingRight: 2,
    }}
  >
    {' '}
    Yes
  </div>
);

export const DispatchSettings = observer(class DispatchSettings extends Component {
  settings = {
    auto_dispatch_enabled: false,
  }

  constructor(props) {
    super(props);
    this.fetchData();
  }

  fetchData = async () => {
    const resp = await client.get('/admin_settings')
    this.settings.auto_dispatch_enabled = resp.autoDispatchEnabled
  }

  updateSettings = async () => {
    await client.makeApiCall(
      'put', '/admin_settings', { settings: this.settings },
      'Settings updated successfully',
      'Unable to update settings',
    )
  }

    render() {
      return (
        <>
          <div className="page-content">
            <Container fluid>

              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Settings" breadcrumbItem="Dispatch" />

              <Row>
                <Col xl="4">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Dispatch Settings</CardTitle>

                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#34c38f"
                        onChange={() => { runInAction(() => {
                          this.settings.auto_dispatch_enabled = !this.settings.auto_dispatch_enabled
                          this.updateSettings()
                        }) }}
                        checked={this.settings.auto_dispatch_enabled}
                      /> <span>Auto Dispatch Enabled?</span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
});

decorate(DispatchSettings, {
  settings: observable,
  fetchData: action,
});
