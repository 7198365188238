import { AvField, AvForm } from 'availity-reactstrap-validation';
import { decorate, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';
import { client } from '../../helpers/client';

export const RefundModal = observer(class RefundModal extends Component {
  submitHandler = async (e, values) => {
    const data = {
      refund: {
        amount_cents: Math.round(values.formattedAmount * 100),
        description: values.description,
      },
    }

    if (this.props.charge) {
      await client.makeApiCall(
        'post', `/charges/${this.props.charge.id}/refunds/`, data,
        'Charge refunded',
        'Unable to refund charge',
        () => {
          runInAction(() => this.addModalOpen = false)
          window.location.reload()
        }
      )
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.submitHandler} model={this.props.charge}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="formattedAmount">Amount</Label>
                  <AvField
                    name="formattedAmount"
                    className="form-control"
                    validate={{number: true, max: {value: this.props.charge?.amountRefundable || 0}}}
                    id="formattedAmount"
                    type="text"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="description">Reason</Label>
                  <AvField
                    name="description"
                    placeholder="Reason for refund"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="description"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Issue Refund</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(RefundModal, {
});
