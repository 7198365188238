// @ts-nocheck
import { action, computed, observable } from "mobx"

export class BankAccount {
    /**
     * unique id of this BankAccount, immutable.
     */
    id = null

    @observable stripeId
    @observable accountHolderName
    @observable bankName
    @observable country
    @observable currency
    @observable last4
    @observable routingNumber
    @observable status
    @observable createdAt
    @observable updatedAt

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        stripeId: this.stripeId,
        accountHolderName: this.accountHolderName,
        bankName: this.bankName,
        country: this.country,
        currency: this.currency,
        last4: this.last4,
        routingNumber: this.routingNumber,
        status: this.status,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.stripeId = data.attributes.stripeId
      this.accountHolderName = data.attributes.accountHolderName
      this.bankName = data.attributes.bankName
      this.country = data.attributes.country
      this.currency = data.attributes.currency
      this.last4 = data.attributes.last4
      this.routingNumber = data.attributes.routingNumber
      this.status = data.attributes.status
      this.createdAt = data.attributes.createdAt
      this.updatedAt = data.attributes.updatedAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
