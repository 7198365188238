import { action, computed, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import Avatar from 'react-avatar'
import RelativeTime from 'react-relative-time'
import { NavLink } from 'react-router-dom'
import { Badge, Button } from 'reactstrap'
import ClipboardLink from '../../components/Common/ClipboardLink'
import { AlertModal } from '../../components/Modals/AlertModal'
import { ConfirmationModal } from '../../components/Modals/ConfirmationModal'
import { DispatchOfferModal } from '../../components/Modals/DispatchOfferModal'
import { DriverModal } from '../../components/Modals/DriverModal'
import { MyTable } from '../../components/MyTable/MyTable'
import { client } from '../../helpers/client'
import { formatters } from '../../helpers/Formatters'
import { Driver } from '../../models/Driver'

export const DriversTable = observer(
  class DriversTable extends Component {
    addModalOpen = false

    dispatchModalOpen = false

    selectedDrivers = []

    _currentRow
    _confirmationModalOpen = false
    _confirmationModalBody
    _alertModalOpen = false

    componentDidMount() {
      this._tableRef = React.createRef()
    }

    refreshData = () => {
      this._tableRef.current.refreshData()
    }

    driverFormatter = (cell, row) => {
      const url = `/driver/${row.id}`

      return (
        <NavLink
          strict={+true}
          to={url}
          style={{ display: 'flex', flexDirection: 'col', textWrap: 'nowrap' }}
        >
          <Avatar
            name={row.name}
            src={row.avatarUrl}
            size={32}
            round
            style={{ marginRight: '10px' }}
          />
          {cell}
        </NavLink>
      )
    }

    idFormatter = (cell, row) => (
      <div
        style={{
          textWrap: 'nowrap',
        }}
      >
        <NavLink strict={+true} to={`/driver/${cell}`} key={cell}>
          {cell.split('-')[0]}
        </NavLink>
        <ClipboardLink text={cell} />
      </div>
    )

    createdAtFormatter = (cell, _row) => {
      if (cell) {
        return <RelativeTime value={cell} />
      }
    }

    emailFormatter = (cell, _row) => {
      if (cell) {
        return <a href={`mailto:${cell}`}>{cell}</a>
      }
    }

    locationLastUpdateFormatter = (_cell, row) => {
      // const location = await client.get(`/loocations/${row.id}`)

      // if (location) {
      //   return (<RelativeTime value={location.data.updatedAt} />)
      // } else {
      return 'unknown'
      // }
    }

    phoneFormatter = (cell, row) => {
      if (cell) {
        return <a href={`tel:${cell}`}>{cell}</a>
      }
    }

    statusFormatter = (cell, row) => {
      switch (cell) {
        case 'applied':
        case 'probationary':
          return (
            <Badge color="warning" className="mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        case 'active':
          return (
            <Badge className="badge-soft-success mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        case 'removed':
        case 'rejected':
        case 'banned':
          return (
            <Badge color="danger" className="mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        default:
          return (
            <Badge color="danger" className="mr-1">
              unknown
            </Badge>
          )
      }
    }

    ratingFormatter = (cell, _row) => {
      if (cell) {
        let klass

        if (cell >= 4) {
          klass = 'success'
        } else if (cell >= 3) {
          klass = 'warning'
        } else {
          klass = 'danger'
        }

        return (
          <span className={[`text-${klass}`, 'font-weight-bold']}>
            {cell}/5
          </span>
        )
      } else {
        return <span className="text-light">n/a</span>
      }
    }

    payFormatter = (cell, _row) => {
      if (cell) {
        return `${cell.pretty}`
      } else {
        return 'n/a'
      }
    }

    get dataColumns() {
      const displayColumns = this.sharedColumns

      if (this.props.dispatching) {
        displayColumns.push({
          dataField: 'distance',
          name: 'Distance',
          priority: 1,
          formatter: (cell, _row) => {
            if (cell) {
              return <div>{cell.toFixed(1)} mi</div>
            }
          },
        })

        // displayColumns.push({
        //   dataField: '',
        //   name: 'Loc Ping',
        //   priority: 1,
        //   formatter: this.locationLastUpdateFormatter,
        //   sort: true,
        // })

        displayColumns.push({
          dataField: '',
          name: 'Action',
          priority: 1,
          formatter: (_cell, row) => (
            <Button
              color="primary"
              onClick={() => {
                this._toggleAssignConfirmationModal(row)
              }}
            >
              Assign
            </Button>
          ),
        })
      } else if (this.props.favorite) {
        displayColumns.push({
          dataField: 'rank',
          name: 'Rank',
          priority: 1,
        })
      } else {
        displayColumns.push({
          dataField: 'city',
          name: 'City',
          priority: 1,
          sort: true,
        })
      }

      return displayColumns
    }

    displayOfferModal = (rows) => {
      if (rows.length === 0) {
        this._toggleAlertModal()
      } else {
        this.dispatchModalOpen = true
        this.selectedDrivers = rows
      }
    }

    submitOfferHandler = async (_e, values) => {
      const data = {
        dispatch_offer: {
          driver_ids: this.selectedDrivers.map((driver) => driver.id),
        },
      }

      if (values.customMessage.length > 0) {
        data.dispatch_offer.custom_message = values.customMessage
      }

      if (values.bonus.length > 0) {
        data.dispatch_offer.bonus_cents = values.bonus * 100
      }

      client.makeApiCall(
        'post',
        `/bookings/${this.props.booking.id}/dispatch_offers`,
        data,
        'Offer created successfully',
        'Unable to create offer',
        () => window.location.reload(),
      )
    }

    _toggleAssignConfirmationModal = (row) => {
      if (row) {
        this._currentRow = row
      }
      this._confirmationModalOpen = !this._confirmationModalOpen
      this._confirmationModalBody = `Are you sure you want to assign ${row?.attributes?.name} driver? Ensure they're available.`
    }

    _assignDriver = async () => {
      await client.makeApiCall(
        'post',
        `/bookings/${this.props.booking.id}/dispatch_offers`,
        {
          dispatch_offer: {
            assign: true,
            driver_ids: [this._currentRow.id],
          },
        },
        'Driver assigned successfully',
        'Unable to assign driver',
        () => window.location.reload(),
      )
    }

    get actions() {
      if (this.props.dispatching) {
        return {
          'Create Driver Offer': (rows) => {
            this.displayOfferModal(rows)
          },
        }
      }

      return this.props.actions
    }

    sharedColumns = [
      {
        dataField: 'id',
        name: 'ID',
        priority: 1,
        formatter: this.idFormatter,
        sort: true,
      },
      {
        dataField: 'name',
        name: 'Name',
        priority: 1,
        formatter: this.driverFormatter,
        sort: true,
      },
      {
        dataField: 'email',
        name: 'Email',
        priority: 1,
        formatter: this.emailFormatter,
        sort: true,
      },
      {
        dataField: 'phoneNumber',
        name: 'Phone',
        priority: 1,
        formatter: this.phoneFormatter,
        sort: true,
      },
      {
        dataField: 'status',
        name: 'Status',
        priority: 1,
        sort: true,
        formatter: this.statusFormatter,
      },
      {
        dataField: 'tripsCount',
        name: 'Rides',
        priority: 1,
      },
      {
        dataField: 'hourlyPayRate',
        name: 'Hr Rate',
        priority: 1,
        formatter: this.payFormatter,
        sort: true,
      },
      {
        dataField: 'rating',
        name: 'Rating',
        priority: 1,
        formatter: this.ratingFormatter,
        sort: true,
      },
      {
        dataField: 'lastTripTime',
        name: 'Last Trip',
        priority: 1,
        formatter: formatters.createdAtRelativeFormatter,
        sort: true,
      },
      {
        dataField: 'createdAt',
        name: 'Joined',
        priority: 1,
        formatter: this.createdAtFormatter,
        sort: true,
      },
    ]

    createDriver = async (_e, values) => {
      const data = {
        driver: {
          name: values.name,
          email: values.email,
          gender: values.gender,
          phone_number: values.phoneNumber,
          hourly_pay_rate_cents: values.hourlyRate && values.hourlyRate * 100,
          zip_code: values.zipCode,
          qualification_tier: values.qualificationTier,
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          status: values.status,
          preferences_attributes: {
            talking_type: values.talkingType,
            driving_type: values.drivingType,
            smokes: values.smokes,
            wears_cologne: values.wearsCologne,
            drives_manual: values.drivesManual,
            large_vehicles: values.largeVehicles,
          },
        },
      }

      client.makeApiCall(
        'post',
        '/drivers',
        data,
        'Driver created successfully',
        'Unable to create new driver',
        (response) => (window.location = `/driver/${response.data.id}`),
      )
    }

    _toggleAlertModal = () => (this._alertModalOpen = !this._alertModalOpen)

    get singleActions() {
      const actions = {}

      if (this.props.singleAction) {
        for (const key of Object.keys(this.props.singleAction)) {
          actions[key] = this.props.singleAction[key]
        }
      }

      if (this.props.addEnabled) {
        actions['Add +'] = () =>
          runInAction(() => {
            this.addModalOpen = !this.addModalOpen
          })
      }

      return actions
    }

    render() {
      return (
        <>
          <AlertModal
            isOpen={this._alertModalOpen}
            toggle={this._toggleAlertModal}
            header={'Error'}
            body={'Please select drivers to make an offer to'}
          />

          <ConfirmationModal
            isOpen={this._confirmationModalOpen}
            toggle={this._toggleAssignConfirmationModal}
            header={'Assign Driver?'}
            body={this._confirmationModalBody}
            confirm={this._assignDriver}
            confirmButtonText={'Assign'}
            closeButtonText={'Cancel'}
          />

          <DriverModal
            isOpen={this.addModalOpen}
            toggle={() => {
              runInAction(() => {
                this.addModalOpen = !this.addModalOpen
              })
            }}
            title="New Driver"
            submitHandler={this.createDriver}
          />
          <DispatchOfferModal
            isOpen={this.dispatchModalOpen}
            toggle={() => {
              runInAction(() => {
                this.dispatchModalOpen = !this.dispatchModalOpen
              })
            }}
            title="Make Trip Offer"
            submitHandler={this.submitOfferHandler}
            drivers={this.selectedDrivers}
          />
          <MyTable
            model={Driver}
            subheading={this.props.subheading}
            filter={this.props.filter}
            dataUrl={this.props.dataUrl || '/drivers'}
            breadcrumbBaseTitle={this.props.baseTitle || 'Drivers'}
            dataColumns={this.dataColumns}
            embedded={this.props.embedded}
            multiActions={this.actions}
            dateFilterColumn={'createdAt'}
            dateFilterLabel={'Created At'}
            defaultLimit={this.props.defaultLimit}
            searchable
            singleActions={this.singleActions}
            ref={this._tableRef}
            highlightRow={(row) => {
              return this.props.dispatching && row.isFavorite
            }}
          />
        </>
      )
    }
  },
)

decorate(DriversTable, {
  dataColumns: computed,
  actions: computed,
  addModalOpen: observable,
  displayOfferModal: action,
  dispatchModalOpen: observable,
  selectedDrivers: observable,
  _currentRow: observable,
  _confirmationModalOpen: observable,
  _confirmationModalBody: observable,
  _toggleAssignConfirmationModal: action,
  _alertModalOpen: observable,
  _toggleAlertModal: action,
})
