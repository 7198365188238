import { AvField, AvForm } from 'availity-reactstrap-validation';
import { action, computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
export const BillingModal = observer(class BillingModal extends Component {
  _reprocessing = false

  _showMarkPaid = true

  _showStripeIdField = false

  _startReprocess = async () => {
    if (this.props.billing) {
      await this.props.billing.reprocess()
      this.props.toggle()
    } else {
      window.alert("no billing selected")
    }
  }

  _showPaidFields = async () => {
    if (this.props.billing) {
      this._showMarkPaid = false
      this._showStripeIdField = true
    } else {
      window.alert("no billing selected")
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {/* billableType:   this.billableType,
        amountRefunded: this.amountRefunded,
        minutesApplied: this.minutesApplied,
        creditsApplied: this.creditsApplied, */}

        { this.props.billing && <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.billing}>
            <Row>
              <Col>
                <div>{ this.props.billing.billableType } - <NavLink strict={+true} to={ `/booking/${ this.props.billing.bookingId }` }>
                  { this.props.billing.bookingId }
                </NavLink></div>
                <hr/>

                { !this._showStripeIdField && <FormGroup>
                  <AvField type="select" id="statusSelect" name="status" label="Status" onChange={this.handleChange}>
                    <option key={'pending'} value={'pending'}>Pending</option>
                    <option key={'charged'} value={'charged'}>Charged</option>
                    <option key={'refunded'} value={'refunded'}>Refunded</option>
                    <option key={'failed'} value={'failed'}>Failed</option>
                  </AvField>
                </FormGroup> }

                <FormGroup>
                  <Label htmlFor="amount">Billing Amount $</Label>
                  <AvField
                    disabled={!this.props.billing.editable}
                    name="amount"
                    placeholder="Billing Amount $"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="amount"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="notes">Notes</Label>
                  <AvField
                    disabled={!this.props.billing.editable}
                    name="notes"
                    placeholder="Notes (for admin/internal purposes)"
                    type="text"
                    className="form-control"
                    id="notes"
                  />
                </FormGroup>

                { this._showStripeIdField && <FormGroup>
                  <Label htmlFor="stripeId">Stripe ID</Label>
                  <AvField
                    disabled={!this.props.billing.editable}
                    name="stripeId"
                    placeholder="Stripe ID of Charge"
                    type="text"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="stripeId"
                  />
                </FormGroup> }
              </Col>
            </Row>

            <div className="d-flex align-items-center justify-content-between">
              { this.props.billing.editable && <Button color="primary" type="submit" className="mr-4">Update Billing</Button> }

              { this.props.billing.editable && !this._showStripeIdField && <Button color="primary" onClick={this._startReprocess}>Reprocess Billing</Button> }
              { this.props.billing.editable && this._showMarkPaid && <Button color="primary" onClick={this._showPaidFields}>Mark as Paid</Button> }
            </div>
          </AvForm>
        </div> }
      </Modal>
    );
  }
});

decorate(BillingModal, {
  _startReprocess: action,
  _reprocessing: observable,
  _showPaidFields: action,
  _showMarkPaid: observable,
  _showStripeIdField: observable,
});
