// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Subscription {
    /**
     * unique id of this Subscription, immutable.
     */
    id = null

    @observable billingFrequency
    @observable status
    @observable description
    @observable activeAt
    @observable firstChargeAt
    @observable subscriptionRenewAt
    @observable cancelledAt
    @observable billingRate
    @observable suspended
    @observable createdAt
    @observable updatedAt
    @observable trialPeriodDays

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:                   this.id,
        billingFrequency:     this.billingFrequency,
        status:               this.status,
        description:          this.description,
        activeAt:             this.activeAt,
        firstChargeAt:        this.firstChargeAt,
        subscriptionRenewAt:  this.subscriptionRenewAt,
        cancelledAt:          this.cancelledAt,
        billingRate:          this.billingRate,
        suspended:            this.suspended,
        createdAt:            this.createdAt,
        updatedAt:            this.updatedAt,
        trialPeriodDays:      this.trialPeriodDays,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.billingFrequency     = data.attributes.billingFrequency
      this.status               = data.attributes.status
      this.description          = data.attributes.description
      this.activeAt             = data.attributes.activeAt
      this.firstChargeAt        = data.attributes.firstChargeAt
      this.subscriptionRenewAt  = data.attributes.subscriptionRenewAt
      this.cancelledAt          = data.attributes.cancelledAt
      this.billingRate          = data.attributes.billingRate
      this.suspended            = data.attributes.suspended
      this.createdAt            = data.attributes.createdAt
      this.updatedAt            = data.attributes.updatedAt
      this.trialPeriodDays      = data.attributes.trialPeriodDays

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
