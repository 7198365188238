// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Expense {
    /**
     * unique id of this Billing, immutable.
     */
    id = null

    @observable expenseType   = null
    @observable status        = null
    @observable description   = null
    @observable reimbursable  = null
    @observable taxable       = null
    @observable amount        = null
    @observable createdAt     = null
    @observable updatedAt     = null
    @observable receiptUrl

    type = 'Expense'

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:           this.id,
        expenseType:  this.expenseType,
        status:       this.status,
        description:  this.description,
        reimbursable: this.reimbursable,
        taxable:      this.taxable,
        amount:       this.amount,
        createdAt:    this.createdAt,
        updatedAt:    this.updatedAt,
        receiptUrl:   this.receiptUrl,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.expenseType  = data.attributes.expenseType
      this.status       = data.attributes.status
      this.description  = data.attributes.description
      this.reimbursable = data.attributes.reimbursable
      this.taxable      = data.attributes.taxable
      this.amount       = data.attributes.amount
      this.createdAt    = data.attributes.createdAt
      this.updatedAt    = data.attributes.updatedAt
      this.receiptUrl   = data.attributes.receiptUrl

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
