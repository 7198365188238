import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Card, Alert, CardBody, Media, Button,
} from 'reactstrap';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

import avatar from '../../assets/images/avatar-placeholder.png';
// actions
import { editProfile } from '../../store/actions';

const UserProfile = (props) => {
  const [email, setemail] = useState('');
  const [name, setname] = useState('');

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'));
      setname(obj.name);
      setemail(obj.email);
    }
  }, [props.success]);

  function handleValidSubmit(event, values) {
    props.editProfile(values);
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumb title="Admin" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}
              {props.success && props.success ? <Alert color="success">{props.success}</Alert> : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="mr-3">
                      <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Edit Personal Details</h4>

          <Card>
            <CardBody>
              <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit(e, v); }}>
                <div className="form-group">
                  <AvField name="name" label="Name" value={name} className="form-control" placeholder="Enter Name" type="text" required />
                </div>
                <div className="form-group">
                  <AvField name="password" label="New Password" type="password" />
                  <AvField name="passwordConfirmation" label="Confirm Password" type="password" validate={{ match: { value: 'password' } }} />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">Edit Details</Button>
                </div>
              </AvForm>

            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, { editProfile })(UserProfile));
