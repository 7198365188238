import {
  action, computed, decorate, observable
} from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Container, Media, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { client } from '../../helpers/client';
import { MonthlyEarning } from './MonthlyEarning';
import RidesChart from './RidesChart';
import SubscriptionsChart from './SubscriptionsChart';
import TippedBookingsChart from './TippedBookingsChart';
import TipPercentageChart from './TipPercentageChart';
import { User } from '../../helpers/CurrentUser';

export const Dashboard = observer(class Dashboard extends Component {
    rideTabs = [
      { title: 'Week', linkto: () => {this.setRideChartData('Week')}, isActive: false },
      { title: 'Month', linkto: () => {this.setRideChartData('Month')}, isActive: true },
      { title: 'Year', linkto: () => {this.setRideChartData('Year')}, isActive: false },
    ]

    subscriptionTabs = [
      { title: 'Week', linkto: () => {this.setSubscriptionChartData('Week')}, isActive: false },
      { title: 'Month', linkto: () => {this.setSubscriptionChartData('Month')}, isActive: true },
      { title: 'Year', linkto: () => {this.setSubscriptionChartData('Year')}, isActive: false },
    ]

    tippedPctTabs = [
      { title: 'Week', linkto: () => {this.setTipPctChartData('Week')}, isActive: false },
      { title: 'Month', linkto: () => {this.setTipPctChartData('Month')}, isActive: true },
    ]

    tippedTabs = [
      { title: 'Week', linkto: () => {this.setTippedChartData('Week')}, isActive: false },
      { title: 'Month', linkto: () => {this.setTippedChartData('Month')}, isActive: true },
    ]

    rideData = {}

    fillData = {}

    subscriptionData = {}

    ratingData = {}

    constructor(props) {
      super(props);
      if (User.canViewStats) { this.fetchData() }
    }

    get rideChartActiveTab() {
      return this.rideTabs.slice().find(data => data.isActive === true).title
    }

    setRideChartData(type) {
      this.rideTabs.find((data) => data.isActive === true).isActive = false
      this.rideTabs.find((data) => data.title === type).isActive = true
    }

    get subscriptionChartActiveTab() {
      return this.subscriptionTabs.slice().find(data => data.isActive === true).title
    }

    setSubscriptionChartData(type) {
      this.subscriptionTabs.find((data) => data.isActive === true).isActive = false
      this.subscriptionTabs.find((data) => data.title === type).isActive = true
    }

    get tipPercentageActiveTab() {
      return this.tippedPctTabs.slice().find(data => data.isActive === true).title
    }

    setTipPctChartData(type) {
      this.tippedPctTabs.find((data) => data.isActive === true).isActive = false
      this.tippedPctTabs.find((data) => data.title === type).isActive = true
    }

    get tippedBookingsActiveTab() {
      return this.tippedTabs.slice().find(data => data.isActive === true).title
    }

    setTippedChartData(type) {
      this.tippedTabs.find((data) => data.isActive === true).isActive = false
      this.tippedTabs.find((data) => data.title === type).isActive = true
    }

    fetchData = async () => {
      this.rideData = await client.get('/admin/dashboard_stats/ride_data');
      this.subscriptionData = await client.get('/admin/dashboard_stats/subscription_data');
      this.ratingData = await client.get('/admin/dashboard_stats/rating_data');
      this.fillData = await client.get('/admin/dashboard_stats/fill_rate_data')
    }

    get reports() {
      return [
        { title: 'Rides (day)', bgColor: 'warning', iconClass: 'bx-car', description: this.rideData.dayCount },
        { title: 'Rides (wk)', bgColor: 'success', iconClass: 'bx-car', description: this.rideData.weekCount },
        { title: 'Rides (mo)', bgColor: 'primary', iconClass: 'bx-car', description: this.rideData.monthCount },

        { title: 'Ride Revenue (day)', bgColor: 'warning', iconClass: 'bx-dollar-circle', description: this.rideData.dayEarning },
        { title: 'Ride Revenue (wk)', bgColor: 'success', iconClass: 'bx-dollar-circle', description: this.rideData.weekEarning },
        { title: 'Ride Revenue (mo)', bgColor: 'primary', iconClass: 'bx-dollar-circle', description: this.rideData.monthEarning },

        { title: 'Fill Rate (wk)', bgColor: 'warning', iconClass: 'bx-pie-chart', description: this.fillData.trailingWeekPercentage },
        { title: 'Fill Rate (mo)', bgColor: 'success', iconClass: 'bx-pie-chart', description: this.fillData.trailingMonthPercentage },
        { title: 'Fill Rate (yr)', bgColor: 'primary', iconClass: 'bx-pie-chart', description: this.fillData.trailingYearPercentage },

        { title: 'New Clients (day)', bgColor: 'warning', iconClass: 'bx-user', description: this.subscriptionData.clientDayCount },
        { title: 'New Clients (wk)', bgColor: 'success', iconClass: 'bx-user', description: this.subscriptionData.clientWeekCount },
        { title: 'New Clients (mo)', bgColor: 'primary', iconClass: 'bx-user', description: this.subscriptionData.clientMonthCount },

        { title: 'Subscription Revenue (day)', bgColor: 'warning', iconClass: 'bx-dollar', description: this.subscriptionData.dayEarning },
        { title: 'Subscription Revenue (wk)', bgColor: 'success', iconClass: 'bx-dollar', description: this.subscriptionData.weekEarning },
        { title: 'Subscription Revenue (mo)', bgColor: 'primary', iconClass: 'bx-dollar', description: this.subscriptionData.monthEarning },

        { title: 'Ratings (day)', bgColor: 'warning', iconClass: 'bx-star', description: `${ this.ratingData.trailingDayAvg } / 5`, subtitle: `(${ this.ratingData.trailingDayTotal } tot)` },
        { title: 'Ratings (wk)', bgColor: 'success', iconClass: 'bx-star', description: `${ this.ratingData.trailingWeekAvg } / 5`, subtitle: `(${ this.ratingData.trailingWeekTotal } tot)` },
        { title: 'Ratings (mo)', bgColor: 'primary', iconClass: 'bx-star', description: `${ this.ratingData.trailingMonthAvg } / 5`, subtitle: `(${ this.ratingData.trailingMonthTotal } tot)` },
      ]
    }

    render() {
      return (
        <>
          <div className="page-content">
            <Container fluid>

              {/* Render Breadcrumb */}
              <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

              { User.canViewStats && <Row>
                <Col xl="4">
                  {/* <WelcomeComp /> */}
                  <MonthlyEarning />
                </Col>
                <Col xl="8">
                  <Row>
                    {/* Reports Render */}
                    {
                        this.reports.map((report, key) => (
                          <Col md="4" key={`_col_${key}`}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <Media>
                                  <Media body>
                                    <p className="text-muted font-weight-medium">{report.title}</p>
                                    <h4 className="mb-0">{report.description}</h4>
                                    { report.subtitle && <h5 className="mb-0 mt-2">{report.subtitle}</h5> }
                                  </Media>
                                  <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                    <span className={`avatar-title bg-${report.bgColor}`}>
                                      <i className={`bx ${report.iconClass} font-size-24`} />
                                    </span>
                                  </div>
                                </Media>
                              </CardBody>
                            </Card>
                          </Col>
                        ))
                    }

                    <Col>
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4 float-sm-left">
                            Ride Breakdown
                          </CardTitle>
                          <div className="float-sm-right">
                            <ul className="nav nav-pills">
                              {
                                this.rideTabs.map((data, key) =>
                                  <li className="nav-item" key={"_li_" + key}>
                                    <a
                                      className={data.isActive ? "nav-link active" : "nav-link"}
                                      onClick={data.linkto}>{data.title}
                                    </a>
                                  </li>
                                )
                              }
                            </ul>
                          </div>

                          <div className="clearfix"></div>

                          <RidesChart activeTab={this.rideChartActiveTab} />
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4 float-sm-left">
                            Subscription Breakdown
                          </CardTitle>
                          <div className="float-sm-right">
                            <ul className="nav nav-pills">
                              {
                                this.subscriptionTabs.map((data, key) =>
                                  <li className="nav-item" key={"_li_" + key}>
                                    <a
                                      className={data.isActive ? "nav-link active" : "nav-link"}
                                      onClick={data.linkto}>{data.title}
                                    </a>
                                  </li>
                                )
                              }
                            </ul>
                          </div>

                          <div className="clearfix"></div>

                          <SubscriptionsChart activeTab={this.subscriptionChartActiveTab} />
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4 float-sm-left">
                            Bookings with Tips
                          </CardTitle>

                          <div className="float-sm-right">
                            <ul className="nav nav-pills">
                              {
                                this.tippedTabs.map((data, key) =>
                                  <li className="nav-item" key={"_li_" + key}>
                                    <a
                                      className={data.isActive ? "nav-link active" : "nav-link"}
                                      onClick={data.linkto}>{data.title}
                                    </a>
                                  </li>
                                )
                              }
                            </ul>
                          </div>

                          <div className="clearfix"></div>

                          <TippedBookingsChart activeTab={this.tippedBookingsActiveTab} />
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4 float-sm-left">
                            Tip Avg Percentage
                          </CardTitle>

                          <div className="float-sm-right">
                            <ul className="nav nav-pills">
                              {
                                this.tippedPctTabs.map((data, key) =>
                                  <li className="nav-item" key={"_li_" + key}>
                                    <a
                                      className={data.isActive ? "nav-link active" : "nav-link"}
                                      onClick={data.linkto}>{data.title}
                                    </a>
                                  </li>
                                )
                              }
                            </ul>
                          </div>

                          <div className="clearfix"></div>

                          <TipPercentageChart activeTab={this.tipPercentageActiveTab} />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row> }

              { !User.canViewStats && <p>Sorry, but you are not authorized to view the dashboard.</p> }

              {/* <Row>
                            <Col xl="4">
                                <SocialSource />
                            </Col>
                            <Col xl="4">
                                <ActivityComp />
                            </Col>

                            <Col xl="4">
                                <TopCities />
                            </Col>
                        </Row> */}

              {/* <Row>
                            <Col lg="12">
                                <LatestTranaction />
                            </Col>
                        </Row> */}
            </Container>
          </div>
        </>
      );
    }
});

decorate(Dashboard, {
  fetchData: action,
  rideData: observable,
  fillData: observable,
  rideTabs: observable,
  subscriptionData: observable,
  tippedPctTabs: observable,
  tippedTabs: observable,
  subscriptionsTabs: observable,
  rideChartActiveTab: computed,
  subscriptionChartActiveTab: computed,
  setRideChartData: action,
  setSubscriptionChartData: action,
  tippedBookingsActiveTab: computed,
  tipPercentageActiveTab: computed,
  reports: computed,
  ratingData: observable,
});
