export function retry(operation, retries, delay) {
  return new Promise((resolve, reject) => {
    function attempt(remainingRetries) {
      if (operation()) {
        resolve("Operation succeeded");
      } else if (remainingRetries > 0) {
        setTimeout(() => attempt(remainingRetries - 1), delay);
      } else {
        reject("Operation failed after maximum retries");
      }
    }
    attempt(retries);
  });
}
