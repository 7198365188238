// @ts-nocheck
import { action, computed, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { Address } from "../models/Address"

export class addressesStore {
	@observable addresses = new Map()

  @action pushData = (addressData: object) => {
    this.addresses.set(addressData.id, new Address(addressData))
  }

  @action clear() {
    this.addresses.clear()
  }

  @action create = async (url: string, data: object) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const address = new Address(response.data)
      this.addresses.set(address.id, address)
      return address
    }
  }

  @action update = async(id: string, data: object) => {
    const address = this.addresses.get(id)

    if (!address) {
      return 'error'
    }

    // clients/:client_id/addresses/:id OR /addresses/:id
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/addresses/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      address.updateFromJson(response.data)
      return address
    }
  }

  @action destroy = async(id: string) => {
    const address = this.addresses.get(id)

    if (!address) {
      // console.log('err')
      return 'error'
    }

    await apiCall(`${process.env.REACT_APP_API_URL}/addresses/${id}`, {
      method: 'DELETE',
    })

    this.addresses.delete(id)
    return true
  }

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.addresses.clear()
      for (const addressData of response.data) {
        this.addresses.set(addressData.id, new Address(addressData))
      }
      return this.addresses
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/addresses/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const address = new Address(response.data)
      this.addresses.set(response.data?.id, address)
      return address
    }
  }

  @computed get default() {
    return Array.from(this.addresses.values()).find(address => address.isDefault)
  }
}
