import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { UsersTable } from '../../components/Tables/UsersTable'

export const TextingTool = observer(class TextingTool extends Component {
  render() {
    return(
      <UsersTable texting={true} embedded={true} skipInitLoad={true} />
    )
  }
});
