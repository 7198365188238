import React from 'react';
import { Redirect } from 'react-router-dom';

// Profile
import UserProfile from '../pages/Authentication/user-profile';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import ResetPassword from '../pages/Authentication/ResetPassword';

//  // Inner Authentication
import Login1 from '../pages/AuthenticationInner/Login';
import Register1 from '../pages/AuthenticationInner/Register';
import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword';
import LockScreen from '../pages/AuthenticationInner/auth-lock-screen';

// Dashboard
import { Dashboard } from '../pages/Dashboard/index';
import DashboardSaas from '../pages/Dashboard-saas/index';
import DashboardCrypto from '../pages/Dashboard-crypto/index';

// Bookings
import { BookingsTable } from '../pages/Bookings/BookingsTable';
import { BookingShow } from '../pages/Bookings/BookingShow';
import { BookingImport } from '../pages/BookingImport/BookingImport';

import { ClientShow } from '../pages/Clients/ClientShow';
import { DriverShow } from '../pages/Drivers/DriverShow';

import { ClientsIndex } from '../pages/Clients/ClientsIndex';
import { DriversIndex } from '../pages/Drivers/DriversIndex';
import { DriversMap } from '../pages/Drivers/DriversMap';
import { BillingsIndex } from '../pages/Billings/Index';
import { EarningsIndex } from '../pages/Earnings/Index';
import { EventsIndex } from '../pages/Events/Index';
import { ShortUrlsIndex } from '../pages/ShortUrls/Index';
import { P2PVehiclesIndex } from '../pages/P2P/Index';

import { PromoCodesIndex } from '../pages/PromoCodes/PromoCodesIndex';
// import { PromoCodeShow } from '../pages/PromoCodes/PromoCodeShow';
import { CouponsIndex } from '../pages/Coupons/CouponsIndex';
import { DispatchSettings } from '../pages/Settings/Dispatch';

import { TextingTool } from '../pages/Communication/TextingTool';
import { UsersSearch } from '../pages/Users/Search';
import { Leads } from '../pages/Leads/Leads';
import { ZipCodesIndex } from '../pages/ZipCodes/ZipCodesIndex';

const userRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/dashboard-saas', component: DashboardSaas },
  { path: '/dashboard-crypto', component: DashboardCrypto },

  { path: '/bookings/dispatching', component: BookingsTable },
  { path: '/bookings/pending-p2p', component: BookingsTable },
  { path: '/bookings/awaiting-review', component: BookingsTable },

  { path: '/bookings/active', component: BookingsTable },
  { path: '/bookings/upcoming', component: BookingsTable },
  { path: '/bookings/upcoming-first', component: BookingsTable },
  { path: '/bookings/confirmed', component: BookingsTable },
  { path: '/bookings/driver-en-route', component: BookingsTable },
  { path: '/bookings/driver-arrived', component: BookingsTable },
  { path: '/bookings/driver-returning', component: BookingsTable },
  { path: '/bookings/in-progress', component: BookingsTable },

  { path: '/bookings/finished', component: BookingsTable },
  { path: '/bookings/closed', component: BookingsTable },
  { path: '/bookings/awaiting-review', component: BookingsTable },
  { path: '/bookings/cancelled', component: BookingsTable },
  { path: '/bookings/failed', component: BookingsTable },

  // internal
  { path: '/bookings/dispatching/internal', component: BookingsTable },
  { path: '/bookings/pending-p2p/internal', component: BookingsTable },
  { path: '/bookings/awaiting-review', component: BookingsTable },

  { path: '/bookings/active/internal', component: BookingsTable },
  { path: '/bookings/upcoming/internal', component: BookingsTable },
  { path: '/bookings/confirmed/internal', component: BookingsTable },
  { path: '/bookings/driver-en-route/internal', component: BookingsTable },
  { path: '/bookings/driver-arrived/internal', component: BookingsTable },
  { path: '/bookings/driver-returning/internal', component: BookingsTable },
  { path: '/bookings/in-progress/internal', component: BookingsTable },

  { path: '/bookings/finished/internal', component: BookingsTable },
  { path: '/bookings/closed/internal', component: BookingsTable },
  { path: '/bookings/awaiting-review/internal', component: BookingsTable },
  { path: '/bookings/cancelled/internal', component: BookingsTable },
  { path: '/bookings/failed/internal', component: BookingsTable },

  { path: '/booking-import', component: BookingImport },

  { path: '/bookings/', component: BookingsTable },
  { path: '/booking/*', component: BookingShow },

  { path: '/clients/active', component: ClientsIndex },
  { path: '/clients/trial', component: ClientsIndex },
  { path: '/clients/awaiting_booking', component: ClientsIndex },
  { path: '/clients/prospects', component: ClientsIndex },
  { path: '/clients/leads', component: ClientsIndex },
  { path: '/clients/suspended', component: ClientsIndex },
  // { path: "/clients/awaiting-call", component: ClientsIndex },
  { path: '/clients/cancelled', component: ClientsIndex },
  { path: '/clients/removed', component: ClientsIndex },
  { path: '/clients', component: ClientsIndex },

  { path: '/client/*', component: ClientShow },
  { path: '/Client/*', component: ClientShow },

  { path: '/drivers/applied', component: DriversIndex },
  { path: '/drivers/rejected', component: DriversIndex },
  { path: '/drivers/probationary', component: DriversIndex },
  { path: '/drivers/active', component: DriversIndex },
  { path: '/drivers/removed', component: DriversIndex },
  { path: '/drivers/banned', component: DriversIndex },
  { path: '/drivers/map', component: DriversMap },
  { path: '/drivers', component: DriversIndex },

  { path: '/driver/*', component: DriverShow },
  { path: '/Driver/*', component: DriverShow },

  { path: '/earnings/pending', component: EarningsIndex },
  { path: '/earnings/paid', component: EarningsIndex },
  { path: '/earnings/failed', component: EarningsIndex },
  { path: '/earnings/cancelled', component: EarningsIndex },
  { path: '/earnings', component: EarningsIndex },

  { path: '/events', component: EventsIndex },

  { path: '/short_urls', component: ShortUrlsIndex },

  { path: '/billings/pending', component: BillingsIndex },
  { path: '/billings/paid', component: BillingsIndex },
  { path: '/billings/failed', component: BillingsIndex },
  { path: '/billings/cancelled', component: BillingsIndex },
  { path: '/billings', component: BillingsIndex },

  { path: '/promo_codes', component: PromoCodesIndex },
  // { path: '/promo_code/*', component: PromoCodesShow },

  { path: '/coupons', component: CouponsIndex },

  { path: '/communication/texting-tool', component: TextingTool },

  { path: '/marketing/lead-import', component: Leads },

  { path: '/dispatch-settings', component: DispatchSettings },
  { path: '/zip-codes', component: ZipCodesIndex },

  // profile
  { path: '/profile', component: UserProfile },

  { path: '/users/search', component: UsersSearch },

  { path: '/vehicles/p2p', component: P2PVehiclesIndex },

  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/reset-password', component: ResetPassword},

  // Authentication Inner
  { path: '/pages-login', component: Login1 },
  { path: '/pages-register', component: Register1 },
  { path: '/pages-forget-pwd', component: ForgetPwd1 },
  { path: '/auth-lock-screen', component: LockScreen },
];

export { userRoutes, authRoutes };
