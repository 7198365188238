import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { createRef, Component } from 'react';
import { Container, Input, Button, Row, Col } from 'reactstrap'
import { ReactGoogleMap } from '../../components/ReactGoogleMap/ReactGoogleMap'
import { client } from '../../helpers/client'

export const DriversMap = observer(class DriversMap extends Component {
  defaultRadius = 30

  constructor(props) {
    super(props);

    this.googleMap = createRef();
    this.state = {
      drivers: [],
      location: '',
      radius: '',
      center: {},
      zoomLevel: null,
      lastGeocodedLocation: null,
    }
  }

  async componentDidMount() {
    this.fetchDrivers();
  }

  async fetchDrivers() {
    const drivers = await client.post('/drivers/map_coordinates');

    this.setState({ drivers });

    this.googleMap.current.fitBounds();
  }

  onSearch = (event) => {
    event.preventDefault();

    if (this.state.location !== this.state.lastGeocodedLocation) {
      (new google.maps.Geocoder()).geocode({ 'address': this.state.location }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.setState({
            center: results[0].geometry.location,
            lastGeocodedLocation: this.state.location,
          });
        }
      });
    }

    const zoomLevel = Math.round(14 - Math.log(parseInt(this.state.radius || this.defaultRadius, 10)) / Math.LN2);

    this.setState({ zoomLevel })
  }

  render() {
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <form onSubmit={this.onSearch}>
              <Row>
                <Col>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Location..."
                    value={this.state.location}
                    onChange={(event) => this.setState({ location: event.target.value })}
                  />
                </Col>
                <Col>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder="Radius (in miles)"
                    value={this.state.radius}
                    onChange={(event) => this.setState({ radius: event.target.value })}
                  />
                </Col>
                <Col>
                  <Button type="submit" color="primary">Search</Button>
                </Col>
              </Row>
            </form>
            <ReactGoogleMap
              ref={this.googleMap}
              style={{ height: '70vh' }}
              title=" "
              mapLocations={this.state.drivers
                .filter((driver) => !!driver.latitude)
                .map((driver) => ({
                  lat: parseFloat(driver.latitude),
                  lng: parseFloat(driver.longitude),
                  title: `${driver.name} (open profile)`,
                  label: {
                    text: driver.name,
                    className: 'bg-white p-2 border border-gray rounded shadow-sm text-truncate'
                  },
                  www: `/driver/${driver.id}`,
                  icon: {
                    url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Standard marker icon
                    labelOrigin: { x: 15, y: 60 },
                  }
                })
              )}
              center={this.state.center}
              zoom={this.state.zoomLevel}
            />
          </Container>
        </div>
      </>
    );
  }
});

decorate(DriversMap, {
  drivers: observable,
});
