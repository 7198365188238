// @ts-nocheck
import { action, computed, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { Vehicle } from "../models/Vehicle"

export class vehiclesStore {
  @observable vehicles = new Map()

  @action clear() {
    this.vehicles.clear()
  }

  @action create = async (url: string, data: object) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const vehicle = new Vehicle(response.data)
      this.vehicles.set(vehicle.id, vehicle)
      return vehicle
    }
  }

  @action update = async(id: string, data: object) => {
    const vehicle = this.vehicles.get(id)

    if (!vehicle) {
      return 'error'
    }

    // clients/:client_id/vehicles/:id OR /vehicles/:id
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/vehicles/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      vehicle.updateFromJson(response.data)
      return vehicle
    }
  }

  @action destroy = async(id: string) => {
    const vehicle = this.vehicles.get(id)

    if (!vehicle) {
      // console.log('err')
      return 'error'
    }

    await apiCall(`${process.env.REACT_APP_API_URL}/vehicles/${id}`, {
      method: 'DELETE',
    })

    this.vehicles.delete(id)
    return true
  }

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.vehicles.clear()
      for (const vehicleData of response.data) {
        this.vehicles.set(vehicleData.id, new Vehicle(vehicleData))
      }
      return this.vehicles
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/vehicles/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const vehicle = new Vehicle(response.data)
      this.vehicles.set(response.data?.id, vehicle)
      return vehicle
    }
  }

  @computed get default() {
    return Array.from(this.vehicles.values()).find(vehicle => vehicle.isDefault)
  }
}
