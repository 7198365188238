import { AvField, AvForm } from 'availity-reactstrap-validation';
import { decorate, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';
import { client } from '../../helpers/client';

export const ShortUrlModal = observer(class ShortUrlModal extends Component {
  submitHandler = async (e, values) => {
    const data = {
      short_url: {
        url: values.url,
        key: values.uniqueKey,
        category: values.category,
        expires_at: values.expiresAt,
      },
    }

    if (this.props.url) {
      await client.makeApiCall(
        'put', `/short_urls/${this.props.url.id}`, data,
        'Url updated',
        'Unable to update url',
        () => {
          runInAction(() => this.addModalOpen = false)
          window.location.reload()
        }
      )
    } else {
      await client.makeApiCall(
        'post', `/short_urls`, data,
        'Url created',
        'Unable to create url',
        () => {
          runInAction(() => this.addModalOpen = false)
          window.location.reload()
        }
      )
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.submitHandler} model={this.props.url}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="url">Destination URL</Label>
                  <AvField
                    name="url"
                    className="form-control"
                    validate={{ required: true, pattern: { value: /^(jeevz|https):\/\// } }}
                    placeholder={"https://... or jeevz://link..."}
                    id="url"
                    type="text"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="uniqueKey">Unique Key (leave blank to auto create)</Label>
                  <AvField
                    name="uniqueKey"
                    placeholder="ABCDEFG"
                    className="form-control"
                    id="uniqueKey"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="category">Category (optional - for tracking)</Label>
                  <AvField
                    name="category"
                    placeholder={"marketing/tracking/"}
                    className="form-control"
                    id="category"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="expiresAt">Expires At</Label>
                  <AvField
                    name="expiresAt"
                    placeholder="Link Expiration Date (optional - blank for never)"
                    type="date"
                    className="form-control"
                    id="expiresAt"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Save</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(ShortUrlModal, {
});
