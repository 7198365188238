import React, { Component } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import strftime from 'strftime';

export default class BookingTimeline extends Component {
  statuses = () => {
    if (!this.props.booking) { return [] }

    if (this.props.booking.p2p) {
      return [
        { id: 1, key: 'createdAt', statusTitle: 'Booked', iconClass: 'bx-calendar-edit' },
        { id: 2, key: 'driverAssignedAt', statusTitle: 'Driver Assigned', iconClass: 'bx-user-check' },
        { id: 3, key: 'driverPickingUpVehicleAt', statusTitle: 'Driver Picking up Vehicle', iconClass: 'bx-car' },
        { id: 4, key: 'driverEmbarkedAt', statusTitle: 'Driver Embarked', iconClass: 'bx-car' },
        { id: 5, key: 'driverArrivedAt', statusTitle: 'Driver Arrived', iconClass: 'bx-map-pin' },
        { id: 6, key: 'bookingStartedAt', statusTitle: 'Trip Started', iconClass: 'bx-map-alt' },
        { id: 7, key: 'driverDroppedOffClientAt', statusTitle: 'Driver Dropped off Client', iconClass: 'bx-map-alt' },
        { id: 8, key: 'bookingEndedAt', statusTitle: 'Trip Ended', iconClass: 'bx-home' },
        { id: 9, key: 'closedOutAt', statusTitle: 'Closed At', iconClass: 'bx-badge-check' },
      ]
    } else if (this.props.booking.bookingType === "one_way") {
      return [
        { id: 1, key: 'createdAt', statusTitle: 'Booked', iconClass: 'bx-calendar-edit' },
        { id: 2, key: 'driverAssignedAt', statusTitle: 'Driver Assigned', iconClass: 'bx-user-check' },
        { id: 3, key: 'driverEmbarkedAt', statusTitle: 'Driver Embarked', iconClass: 'bx-car' },
        { id: 4, key: 'driverArrivedAt', statusTitle: 'Driver Arrived', iconClass: 'bx-map-pin' },
        { id: 5, key: 'bookingStartedAt', statusTitle: 'Trip Started', iconClass: 'bx-map-alt' },
        { id: 5, key: 'driverReturningAt', statusTitle: 'Driver Dropped Off Client', iconClass: 'bx-map-alt' },
        { id: 6, key: 'bookingEndedAt', statusTitle: 'Trip Ended', iconClass: 'bx-home' },
        { id: 7, key: 'closedOutAt', statusTitle: 'Closed At', iconClass: 'bx-badge-check' },
      ]
    } else {
      return [
        { id: 1, key: 'createdAt', statusTitle: 'Booked', iconClass: 'bx-calendar-edit' },
        { id: 2, key: 'driverAssignedAt', statusTitle: 'Driver Assigned', iconClass: 'bx-user-check' },
        { id: 3, key: 'driverEmbarkedAt', statusTitle: 'Driver Embarked', iconClass: 'bx-car' },
        { id: 4, key: 'driverArrivedAt', statusTitle: 'Driver Arrived', iconClass: 'bx-map-pin' },
        { id: 5, key: 'bookingStartedAt', statusTitle: 'Trip Started', iconClass: 'bx-map-alt' },
        { id: 6, key: 'bookingEndedAt', statusTitle: 'Trip Ended', iconClass: 'bx-home' },
        { id: 7, key: 'closedOutAt', statusTitle: 'Closed At', iconClass: 'bx-badge-check' },
      ]
    }
  }

  currentStep() {
    const reversedStatuses = this.statuses().slice().reverse();
    for (const status of reversedStatuses) {
      if (this.props.booking[status.key]) {
        return status.id;
      }
    }
  }

  formattedTime = (time) => {
    if (!time) {
      return '';
    }
    const date = new Date(time);
    return (
      <>
        { strftime('%B %d, %Y', date) }
        <br />
        { strftime('%l:%M%P', date) }
        <br />
        { strftime('(%Z)', date) }
      </>
    );
  }

  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Booking Timeline</CardTitle>
          <div className="">
            <ul className="verti-timeline list-unstyled">
              {
                this.statuses().map((status, key) => (
                  <li key={key} className="event-list">
                    <div className="event-timeline-dot">
                      <i className={this.currentStep() === status.id ? 'bx bx-right-arrow-circle bx-fade-right' : 'bx bx-right-arrow-circle'} />
                    </div>
                    <div className="media">
                      <div className="mr-3">
                        <i className={`bx ${status.iconClass} h2 text-primary`} />
                      </div>
                      <div className="media-body">
                        <div>
                          <h5>{status.statusTitle}</h5>
                          <p className="text-muted">{this.formattedTime(this.props.booking[status.key])}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  }
}
