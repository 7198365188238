import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = (props) => (
  <>
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col md={6}>
            {new Date().getFullYear()}
            {' '}
            © Jeevz.
          </Col>
        </Row>
      </Container>
    </footer>
  </>
);

export default Footer;
