import { AvField, AvForm } from 'availity-reactstrap-validation';
import {
  action, computed, decorate, observable, runInAction
} from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Avatar from 'react-avatar';
import RelativeTime from 'react-relative-time';
import { NavLink } from 'react-router-dom';
import {
  Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row
} from 'reactstrap';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { ConfirmationModal } from '../../components/Modals/ConfirmationModal';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { User } from '../../models/User';

export const UsersTable = observer(class UsersTable extends Component {
  recipients = []
  message
  manualPhoneNumber
  confirmationModalOpen = false
  phoneNumbers

  urlFor = (row) => {
    let url

    if (row.type === 'Client') {
      url = `/client/${row.id}`;
    } else if (row.type === 'Driver') {
      url = `/driver/${row.id}`;
    } else {
      url = `/#`;
    }

    return url
  }

  userFormatter = (cell, row) => {
    return (
      <NavLink strict={+true} to={this.urlFor(row)}>
        <Avatar
          name={row.name}
          src={row.avatarUrl}
          size={32}
          round
          style={{marginRight: '10px'}}
        />
        {row.name}
      </NavLink>
    );
  }

  idFormatter = (cell, row) => (
    <>
      <NavLink strict={+true} to={this.urlFor(row)} key={cell}>{cell.split('-')[0]}</NavLink>
      <ClipboardLink text={cell} />
    </>
  )

  createdAtFormatter = (cell, row) => {
    if (cell) {
      return (<RelativeTime value={cell} />);
    }
  }

  emailFormatter = (cell, row) => {
    if (cell) {
      return <a href={`mailto:${cell}`}>{cell}</a>;
    }
  }

  phoneFormatter = (cell, _row) => {
    if (cell) {
      return <a href={`tel:${cell}`}>{cell}</a>;
    }
  }

  addToRecipients = (rows) => {
    for (const row of rows) {
      if (row && !this.recipients.includes(row)) {
        this.recipients.push(row)
      }
    }
  }

  _addManualRecipient = (_e, values) => {
    this.recipients.push({
      id: values.phoneNumber,
      manual: true,
      name: 'Unknown',
      phoneNumber: values.phoneNumber,
    })
    this.manualPhoneNumber = null
  }

  removeRecipient = (row) => {
    this.recipients.remove(row)
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
      sort: true,
    },
    {
      dataField: 'name',
      name: 'Name',
      priority: 1,
      formatter: this.userFormatter,
      sort: true,
    },
    {
      dataField: 'type',
      name: 'Type',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'email',
      name: 'Email',
      priority: 1,
      formatter: this.emailFormatter,
      sort: true,
    },
    {
      dataField: 'phoneNumber',
      name: 'Phone',
      priority: 1,
      formatter: this.phoneFormatter,
      sort: true,
    },
    {
      dataField: 'city',
      name: 'City',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'zipCode',
      name: 'Zip',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'status',
      name: 'Status',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'createdAt',
      name: 'Joined At',
      priority: 1,
      formatter: this.createdAtFormatter,
      sort: true,
    },
  ]

  _submitHandler = async (_e, values) => {
    this.message = values.message
    this.phoneNumbers = this.recipients.map(x => x.phoneNumber)

    this.toggleConfirmationModal()
  }

  get modalBody() {
    return `Send Text Message to ${ this.recipients.length } recipients?  Replies will be routed to https://flex.twilio.com/glitter-guppy-4753.`
  }

  toggleConfirmationModal = () => this.confirmationModalOpen = !this.confirmationModalOpen

  sendTexts = async () => {
    client.makeApiCall(
      'post', '/mass_text_tool', {
        text: { body: this.message, phone_numbers: this.phoneNumbers }
      },
      'Messages send successfully',
      'Unable to send some or all messages',
      () => runInAction(() => {
        this.recipients = []
        this.message = null
        this.manualPhoneNumber = null
        this.confirmationModalOpen = false
        this.phoneNumbers = null
      })
    )
  }

  render() {
    return (
      <>
        <ConfirmationModal
          isOpen={ this.confirmationModalOpen }
          toggle={ this.toggleConfirmationModal }
          header={ 'Send Text?' }
          body={ this.modalBody }
          confirm={ this.sendTexts }
          confirmButtonText={ 'Send' }
        />

        { this.props.texting && <div className={'page-content pb-0 pr-0 pl-0'}>
          <div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle>Text Message</CardTitle>
                    <div style={{fontWeight: '500'}} className={'mb-2'}>Recipients</div>

                    <AvForm className="needs-validation form-inline mb-2" onValidSubmit={this._addManualRecipient}>
                      <AvField
                        onChange={(_e, v) => runInAction(() => this.manualPhoneNumber = v)}
                        name="phoneNumber"
                        placeholder="Phone Number"
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        value={this.manualPhoneNumber}
                      />
                      &nbsp;
                      <Button color="primary" className="btn btn-primary" type="submit">Add Recipient</Button>
                    </AvForm>

                    { this.recipients.map((row) => {
                      return (<button key={row.id} type="button" className="btn btn-info waves-effect waves-light mr-1 mb-1 p-1 font-size-12">
                        { row.name } - { row.phoneNumber }
                        <i onClick={() => this.removeRecipient(row)} className="bx bx-x font-size-16 align-middle ml-2" />
                      </button>)
                    }) }
                    <AvForm className="needs-validation" onValidSubmit={this._submitHandler}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="message">Message { this.message && `- ${this.message.length} chars`}</Label>
                            <AvField
                              onChange={(_e, v) => runInAction(() => this.message = v)}
                              name="message"
                              placeholder="Message"
                              type="textarea"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="message"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button color="primary" type="submit">Send Text</Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div> }

        <MyTable
          model={User}
          filter={this.props.filter}
          dataUrl={this.props.dataUrl || '/users'}
          dataColumns={this.dataColumns}

          breadcrumbTitle={this.props.filter || 'All'}
          breadcrumbBaseTitle={this.props.title || 'Texting Tool'}

          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}

          embedded={this.props.embedded}
          searchable
          defaultLimit={this.props.defaultLimit}
          skipInitLoad={this.props.skipInitLoad}

          multiActions={this.props.texting && {
            'Add to Recipients': async (rows) => {
              this.addToRecipients(rows)
            },
          }}
        />
      </>
    );
  }
});

decorate(UsersTable, {
  recipients: observable,
  message: observable,
  addToRecipients: action,
  removeRecipient: action,
  manualPhoneNumber: observable,
  _addManualRecipient: action,
  modalBody: computed,
  toggleConfirmationModal: action,
  confirmationModalOpen: observable,
  phoneNumbers: observable,
  _submitHandler: action,
});
