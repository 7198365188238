// @ts-nocheck
import { action, computed, observable } from "mobx"

export class PromoCode {
  /**
   * unique id of this PromoCode, immutable.
   */
  id = null

  @observable adminId
  @observable code
  @observable maxUses
  @observable totalUsesCount
  @observable timeValueMinutes
  @observable validAt
  @observable expiresAt
  @observable deletedAt: string
  @observable createdAt: string
  @observable cashValue
  @observable monthlyRate
  @observable yearlyRate
  @observable admin: boolean
  @observable ambassador: boolean
  @observable babyAmbassador: boolean

  /**
   * Indicates whether changes in this object
   * should be submitted to the server
   */
  autoSave = true

  /**
   * Disposer for the side effect that automatically
   * stores this object, see @dispose.
   */
  saveHandler = null

  constructor(data) {
    this.id = data?.id

    this.updateFromJson(data)
  }

  formatDate(date) {
    const offset = date.getTimezoneOffset() / 60
    date.setTime(date.getTime() - offset * 60 * 60 * 1000)
    date.setSeconds(0, 0)
    return date.toISOString().replace(/T/, " ").replace(/:00.000Z/, "");
  }

  @computed get validAtLocalizedString() {
    if (this.validAt) {
      let date = new Date(this.validAt)
      return date.toLocaleDateString('en-CA')
    }
  }

  @computed get expiresAtLocalizedString() {
    if (this.expiresAt) {
      let date = new Date(this.expiresAt)
      return date.toLocaleDateString('en-CA')
    }
  }

  @computed get asJson() {
    return {
      adminId: this.adminId,
      code: this.code,
      maxUses: this.maxUses,
      totalUsesCount: this.totalUsesCount,
      timeValueMinutes: this.timeValueMinutes,
      validAt: this.validAt,
      expiresAt: this.expiresAt,
      deletedAt: this.deletedAt,
      createdAt: this.createdAt,
      cashValue: this.cashValue,
      monthlyRate: this.monthlyRate,
      yearlyRate: this.yearlyRate,
      admin: this.admin,
      ambassador: this.ambassador,
      babyAmbassador: this.babyAmbassador,
    }
  }

  /**
   * Update this object with information from the server
   */
  @action updateFromJson(data) {
    // make sure our changes aren't sent back to the server
    this.autoSave = false

    this.adminId          = data.attributes.adminId
    this.code             = data.attributes.code
    this.maxUses          = data.attributes.maxUses
    this.totalUsesCount   = data.attributes.totalUsesCount
    this.timeValueMinutes = data.attributes.timeValueMinutes
    this.validAt          = data.attributes.validAt
    this.expiresAt        = data.attributes.expiresAt
    this.deletedAt        = data.attributes.deletedAt
    this.createdAt        = data.attributes.createdAt
    this.cashValue        = data.attributes.cashValue
    this.admin            = data.attributes.admin
    this.ambassador       = data.attributes.ambassador
    this.babyAmbassador   = data.attributes.babyAmbassador
    this.monthlyRate      = data.attributes.monthlyRate
    this.yearlyRate       = data.attributes.yearlyRate

    this.autoSave = true
  }

  dispose() {
    // clean up the observer
    this.saveHandler()
  }
}
