import { action, computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import RelativeTime from 'react-relative-time';
import { NavLink } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import strftime from 'strftime';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { Offer } from '../../models/Offer';
import { client } from '../../helpers/client';
import { ConfirmationModal } from '../../components/Modals/ConfirmationModal';
import { AlertModal } from '../../components/Modals/AlertModal';

export const DispatchingTable = observer(class DispatchingTable extends Component {
  filter

  _confirmAssignModalOpen = false
  _confirmRemoveModalOpen = false

  _selectedOffer

  _alertMessage
  _alertModalOpen = false

  idFormatter = (cell, _row) => (
    <>
      {cell.split('-')[0]}
      <ClipboardLink text={cell} />
    </>
  )

  createdAtFormatter = (cell, row) => {
    if (cell) {
      return (<RelativeTime value={cell} />);
    }
  }

  driverFormatter = (cell, _row) => {
    const url = `/driver/${cell?.id}`;

    return (
      <NavLink strict={+true} to={url}>{cell?.name}</NavLink>
    );
  }

  bonusFormatter = (cell, _row) => cell?.pretty

  formattedTime = (time) => {
    if (!time) {
      return '';
    }
    const date = new Date(time);
    return (
      <>
        { strftime('%B %d, %Y', date) }
        <br />
        { strftime('%l:%M%P', date) }
        <br />
        { strftime('(%Z)', date) }
      </>
    );
  }

  responseFormatter = (cell, row) => {
    const responseAt = row.acceptedAt || row.rejectedAt;
    if (responseAt) {
      return this.formattedTime(responseAt);
    }
    return 'No Response';
  }

  assignButton = (cell, row) => {
    if (row.status === 'accepted' && this.driverRemoveable) {
      return (<Button color="danger" onClick={() => { this.removeDriver(row); }}>Remove Driver</Button>);
    } if (this.driverAssignable && row.status === 'pending') {
      return (<Button color="primary" onClick={() => { this.assignDriver([row]); }}>Accept</Button>);
    }
  }

  removeDriver = async (offer) => {
    this._selectedOffer = offer
    this._toggleConfirmRemoveModal()
  }

  assignDriver = async (rows) => {
    if (rows.length === 0) {
      this._toggleAlertModal('You must select a driver to assign.')
    } else if (rows.length !== 1) {
      this._toggleAlertModal('You can only assign 1 driver to the trip.')
    } else {
      const offer = rows[0];
      this._selectedOffer = offer
      this._toggleConfirmAssignModal()
    }
  }

  _toggleAlertModal = (message) => {
    if (message) { this._alertMessage = message }
    this._alertModalOpen = !this._alertModalOpen
  }

  get driverAssignable() {
    switch (this.props.booking.status) {
      case 'requested': case 'dispatching':
        return true;
      default:
        return false;
    }
  }

  get driverRemoveable() {
    switch (this.props.booking.status) {
      case 'confirmed': case 'driver_en_route': case 'driver_arrived':
        return true;
      default:
        return false;
    }
  }

  statusFormatter = (cell, _row) => {
    switch (cell) {
      case 'pending':
        return <Badge color="warning" className="mr-1">{ cell.toUpperCase() }</Badge>;
      case 'accepted':
        return <Badge className="badge-soft-success mr-1">{ cell.toUpperCase() }</Badge>;
      case 'failed': case 'expired': case 'rejected': case 'terminated': case 'cancelled':
        return <Badge color="danger" className="mr-1">{ cell.toUpperCase() }</Badge>;
      default:
        return <Badge color="danger" className="mr-1">unknown</Badge>;
    }
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'status',
      name: 'Status',
      priority: 1,
      sort: true,
      formatter: this.statusFormatter,
    },
    {
      dataField: 'driver',
      name: 'Driver',
      priority: 1,
      formatter: this.driverFormatter,
      sort: true,
    },
    {
      dataField: 'bonus',
      name: 'Bonus',
      priority: 1,
      formatter: this.bonusFormatter,
    },
    {
      dataField: 'customMessage',
      name: 'Custom Message',
      priority: 1,
    },
    {
      dataField: 'createdAt',
      name: 'Offered At',
      priority: 1,
      sort: true,
      formatter: this.createdAtFormatter,
    },
    {
      dataField: 'acceptedAt',
      name: 'Driver Responded At',
      priority: 1,
      sort: true,
      formatter: this.responseFormatter,
    },
    {
      dataField: '',
      name: 'Actions',
      priority: 1,
      formatter: this.assignButton || this.removeButton,
    },
  ]

  _toggleConfirmAssignModal = () => this._confirmAssignModalOpen = !this._confirmAssignModalOpen
  _toggleConfirmRemoveModal = () => this._confirmRemoveModalOpen = !this._confirmRemoveModalOpen

  get _confirmRemoveModalBody() {
    return `Are you sure you want to remove ${this._selectedOffer?.driver?.name} from this trip? You'll need to generate new offers and assign a new driver.`
  }

  get _confirmAssignModalBody() {
    return `Are you sure you want to assign ${this._selectedOffer?.driver?.name} to this trip? Ensure you've confirmed the driver is available.`
  }

  _assignDriver = async () => {
    await client.makeApiCall(
      'put', `/dispatch_offers/${this._selectedOffer.id}`, { dispatch_offer: { status: 'accepted' } },
      'Driver assigned to trip',
      'Unable to assign driver to trip',
      () => window.location.reload()
    )
  }

  _removeDriver = async () => {
    await client.makeApiCall(
      'put', `/dispatch_offers/${this._selectedOffer.id}`, { dispatch_offer: { status: 'remove' } },
      'Driver removed from trip',
      'Unable to remove driver from trip',
      () => window.location.reload()
    )
  }

  render() {
    return (
      <>
        <AlertModal
          isOpen={ this._alertModalOpen }
          toggle={ this._toggleAlertModal }
          header={ 'Error' }
          body={ this._alertMessage }
        />

        {/* Assign */}
        <ConfirmationModal
          isOpen={ this._confirmAssignModalOpen }
          toggle={ this._toggleConfirmAssignModal }
          header={ 'Assign Driver?' }
          body={ this._confirmAssignModalBody }
          confirmButtonText={ 'Assign' }
          closeButtonText={ 'Cancel' }
          confirm={ this._assignDriver }
        />

        {/* Remove */}
        <ConfirmationModal
          isOpen={ this._confirmRemoveModalOpen }
          toggle={ this._toggleConfirmRemoveModal }
          header={ 'Remove Driver?' }
          body={ this._confirmRemoveModalBody }
          confirmButtonText={ 'Remove' }
          closeButtonText={ 'Cancel' }
          confirm={ this._removeDriver }
        />

        <MyTable
          model={Offer}
          dataUrl={this.props.dataUrl}
          dataColumns={this.dataColumns}
          breadcrumbTitle={this.props.filter || 'Offers'}
          breadcrumbBaseTitle={this.props.baseTitle || 'Dispatch'}

          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}

          embedded={this.props.embedded}
          searchable

          defaultLimit={this.props.defaultLimit}
          filter={this.filter}
        />
      </>
    );
  }
});

decorate(DispatchingTable, {
  filter: observable,
  driverAssignable: computed,
  driverRemoveable: computed,
  _confirmAssignModalOpen: observable,
  _toggleConfirmAssignModal: action,
  _confirmRemoveModalOpen: observable,
  _toggleConfirmRemoveModal: action,
  _selectedOffer: observable,
  _confirmRemoveModalBody: computed,
  assignDriver: action,
  _toggleAlertModal: action,
  _alertMessage: observable,
  _alertModalOpen: observable,
});
