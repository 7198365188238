import { computed, decorate, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import RelativeTime from 'react-relative-time'
import { NavLink } from 'react-router-dom'
import { Badge } from 'reactstrap'
import strftime from 'strftime'
import { titleize } from '../../helpers/titleize'
import ClipboardLink from '../../components/Common/ClipboardLink'
import { MyTable } from '../../components/MyTable/MyTable'
import { Trip } from '../../models/Trip'
import dryverLogo from '../../assets/images/dryver-icon.png'
import jeevzLogo from '../../assets/images/jeevz-icon.png'

export const BookingsTable = observer(
  class BookingsTable extends Component {
    filter

    constructor(props) {
      super(props)

      if (!this.props.location) {
        return
      }

      switch (this.props.location.pathname) {
        case '/bookings/awaiting-review':
          this.filter = 'awaiting-close'
          break
        default:
          this.filter = this.props.location.pathname.replace('/bookings/', '')
      }
    }

    get title() {
      let filter

      if (this.filter) {
        filter = this.filter.replace('/internal', '')
      }

      switch (filter) {
        case 'awaiting-close':
          return 'Awaiting Close Out'
        case 'pending-p2p':
          return 'Pending P2P Response'
        case 'upcoming-first':
          return 'Upcoming 1st Timers'
        default:
          return titleize(filter || 'All')
      }
    }

    driverFormatter = (cell, row) => {
      if (cell) {
        const url = `/driver/${cell.id}`
        return (
          <NavLink strict={+true} to={url}>
            {cell.name}
          </NavLink>
        )
      }
    }

    clientFormatter = (cell, row) => {
      const url = `/client/${row.userId}`

      return (
        <div>
          <NavLink strict={+true} to={url}>
            {cell}
          </NavLink>

          {row.dryver ? (
            <>
              <div className="d-flex mt-2">
                <img
                  src={dryverLogo}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '5px',
                  }}
                />
                Dryver {row?.client?.currentSubscription ? ' Plus' : ''}
              </div>
            </>
          ) : (
            <div className="d-flex mt-2">
              <img
                src={jeevzLogo}
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '5px',
                }}
              />
              Jeevz
            </div>
          )}
        </div>
      )
    }

    idFormatter = (cell, _row) => (
      <>
        <NavLink strict={+true} to={`/booking/${cell}`} key={cell}>
          {cell.split('-')[0]}
        </NavLink>
        <ClipboardLink text={cell} />
      </>
    )

    createdAtFormatter = (cell, row) => {
      if (cell) {
        return <RelativeTime value={cell} />
      }
    }

    startTimeFormatter = (cell, row) => {
      const time = new Date(cell)

      return (
        <div>
          {strftime('%B %d, %Y', time)}
          <br />
          {strftime('%l:%M%P', time)}
          <br />
          <span style={{ fontSize: 10 }}>{strftime('(%Z)', time)}</span>
        </div>
      )
    }

    statusFormatter = (cell, row) => {
      switch (cell) {
        case 'requested':
        case 'dispatching':
          return (
            <Badge color="warning" className="mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        case 'confirmed':
          return (
            <Badge className="badge-soft-success mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        case 'completed':
          if (row.closedOutAt) {
            return <Badge className="badge-soft-success mr-1">Completed</Badge>
          }
          return (
            <Badge color="warning" className="mr-1">
              AWAITING CLOSE
              <br />
              (Completed)
            </Badge>
          )
        case 'driver_returning':
          return (
            <Badge className="badge-soft-info mr-1">
              Driver Returning to Origin
            </Badge>
          )
        case 'driver_en_route':
        case 'driver_arrived':
        case 'in_progress':
          return (
            <Badge className="badge-soft-info mr-1">{cell.toUpperCase()}</Badge>
          )
        case 'cancelled':
          if (row.closedOutAt) {
            return <Badge className="badge-soft-danger mr-1">Cancelled</Badge>
          }
          return (
            <Badge color="warning" className="mr-1">
              AWAITING CLOSE
              <br />
              (Cancelled)
            </Badge>
          )
        case 'failed':
          return (
            <Badge className="badge-soft-danger mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        default:
          return (
            <Badge color="danger" className="mr-1">
              unknown
            </Badge>
          )
      }
    }

    typeFormatter = (cell, row) => {
      if (cell) {
        return titleize(cell.replaceAll('_', ' '))
      } else {
        return row.roundTrip ? 'Round Trip' : 'One Way'
      }
    }

    dataColumns = [
      {
        dataField: 'id',
        name: 'ID',
        priority: 1,
        formatter: this.idFormatter,
      },
      {
        dataField: 'clientName',
        name: 'Client',
        priority: 1,
        formatter: this.clientFormatter,
        sort: true,
      },
      {
        dataField: 'driver',
        name: 'Driver',
        priority: 1,
        formatter: this.driverFormatter,
        sort: true,
      },
      {
        dataField: 'bookingType',
        name: 'Type',
        priority: 1,
        formatter: this.typeFormatter,
        sort: true,
      },
      {
        dataField: 'city',
        name: 'City',
        priority: 1,
        sort: true,
      },
      {
        dataField: 'status',
        name: 'Status',
        priority: 1,
        sort: true,
        formatter: this.statusFormatter,
      },
      {
        dataField: 'requestedStartTime',
        name: 'Requested Start Time',
        priority: 1,
        formatter: this.startTimeFormatter,
        sort: true,
      },
      {
        dataField: 'closedOutAt',
        name: 'Closed At',
        priority: 1,
        formatter: this.createdAtFormatter,
        sort: true,
      },
      {
        dataField: 'estimatedDurationMinutes',
        name: 'Trip Length Est (minutes)',
        priority: 1,
        sort: true,
      },
      {
        dataField: 'createdAt',
        name: 'Booked At',
        priority: 1,
        formatter: this.createdAtFormatter,
        sort: true,
      },
    ]

    render() {
      return (
        <MyTable
          model={Trip}
          title={this.props.title || this.title}
          dataUrl={this.props.dataUrl || '/bookings'}
          breadcrumbBaseTitle={this.props.baseTitle || 'Bookings'}
          dataColumns={this.dataColumns}
          embedded={this.props.embedded}
          dateFilterColumn={'requestedStartTime'}
          dateFilterLabel={'Start Time'}
          defaultLimit={this.props.defaultLimit}
          searchable
          filter={this.filter}
        />
      )
    }
  },
)

decorate(BookingsTable, {
  title: computed,
  filter: observable,
})
