import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { PromoCodesTable } from '../../components/Tables/PromoCodesTable';

export const PromoCodesIndex = observer(class PromoCodesIndex extends Component {
  filter

  constructor(props) {
    super(props);

    switch (this.props.location.pathname) {
      case '/promo_codes':
        this.filter = null;
        break;
      default:
        this.filter = this.props.location.pathname.replace('/promo_codes/', '');
    }
  }

  render() {
    return (
      <PromoCodesTable
        filter={this.filter}
        dataUrl="/promo_codes"
        baseTitle="Promo Codes"
        title={this.filter || 'All'}
        addEnabled
      />
    );
  }
});

decorate(PromoCodesIndex, {
  filter: observable,
});
