// @ts-nocheck
import { action, computed, observable } from "mobx"

export class ShortUrl {
    /**
     * unique id of this ShortUrl, immutable.
     */
    id = null

    @observable ownerId
    @observable ownerType
    @observable url
    @observable uniqueKey
    @observable category
    @observable useCount
    @observable expiresAt
    @observable createdAt
    @observable updatedAt

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:         this.id,
        ownerId:    this.ownerId,
        ownerType:  this.ownerType,
        url:        this.url,
        uniqueKey:  this.uniqueKey,
        category:   this.category,
        useCount:   this.useCount,
        expiresAt:  this.expiresAt,
        createdAt:  this.createdAt,
        updatedAt:  this.updatedAt,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.ownerId    = data.attributes.ownerId
      this.ownerType  = data.attributes.ownerType
      this.url        = data.attributes.url
      this.uniqueKey  = data.attributes.uniqueKey
      this.category   = data.attributes.category
      this.useCount   = data.attributes.useCount
      this.expiresAt  = data.attributes.expiresAt
      this.createdAt  = data.attributes.createdAt
      this.updatedAt  = data.attributes.updatedAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
