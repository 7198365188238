import { AvField, AvForm } from 'availity-reactstrap-validation';
import { action, computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { client } from "../../helpers/client"
import { values } from 'lodash';

export const ManualChargeModal = observer(class ManualChargeModal extends Component {
  get _billingsTotal() {
    let total = 0
    for (const billing of this.props.billings) {
      total += billing.amount.cents
    }
    return (total / 100).toFixed(2)
  }

  get _billingClient() {
    return this.props.billings[0].client.name
  }

  _submitHandler = (_e, values) => {
    const data = {
      billing_ids: this.props.billings.map(billing => billing.id),
      stripe_charge_id: values.stripeId,
    }

    client.makeApiCall(
      'post', `/billings/mark_charged`, data,
      'Billings successfully marked as charged',
      'Unable to update billings',
      () => window.location.reload()
    )
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        { this.props.billings && <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this._submitHandler} model={this.props.billings}>
            <Row>
              <Col>
                <div>Marking ${this._billingsTotal} worth of Billings for {this._billingClient} as Paid</div>
                <hr/>

                <FormGroup>
                  <Label htmlFor="stripeId">Stripe ID for Successful Charge</Label>
                  <AvField
                    name="stripeId"
                    placeholder="ID of Successful Stripe Charge"
                    type="text"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="stripeId"
                  />
                </FormGroup>

              </Col>
            </Row>

            <div className="d-flex align-items-center justify-content-between">
              <Button color="primary" type="submit" className="mr-4">Mark as Charged</Button>
            </div>
          </AvForm>
        </div> }
      </Modal>
    );
  }
});

decorate(ManualChargeModal, {
  _billingsTotal: computed,
  _billingClient: computed,
});
