import { AvField, AvForm } from 'availity-reactstrap-validation';
import { decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row
} from 'reactstrap';
import { User } from '../../helpers/CurrentUser'

export const RatingModal = observer(class RatingModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.rating}>
            <Row>
              <Col>
                <FormGroup>
                  { !User.canEditRatings && "You are not able to edit this rating" }<br/><br/>
                  <Label htmlFor="value">Value</Label>
                  <AvField
                    name="value"
                    placeholder="Rating (1-5)"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    validate={{
                      required: { value: true },
                      min: { value: 1 },
                      max: { value: 5 },
                    }}
                    id="value"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              color="primary"
              type="submit"
              disabled={!User.canEditRatings}
            >Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(RatingModal, {
});
