// @ts-nocheck
import { action, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { Payout } from "../models/Payout"

export class payoutsStore {
  @observable payouts = new Map()

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.payouts.clear()
      for (const payoutData of response.data) {
        this.payouts.set(payoutData.id, new Payout(payoutData))
      }
      return this.payouts
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/payouts/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const payout = new Payout(response.data)
      this.payouts.set(response.data?.id, payout)
      return payout
    }
  }
}
