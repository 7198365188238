import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation';
import { action, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, CustomInput, FormGroup, Label, Modal, Row,
} from 'reactstrap';
import { client } from '../../helpers/client';

export const SubscriptionModal = observer(class SubscriptionModal extends Component {
  products = []

  componentDidMount = async () => {
    this.fetchProducts()
  }

  fetchProducts = async () => {
    let result;

    try {
      result = await client.get('/products?enabled=true');
      for (const product of result.data) {
        this.products.push(product);
      }

      this.loaded = true;
    } catch {
      this.errorVisible = true;
      this.errorMessage = 'Unable to fetch products';
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.subscription}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="productId">Product</Label>
                  <AvField
                    type="select"
                    name="productId"
                    id="productId"
                    validate={{ required: { value: true } }}
                  >
                    <option value={null}>Select</option>
                    {
                      this.products.map((product) => {
                        return (<option key={ product.id } value={ product.id }>{ product.attributes.displayName }</option>)
                      })
                    }
                  </AvField>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="billingRate">Membership Billing Rate</Label>
                  <AvField
                    name="billingRate"
                    placeholder="Billing Rate $"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="billingRate"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="billingFrequency">Billing Frequency</Label>
                  <AvField
                    type="select"
                    name="billingFrequency"
                    id="billingFrequency"
                    validate={{ required: { value: true } }}
                  >
                    <option value={null}>Select</option>
                    <option>monthly</option>
                    <option>annually</option>
                  </AvField>
                </FormGroup>

                <FormGroup>
                  <AvInput
                    type="checkbox"
                    label="One Way Trips?"
                    id="oneWayEnabled"
                    name="oneWayEnabled"
                    tag={CustomInput}
                  />
                </FormGroup>

                <FormGroup>
                  <AvInput
                    type="checkbox"
                    label="Preferred Drivers?"
                    id="preferredDriversEnabled"
                    name="preferredDriversEnabled"
                    tag={CustomInput}
                  />
                </FormGroup>

                <FormGroup>
                  <AvInput
                    type="checkbox"
                    label="Lock Box?"
                    id="lockBoxEnabled"
                    name="lockBoxEnabled"
                    tag={CustomInput}
                  />
                </FormGroup>

                <FormGroup>
                  <AvInput
                    type="checkbox"
                    label="Suspended?"
                    id="suspended"
                    name="suspended"
                    tag={CustomInput}
                  />
                </FormGroup>

                { !this.props.subscription && <FormGroup>
                  <AvInput
                    type="checkbox"
                    label="Charge now? (if not, user will get trial after first booking)"
                    id="chargeNow"
                    name="chargeNow"
                    tag={CustomInput}
                  />
                </FormGroup> }
              </Col>
            </Row>
            <Button color="primary" type="submit">Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(SubscriptionModal, {
  products: observable,
  fetchProducts: action,
});
