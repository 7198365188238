import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { titleize } from '../../helpers/titleize';
import { BillingsTable } from '../../components/Tables/BillingsTable';

export const BillingsIndex = observer(class BillingsIndex extends Component {
  filter

  constructor(props) {
    super(props);

    switch (this.props.location.pathname) {
      case '/billings':
        this.filter = null;
        break;
      default:
        this.filter = this.props.location.pathname.replace('/billings/', '');
    }
  }

  get title() {
    if (this.filter === 'pending') {
      return 'Unpaid';
    }
    return titleize(this.filter || 'All');
  }

  render() {
    return (
      <BillingsTable
        filter={this.filter}
        dataUrl="/billings"
        baseTitle="Client Billings"
        title={this.title}
      />
    );
  }
});

decorate(BillingsIndex, {
  filter: observable,
  title: computed,
});
