import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { MyTable } from '../../components/MyTable/MyTable';
import { TextMessage } from '../../models/TextMessage';
export const TextMessagesTable = observer(class TextMessagesTable extends Component {
  filter

  twilioFormatter = (_cell, row) => {
    if (row) return (<>{ row.id }</>);
  }

  userFormatter = (cell, _row) => {
    return <>
      <NavLink strict={+true} to={`/${cell.type.toLowerCase()}/${cell.id}`}>{cell.name} ({cell.type})</NavLink>
    </>
  }

  createdAtFormatter = (time) => {
    return DateTime.fromISO(time).setLocale('en').setZone("America/New_York").toFormat('f')
  }

  dataColumns = [
    {
      dataField: 'sender',
      name: 'Sent By',
      formatter: this.userFormatter,
      priority: 1,
    },
    {
      dataField: 'recipient',
      name: 'Set To',
      formatter: this.userFormatter,
      priority: 1,
    },
    {
      dataField: 'body',
      name: 'Body',
      priority: 1,
    },
    {
      dataField: 'createdAt',
      name: 'Created',
      formatter: this.createdAtFormatter,
      sort: true,
      priority: 1,
    },
  ]

  render() {
    return (
      <MyTable
        model={TextMessage}
        dataUrl={this.props.dataUrl}
        breadcrumbBaseTitle={"Text Messages"}
        dataColumns={this.dataColumns}
        dateFilterColumn={'createdAt'}
        dateFilterLabel={'Created At'}
        embedded={this.props.embedded}
      />
    );
  }
});
