import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation'

import { computed, decorate, action } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Button, Col, FormGroup, Label, Modal, Row } from 'reactstrap'

const REGIONS = [
  {
    label: 'All regions',
    value: 'all',
  },
  {
    label: 'Broward',
    value: 'Broward',
  },
  {
    label: 'Central Florida',
    value: 'Central Florida',
  },
  {
    label: 'Miami',
    value: 'Miami',
  },
  {
    label: 'Nassau',
    value: 'Nassau',
  },
  {
    label: 'New York',
    value: 'New York',
  },
  {
    label: 'Tampa/St. Pete',
    value: 'Tampa/St. Pete',
  },
  {
    label: 'Westchester',
    value: 'Westchester',
  },
  {
    label: 'West Palm Beach',
    value: 'West Palm Beach',
  },
]
export const CouponModal = observer(
  class CouponModal extends Component {
    _selectDiscountType = (discountType) => {
      this.props.coupon.discountType = discountType
      this.discountType = discountType
    }

    getDiscountNumericValue = () => {
      let value = 0
      if (this.props.coupon) {
        value =
          this.props.coupon?.valuePercent > 0
            ? parseInt(this.props.coupon?.valuePercent)
            : parseFloat(this.props.coupon?.value?.cents / 100)
      }
      return value
    }

    render() {
      return (
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {this.props.title}
            </h5>

            <button
              type="button"
              onClick={this.props.toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.props.submitHandler}
              model={this.props.coupon}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="coupon">Code</Label>
                    <AvField
                      value={this.props.coupon?.name}
                      name="code"
                      placeholder="code"
                      type="text"
                      className="form-control"
                      id="code"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="valuePercent">Coupon Discount</Label>
                    <AvGroup>
                      <AvField
                        type="select"
                        name="discountType"
                        id="discountType"
                        value={
                          this.props.coupon?.valuePercent > 0
                            ? 'valuePercentage'
                            : 'value'
                        }
                      >
                        <option
                          key={'valuePercentage'}
                          value={'valuePercentage'}
                        >
                          Percentage
                        </option>
                        <option key={'value'} value={'value'}>
                          Dollars
                        </option>
                      </AvField>
                      <AvField
                        name="discountValue"
                        value={this.getDiscountNumericValue()}
                        placeholder="Discount"
                        type="number"
                        className="form-control"
                        id="discountValue"
                      />
                    </AvGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="tripsEnabled">Select valid trip</Label>
                    <AvGroup>
                      <div className="ml-5">
                        <AvInput
                          type="checkbox"
                          name="enabledForRoundTrip"
                          checked={this.props.coupon?.enabledForRoundTrip}
                        />
                        Round Trip
                        <br />
                        <AvInput
                          type="checkbox"
                          name="enabledForOneWay"
                          checked={this.props.coupon?.enabledForOneWay}
                        />
                        One Way
                        <br />
                        <AvInput
                          type="checkbox"
                          name="enabledForAirport"
                          checked={this.props.coupon?.enabledForAirport}
                        />{' '}
                        Airport
                        <br />
                        <AvInput
                          type="checkbox"
                          name="enabledForOvernighter"
                          checked={this.props.coupon?.enabledForOvernighter}
                        />
                        Overnighter
                        <br />
                      </div>
                    </AvGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="valuePercent">Regions enabled</Label>
                    <AvGroup>
                      <AvField
                        type="select"
                        multiple
                        name="regions"
                        id="regions"
                        value={this.props.coupon?.enabledRegions || ['all']}
                      >
                        <option key={'all'} value={'all'}>
                          -- All
                        </option>
                        <option key={'Broward'} value={'Broward'}>
                          Broward
                        </option>
                        <option
                          key={'Central_Florida'}
                          value={'Central_Florida'}
                        >
                          Central Florida
                        </option>
                        <option key={'Miami'} value={'Miami'}>
                          Miami
                        </option>
                        <option key={'Nassau'} value={'Nassau'}>
                          Nassau
                        </option>
                        <option key={'NY'} value={'NY'}>
                          New Yourk
                        </option>
                        <option key={'Tampa'} value={'Tampa'}>
                          Tampa/St. Pete
                        </option>
                        <option key={'Westchester'} value={'Westchester'}>
                          Westchester
                        </option>
                        <option
                          key={'West_Palm_Beach'}
                          value={'West_Palm_Beach'}
                        >
                          West Palm Beach
                        </option>
                      </AvField>
                    </AvGroup>
                  </FormGroup>
                  <FormGroup>
                    <div className="control-group">
                      <Label>Enable coupon</Label>
                      <AvGroup>
                        <div className="ml-5">
                          <AvInput
                            type="checkbox"
                            name="enabled"
                            checked={this.props.coupon?.enabled}
                          />
                          Enabled?
                          <br />
                        </div>
                      </AvGroup>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" type="submit">
                Submit form
              </Button>
            </AvForm>
          </div>
        </Modal>
      )
    }
  },
)

decorate(CouponModal, {
  editable: computed,
  _selectDiscountType: action,
})
