import { AvField, AvForm } from 'availity-reactstrap-validation';
import { decorate, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';
import { client } from '../../helpers/client';

export const SubaccountModal = observer(class SubaccountModal extends Component {
  submitHandler = async (e, values) => {
    const data = {
      subaccount: {
        parent_id: this.props.clientId,
        child_id: values.childId,
        relationship: values.relationship,
      },
    }

    await client.makeApiCall(
      'post', `/subaccounts`, data,
      'Subaccount created',
      'Unable to add subaccount',
      () => {
        runInAction(() => this.addModalOpen = false)
        window.location.reload()
      }
    )
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.submitHandler}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="childId">Subaccount Client ID</Label>
                  <AvField
                    name="childId"
                    className="form-control"
                    validate={{ required: true }}
                    placeholder={"FULL Client ID"}
                    id="childId"
                    type="text"
                  />
                </FormGroup>

                <FormGroup>
                  <AvField
                    type="select"
                    name="relationship"
                    label="Relationship"
                    validate={{ required: { value: true } }}
                    id="relationship"
                    className="form-control"
                  >
                    <option key="child" value="child">Child</option>
                    <option key="spouse" value="spouse">Spouse</option>
                    <option key="partner" value="partner">Partner</option>
                    <option key="parent" value="parent">Parent</option>
                    <option key="grandparent" value="grandparent">Grandparent</option>
                    <option key="sibling" value="sibling">Sibling</option>
                    <option key="other" value="other">Other</option>
                  </AvField>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Save</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(SubaccountModal, {
});
