// @ts-nocheck
import { action, computed, observable } from "mobx"

export class User {
    /**
     * unique id of this User, immutable.
     */
    id = null

    @observable type
    @observable name
    @observable avatarUrl
    @observable email
    @observable phoneNumber
    @observable city
    @observable zipCode
    @observable status
    @observable gender
    @observable createdAt



    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:           this.id,
        type:         this.type,
        name:         this.name,
        avatarUrl:    this.avatarUrl,
        email:        this.email,
        phoneNumber:  this.phoneNumber,
        city:         this.city,
        zipCode:      this.zipCode,
        status:       this.status,
        gender:       this.gender,
        createdAt:    this.createdAt,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.type         = data.attributes.type
      this.name         = data.attributes.name
      this.avatarUrl    = data.attributes.avatarUrl
      this.email        = data.attributes.email
      this.phoneNumber  = data.attributes.phoneNumber
      this.city         = data.attributes.city
      this.zipCode      = data.attributes.zipCode
      this.status       = data.attributes.status
      this.gender       = data.attributes.gender
      this.createdAt    = data.attributes.createdAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
