// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Utm {
    /**
     * unique id of this Utm, immutable.
     */
    id = null

    @observable campaign
    @observable source
    @observable medium
    @observable term
    @observable content
    @observable other
    @observable createdAt

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:         this.id,
        campaign:   this.campaign,
        source:     this.source,
        medium:     this.medium,
        term:       this.term,
        content:    this.content,
        other:      this.other,
        createdAt:  this.createdAt,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.campaign   = data.attributes.campaign
      this.source     = data.attributes.source
      this.medium     = data.attributes.medium
      this.term       = data.attributes.term
      this.content    = data.attributes.content
      this.other      = data.attributes.other
      this.createdAt  = data.attributes.createdAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
