import { action, computed, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import RelativeTime from 'react-relative-time'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import carIcon from '../../assets/images/carMarker.png'
import userIcon from '../../assets/images/clientMarker.png'
import BookingTimeline from '../../components/Common/BookingTimeline'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Chat } from '../../components/Common/Chat'
import PersonalInfoCard from '../../components/Common/PersonalInfoCard'
import { RequestCard } from '../../components/Common/RequestCard'
import { ReactGoogleMap } from '../../components/ReactGoogleMap/ReactGoogleMap'
import { BookingStopsTable } from '../../components/Tables/BookingStopsTable'
import { AdminNotesTable } from '../../components/Tables/AdminNotesTable'
import { AuditsTable } from '../../components/Tables/AuditsTable'
import { BillingsTable } from '../../components/Tables/BillingsTable'
import { EarningsTable } from '../../components/Tables/EarningsTable'
import { ExpensesTable } from '../../components/Tables/ExpensesTable'
import { RatingsTable } from '../../components/Tables/RatingsTable'
import { TextMessagesTable } from '../../components/Tables/TextMessagesTable'
import { TipsTable } from '../../components/Tables/TipsTable'
import { client } from '../../helpers/client'
import { Client } from '../../models/Client'
import { Driver } from '../../models/Driver'
import { Trip } from '../../models/Trip'
import { DriversTable } from '../Drivers/DriversTable'
import { DispatchingTable } from './DispatchingTable'
import { NewChat } from '../../components/Common/NewChat'

export const BookingShow = observer(
  class BookingShow extends Component {
    booking

    client

    driver

    errorVisible = false

    addresses = []

    loaded = false

    tripId

    showBookingModal = false

    showTipModal = false

    booking

    bookingModalOpen = false
    _showjumpDropdown = false

    driverLocation

    clientLocation

    eta

    interval

    constructor(props) {
      super(props)

      this.tripId = this.props.location.pathname.replace('/booking/', '')

      this.dispatchOffersUrl = `/bookings/${this.tripId}/dispatch_offers`
      this.adminNotesUrl = `/bookings/${this.tripId}/admin_notes`
      this.expensesUrl = `/bookings/${this.tripId}/expenses`
      this.incidentsUrl = `/bookings/${this.tripId}/incidents`
      this.textMessagesUrl = `/bookings/${this.tripId}/text_messages`
      this.phoneCallsUrl = `/bookings/${this.tripId}/phone_calls`
      this.tripStopsUrl = `/bookings/${this.tripId}/booking_stops`
      this.potentialDriversUrl = `/bookings/${this.tripId}/potential_drivers`
      this.chargesUrl = `/bookings/${this.tripId}/charges`
      this.billingsUrl = `/bookings/${this.tripId}/billings`
      this.earningsUrl = `/bookings/${this.tripId}/earnings`
      this.ratingsUrl = `/bookings/${this.tripId}/ratings`
      this.tipsUrl = `/bookings/${this.tripId}/tips`

      this.fetchData()
    }

    componentDidMount() {
      this.interval = setInterval(this.updateLocations, 15000)
    }

    componentWillUnmount() {
      this.interval = null
    }

    updateLocations = async () => {
      if (this.booking) {
        await this.booking.fetchLocations()
        this.calculateEta()
        this.driverLocation = this.booking.newDriverLocation
        this.clientLocation = this.booking.newClientLocation
      }
    }

    fetchData = async () => {
      let result

      try {
        result = await client.get(`/bookings/${this.tripId}`)
        this.booking = new Trip(result.data)
        result = await client.get(`/clients/${this.booking.userId}`)
        this.client = new Client(result.data)

        if (this.booking.driver?.id) {
          result = await client.get(`/drivers/${this.booking.driver?.id}`)
          this.driver = new Driver(result.data)
        }

        this.updateLocations()
        this.calculateEta()

        this.loaded = true
      } catch {
        this.errorVisible = true
        this.errorMessage = 'Unable to fetch trip data. Please reload page.'
      }
    }

    onDismiss = () => {
      this.errorVisible = false
    }

    credits = () => {
      if (this.booking.estimatedCreditsToApply) {
        return this.booking.estimatedCreditsToApply.pretty
      }
      return 'None'
    }

    lastSeen = (t, type) => {
      const time = new Date(t)
      let str = ReactDOMServer.renderToString(<RelativeTime value={time} />)
      str = RegExp('>.*<').exec(str)[0].replace(/>|</g, '')
      return `${type} - Last Seen - ${str}`
    }

    get mapLocations() {
      if (!this.loaded) {
        return []
      }

      let count = 0

      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

      let locations = []

      if (this.booking.pickupAddress?.id) {
        const lat = parseFloat(this.booking.pickupAddress.latitude)
        const lng = parseFloat(this.booking.pickupAddress.longitude)

        locations = locations.concat({
          lat,
          lng,
          label: alphabet[count],
          draggable: false,
          title: 'Start',
        })

        count++
      }

      for (const address of this.booking.tripStops) {
        if (
          !address.attributes ||
          !address.attributes?.latitude ||
          !address.attributes?.longitude
        ) {
          break
        }

        const lat = parseFloat(address.attributes.latitude)
        const lng = parseFloat(address.attributes.longitude)

        locations = locations.concat({
          lat,
          lng,
          label: alphabet[count],
          draggable: false,
          title: `Stop ${count}`,
        })

        count++
      }

      if (this.booking.dropoffAddress?.id) {
        const lat = parseFloat(this.booking.dropoffAddress.latitude)
        const lng = parseFloat(this.booking.dropoffAddress.longitude)

        locations = locations.concat({
          lat,
          lng,
          label: alphabet[count],
          draggable: false,
          title: 'End',
        })
      }

      if (this.clientLocation) {
        const lat = parseFloat(this.clientLocation.latitude)
        const lng = parseFloat(this.clientLocation.longitude)

        locations = locations.concat({
          lat,
          lng,
          title: this.lastSeen(this.clientLocation.updatedAt, 'Client'),
          draggable: false,
          icon: userIcon,
        })
      }

      if (this.driverLocation) {
        const lat = parseFloat(this.driverLocation.latitude)
        const lng = parseFloat(this.driverLocation.longitude)

        locations = locations.concat({
          lat,
          lng,
          title: this.lastSeen(this.driverLocation.updatedAt, 'Driver'),
          draggable: false,
          icon: carIcon,
        })
      }

      return locations
    }

    get mapCenter() {
      const lat = this.booking?.pickupAddress?.id
        ? parseFloat(this.booking?.pickupAddress?.latitude)
        : 0
      const lng = this.booking?.pickupAddress?.id
        ? parseFloat(this.booking?.pickupAddress?.longitude)
        : 0

      return {
        lat,
        lng,
      }
    }

    toggleBookingModal = () => {
      this.showBookingModal = !this.showBookingModal
    }

    toggleTipModal = () => {
      this.showTipModal = !this.showTipModal
    }

    calculateEta = async () => {
      const driverLocation = this.booking.newDriverLocation
      let destination

      if (!driverLocation?.latitude) return

      switch (this.booking.status) {
        case 'driver_en_route':
          destination = this.booking.pickupAddress
          break
        default:
          destination = this.booking.newClientLocation
      }

      if (!destination?.latitude) return

      const waypoints = []
      waypoints.push(
        `waypoint0=geo!${driverLocation.latitude},${driverLocation.longitude}`,
      )
      waypoints.push(
        `waypoint1=geo!${destination.latitude},${destination.longitude}`,
      )

      await fetch(
        `https://route.ls.hereapi.com/routing/7.2/calculateroute.json?apiKey=mOcoMFOFb57hdVeFw2eZcujmHHXHVLNEjTmifH4GYG4&${waypoints.join(
          '&',
        )}&mode=car;balanced;traffic:enabled&routeAttributes=sm&representation=overview`,
        {
          method: 'GET',
        },
      )
        .then((response) => response.json())
        .then((responseJSON) => {
          const travelTimeSeconds =
            responseJSON.response.route[0].summary.travelTime
          const travelTimeMinutes = Math.ceil(travelTimeSeconds / 60)

          runInAction(() => {
            this.eta = `- (Driver ~${travelTimeMinutes} min away)`
          })
        })
    }

    render() {
      return (
        <>
          <div className="page-content">
            <Container fluid>
              <div className="mb-4">
                <ButtonDropdown
                  isOpen={this._showjumpDropdown}
                  toggle={() =>
                    runInAction(() => {
                      this._showjumpDropdown = !this._showjumpDropdown
                    })
                  }
                >
                  <DropdownToggle
                    caret
                    color="secondary"
                    className="btn btn-secondary btn-sm"
                  >
                    Jump To &nbsp; <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-left">
                    {[
                      ['map', 'Map'],
                      ['stops', 'Booking Stops'],
                      ['timeline', 'BookingTimeline'],
                      ['chat', 'Chat'],
                      ['dispatch', 'Dispatch'],
                      ['ratings', 'Ratings'],
                      ['notes', 'Notes'],
                      ['expenses', 'Expenses'],
                      ['tips', 'Tips'],
                      ['billings', 'Billings'],
                      ['earnings', 'Earnings'],
                      ['texts', 'Texts'],
                      ['audits', 'Audits'],
                    ].map((item) => (
                      <DropdownItem
                        key={item[0]}
                        onClick={() =>
                          document.getElementById(item[0]).scrollIntoView()
                        }
                      >
                        {item[1]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              {/* Render Breadcrumb */}
              <Breadcrumbs title="Admin" breadcrumbItem="Booking" />
              <Row>
                <Col xl="4">
                  {this.booking && this.client && (
                    <RequestCard booking={this.booking} client={this.client} />
                  )}
                </Col>
                <Col xl="4">
                  {this.client && <PersonalInfoCard user={this.client} />}
                </Col>
                <Col xl="4">
                  {this.driver && <PersonalInfoCard user={this.driver} />}
                  {!this.driver && (
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Awaiting Driver Assignment
                        </CardTitle>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>

              <Row>
                <Col lg="12" id="map">
                  <ReactGoogleMap
                    mapLocations={this.mapLocations}
                    center={this.mapCenter}
                    eta={this.eta}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="12" id="stops">
                  {this.booking && (
                    <BookingStopsTable
                      dataUrl={`/bookings/${this.booking.id}/booking_stops`}
                      baseTitle="Waypoints"
                      addEnabled
                      type={'waypoint'}
                      removable={this.booking.editable}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="4" id="timeline">
                  {this.booking && <BookingTimeline booking={this.booking} />}
                </Col>

                <Col xl="8" id="chat">
                  {this.booking && <NewChat booking={this.booking} />}
                </Col>
              </Row>

              {this.booking && (
                <>
                  <Row>
                    <Col xs="12" id="dispatch">
                      <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-20">Dispatching</h4>
                      </div>
                    </Col>
                  </Row>

                  {this.booking &&
                    !this.booking.driver?.id &&
                    this.booking.status === 'requested' && (
                      <DriversTable
                        baseTitle="Potential Drivers"
                        subheading="Green highlight indicates favorite drivers"
                        embedded
                        dataUrl={`/bookings/${this.booking.id}/potential_drivers`}
                        dispatching
                        booking={this.booking}
                      />
                    )}
                  <DispatchingTable
                    booking={this.booking}
                    embedded
                    dataUrl={`/bookings/${this.booking.id}/dispatch_offers`}
                  />
                </>
              )}

              <Row>
                <Col xs="12" id="ratings">
                  {this.booking && (
                    <RatingsTable
                      dataUrl={`/bookings/${this.booking.id}/ratings`}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-20">Admin</h4>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" id="notes">
                  {this.booking && (
                    <AdminNotesTable
                      dataUrl={`/bookings/${this.booking.id}/admin_notes`}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-20">Financial</h4>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" id="expenses">
                  {this.booking && (
                    <ExpensesTable
                      dataUrl={`/bookings/${this.booking.id}/expenses`}
                      embedded={true}
                      addEnabled={true}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12" id="tips">
                  {this.booking && (
                    <TipsTable
                      dataUrl={`/bookings/${this.booking.id}/tips`}
                      embedded={true}
                      addEnabled={this.booking?.tippable}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12" id="billings">
                  {this.booking && (
                    <BillingsTable
                      dataUrl={`/bookings/${this.booking.id}/billings`}
                      booking={this.booking}
                      embedded={true}
                      addEnabled={this.booking?.closeable}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12" id="earnings">
                  {this.booking && (
                    <EarningsTable
                      dataUrl={`/bookings/${this.booking.id}/earnings`}
                      booking={this.booking}
                      embedded={true}
                      addEnabled={
                        this.booking?.driver && this.booking?.closeable
                      }
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-20">Communication</h4>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" id="texts">
                  {this.booking && (
                    <TextMessagesTable
                      dataUrl={`/bookings/${this.booking.id}/text_messages`}
                      booking={this.booking}
                      embedded={true}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-20">Misc</h4>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" id="audits">
                  {this.booking && (
                    <AuditsTable
                      dataUrl={`/bookings/${this.booking.id}/audits`}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )
    }
  },
)

decorate(BookingShow, {
  filter: observable,
  errorVisible: observable,
  addresses: observable,
  loaded: observable,
  tripId: observable,
  showTipModal: observable,
  onDismiss: action,
  fetchData: action,
  mapLocations: computed,
  mapCenter: computed,
  toggleBookingModal: action,
  toggleTipModal: action,
  booking: observable,
  client: observable,
  driver: observable,
  eta: observable,
  calculateEta: action,
  driverLocation: observable,
  clientLocation: observable,
  _showjumpDropdown: observable,
})
