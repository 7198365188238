import { decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { formatters } from '../../helpers/Formatters';
import { Payout } from '../../models/Payout';

export const PayoutsTable = observer(class PayoutsTable extends Component {
  filter

  idFormatter = (cell, _row) => (
    <>
      <NavLink strict={+true} to={`/payout/${cell}`} key={cell}>{cell.split('-')[0]}</NavLink>
      <ClipboardLink text={cell} />
    </>
  )

  driverFormatter = (cell, row) => {
    if (!cell) { return; }

    const url = `/driver/${cell.id}`;

    return (
      <NavLink strict={+true} to={url}>{cell.name}</NavLink>
    );
  }

  stripeLink = (cell, row) => {
    if (!cell) { return; }

    const url = `https://dashboard.stripe.com/transfers/${cell}`;

    return (
      <a href={url} rel="noopener noreferrer" target="_blank">View in Stripe</a>
    );
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'amount.pretty',
      name: 'Amount',
      priority: 1,
      sort: true,
      sortKey: 'amount_cents',
    },
    {
      dataField: 'createdAt',
      name: 'Created',
      priority: 1,
      formatter: formatters.fullTimeFormatter,
      sort: true,
    },
    {
      dataField: 'stripeId',
      name: 'Stripe Link',
      priority: 1,
      formatter: this.stripeLink,
    },
    {
      dataField: 'booking',
      name: 'Trip',
      priority: 1,
      formatter: this.tripFormatter,
    },
    {
      dataField: 'driver',
      name: 'Driver',
      priority: 1,
      formatter: this.driverFormatter,
    },
  ]

  render() {
    return (
      <MyTable
        model={Payout}
        dataUrl={this.props.dataUrl}
        breadcrumbBaseTitle={this.props.baseTitle || 'Payouts'}
        dataColumns={this.dataColumns}
        embedded
        dateFilterColumn={'createdAt'}
        dateFilterLabel={'Created At'}
        defaultLimit={this.props.defaultLimit}
      />
    );
  }
});

decorate(PayoutsTable, {
});
