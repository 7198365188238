// @ts-nocheck
import { action, computed, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { earningsStore } from "../stores/earningsStore"
import { offersStore } from "../stores/offersStore"
import { payoutsStore } from "../stores/payoutsStore"
import { tripsStore } from "../stores/tripsStore"
import { bankAccountsStore } from "../stores/bankAccountsStore"
import qs from "qs"

export class Driver {
    /**
     * unique id of this Driver, immutable.
     */
    id = null

    @observable email
    @observable name
    @observable gender
    @observable stripeId
    @observable jwt
    @observable status
    @observable phoneNumber
    @observable inviteCode
    @observable city
    @observable preferences
    @observable createdAt
    @observable updatedAt
    @observable avatarUrl
    @observable hourlyPayRate
    @observable zipCode
    @observable tripsCount
    @observable totalEarnings
    @observable memberFor
    @observable lastTripDate
    @observable lastTripTime
    @observable deviceData
    @observable rating
    @observable paylocityId
    @observable acceptedTosAt
    @observable rank

    @observable monthlyRideAverage
    @observable rideBreakdown
    @observable offerBreakdown
    @observable avgResponseTime
    @observable qualificationTier
    @observable distance
    @observable isFavorite

    offersStore
    tripsStore
    earningsStore
    payoutsStore
    bankAccountsStore

    type = 'driver'

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data: object, jwt?: string) {
      this.id   = data?.id
      this.jwt  = jwt

      this.offersStore   = new offersStore()
      this.tripsStore    = new tripsStore()
      this.earningsStore = new earningsStore()
      this.payoutsStore  = new payoutsStore()
      this.bankAccountsStore = new bankAccountsStore()

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:             this.id,
        email:          this.email,
        name:           this.name,
        gender:         this.gender,
        stripeId:       this.stripeId,
        status:         this.status,
        phoneNumber:    this.phoneNumber,
        inviteCode:     this.inviteCode,
        city:           this.city,
        preferences:    this.preferences,
        createdAt:      this.createdAt,
        updatedAt:      this.updatedAt,
        avatarUrl:      this.avatarUrl,
        hourlyPayRate:  this.hourlyPayRate,
        zipCode:        this.zipCode,
        tripsCount:     this.tripsCount,
        totalEarnings:  this.totalEarnings,
        memberFor:      this.memberFor,
        lastTripDate:   this.lastTripDate,
        lastTripTime:   this.lastTripTime,
        deviceData:     this.deviceData,
        rating:         this.rating,
        paylocityId:    this.paylocityId,
        acceptedTosAt:  this.acceptedTosAt,
        rank:           this.rank,
        monthlyRideAverage: this.monthlyRideAverage,
        rideBreakdown: this.rideBreakdown,
        offerBreakdown: this.offerBreakdown,
        avgResponseTime: this.avgResponseTime,
        qualificationTier: this.qualificationTier,
        distance: this.distance,
        isFavorite: this.isFavorite,
      }
    }

    @action fetchUpcomingTrips() {
      return this.tripsStore.fetchAll(`drivers/${this.id}/bookings?filter=upcoming`)
    }

    @action fetchPastTrips() {
      return this.tripsStore.fetchAll(`drivers/${this.id}/bookings?filter=past`)
    }

    @computed get upcomingTrips() {
      return Array.from(this.tripsStore.trips.values()).filter((trip) => {
        return trip.upcoming
      })
    }

    @computed get pastTrips() {
      return Array.from(this.tripsStore.trips.values()).filter((trip) => {
        return ['completed', 'cancelled', 'failed'].includes(trip.status)
      })
    }

    @computed get currentTrips() {
      return Array.from(this.tripsStore.trips.values()).filter((trip) => {
        return ['driver_en_route', 'driver_arrived', 'in_progress'].includes(trip.status)
      })
    }

    @action fetchTrips() {
      return this.cardsStore.fetchAll(`drivers/${this.id}/bookings`)
    }

    @computed get trips() {
      return Array.from(this.tripsStore.trips.values())
    }

    @action fetchOffers() {
      return this.offersStore.fetchAll(`drivers/${this.id}/dispatch_offers?status=pending`)
    }

    @computed get offers() {
      return Array.from(this.offersStore.offers.values())
    }

    @action fetchUnpaidEarnings() {
      return this.earningsStore.fetchAll(`drivers/${this.id}/earnings?filter=pending`)
    }

    @action fetchPaidPayouts() {
      return this.payoutsStore.fetchAll(`drivers/${this.id}/stripe_transfers`)
    }

    @computed get unpaidEarnings() {
      return Array.from(this.earningsStore.earnings.values()).filter((earning) => {
        return earning
      })
    }

    @computed get paidPayouts() {
      return Array.from(this.payoutsStore.payouts.values()).filter((payout) => {
        return payout
      })
    }

    @computed get hourlyRate() {
      return this.hourlyPayRate.cents / 100
    }

    @computed get talkingType() {
      return this.preferences.data.attributes.talkingType
    }
    @computed get drivingType() {
      return this.preferences.data.attributes.drivingType
    }
    @computed get largeVehicles() {
      return this.preferences.data.attributes.largeVehicles
    }
    @computed get smokes() {
      return this.preferences.data.attributes.smokes
    }
    @computed get wearsCologne() {
      return this.preferences.data.attributes.wearsCologne
    }
    @computed get drivesManual() {
      return this.preferences.data.attributes.drivesManual
    }

    @action submitBankingInfo = async (formData) => {

    }

    @action submitInfoToStripe = async (formData) => {
      let nameTokens = this.name.split(" ")

      const params = {
        type: "custom",
        email: this.email,
        business_type: "individual",
        individual: {
          email: this.email,
          first_name: nameTokens.shift(),
          last_name: nameTokens.join(" "),
          phone: this.phoneNumber,
          ssn_last_4: formData.ssnLast4,
          dob: {
            day: formData.dobDay,
            month: formData.dobMonth,
            year: formData.dobYear,
          },
          address: {
            line1: formData.address,
            city: formData.city,
            state: formData.state,
            postal_code: formData.postalCode,
            country: "US",
          }
        },
        business_profile: {
          product_description: 'This user is a driver for the Jeevz platform',
          mcc: 4789,
        },
        tos_acceptance: {
          date: Math.floor(Date.now() / 1000),
          ip: formData.ipAddress,
        },
        external_account: {
          object: "bank_account",
          country: "US",
          currency: "usd",
          routing_number: formData.routingNumber,
          account_number: formData.accountNumber,
        },
        requested_capabilities: ["transfers"],
      }

      const response = await fetch('https://api.stripe.com/v1/accounts', {
        method: 'POST',
        headers: {
          'Content-Type':   'application/x-www-form-urlencoded',
          'Authorization':  `Bearer ${process.env.REACT_APP_STRIPE_KEY}`,
        },
        body: qs.stringify(params),
      }),
      payload = await response.json()

      // TODO: handle failed verification
      if (payload.id) {
        await this.update({
          driver: {
            stripe_id: payload.id,
          }
        })

        const account = payload.external_accounts.data[0]

        const apiResp = await this.bankAccountsStore.create(`users/${this.id}/bank_accounts`, {
          bank_account: {
            account_holder_name: account.account_holder_name,
            account_holder_type: account.account_holder_type,
            bank_name: account.bank_name,
            country: account.country,
            currency: account.currency,
            fingerprint: account.fingerprint,
            last4: account.last4,
            routing_number: account.routing_number,
            status: account.status,
            stripe_id: account.id,
            stripe_account_id: account.account,
          }
        })

        if (!apiResp.id) {
          return 'error'
        }
      }
      else {
        return 'error'
      }

      return payload
    }

    async uploadImage(uri) {
      let uriParts = uri.split('.')
      let fileType = uriParts[uriParts.length - 1]

      let formData = new FormData()

      formData.append('avatar', {
        uri,
        name: `photo.${fileType}`,
        type: `image/${fileType}`,
      })

      await apiCall(`${process.env.REACT_APP_API_URL}/drivers/${this.id}`, {
        method: 'PUT',
        body: formData,
        contentType: 'multipart/form-data'
      })
    }

    @action update = async(data: object) => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/drivers/${this.id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
      })

      if (response.data && response.data?.type === 'errors') {
        // console.log('errors');
      } else {
        this.updateFromJson(response.data)
      }

      return this
    }

    @action updateBankAccount = async (data: object) => {
      const params = {}

      params["bank_account"] = {
        account_holder_name: data.accountName,
        account_type: data.accountType,
        bank_name: data.bankName,
        account_number: data.accountNumber,
        routing_number: data.routingNumber,
      }

      if (data.address) {
        params["bank_account"]["address"] = {
          line1: data.address,
          line2: data.line2,
          city: data.city,
          postal_code: data.postalCode,
          state: data.state,
        }
      }

      if (data.ssn) {
        params["bank_account"]["personal_info"] = {
          ssn: data.ssn,
          dob_day: data.dobDay,
          dob_month: data.dobMonth,
          dob_year: data.dobYear,
        }
      }

      return await this.bankAccountsStore.create(`users/${this.id}/bank_accounts`, params)
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      if (!data.attributes) {
        return
      }

      this.name           = data.attributes.name
      this.email          = data.attributes.email
      this.gender         = data.attributes.gender
      this.stripeId       = data.attributes.stripeId
      this.status         = data.attributes.status
      this.phoneNumber    = data.attributes.phoneNumber
      this.inviteCode     = data.attributes.inviteCode
      this.city           = data.attributes.city
      this.preferences    = data.attributes.preferences
      this.createdAt      = data.attributes.createdAt
      this.updatedAt      = data.attributes.updatedAt
      this.avatarUrl      = data.attributes.avatarUrl
      this.hourlyPayRate  = data.attributes.hourlyPayRate
      this.zipCode        = data.attributes.zipCode
      this.tripsCount     = data.attributes.tripsCount
      this.totalEarnings  = data.attributes.totalEarnings
      this.memberFor      = data.attributes.memberFor
      this.lastTripDate   = data.attributes.lastTripDate
      this.lastTripTime   = data.attributes.lastTripTime
      this.deviceData     = data.attributes.deviceData
      this.rating         = data.attributes.rating
      this.paylocityId    = data.attributes.paylocityId
      this.acceptedTosAt  = data.attributes.acceptedTosAt
      this.rank           = data.attributes.rank
      this.monthlyRideAverage = data.attributes.monthlyRideAverage
      this.rideBreakdown = data.attributes.rideBreakdown
      this.offerBreakdown = data.attributes.offerBreakdown
      this.avgResponseTime = data.attributes.avgResponseTime
      this.qualificationTier = data.attributes.qualificationTier
      this.distance = data.attributes.distance
      this.isFavorite = data.attributes.isFavorite

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
