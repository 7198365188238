import React, { Component } from 'react';
import { myToast } from '../Common/MyToast';

export default class ClipboardLink extends Component {
  render() {
    return <i className={`bx bx-clipboard ml-2 clipboard`} title="Copy to clipboard" onClick={
      () => {
        navigator.clipboard.writeText(this.props.text)
        myToast.showToast('success', 'Copied to clipboard', 'Success')
      }
    } />
  }
}
