import { AvForm } from 'availity-reactstrap-validation';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';

export const PauseSubscriptionModal = observer(class CancelSubscriptionModal extends Component {
  render() {
    return(
      <Modal
        isOpen={ this.props.isOpen }
        toggle={ this.props.toggle }
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.header }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          Are you sure you want to pause this subscription?<br/><br/>

          <AvForm className="needs-validation" onValidSubmit={this.props.confirm}>

            <div className="modal-footer">
              <button
                type="button"
                onClick={ this.props.toggle }
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                { this.props.closeButtonText || 'Cancel' }
              </button>

              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                { this.props.confirmButtonText || 'Submit' }
              </button>
            </div>
          </AvForm>
        </div>


      </Modal>
    )
  }
})

