import { action, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import RelativeTime from 'react-relative-time'
import { ZipCode } from '../../models/ZipCode'
import ClipboardLink from '../Common/ClipboardLink'
import { MyTable } from '../MyTable/MyTable'
import { ZipCodeModal } from '../Modals/ZipCodeModal'
import { client } from '../../helpers/client'
import dryverLogo from '../../assets/images/dryver-icon.png'
import jeevzLogo from '../../assets/images/jeevz-icon.png'

export const ZipCodesTable = observer(
  class ZipCodesTable extends Component {
    _addModalOpen = false

    componentDidMount() {
      this._tableRef = React.createRef()
    }

    idFormatter = (cell, row) => (
      <>
        <a
          href="#"
          key={row.id}
          onClick={() => {
            runInAction(() => {
              this._selectedZipCode = row
              this._addModalOpen = true
            })
          }}
        >
          {cell.split('-')[0]}
        </a>
        <ClipboardLink text={cell} />
      </>
    )

    ratesFormatter = (cell, row) => {
      return (
        <div>
          <div className="mb-2">
            <img
              src={jeevzLogo}
              style={{
                width: '20px',
                height: '20px',
                marginRight: '5px',
              }}
            />
            {row.hourlyRate.pretty}
          </div>
          <div>
            <img
              src={dryverLogo}
              style={{
                width: '20px',
                height: '20px',
                marginRight: '5px',
              }}
            />
            {row.dryverHourlyRate.pretty}
          </div>
        </div>
      )
    }

    cityFormatter = (cell, row) => {
      return (
        <div>
          <div>
            {row.cityName}, {row.stateAbbr}
          </div>
          {row.regionName && <div>({row.regionName})</div>}
        </div>
      )
    }

    createdAtFormatter = (cell, row) => {
      if (cell) {
        return <RelativeTime value={cell} />
      }
    }

    dataColumns = [
      {
        dataField: 'id',
        name: 'ID',
        priority: 1,
        formatter: this.idFormatter,
        sort: true,
      },
      {
        dataField: 'code',
        name: 'Zip Code',
        priority: 1,
        sort: true,
      },
      {
        dataField: '',
        name: 'City',
        priority: 1,
        sort: true,
        formatter: this.cityFormatter,
      },
      {
        dataField: 'status',
        name: 'Status',
        priority: 1,
        sort: true,
      },
      {
        dataField: 'driverCount',
        name: 'Drivers',
        priority: 1,
        sort: false,
      },
      {
        dataField: 'clientCount',
        name: 'Clients',
        priority: 1,
        sort: false,
      },
      {
        dataField: 'addressCount',
        name: 'Addresses',
        priority: 1,
        sort: false,
      },
      {
        dataField: 'rideCount',
        name: 'Rides',
        priority: 1,
        sort: false,
      },
      {
        dataField: '',
        name: 'Rates',
        sort: false,
        formatter: this.ratesFormatter,
      },
      {
        dataField: 'createdAt',
        name: 'Added At',
        priority: 1,
        formatter: this.createdAtFormatter,
        sort: true,
      },
    ]

    modalSubmitHandler = async (e, values) => {
      const data = {
        code: values.code,
        status: values.status,
        city_name: values.cityName,
        region_name: values.regionName,
        state_abbr: values.stateAbbr,
      }

      if (this._selectedZipCode) {
        await client.makeApiCall(
          'put',
          `/zip_codes/${this._selectedZipCode.id}`,
          { zip_code: data },
          'ZipCode updated successfully',
          'Unable to update zip_code',
          (response) => {
            this._selectedZipCode.updateFromJson(response.data)
            this._closeModals()
          },
        )
      } else {
        await client.makeApiCall(
          'post',
          '/zip_codes',
          { zip_code: data },
          'ZipCode created successfully',
          'Unable to create zip_code',
          this._postSubmitCallback,
        )
      }
    }

    _postSubmitCallback = () => {
      this._tableRef.current.fetchData()
      this._closeModals()
    }

    _closeModals = () => {
      this._addModalOpen = false
    }

    _openModal = () => {
      this._addModalOpen = true
    }

    render() {
      return (
        <>
          <ZipCodeModal
            zipCode={this._selectedZipCode}
            isOpen={this._addModalOpen}
            toggle={() => {
              runInAction(() => {
                this._addModalOpen = !this._addModalOpen
                this._selectedZipCode = null
              })
            }}
            title={`${this._selectedZipCode ? 'Edit' : 'Add New'} Zip`}
            submitHandler={this.modalSubmitHandler}
          />

          <MyTable
            model={ZipCode}
            dataUrl={'/zip_codes'}
            dataColumns={this.dataColumns}
            ref={this._tableRef}
            singleActions={
              this.props.addEnabled && {
                'Add +': () => {
                  runInAction(() => {
                    this._addModalOpen = !this._addModalOpen
                  })
                },
              }
            }
            breadcrumbTitle={'All'}
            breadcrumbBaseTitle={'Zip Codes'}
            dateFilterColumn={'createdAt'}
            dateFilterLabel={'Created At'}
            embedded={this.props.embedded}
            searchable
            defaultLimit={this.props.defaultLimit}
          />
        </>
      )
    }
  },
)

decorate(ZipCodesTable, {
  _addModalOpen: observable,
  _closeModals: action,
  _openModal: action,
  _selectedZipCode: observable,
})
