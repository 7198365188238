import React from 'react';
import {
  Row, Col, CardBody, Card, Container,
} from 'reactstrap';

import { Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/J.png';

const ForgetPasswordPage = (props) => (
  <>
    <div className="home-btn d-none d-sm-block">
      <Link to="/" className="text-dark"><i className="fas fa-home h2" /></Link>
    </div>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p>Sign in to continue to Jeevz.</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to="/">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} alt="avatar" height="34" />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">

                  <AvForm
                    className="form-horizontal mt-4"
                  >
                    <div className="form-group">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        required
                      />
                    </div>
                    <Row className="form-group">
                      <Col className="text-right">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Reset
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Go back to
                {' '}
                <Link
                  to="pages-login"
                  className="font-weight-medium text-primary"
                >
                  Login
                </Link>
                {' '}
              </p>
              <p>
                ©
                {new Date().getFullYear()}
                {' '}
                Jeevz.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default ForgetPasswordPage;
