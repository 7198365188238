// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Payout {
    /**
     * unique id of this Payout, immutable.
     */
    id = null

    @observable label
    @observable stripeId
    @observable stripeBalanceTransactionId
    @observable stripeDestinationPaymentId
    @observable stripeDestinationId
    @observable stripeSourceTransactionId
    @observable sourceType
    @observable description
    @observable arrivalDate
    @observable createdAt
    @observable updatedAt
    @observable amount
    @observable paid = true

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:                         this.id,
        label:                      this.label,
        stripeId:                   this.stripeId,
        stripeBalanceTransactionId: this.stripeBalanceTransactionId,
        stripeDestinationPaymentId: this.stripeDestinationPaymentId,
        stripeDestinationId:        this.stripeDestinationId,
        stripeSourceTransactionId:  this.stripeSourceTransactionId,
        sourceType:                 this.sourceType,
        description:                this.description,
        arrivalDate:                this.arrivalDate,
        createdAt:                  this.createdAt,
        updatedAt:                  this.updatedAt,
        amount:                     this.amount,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.id                         = data.id
      this.label                      = data.attributes.label
      this.stripeId                   = data.attributes.stripeId
      this.stripeBalanceTransactionId = data.attributes.stripeBalanceTransactionId
      this.stripeDestinationPaymentId = data.attributes.stripeDestinationPaymentId
      this.stripeDestinationId        = data.attributes.stripeDestinationId
      this.stripeSourceTransactionId  = data.attributes.stripeSourceTransactionId
      this.sourceType                 = data.attributes.sourceType
      this.description                = data.attributes.description
      this.arrivalDate                = data.attributes.arrivalDate
      this.createdAt                  = data.attributes.createdAt
      this.updatedAt                  = data.attributes.updatedAt
      this.arrivalDate                = data.attributes.arrivalDate
      this.amount                     = data.attributes.amount

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
