import { observer } from 'mobx-react'
import { action, computed, decorate, observable, runInAction } from 'mobx'
import React, { Component } from 'react'
import { Table, Tbody, Th, Thead, Tr, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Row,
  Col,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Spinner,
} from 'reactstrap'
import Breadcrumbs from '../Common/Breadcrumb'
import { client } from '../../helpers/client'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import debounce from 'lodash.debounce'
import ReactPaginate from 'react-paginate'
import humps from 'humps'

export const MyTable = observer(
  class MyTable extends Component {
    dateRange
    showActionDropdown
    dataRows = []
    loading = false
    searchText
    page = 1
    defaultPerPage = 25
    perPage = this.defaultPerPage
    showPerPageDropdown = false
    allSelected = false
    selectedItems = {}
    selectedRows = []

    componentDidMount() {
      if (this.props.defaultLimit) {
        this.perPage = this.props.defaultLimit
      }

      if (!this.props.skipInitLoad) {
        this.fetchData()
      }

      this._debouncedSearch = debounce(function () {
        this.perPage = this.props.defaultLimit || this.defaultPerPage

        this.fetchData()
      }, 1000)
    }

    fetchData = async (params) => {
      this.loading = true
      this.dataRows.clear()

      if (!params) {
        params = {}
      }

      this.params = params

      if (this.props.filter && !params.filter) {
        params.filter = this.props.filter
      }
      if (this.sortColumn && !params.sort_column) {
        params.sort_column = humps.decamelize(
          this.sortColumn.replace('attributes.', ''),
        )
      }
      if (this.sortOrder && !params.sort_dir) {
        params.sort_dir = this.sortOrder
      }
      if (this.perPage && !params.limit) {
        params.limit = this.perPage
      }
      if (this.page && !params.page) {
        params.page = this.page
      }
      if (this.searchText && !params.query) {
        params.query = this.searchText
      }
      if (this.dateRange) {
        params.range_filter_column = humps.decamelize(
          this.props.dateFilterColumn,
        )

        params.range_filter_start = this.dateRange[0].toDateString()
        params.range_filter_end = this.dateRange[1].toDateString()
      }
      // TODO: if not ok. need to check why is not working fine with coupons the get
      // if (this.props.dataUrl !== 'coupons'){
      const response = await client.get(this.props.dataUrl, params)
      if (this.props.model) {
        for (const row of response.data.map(
          (item) => new this.props.model(item),
        )) {
          this.dataRows.push(row)
        }
      } else {
        for (const row of response.data) {
          this.dataRows.push(row)
        }
      }

      this.page = response.meta.page
      this.totalRecords = response.meta.total
      this.perPage = response.meta.perPage
      this.runCallbacks()
      // }
      this.loading = false
    }

    runCallbacks = async () => {
      if (this.props.reactions) {
        for (const reaction of this.props.reactions) {
          await reaction()
        }
      }
    }

    fetchField(item, dataField, formatter) {
      const tokens = dataField.split('.')
      let data = item
      for (const token of tokens) {
        data = data[token]
      }
      if (formatter) {
        data = formatter(data, item)
      }

      return data
    }

    onRangeChange = (newRange) => {
      this.dateRange = newRange

      this.fetchData()
    }

    get currentRowStart() {
      return (this.page - 1) * this.perPage + 1
    }

    get currentRowEnd() {
      if (this.totalRecords) {
        return Math.min(this.currentRowStart + this.perPage, this.totalRecords)
      } else {
        return '?'
      }
    }

    get totalRows() {
      return this.totalRecords || 0
    }

    get totalPages() {
      if (this.totalRows && this.perPage) {
        return Math.ceil(this.totalRows / this.perPage)
      } else {
        return false
      }
    }

    handlePagination = (event) => {
      this.page = event.selected + 1
      this.fetchData()
    }

    _changePerPage = (count) => {
      this.perPage = count
      this.togglePerPageDropdown()
      this.fetchData()
    }

    togglePerPageDropdown = () =>
      (this.showPerPageDropdown = !this.showPerPageDropdown)

    get limitDropdown() {
      return (
        <span
          className="react-bs-table-sizePerPage-dropdown dropdown"
          style={{ visibility: 'visible' }}
        >
          <button
            id="pageDropDown"
            type="button"
            className="btn btn-default btn-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
            onClick={this.togglePerPageDropdown}
          >
            {this.perPage}{' '}
            <span>
              <span className="caret"></span>
            </span>
          </button>
          <ul
            className={`dropdown-menu ${this.showPerPageDropdown && 'show'}`}
            role="menu"
            aria-labelledby="pageDropDown"
          >
            <li
              role="presentation"
              className="dropdown-item"
              onClick={() => this._changePerPage(10)}
            >
              <button
                className="border-0 bg-white"
                tabIndex="-1"
                role="menuitem"
                data-page="10"
              >
                10
              </button>
            </li>
            <li
              role="presentation"
              className="dropdown-item"
              onClick={() => this._changePerPage(25)}
            >
              <button
                className="border-0 bg-white"
                tabIndex="-1"
                role="menuitem"
                data-page="25"
              >
                25
              </button>
            </li>
            <li
              role="presentation"
              className="dropdown-item"
              onClick={() => this._changePerPage(30)}
            >
              <button
                className="border-0 bg-white"
                tabIndex="-1"
                role="menuitem"
                data-page="30"
              >
                30
              </button>
            </li>
            <li
              role="presentation"
              className="dropdown-item"
              onClick={() => this._changePerPage(50)}
            >
              <button
                className="border-0 bg-white"
                tabIndex="-1"
                role="menuitem"
                data-page="50"
              >
                50
              </button>
            </li>
            <li
              role="presentation"
              className="dropdown-item"
              onClick={() => this._changePerPage(100)}
            >
              <button
                className="border-0 bg-white"
                tabIndex="-1"
                role="menuitem"
                data-page="100"
              >
                100
              </button>
            </li>
            <li
              role="presentation"
              className="dropdown-item"
              onClick={() => this._changePerPage(250)}
            >
              <button
                className="border-0 bg-white"
                tabIndex="-1"
                role="menuitem"
                data-page="250"
              >
                250
              </button>
            </li>
            <li
              role="presentation"
              className="dropdown-item"
              onClick={() => this._changePerPage(500)}
            >
              <button
                className="border-0 bg-white"
                tabIndex="-1"
                role="menuitem"
                data-page="500"
              >
                500
              </button>
            </li>
          </ul>
        </span>
      )
    }

    searchChange = (e) => {
      this.searchText = e.target.value
      this._debouncedSearch()
    }

    sortButtons(column) {
      if (
        this.sortColumn &&
        this.sortColumn === (column.sortKey || column.dataField)
      ) {
        if (this.sortOrder === 'asc') {
          return (
            <span
              className="react-bootstrap-table-sort-order dropup"
              onClick={() =>
                this.sortBy(column.sortKey || column.dataField, 'desc')
              }
            >
              <span className="caret"></span>
            </span>
          )
        } else {
          return (
            <span
              className="react-bootstrap-table-sort-order"
              onClick={() =>
                this.sortBy(column.sortKey || column.dataField, 'asc')
              }
            >
              <span className="caret"></span>
            </span>
          )
        }
      } else {
        return (
          <span className="order">
            <span
              className="dropdown"
              onClick={() =>
                this.sortBy(column.sortKey || column.dataField, 'desc')
              }
            >
              <span className="caret"></span>
            </span>
            <span
              className="dropup"
              onClick={() =>
                this.sortBy(column.sortKey || column.dataField, 'asc')
              }
            >
              <span className="caret"></span>
            </span>
          </span>
        )
      }
    }

    sortBy = (dataField, direction) => {
      this.sortColumn = dataField
      this.sortOrder = direction
      this.fetchData()
    }

    _selectAll = () => {
      this.allSelected = !this.allSelected

      for (const item of this.dataRows) {
        this.selectedItems[item.id] = this.allSelected
        if (!this.selectedRows.find((row) => row.id === item.id)) {
          this.selectedRows.push(item)
        }
      }
    }

    toggleSingleRow = (item) => {
      this.selectedItems[item.id] = !this.selectedItems[item.id]

      if (this.selectedItems[item.id]) {
        this.selectedRows.push(item)
      } else {
        const existingRow = this.selectedRows.find((row) => row.id === item.id)
        if (existingRow) {
          this.selectedRows.remove(existingRow)
        }
      }
    }

    // get selectedRows() {
    //   // TODO: this is inefficient as hell
    //   const rows = []
    //   for (const key in this.selectedItems) {
    //     if (this.selectedItems[key]) {
    //       rows.push(this.dataRows.find(row => row.id === key))
    //     }
    //   }
    //   return rows
    // }

    render() {
      const {
        title,
        subheading,
        breadcrumbBaseTitle,
        breadcrumbTitle,
        dateFilterColumn,
        dateFilterLabel,
        multiActions,
        singleActions,
        searchable,
      } = this.props

      return (
        <>
          <div className={`${!this.props.embedded && 'page-content'}`}>
            <div className={`${!this.props.embedded && 'container-fluid'}`}>
              {!this.props.embedded && (
                <Breadcrumbs
                  title={breadcrumbBaseTitle}
                  breadcrumbItem={breadcrumbTitle}
                />
              )}

              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <Col
                        sm="12"
                        className="d-flex justify-content-between pl-0 pr-0"
                      >
                        <div>
                          <CardTitle>
                            {title || breadcrumbBaseTitle}{' '}
                            {this.searchText && `(query: ${this.searchText})`}
                          </CardTitle>

                          {subheading && (
                            <CardSubtitle className="mb-3">
                              {subheading}
                            </CardSubtitle>
                          )}
                        </div>

                        <div className="mb-4">
                          {singleActions && (
                            <span>
                              {Object.keys(singleActions).map((key) => (
                                <button
                                  key={`button-${key}`}
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light mr-2"
                                  onClick={() =>
                                    singleActions[key](
                                      this.selectedRows,
                                      this.dataRows,
                                    )
                                  }
                                >
                                  {key}
                                </button>
                              ))}
                            </span>
                          )}

                          {multiActions && (
                            <span>
                              <ButtonDropdown
                                isOpen={this.showActionDropdown}
                                toggle={() =>
                                  runInAction(() => {
                                    this.showActionDropdown =
                                      !this.showActionDropdown
                                  })
                                }
                              >
                                <DropdownToggle
                                  caret
                                  color="secondary"
                                  className="btn btn-secondary btn-md"
                                >
                                  Actions &nbsp;{' '}
                                  <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-right">
                                  {Object.keys(multiActions).map(
                                    (key, index) => (
                                      <DropdownItem
                                        key={key}
                                        onClick={async () => {
                                          await multiActions[key](
                                            this.selectedRows,
                                          )
                                        }}
                                      >
                                        {key}
                                      </DropdownItem>
                                    ),
                                  )}
                                </DropdownMenu>
                              </ButtonDropdown>
                            </span>
                          )}
                        </div>
                      </Col>

                      <Col
                        sm="12"
                        className="d-flex justify-content-between pl-0 pr-0"
                      >
                        <div className="search-box mr-2 mb-2 d-inline-block">
                          {searchable && (
                            <div className="position-relative">
                              <label className="search-label">
                                <span
                                  id="search-bar-0-label"
                                  className="sr-only"
                                >
                                  Search this table
                                </span>
                                <Input
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  value={this.searchText}
                                  onChange={this.searchChange}
                                />
                              </label>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          )}
                        </div>

                        {dateFilterColumn && (
                          <div
                            className="search-box mb-2 d-inline-block"
                            title={`Filter by: ${
                              dateFilterLabel || dateFilterColumn
                            }`}
                          >
                            <div className="position-relative">
                              <label className="search-label">
                                <DateRangePicker
                                  onChange={this.onRangeChange}
                                  value={this.dateRange}
                                  className="form-control"
                                />
                              </label>
                            </div>
                          </div>
                        )}
                      </Col>

                      <div className="table-rep-plugin react-bootstrap-table">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                {multiActions && (
                                  <Th data-priority={'1'}>
                                    <input
                                      type="checkbox"
                                      onChange={this._selectAll}
                                      checked={this.allSelected ? true : false}
                                    />
                                  </Th>
                                )}
                                {this.props.dataColumns.map((column) => (
                                  <Th
                                    key={`column-${column.name}`}
                                    data-priority={column.priority || '1'}
                                  >
                                    {column.name}{' '}
                                    {column.sort && this.sortButtons(column)}
                                  </Th>
                                ))}
                              </Tr>
                            </Thead>
                            <Tbody>
                              {!this.loading &&
                                this.dataRows.map((item) => (
                                  <Tr
                                    key={`tr-${item.id}`}
                                    className={`${
                                      this.props.highlightRow &&
                                      this.props.highlightRow(item)
                                        ? 'row-highlighted'
                                        : ''
                                    } ${
                                      this.selectedItems[item.id] && 'selected'
                                    }`}
                                  >
                                    {multiActions && (
                                      <Td>
                                        <input
                                          type="checkbox"
                                          onChange={() =>
                                            this.toggleSingleRow(item)
                                          }
                                          checked={
                                            this.selectedItems[item.id]
                                              ? true
                                              : false
                                          }
                                        />
                                      </Td>
                                    )}
                                    {this.props.dataColumns.map((column) => (
                                      <Td key={`columnField-${item.id}`}>
                                        {this.fetchField(
                                          item,
                                          column.dataField,
                                          column.formatter,
                                        )}
                                      </Td>
                                    ))}
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>

                          {this.loading && (
                            <div className="text-center">
                              <Spinner className="mr-2" color="primary" />
                            </div>
                          )}
                          {!this.loading && this.dataRows.length === 0 && (
                            <div className="text-center">No Data</div>
                          )}
                        </div>
                      </div>

                      <Col className="pr-0 pl-0">
                        <div className="d-flex justify-content-between">
                          <span>
                            {this.limitDropdown}
                            <span className="react-bootstrap-table-pagination-total">
                              &nbsp;&nbsp;&nbsp;Showing rows{' '}
                              {this.currentRowStart} to&nbsp;
                              {this.currentRowEnd} of&nbsp;{this.totalRows}
                            </span>
                          </span>
                          <span>
                            {this.totalPages && (
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.handlePagination}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                pageCount={this.totalPages}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                className={
                                  'pagination react-bootstrap-table-page-btns-ul'
                                }
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                activeClassName="active"
                                initialSelected={1}
                              />
                            )}
                          </span>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )
    }
  },
)

export default MyTable

decorate(MyTable, {
  dateRange: observable,
  showActionDropdown: observable,
  onRangeChange: action,
  fetchData: action,
  dataRows: observable,
  loading: observable,
  currentRowStart: computed,
  currentRowEnd: computed,
  totalRows: computed,
  limitDropdown: computed,
  searchText: observable,
  searchChange: action,
  page: observable,
  perPage: observable,
  _changePerPage: action,
  totalRecords: observable,
  totalPages: computed,
  showPerPageDropdown: observable,
  togglePerPageDropdown: action,
  handlePagination: action,
  sortBy: action,
  modifyRow: action,
  _selectAll: action,
  toggleSingleRow: action,
  allSelected: observable,
  selectedItems: observable,
  selectedRows: observable,
})
