import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { UsersTable } from '../../components/Tables/UsersTable'
import qs from "qs"
import {
  action, decorate, observable
} from 'mobx';


export const UsersSearch = observer(class UsersSearch extends Component {
  query

  componentWillMount() {
    this.loadQuery()
  }

  loadQuery = () => {
    this.query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).query
  }

  render() {
    return(
      <UsersTable
        title={'Search'}
        dataUrl={`/users?query=${this.query}`}
      />
    )
  }
});

decorate(UsersSearch, {
  query: observable,
  loadQuery: action,
});
