// @ts-nocheck
import { action, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { Trip } from "../models/Trip"

export class tripsStore {
  @observable trips = new Map()

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      return 'error'
    } else {
      this.trips.clear()
      for (const tripData of response.data) {
        this.trips.set(tripData.id, new Trip(tripData))
      }
      return this.trips
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/trips/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const trip = new Trip(response.data)
      this.trips.set(response.data?.id, trip)
      return trip
    }
  }

  @action create = async (userId: string, data: object) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/clients/${userId}/bookings`, {
      method: 'POST',
      body: JSON.stringify({
        booking: {
          address_id: data.addressId,
          card_id: data.cardId,
          vehicle_id: data.vehicleId,
          requested_start_time: data.selectedDate,
          round_trip: data.roundTrip,
          estimated_duration_minutes: data.estDuration,
          preferred_driver_id: data.preferredDriverId,
          dropoff_address_id: data.dropoffAddressId,
        },
      }),
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const trip = new Trip(response.data)
      this.trips.set(response.data?.id, trip)
      return trip
    }
  }

  @action update = async (id: string, data: object) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        booking: {
          address_id: data.addressId,
          card_id: data.cardId,
          vehicle_id: data.vehicleId,
          requested_start_time: data.selectedDate,
          round_trip: data.roundTrip,
          estimated_duration_minutes: data.estDuration,
          preferred_driver_id: data.preferredDriverId,
          dropoff_address_id: data.dropoffAddressId,
        },
      }),
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const trip = new Trip(response.data)
      this.trips.set(response.data?.id, trip)
      return trip
    }
  }

  @action cancel = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${id}`, {
      method: 'DELETE',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.trips.delete(id)
      return 'success'
    }
  }
}
