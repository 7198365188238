import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { formatters } from '../../helpers/Formatters';
import { Subscription } from '../../models/Subscription';

export const SubscriptionsTable = observer(class SubscriptionsTable extends Component {
  filter

  idFormatter = (cell, _row) => (
    <>
      <NavLink strict={true} to={`/subscription/${cell}`} key={cell}>{cell.split('-')[0]}</NavLink>
      <ClipboardLink text={cell} />
    </>
  )

  createdAtFormatter = (cell, row) => {
    if (cell)  {
      return formatters.createdAtFormatter(cell, row)
    } else if(!['trial', 'cancelled'].includes(row.status)  && !row.stripeId) {
      return 'legacy'
    }
  }

  billingFormatter = (cell, row) => {
    return `${row.billingRate.pretty} / ${row.billingFrequency}`
  }

  statusFormatter = (cell, _row) => {
    switch (cell) {
      case 'trial':
        return <Badge color="warning" className="mr-1">{ cell.toUpperCase() }</Badge>;
      case 'active':
        return <Badge className="badge-soft-success mr-1">{ cell.toUpperCase() }</Badge>;
      case 'cancelled':
        return <Badge className="badge-soft-info mr-1">{ cell.toUpperCase() }</Badge>;
      default:
        return <Badge color="danger" className="mr-1">unknown</Badge>;
    }
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'billingFrequency',
      name: 'Billing frequency',
      priority: 1,
      formatter: this.billingFormatter,
    },
    {
      dataField: 'status',
      name: 'Status',
      priority: 1,
      formatter: this.statusFormatter,
    },
    {
      dataField: 'activeAt',
      name: 'Active at',
      priority: 1,
      formatter: this.createdAtFormatter,
    },
    {
      dataField: 'firstChargeAt',
      name: 'First charge at',
      priority: 1,
      formatter: this.createdAtFormatter,
    },
    {
      dataField: 'subscriptionRenewAt',
      name: 'Subscription renew at',
      priority: 1,
      formatter: this.createdAtFormatter,
    },
    {
      dataField: 'cancelledAt',
      name: 'Cancelled at',
      priority: 1,
      formatter: formatters.createdAtFormatter,
    },
    {
      dataField: 'createdAt',
      name: 'Created at',
      priority: 1,
      formatter: formatters.createdAtFormatter,
    },
  ]

  render() {
    return (
      <MyTable
          model={Subscription}
          dataUrl={this.props.dataUrl}
          dataColumns={this.dataColumns}
          breadcrumbTitle={this.props.filter || 'All'}
          breadcrumbBaseTitle={this.props.baseTitle || 'Subscriptions'}

          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}

          embedded
          defaultLimit={this.props.defaultLimit}
          filter={this.props.filter}
        />
    );
  }
});
