import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { titleize } from '../../helpers/titleize';
import { EarningsTable } from '../../components/Tables/EarningsTable';

export const EarningsIndex = observer(class EarningsIndex extends Component {
  filter

  constructor(props) {
    super(props);

    switch (this.props.location.pathname) {
      case '/earnings':
        this.filter = null;
        break;
      default:
        this.filter = this.props.location.pathname.replace('/earnings/', '');
    }
  }

  get title() {
    if (this.filter === 'pending') {
      return 'Unpaid';
    }
    return titleize(this.filter || 'All');
  }

  render() {
    return (
      <EarningsTable
        filter={this.filter}
        dataUrl="/earnings"
        baseTitle="Driver Earnings"
        title={this.title}
      />
    );
  }
});

decorate(EarningsIndex, {
  filter: observable,
  title: computed,
});
