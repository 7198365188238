import { observer } from 'mobx-react';
import React, { Component } from 'react';
import strftime from 'strftime';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { Utm } from '../../models/Utm';
export const UtmsTable = observer(class UtmsTable extends Component {
  filter

  idFormatter = (cell, row) => (
    <>{ cell.split('-')[0] }<ClipboardLink text={cell} /></>
  )

  createdAtFormatter = (cell, row) => {
    const time = new Date(cell);

    return (
      <div>
        { strftime('%B %d, %Y', time) }
        <br />
        { strftime('%l:%M%P', time) }
        <br />
        { strftime('(%Z)', time) }
      </div>
    );
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'campaign',
      name: 'Campaign',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'source',
      name: 'Source',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'medium',
      name: 'Medium',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'term',
      name: 'Term',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'content',
      name: 'Content',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'other',
      name: 'Other',
      priority: 2,
    },
    {
      dataField: 'createdAt',
      name: 'Created',
      priority: 2,
      formatter: this.createdAtFormatter,
      sort: true,
    },
  ]

  render() {
    return (
      <MyTable
        model={Utm}
        dataUrl={this.props.dataUrl}
        dataColumns={this.dataColumns}
        breadcrumbTitle={this.props.filter || 'All'}
        breadcrumbBaseTitle={this.props.baseTitle || 'UTMs'}

        dateFilterColumn={'createdAt'}
        dateFilterLabel={'Created At'}

        embedded
        searchable
        defaultLimit={this.props.defaultLimit}
        filter={this.props.filter}
      />
    );
  }
});
