// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Tip {
    /**
     * unique id of this Tip, immutable.
     */
    id = null

    @observable amount
    @observable paid
    @observable createdAt
    @observable updatedAt
    @observable client

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:         this.id,
        amount:     this.amount,
        paid:       this.paid,
        createdAt:  this.createdAt,
        updatedAt:  this.updatedAt,
        client:     this.client,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.amount     = data.attributes.amount
      this.paid       = data.attributes.paid
      this.createdAt  = data.attributes.createdAt
      this.updatedAt  = data.attributes.updatedAt
      this.client     = data.attributes.client

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
