import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Button, Col, FormGroup, Label, Modal, Row } from 'reactstrap'

export const ClientModal = observer(
  class ClientModal extends Component {
    render() {
      return (
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {this.props.title}
            </h5>

            <button
              type="button"
              onClick={this.props.toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.props.submitHandler}
              model={this.props.client}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <AvField
                      name="name"
                      placeholder="Name"
                      type="text"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <AvField
                      name="email"
                      placeholder="Email"
                      type="email"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="email"
                    />
                  </FormGroup>
                  <AvGroup>
                    <AvField
                      type="select"
                      name="gender"
                      label="Gender"
                      id="gender"
                    >
                      <option>undefined</option>
                      <option>male</option>
                      <option>female</option>
                    </AvField>
                  </AvGroup>
                  <FormGroup>
                    <Label htmlFor="phoneNumber">Phone Number</Label>
                    <AvField
                      name="phoneNumber"
                      placeholder="Phone Number"
                      type="tel"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="phoneNumber"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="accountType">Account Type</Label>
                    <AvField type="select" name="accountType" id="accountType">
                      <option value="individual">Individual</option>
                      <option value="admin">Admin</option>
                      <option value="driver">Driver</option>
                      <option value="corporate">Corporate</option>
                      <option value="partnership">Partnership</option>
                      <option value="event_partnership">
                        Event Partnership
                      </option>
                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="zipCode">Zip Code</Label>
                    <AvField
                      name="zipCode"
                      placeholder="Zip Code"
                      type="text"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: { value: /^\d{5}(?:[-\s]\d{4})?$/ },
                      }}
                      id="zipCode"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="inviteCode">Invite Code (to share)</Label>
                    <AvField
                      name="inviteCode"
                      placeholder="Invite Code"
                      type="text"
                      className="form-control"
                      id="inviteCode"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="hourlyRateOverride">
                      Override Hourly Rate
                    </Label>
                    <AvField
                      name="hourlyRateOverride"
                      placeholder="Hourly Rate Override"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      validate={{
                        pattern: { value: '^[0-9]+(.[0-9]{1,2})?$' },
                      }}
                      id="hourlyRateOverride"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="billingRateOverride">
                      Override Monthly Subscription Rate
                    </Label>
                    <AvField
                      name="billingRateOverride"
                      placeholder="Monthly Subscription Rate Override"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      validate={{
                        pattern: { value: '^[0-9]+(.[0-9]{1,2})?$' },
                      }}
                      id="billingRateOverride"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="availableCreditsFormatted">
                      Available Free Credits
                    </Label>
                    <AvField
                      name="availableCreditsFormatted"
                      placeholder="0.00"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      validate={{
                        pattern: { value: '^[0-9]+(.[0-9]{1,2})?$' },
                      }}
                      id="availableCreditsFormatted"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="availableMinutes">
                      Available Free Minutes
                    </Label>
                    <AvField
                      name="availableMinutes"
                      placeholder="0.00"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      validate={{
                        pattern: { value: '^[0-9]+$' },
                      }}
                      id="availableMinutes"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="autoTipPercentage">
                      Auto Tip Percentage (0 will not auto tip)
                    </Label>
                    <AvField
                      name="autoTipPercentage"
                      placeholder="0"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      validate={{
                        max: { value: 100 },
                        min: { value: 0 },
                      }}
                      id="autoTipPercentage"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="talkingType">
                      Prefers Driver Talking Type
                    </Label>
                    <AvField
                      type="select"
                      name="driverTalkingType"
                      id="driverTalkingType"
                    >
                      <option>quiet</option>
                      <option>average</option>
                      <option>talkative</option>
                    </AvField>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="talkingType">
                      Prefers Driver Driving Type
                    </Label>
                    <AvField
                      type="select"
                      name="driverDrivingType"
                      id="driverDrivingType"
                    >
                      <option>quiet</option>
                      <option>average</option>
                      <option>talkative</option>
                    </AvField>
                  </FormGroup>
                  <FormGroup>
                    <AvGroup check>
                      <Label check>
                        <AvInput type="checkbox" name="internalAccount" />{' '}
                        Internal Account (only shows bookings under internal)
                      </Label>
                    </AvGroup>
                  </FormGroup>
                  <FormGroup>
                    <AvGroup check>
                      <Label check>
                        <AvInput type="checkbox" name="ambassador" /> Ambassador
                      </Label>
                    </AvGroup>
                  </FormGroup>
                  <FormGroup>
                    <div>Which app?</div>
                    <AvGroup check>
                      <Label check>
                        <AvInput type="checkbox" name="dryver" /> Dryver?
                        (uncheck for Jeevz)
                      </Label>
                    </AvGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="password">Password</Label>
                    <AvField
                      name="password"
                      placeholder="Password"
                      type="password"
                      className="form-control"
                      validate={{
                        required: {
                          value: !this.props.client,
                          errorMessage: 'Password is required for new users',
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            'Password must be at least 6 characters',
                        },
                      }}
                      id="password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="passwordConfirmation">
                      Password Confirmation
                    </Label>
                    <AvField
                      name="passwordConfirmation"
                      placeholder="Confirm Password"
                      type="password"
                      validate={{
                        required: {
                          value: !this.props.client,
                          errorMessage: 'Please enter password confirmation',
                        },
                        match: {
                          value: 'password',
                          errorMessage:
                            'Password confirmation must match Password',
                        },
                      }}
                      id="passwordConfirmation"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" type="submit">
                Submit form
              </Button>
            </AvForm>
          </div>
        </Modal>
      )
    }
  },
)

decorate(ClientModal, {})
