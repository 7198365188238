import humps from 'humps'

export function titleize(str: string): string {
  const result = humps
    .decamelize(str)
    .replaceAll(/([A-Z])/g, ' $1')
    .replaceAll(/-|_/g, ' ')

  return result.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  )
}
