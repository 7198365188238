class CurrentUser {
	get roles() {
		return JSON.parse(localStorage.getItem('authUser')).roles
	}

  get canViewStats() {
    return this.roles.includes("admin:general")
  }

  get canViewBookings() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:bookings:view")
  }

  get canViewDrivers() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:drivers:view")
  }

  get canViewClients() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:clients:view")
  }

  get canViewBillings() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:billings:view")
  }

  get canViewEarnings() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:earnings:view")
  }

  get canViewPromoCodes() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:promo_codes:view")
  }

  get canViewCoupons() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:coupons:view")
  }

  get canViewShortUrls() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:short_urls:view")
  }

  get canViewLeadImport() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:lead_import:view")
  }

  get canViewTexting() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:texting:view")
  }

  get canViewFlex() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:flex:view")
  }

  get canViewDispatch() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:dispatch:view")
  }

  get canViewFlexConfig() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:flex_config:view")
  }

  get canViewEvents() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:events:view")
  }

  get canViewZips() {
    return this.roles.includes("admin:general") || this.roles.includes("admin:zips:view")
  }

  get canEditRatings() {
    return this.roles.includes("admin:ratings:edit")
  }
}

const User = new CurrentUser();

export { User };
