import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row
} from 'reactstrap';
import { client } from '../../helpers/client';
import { Address } from '../../models/Address';
import { AddressModal } from './AddressModal';

export const EventModal = observer(class EventModal extends Component {
	addresses = []
	addressModalOpen = false

	async componentDidMount() {
		await this.fetchAddresses()
	}

	handleChange = (_e, v) => {
    switch (v) {
      case 'displayAddressForm':
        this.addressModalOpen = true;
        break;
      default:
    }
  }

  fetchAddresses = async () => {
  	const addresses = await client.get('/addresses?filter=booking_restriction&limit=100');
    this.addresses.clear()
  	for (const address of addresses.data) {
  		this.addresses.push(new Address(address))
  	}
  }

  addressSubmitHandler = async (_e, values) => {
    const data = {
      address: {
        label: values.label,
        line1: values.line1,
        line2: values.line2,
        city: values.city,
        state: values.state,
        postal_code: values.postalCode,
        addressable_type: 'BookingRestriction',
      },
    };

    Object.keys(data).forEach((key) => (data[key] === null) && delete data[key]);

    client.makeApiCall(
      'post', '/addresses', data,
      'Address added successfully',
      'Unable to add new address',
      async () => {
        runInAction(() => this.addressModalOpen = false);
        await this.fetchAddresses(100);
      }
    )
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
      	<AddressModal
          title="Add New Address"
          isOpen={this.addressModalOpen}
          toggle={() => { runInAction(() => { this.addressModalOpen = !this.addressModalOpen; }); }}
          submitHandler={this.addressSubmitHandler}
        />

        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title || 'Add Event' }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.event}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <AvField
                    name="name"
                    placeholder="Event Name"
                    type="text"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="name"
                  />
                </FormGroup>

                <FormGroup>
                  <AvField type="select" name="addressId" label="Address" onChange={this.handleChange} validate={{ required: { value: true } }}>
                    <option />
                    { this.addresses.map((address) => (
                      <option key={address.id} value={address.id}>
                        {
                          [address.label && `[${address.label}]`, address.line1, address.city, address.state, address.isDefault ? '(default)' : null].filter((el) => el != null).join(', ')
                        }
                      </option>
                    ))}
                    <optgroup>
                      <option key="newPickupOpt" value="displayAddressForm">New Address</option>
                    </optgroup>
                  </AvField>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="startTimeLocalizedString">Start Time for Restriction</Label>
                  <AvField
                    name="startTimeLocalizedString"
                    placeholder="Start Time"
                    type="datetime-local"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startTimeLocalizedString"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="endTimeLocalizedString">End Time for Restriction</Label>
                  <AvField
                    name="endTimeLocalizedString"
                    placeholder="End Time"
                    type="datetime-local"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="endTimeLocalizedString"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="dangerRadiusMiles">Danger Radius in Miles (opt)</Label>
                  <AvField
                    name="dangerRadiusMiles"
                    placeholder="Danger Radius mi"
                    className="form-control"
                    validate={{ required: { value: false } }}
                    id="dangerRadiusMiles"
                    type="number"
                    onwheel="return false;"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="errorRadiusMiles">Error Radius in Miles (opt)</Label>
                  <AvField
                    name="errorRadiusMiles"
                    placeholder="Error Radius mi"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="errorRadiusMiles"
                    type="number"
                    onwheel="return false;"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="dangerMessage">Danger Message (required if radius set)</Label>
                  <AvField
                    name="dangerMessage"
                    placeholder="Danger Message"
                    type="text"
                    className="form-control"
                    validate={{ required: { value: false } }}
                    id="dangerMessage"
                  />
                </FormGroup>

                <strong>Example:</strong>
                <ul>
                  <li>If "Error/Danger on Round Trip" is checked, booking is within error radius, and error message is set, the user cannot book a round trip or airport ride.</li>
                  <li>If "Error/Danger on Round Trip" is checked, booking is outside the error radius, but within danger radius, and danger message is set, the user can book a round trip or airport ride and will see the danger message.</li>
                  <li>If "Error/Danger on Round Trip" is NOT checked, booking is within the danger radius, and danger message is set, the user can book a round trip or airport ride but will see the danger message.</li>
                </ul>

                <FormGroup>
                  <AvGroup check>
                    <Label check>
                      <AvInput type="checkbox" name="roundTripError" /> Error/Danger on Round Trips (also airport)
                    </Label>
                  </AvGroup>
                </FormGroup>

                <FormGroup>
                  <AvGroup check>
                    <Label check>
                      <AvInput type="checkbox" name="oneWayError" /> Error/Danger on One Ways
                    </Label>
                  </AvGroup>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="dangerMessage">Error Message</Label>
                  <AvField
                    name="errorMessage"
                    placeholder="Error Message"
                    type="text"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="errorMessage"
                  />
                </FormGroup>

              </Col>
            </Row>
            <Button color="primary" type="submit">Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(EventModal, {
	addresses: observable,
	handleChange: action,
	addressModalOpen: observable,
	fetchAddresses: action,
});
