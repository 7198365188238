// @ts-nocheck
import { action, computed, observable } from "mobx"
import { addressesStore } from "../stores/addressesStore"
import { cardsStore } from "../stores/cardsStore"
import { tripsStore } from "../stores/tripsStore"
import { vehiclesStore } from "../stores/vehiclesStore"
import { driversStore } from "../stores/driversStore"
import { insurancePoliciesStore } from "../stores/insurancePoliciesStore"
import { apiCall } from "../constants/apiCall"

export class Client {
  /**
   * unique id of this Client, immutable.
   */
  id = null

  @observable email
  @observable name
  @observable gender
  @observable stripeId
  @observable jwt
  @observable status
  @observable phoneNumber
  @observable inviteCode
  @observable city
  @observable preferences
  @observable currentSubscription
  @observable createdAt
  @observable updatedAt
  @observable hasFavoriteDrivers  = false
  @observable avatarUrl
  @observable bookingCount = 0
  @observable tripsCount = 0
  @observable birthdate
  @observable signedUpVia
  @observable hourlyRateCentsOverride
  @observable billingRateCentsOverride
  @observable zipCode
  @observable promoCodeUsed
  @observable availableCredits
  @observable availableMinutes
  @observable deviceData
  @observable lastTripDate
  @observable autoTipPercentage
  @observable leadSource
  @observable invitedBy
  @observable pendingChurnOn
  @observable isChildAccount
  @observable isParent
  @observable parentAccount
  @observable totalSpend
  @observable acceptedTosAt
  @observable internalAccount
  @observable accountType
  @observable ambassador
  @observable becameAmbassadorAt
  @observable ambassadorInviteCode
  @observable activeGroup
  @observable dryver

  @observable favoriteDrivers   = []
  @observable blockedDrivers    = []

  type = 'client'

  // @observable upcomingTrips = []

  tripsStore
  addressesStore
  vehiclesStore
  cardsStore
  driversStore
  insurancePoliciesStore

  subscriptionsStore
  ratingsStore
  tipsStore
  incidentsStore
  chargesStore

  /**
   * Indicates whether changes in this object
   * should be submitted to the server
   */
  autoSave = true

  /**
   * Disposer for the side effect that automatically
   * stores this object, see @dispose.
   */
  saveHandler = null

  constructor(data: any, jwt?: string) {
    this.id   = data?.id
    this.jwt  = jwt

    this.tripsStore     = new tripsStore()
    this.addressesStore = new addressesStore()
    this.vehiclesStore  = new vehiclesStore()
    this.cardsStore     = new cardsStore()
    this.driversStore   = new driversStore()
    this.insurancePoliciesStore = new insurancePoliciesStore()

    // this.subscriptionsStore = global.store
    // this.ratingsStore = global.store
    // this.tipsStore = global.store
    // this.incidentsStore = global.store
    // this.chargesStore = global.store

    this.updateFromJson(data)
  }

  @computed get asJson() {
    return {
      id:                   this.id,
      email:                this.email,
      name:                 this.name,
      gender:               this.gender,
      stripeId:             this.stripeId,
      status:               this.status,
      phoneNumber:          this.phoneNumber,
      inviteCode:           this.inviteCode,
      city:                 this.city,
      preferences:          this.preferences,
      currentSubscription:  this.currentSubscription,
      createdAt:            this.createdAt,
      updatedAt:            this.updatedAt,
      hasFavoriteDrivers:   this.hasFavoriteDrivers,
      avatarUrl:            this.avatarUrl,
      bookingCount:         this.bookingCount,
      tripsCount:           this.tripsCount,
      birthdate:            this.birthdate,
      signedUpVia:          this.signedUpVia,
      zipCode:              this.zipCode,
      deviceData:           this.deviceData,
      autoTipPercentage:    this.autoTipPercentage,
      leadSource:           this.leadSource,
      isChildAccount:       this.isChildAccount,
      isParent:             this.isParent,
      parentAccount:        this.parentAccount,
      totalSpend:           this.totalSpend,
      acceptedTosAt:        this.acceptedTosAt,
      internalAccount:      this.internalAccount,
      accountType:          this.accountType,
      hourlyRateCentsOverride:  this.hourlyRateCentsOverride,
      billingRateCentsOverride: this.billingRateCentsOverride,
      availableCredits:         this.availableCredits,
      availableMinutes:         this.availableMinutes,
      promoCodeUsed:            this.promoCodeUsed,
      lastTripDate:             this.lastTripDate,
      invitedBy:                this.invitedBy,
      pendingChurnOn:           this.pendingChurnOn,
      ambassador:               this.ambassador,
      becameAmbassadorAt:       this.becameAmbassadorAt,
      ambassadorInviteCode: this.ambassadorInviteCode,
      activeGroup: this.activeGroup,
      dryver: this.dryver
    }
  }

  @computed get firstName() {
    return this.name.split(' ')[0];
  }

  // ADDRESSES

  @action fetchAddresses(limit) {
    let url
    if (limit) {
      url = `clients/${this.id}/addresses?limit=${ limit }`
    } else {
      url = `clients/${this.id}/addresses`
    }

    return this.addressesStore.fetchAll(url)
  }

  @computed get addresses() {
    return Array.from(this.addressesStore.addresses.values())
  }

  createAddress = async (data) => {
    const response = await this.addressesStore.create(`users/${this.id}/addresses`, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  @computed get hourlyRateOverride() {
    if (typeof(this.hourlyRateCentsOverride) === 'number') {
      return this.hourlyRateCentsOverride / 100
    } else {
      return undefined
    }
  }

  @computed get billingRateOverride() {
    if (typeof(this.billingRateCentsOverride) === 'number') {
      return this.billingRateCentsOverride / 100
    } else {
      return undefined
    }
  }

  updateAddress = async (id, data) => {
    const response = await this.addressesStore.update(id, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  // INSURANCE POLICIES

  @action fetchInsurancePolicies() {
    return this.insurancePoliciesStore.fetchAll(`users/${this.id}/insurance_policies`)
  }

  @computed get insurancePolicies() {
    return Array.from(this.insurancePoliciesStore.insurancePolicies.values())
  }

  createInsurancePolicy = async (data) => {
    const response = await this.insurancePoliciesStore.create(`users/${this.id}/insurance_policies`, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  updateInsurancePolicy = async (id, data) => {
    const response = await this.insurancePoliciesStore.update(id, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  @computed get isElPrez() {
    return ["El Prez", "El Prez Annual"].includes(this.currentSubscription?.displayName)
  }

  // VEHICLES

  @action fetchVehicles(limit) {
    let url
    if (limit) {
      url = `clients/${this.id}/vehicles?limit=${ limit }`
    } else {
      url = `clients/${this.id}/vehicles`
    }

    return this.vehiclesStore.fetchAll(url)
  }

  @computed get vehicles() {
    return Array.from(this.vehiclesStore.vehicles.values())
  }

  createVehicle = async (data) => {
    const response = await this.vehiclesStore.create(`clients/${this.id}/vehicles`, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  updateVehicle = async (id, data) => {
    const response = await this.vehiclesStore.update(id, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  // CARDS

  @action fetchCards(limit) {
    let url
    if (limit) {
      url = `clients/${this.id}/cards?limit=${ limit }`
    } else {
      url = `clients/${this.id}/cards`
    }

    return this.cardsStore.fetchAll(url)
  }

  @computed get cards() {
    return Array.from(this.cardsStore.cards.values())
  }

  createCard = async (data) => {
    const response = await this.cardsStore.create(`clients/${this.id}/cards`, data, this.stripeId)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  updateCard = async (id, data) => {
    const response = await this.cardsStore.update(id, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  // TRIPS

  @action fetchUpcomingTrips() {
    return this.tripsStore.fetchAll(`clients/${this.id}/bookings?filter=upcoming`)
  }

  @action fetchPastTrips() {
    return this.tripsStore.fetchAll(`clients/${this.id}/bookings?filter=past`)
  }

  @computed get upcomingTrips() {
    return Array.from(this.tripsStore.trips.values()).filter((trip: any) => {
      return trip.upcoming
    })
  }

  @computed get pastTrips() {
    return Array.from(this.tripsStore.trips.values()).filter((trip: any) => {
      return ['completed', 'cancelled', 'failed'].includes(trip.status)
    })
  }

  @action fetchTrips() {
    return this.cardsStore.fetchAll(`clients/${this.id}/bookings`)
  }

  @computed get trips() {
    return Array.from(this.tripsStore.trips.values())
  }

  @computed get driverTalkingType() {
    return this.preferences.driverTalkingType
  }

  @computed get driverDrivingType() {
    return this.preferences.driverDrivingType
  }

  @computed get driverPreferredGender() {
    return this.preferences.driverPreferredGender
  }

  @computed get driverSpokenLanguage() {
    return this.preferences.driverSpokenLanguage
  }

  @computed get acceptsCologneWearer() {
    return this.preferences.acceptsCologneWearer
  }

  @computed get acceptsSmoker() {
    return this.preferences.acceptsSmoker
  }

  @computed get prefersHat() {
    return this.preferences.prefersHat
  }

  @computed get availableCreditsFormatted() {
    return this.availableCredits.cents / 100
  }


  createTrip = async (data) => {
    const response = await this.tripsStore.create(`clients/${this.id}/trips`, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  updateTrip = async (id, data) => {
    const response = await this.tripsStore.update(id, data)

    if (typeof(response) === 'object') {
      return true
    } else {
      return false
    }
  }

  @action fetchPreferredDrivers() {
    return this.driversStore.fetchAll(`clients/${this.id}/favorite_drivers`)
  }

  @computed get preferredDrivers() {
    return Array.from(this.driversStore.drivers.values())
  }

  async uploadImage(uri) {
    let uriParts = uri.split('.')
    let fileType = uriParts[uriParts.length - 1]

    let formData = new FormData()

    formData.append('avatar', {
      uri,
      name: `photo.${fileType}`,
      type: `image/${fileType}`,
    })

    await apiCall(`${process.env.REACT_APP_API_URL}/clients/${this.id}`, {
      method: 'PUT',
      body: formData,
      contentType: 'multipart/form-data'
    })
  }

  /**
   * Update this object with information from the server
   */
  @action updateFromJson(data) {
    // make sure our changes aren't sent back to the server
    this.autoSave = false

    if (!data?.attributes) {
      return
    }

    this.name                 = data.attributes.name
    this.gender               = data.attributes.gender
    this.email                = data.attributes.email
    this.stripeId             = data.attributes.stripeId
    this.status               = data.attributes.status
    this.phoneNumber          = data.attributes.phoneNumber
    this.inviteCode           = data.attributes.inviteCode
    this.city                 = data.attributes.city
    this.preferences          = data.attributes.preferences
    this.currentSubscription  = data.attributes.currentSubscription
    this.createdAt            = data.attributes.createdAt
    this.updatedAt            = data.attributes.updatedAt
    this.hasFavoriteDrivers   = data.attributes.hasFavoriteDrivers
    this.avatarUrl            = data.attributes.avatarUrl
    this.bookingCount         = data.attributes.bookingCount
    this.tripsCount           = data.attributes.tripsCount
    this.birthdate            = data.attributes.birthdate
    this.signedUpVia          = data.attributes.signedUpVia
    this.zipCode              = data.attributes.zipCode
    this.deviceData           = data.attributes.deviceData
    this.availableCredits     = data.attributes.availableCredits
    this.availableMinutes     = data.attributes.availableMinutes
    this.promoCodeUsed        = data.attributes.promoCodeUsed
    this.lastTripDate         = data.attributes.lastTripDate
    this.autoTipPercentage    = data.attributes.preferences?.autoTipPercentage
    this.leadSource           = data.attributes.leadSource
    this.isChildAccount       = data.attributes.isChildAccount
    this.isParent             = data.attributes.isParent
    this.parentAccount        = data.attributes.parentAccount
    this.totalSpend           = data.attributes.totalSpend
    this.acceptedTosAt        = data.attributes.acceptedTosAt
    this.internalAccount      = data.attributes.internalAccount
    this.accountType          = data.attributes.accountType
    this.hourlyRateCentsOverride  = data.attributes.hourlyRateCentsOverride
    this.billingRateCentsOverride = data.attributes.billingRateCentsOverride
    this.invitedBy                = data.attributes.invitedBy
    this.pendingChurnOn           = data.attributes.pendingChurnOn
    this.ambassador               = data.attributes.ambassador
    this.becameAmbassadorAt       = data.attributes.becameAmbassadorAt
    this.ambassadorInviteCode     = data.attributes.ambassadorInviteCode
    this.activeGroup              = data.attributes.activeGroup
    this.dryver              = data.attributes.dryver

    this.autoSave = true
  }

  dispose() {
    // clean up the observer
    this.saveHandler()
  }
}
