import { AvField, AvForm } from 'availity-reactstrap-validation';
import { decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';

const stateAbbreviations = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
  'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
  'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
  'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
];

export const AddressModal = observer(class AddressModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.address}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="label">Label</Label>
                  <AvField
                    name="label"
                    placeholder="Label (optional)"
                    type="text"
                    className="form-control"
                    id="label"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="line1">Address Line 1</Label>
                  <AvField
                    name="line1"
                    placeholder="Address Line 1"
                    type="text"
                    errorMessage="Address Line 1 required"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="line1"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="line1">Address Line 2</Label>
                  <AvField
                    name="line2"
                    placeholder="Address Line 2"
                    type="text"
                    className="form-control"
                    id="line2"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="city">City</Label>
                  <AvField
                    name="city"
                    placeholder="City"
                    type="text"
                    errorMessage=" Please provide a valid city"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="city"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <AvField
                    type="select"
                    name="state"
                    label="State"
                    validate={{ required: { value: true } }}
                    id="state"
                    className="form-control"
                  >
                    {
                          stateAbbreviations.map((opt) => <option key={opt} value={opt}>{opt}</option>)
                        }
                  </AvField>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="postalCode">Zip</Label>
                  <AvField
                    name="postalCode"
                    placeholder="Zip Code"
                    type="text"
                    errorMessage="Please provide a valid zip"
                    className="form-control"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: '^[0-9]{5}$',
                        errorMessage: 'Please provide a valid zip code',
                      },
                    }}
                    id="postalCode"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="buildingCode">Building Code</Label>
                  <AvField
                    name="buildingCode"
                    placeholder="Building Code"
                    type="text"
                    className="form-control"
                    id="buildingCode"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="neighborhoodCode">Neighboorhood Code</Label>
                  <AvField
                    name="neighborhoodCode"
                    placeholder="Neighboorhood Code"
                    type="text"
                    className="form-control"
                    id="neighborhoodCode"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(AddressModal, {
});
