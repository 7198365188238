import { action, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import ClipboardLink from '../../components/Common/ClipboardLink'
import { client } from '../../helpers/client'
import { formatters } from '../../helpers/Formatters'
import { CouponModal } from '../Modals/CouponModal'
import { MyTable } from '../../components/MyTable/MyTable'
import { Coupon } from '../../models/Coupon'

export const CouponsTable = observer(
  class CouponsTable extends Component {
    _addModalOpen = false

    _selectedCoupon

    componentDidMount() {
      this._tableRef = React.createRef()
    }

    idFormatter = (cell, row) => (
      <>
        <a
          href="#"
          key={row.id}
          onClick={() => {
            runInAction(() => {
              this._selectedCoupon = row

              if (row.cashValue) {
                this._selectedCoupon.cashValue = row.cashValue?.cents / 100
              }
              if (row.expiresAt) {
                this._selectedCoupon.expiresAt =
                  formatters.localizedDateFormatter(row.expiresAt)
              }

              this._addModalOpen = true
            })
          }}
        >
          {cell.split('-')[0]}
        </a>
        <ClipboardLink text={cell} />
      </>
    )

    adminFormatter = (cell, _row) => {
      return cell?.name || 'Unknown/Removed'
    }

    enabledFormatter = (cell, _row) => {
      if (cell) {
        return 'enabled'
      } else {
        return 'Not enabled'
      }
    }

    tripsEnabledFormatter = (cell, _row) => {
      return (
        <ul>
          {cell.map((trip, i) => (
            <li key={i}>{trip}</li>
          ))}
        </ul>
      )
    }

    arrayGeneralFormatter = (emptyMessage, data) => {
      let rtn = (
        <ul>
          <li>{emptyMessage}</li>
        </ul>
      )
      if (data?.length > 0) {
        rtn = (
          <ul>
            {data.map((usr, i) => (
              <li key={i}>{usr}</li>
            ))}
          </ul>
        )
      }
      return rtn
    }

    enabledrRegionsFormatter = (cell, _row) =>
      this.arrayGeneralFormatter('All Regions', cell)

    discountTypeFormatter = (cell, _row) => {
      let rtn = <div>Dollars</div>
      if (cell) {
        rtn = <div>Percentage</div>
      }
      return rtn
    }

    dataColumns = [
      {
        dataField: 'id',
        name: 'ID',
        priority: 1,
        formatter: this.idFormatter,
      },
      {
        dataField: 'enabled',
        name: 'Enabled',
        priority: 1,
        formatter: this.enabledFormatter,
      },
      {
        dataField: 'code',
        name: 'Code',
        priority: 1,
        sort: true,
      },
      {
        dataField: 'admin',
        name: 'Created By',
        priority: 1,
        formatter: this.adminFormatter,
      },
      {
        dataField: 'tripsEnabled',
        name: 'Trips enabled for',
        priority: 1,
        formatter: this.tripsEnabledFormatter,
      },
      {
        dataField: 'enabledRegions',
        name: 'Regions enabled for',
        priority: 1,
        sort: true,
        formatter: this.enabledrRegionsFormatter,
      },
      {
        dataField: 'valuePercent',
        name: 'Discount type',
        priority: 1,
        sort: true,
        formatter: this.discountTypeFormatter,
      },
    ]

    modalSubmitHandler = async (e, values) => {
      const data = {}

      if (values.code) {
        data.code = values.code
      }
      if (values.valuePercent) {
        data.value_percent = values.valuePercent
      }

      if (values.discountType === 'valuePercentage') {
        data.value_percent = values.discountValue
        data.value_cents = 0
      } else {
        data.value_cents = values.discountValue * 100
        data.value_percent = null
      }

      data.enabled_for_round_trip = values.enabledForRoundTrip
      data.enabled_for_one_way = values.enabledForOneWay
      data.enabled_for_airport = values.enabledForAirport
      data.enabled_for_overnighter = values.enabledForOvernighter
      data.enabled_regions =
        values.regions.indexOf('all') >= 0 || values.regions.length === 8
          ? []
          : values.regions
      data.enabled = values.enabled

      if (this._selectedCoupon) {
        await client.makeApiCall(
          'put',
          `/coupons/${this._selectedCoupon.id}`,
          { coupon: data },
          'Coupon updated successfully',
          'Unable to update coupon',
          (response) => {
            this._selectedCoupon.updateFromJson(response.data)
            this._closeModals()
          },
        )
      } else {
        await client.makeApiCall(
          'post',
          this.props.dataUrl,
          { coupon: data },
          'Coupon created successfully',
          'Unable to create coupon',
          this._postSubmitCallback,
        )
      }
    }

    _postSubmitCallback = () => {
      this._tableRef.current.fetchData()
      this._closeModals()
    }

    _closeModals = () => {
      this._addModalOpen = false
    }

    render() {
      return (
        <>
          <CouponModal
            coupon={this._selectedCoupon}
            isOpen={this._addModalOpen}
            toggle={() => {
              runInAction(() => {
                this._addModalOpen = !this._addModalOpen
                this._selectedCoupon = null
              })
            }}
            title={`${this._selectedCoupon ? 'Edit' : 'Add New'} Coupon`}
            submitHandler={this.modalSubmitHandler}
          />
          <MyTable
            model={Coupon}
            dataUrl={this.props.dataUrl}
            dataColumns={this.dataColumns}
            title={'Coupons'}
            breadcrumbTitle={this.props.filter || 'All'}
            breadcrumbBaseTitle={'Coupons'}
            embedded={this.props.embedded}
            defaultLimit={this.props.defaultLimit}
            singleActions={{
              'Add +': () =>
                runInAction(() => {
                  this._addModalOpen = !this._addModalOpen
                }),
            }}
            ref={this._tableRef}
          />
        </>
      )
    }
  },
)

decorate(CouponsTable, {
  _addModalOpen: observable,
  addSubmitHandler: action,
  _selectedCoupon: observable,
  _closeModals: action,
})
