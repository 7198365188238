import { AvForm } from 'availity-reactstrap-validation';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button, Col, FormGroup, Label, Modal, Row
} from 'reactstrap';
import { client } from '../../helpers/client';

export const FavoritesBlockedModal = observer(class FavoritesBlockedModal extends Component {
  values = []

  options = async (inputValue) => {
    const users = await client.get(this.props.dataUrl, {
      query: inputValue,
    })

    return users.data.map((user) => {
      return { label: `${user.attributes.name} (${user.attributes.city})`, value: user.id }
    })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={() => {
            this.props.submitHandler(this.values)
          }}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="status">{ this.props.type }</Label>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions={true}
                    loadOptions={this.options}
                    onChange={ (values, _e) => {
                      this.values = values
                    } }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(FavoritesBlockedModal, {
  values: observable,
});
