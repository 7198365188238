import React, { Component } from 'react';
import {
  Card, CardBody, CardTitle, Table,
} from 'reactstrap';
import { computed, decorate } from 'mobx';
import humanizeString from 'humanize-string';
import { Link } from 'react-router-dom';

export default class PersonalInfoCard extends Component {
  get preferences() {
    const prefs = [];
    let prefData = this.props.user.preferences;
    if (prefData?.data) {
      prefData = prefData.data.attributes;
    }
    if (prefData) {
      for (let [key, value] of Object.entries(prefData)) {
        if (value !== null && key !== 'createdAt' && key !== 'updatedAt') {
          if (typeof (value) === 'boolean') {
            value = value ? 'Yes' : 'No';
          }

          prefs.push(<tr>
            <th scope="row">
              {humanizeString(key)}
              {' '}

            </th>
            <td>{value}</td>
          </tr>);
        }
      }
    }

    return prefs;
  }

  get client() {
    return (this.props.user.type === 'client');
  }

  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{humanizeString(this.props.user.type)}</CardTitle>

          <div className="table-responsive">
            <Table className="table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">Full Name</th>
                  <td>
                    <Link to={`/${this.props.user.type}/${this.props.user.id}`}>
                      {this.props.user.name}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Mobile</th>
                  <td>
                    <a href={`tel:${this.props.user.phoneNumber}`}>
                      {this.props.user.phoneNumber}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">E-mail</th>
                  <td>
                    <a href={`mailto:${this.props.user.email}`}>
                      {this.props.user.email}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Location</th>
                  <td>{this.props.user.city}</td>
                </tr>

                { this.preferences }

                { this.client && this.props.user.currentSubscription && (
                <tr>
                  <th scope="row">Current Subscription</th>
                  <td>
                    {this.props.user.currentSubscription.displayName}
                  </td>
                </tr>
                )}

                { this.client && (
                <tr>
                  <th scope="row">Promo Code Used</th>
                  <td>
                    {this.props.user.promoCodeUsed || "n/a"}
                  </td>
                </tr>
                )}

                { this.client && (
                <tr>
                  <th scope="row">Available Credits</th>
                  <td>
                    {this.props.user.availableCredits.pretty}
                  </td>
                </tr>
                )}

                { this.client && (
                <tr>
                  <th scope="row">Available Minutes</th>
                  <td>
                    {this.props.user.availableMinutes} min
                  </td>
                </tr>
                )}

                { this.props.user?.deviceData && (
                <tr>
                  <th scope="row">Device Info</th>
                  <td>
                    { Object.keys(this.props.user.deviceData).map(key => (
                      <div><strong>{key}:</strong> { JSON.stringify(this.props.user.deviceData[key]) }</div>
                    )) }
                  </td>
                </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    );
  }
}

decorate(PersonalInfoCard, {
  preferences: computed,
  client: computed,
});
