// @ts-nocheck
import { action, computed, observable } from "mobx"

export class BookingRestriction {
    /**
     * unique id of this BookingRestriction, immutable.
     */
    id = null

    @observable id
    @observable address
    @observable admin
    @observable name
    @observable startTime
    @observable endTime
    @observable dangerRadiusMiles
    @observable errorRadiusMiles
    @observable dangerMessage
    @observable errorMessage
    @observable allowRoundTrip
    @observable allowOneWay
    @observable createdAt

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id: 								this.id,
				address: 						this.address,
				admin: 							this.admin,
				name: 							this.name,
				startTime: 					this.startTime,
				endTime: 						this.endTime,
				dangerRadiusMiles: 	this.dangerRadiusMiles,
				errorRadiusMiles: 	this.errorRadiusMiles,
				dangerMessage: 			this.dangerMessage,
				errorMessage: 			this.errorMessage,
				allowRoundTrip: 		this.allowRoundTrip,
				allowOneWay: 				this.allowOneWay,
				createdAt: 					this.createdAt,
      }
    }

    @computed get startTimeLocalizedString() {
      if (!this.startTime) {
        return ''
      }

      const date = new Date(this.startTime)
      const offset = date.getTimezoneOffset() / 60

      date.setTime(date.getTime() - offset * 60 * 60 * 1000)

      return date.toISOString().replace('Z', '')
    }

    @computed get endTimeLocalizedString() {
      if (!this.endTime) {
        return ''
      }

      const date = new Date(this.endTime)
      const offset = date.getTimezoneOffset() / 60

      date.setTime(date.getTime() - offset * 60 * 60 * 1000)

      return date.toISOString().replace('Z', '')
    }

    @computed get addressId() {
      return (this.address?.id)
    }

    @computed get roundTripError() {
      return !this.allowRoundTrip
    }

    @computed get oneWayError() {
      return !this.allowOneWay
    }

    /**
     * Update this object with information from the server
     */
    @action @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.id 								= data.attributes.id
			this.address 						= data.attributes.address
			this.admin 							= data.attributes.admin
			this.name 							= data.attributes.name
			this.startTime 					= data.attributes.startTime
			this.endTime 						= data.attributes.endTime
			this.dangerRadiusMiles 	= data.attributes.dangerRadiusMiles
			this.errorRadiusMiles 	= data.attributes.errorRadiusMiles
			this.dangerMessage 			= data.attributes.dangerMessage
			this.errorMessage 			= data.attributes.errorMessage
			this.allowRoundTrip 		= data.attributes.allowRoundTrip
			this.allowOneWay 				= data.attributes.allowOneWay
			this.createdAt 					= data.attributes.createdAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
