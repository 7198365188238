import { action, computed, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import RelativeTime from 'react-relative-time';
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { Earning } from '../../models/Earning';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { EarningModal } from '../Modals/EarningModal';

export const EarningsTable = observer(class EarningsTable extends Component {
  selectedEarning

  totalPayouts

  _addModalOpen = false
  _confirmApproveModalOpen = false
  _confirmIssueModalOpen = false
  _selectedEarnings

  constructor(props) {
    super(props)

    if (this.props.filter === 'pending') {
      this.fetchTotals()
    }
  }

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  fetchTotals = async () => {
    const result = await client.get('/earnings/payout_total')
    this.totalPayouts = result.total
  }

  idFormatter = (cell, row) => (
    <><a
      href={`#${row.id}`}
      key={row.id}
      onClick={
        () => {
          runInAction(() => {
            row.amount = row.amount.cents / 100;
            this._addModalOpen = true;
            this.selectedEarning = row;
          });
        }
      }
    >
      {cell.split('-')[0]}
    </a><ClipboardLink text={cell} /></>
  )

  createdByFormatter = (cell, _row) => {
    if (!cell) {
      return 'System'
    } else {
      return cell.name
    }
  }

  earnableFormatter = (cell, row) => {
    return <a href={`/booking/${row.bookingId}`}>
      {cell}
    </a>
  }

  userFormatter = (cell, row) => {
    const url = `/driver/${cell.id}`;

    return (
      <NavLink strict={+true} to={url}>
        { cell.name }
      </NavLink>
    );
  }

  createdAtFormatter = (cell, row) => {
    if (cell) {
      return (<RelativeTime value={cell} />);
    }
  }

  emailFormatter = (cell, row) => {
    if (cell) {
      return <a href={`mailto:${cell}`}>{cell}</a>;
    }
  }

  statusFormatter = (cell, _row) => {
    switch (cell) {
      case 'pending':
        return <Badge color="warning" className="mr-1">Pending</Badge>;
      case 'approved':
        return <Badge className="badge-soft-warning mr-1">Approved</Badge>;
      case 'paid':
        return <Badge className="badge-soft-success mr-1">{ cell.toUpperCase() }</Badge>;
      case 'cancelled':
        return <Badge className="badge-soft-info mr-1">{ cell.toUpperCase() }</Badge>;
      case 'failed':
        return <Badge color="danger" className="mr-1">{ cell.toUpperCase() }</Badge>;
      default:
        return <Badge color="danger" className="mr-1">unknown</Badge>;
    }
  }

  amountFormatter = (cell, _row) => cell.pretty

  transferFormatter = (cell, _row) => {
    if (cell) {
      return <a href={`/transfers/${cell}`}>{ cell.split('-')[0] }</a>;
    }
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
      sort: true,
    },
    {
      dataField: 'user',
      name: 'Driver',
      priority: 1,
      formatter: this.userFormatter,
      sort: true,
    },
    {
      dataField: 'amount',
      name: 'Amount',
      priority: 1,
      formatter: this.amountFormatter,
      sort: true,
    },
    {
      dataField: 'earnableType',
      name: 'Type',
      priority: 1,
      formatter: this.earnableFormatter,
    },
    {
      dataField: 'status',
      name: 'Status',
      priority: 1,
      sort: true,
      formatter: this.statusFormatter,
    },
    {
      dataField: 'stripeTransferId',
      name: 'Transfer',
      priority: 1,
      formatter: this.transferFormatter,
    },
    {
      dataField: 'createdAt',
      name: 'Created At',
      priority: 1,
      formatter: this.createdAtFormatter,
      sort: true,
    },
    {
      dataField: 'expectedPayDate',
      name: 'Expected Pay Date',
      priority: 1,
    },
    {
      dataField: 'admin',
      name: 'Created By',
      priority: 1,
      formatter: this.createdByFormatter,
    },
    {
      dataField: 'issuedAt',
      name: 'Issued At',
      priority: 1,
      formatter: this.createdAtFormatter,
    },
  ]

  submitEarningHandler = async (_e, values) => {
    const data = {
      earning: {
        amount_cents: Math.round(values.amount * 100),
        notes: values.notes,
      },
    }

    if (this.selectedEarning) {
      client.makeApiCall(
        'put', `/earnings/${this.selectedEarning.id}`, data,
        'Earning updated successfully',
        'Unable to update earning',
        (response) => {
          this.selectedEarning.updateFromJson(response.data)
          this._closeModals()
        }
      )
    } else {
      client.makeApiCall(
        'post', `/bookings/${this.props.booking.id}/earnings`, data,
        'Earning created successfully',
        'Unable create new earning',
        this._postSubmitCallback
      )
    }
  }

  _postSubmitCallback = () => {
    this._tableRef.current.fetchData()
    this._closeModals()
  }

  _closeModals = () => {
    this._addModalOpen = false
    this._confirmApproveModalOpen = false
    this._confirmIssueModalOpen = false
  }

  get earningTotal() {
    if (this.totalPayouts) {
      return `${this.totalPayouts}`
    } else {
      return "..."
    }
  }

  get limit() {
    return this.props.filter === 'pending' ? 250 : this.props.defaultLimit
  }

  get modalTitle() {
    if (this.selectedEarning) {
      return `${this.selectedEarning.status === 'pending' ? 'Edit' : 'View'} Earning`
    } else {
      return 'New Earning'
    }
  }

  _toggleConfirmApproveModal = () => this._confirmApproveModalOpen = !this._confirmApproveModalOpen
  _toggleConfirmIssueModal = () => this._confirmIssueModalOpen = !this._confirmIssueModalOpen

  _issuePayouts = async () => {
    client.makeApiCall(
      'post', '/earnings/payout', {},
      'Payouts issued successfully',
      'Unable to issue payouts',
      this._postSubmitCallback
    )
  }

  _approveAllEarnings = async (earnings) => {
    this._selectedEarnings = earnings
    this._toggleConfirmApproveModal()
  }

  _approveEarnings = async () => {
    await Promise.allSettled(this._selectedEarnings.map(async (earning) => {
      if (earning.status !== 'approved') {
        await client.put(`/earnings/${earning.id}/`, { earning: { status: 'approved' } });
        runInAction(() => { earning.status = 'approved' });
      }
    }))
    this.fetchTotals()
    this._toggleConfirmApproveModal()
  }

  get tableActions() {
    const actions = {}

    if (this.props.filter === 'pending') {
      actions[`Payout Approved Earnings: ${ this.earningTotal }`] = this._toggleConfirmIssueModal
    }

    if (this.props.addEnabled) {
      actions['Add +'] = () => {
        runInAction(() => {
          this.selectedEarning = null;
          this._addModalOpen = !this._addModalOpen;
        });
      }
    }

    return actions
  }

  render() {
    return (
      <>
        {/* Approve Earnings */}
        <ConfirmationModal
          isOpen={ this._confirmApproveModalOpen }
          toggle={ this._toggleConfirmApproveModal }
          header={ 'Approve Earnings?' }
          body={ 'Are you sure you want to approve all selected earnings?' }
          confirm={ this._approveEarnings }
          confirmButtonText={ 'Approve' }
          closeButtonText={ 'Cancel' }
        />

        {/* Issue Payouts */}
        <ConfirmationModal
          isOpen={ this._confirmIssueModalOpen }
          toggle={ this._toggleConfirmIssueModal }
          header={ 'Issue Payouts?' }
          body={ 'Are you sure you want to pay all drivers their approved Earnings through Stripe at this time?  There is a 48 hour delay before their funds are available.' }
          confirm={ this._issuePayouts }
          confirmButtonText={ 'Confirm' }
          closeButtonText={ 'Cancel' }
        />

        <EarningModal
          isOpen={this._addModalOpen}
          toggle={() => {
            runInAction(() => {
              this._addModalOpen = !this._addModalOpen
            });
          }}
          title={this.modalTitle}
          submitHandler={this.submitEarningHandler}
          earning={this.selectedEarning}
        />

        <MyTable
          model={Earning}
          dataUrl={this.props.dataUrl}
          dataColumns={this.dataColumns}

          breadcrumbTitle={this.props.filter || 'All'}
          breadcrumbBaseTitle={this.props.baseTitle || 'Earnings'}

          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}

          embedded={this.props.embedded}

          defaultLimit={this.limit}
          filter={this.props.filter}

          reactions={[this.fetchTotals]}
          singleActions={
            this.tableActions
          }
          multiActions={{
            'Approve Checked': rows => this._approveAllEarnings(rows),
          }}
          ref={this._tableRef}
        />
      </>
    );
  }
});

decorate(EarningsTable, {
  selectedEarning: observable,
  earningTotal: computed,
  totalPayouts: observable,
  fetchTotals: action,
  limit: computed,
  _approveAllEarnings: action,
  _addModalOpen: observable,
  modalTitle: computed,
  _confirmApproveModalOpen: observable,
  _confirmIssueModalOpen: observable,
  _toggleConfirmApproveModal: action,
  _toggleConfirmIssueModal: action,
  _selectedEarnings: observable,
  tableActions: computed,
  _closeModals: action,
});
