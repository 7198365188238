import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';

export const PromptModal = observer(class PromptModal extends Component {
	_inputValue

  render() {
    return(
      <Modal
        isOpen={ this.props.isOpen }
        toggle={ this.props.toggle }
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.header }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          { this.props.body }

          <input
            type="text"
            value={this._inputValue}
            onChange={
              (e) => {
                runInAction(() => {
                  this._inputValue = e.target.value;
                });
              }
						}
            className="form-control chat-input"
            placeholder=""
          />
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={ this.props.toggle }
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            { this.props.closeButtonText || 'Cancel' }
          </button>

          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={ () => this.props.confirm(this._inputValue) }
          >
            { this.props.confirmButtonText || 'Submit' }
          </button>
        </div>
      </Modal>
    )
  }
})

