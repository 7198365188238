import { action, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { Button, Card, CardBody, CardHeader, CardTitle, CardText, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { client } from '../../helpers/client';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { myToast } from '../../components/Common/MyToast';

export const Leads = observer(class Leads extends Component {
  textMessage

  updateTextMessage = async (message) => this.textMessage = message

  handleSubmit = async (_e, values) => {
    const data = {
      name: values.name,
      email: values.email,
      phone_number: values.phoneNumber,
      zip_code: values.zipCode,
      lead_source: values.leadSource,
      text_message_body: values.textMessageBody,
    }

    Object.keys(data).forEach((key) => (data[key] === null) && delete data[key]);

    try {
      await this.createLead(data);
      myToast.showToast('success', 'Lead created successfully', 'Success')
    } catch(err) {
      myToast.showToast('error', `Unable to create lead: ${err.message}`, 'Error')
    }
  }

  createLead = async (data) => {
    if (this.textMessage) {
      data['text_message_body'] = this.textMessage
    }
    return await client.post(`/leads`, {
      client: data,
      csv_upload: true,
    });
  }

  render() {
    return(
      <>
        <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="Lead Import" breadcrumbItem="Marketing" />

              <Row>
                <Col xl="6">
                  <Card>
                    <CardHeader className="mt-0 h5 bg-transparent border-bottom text-uppercase card-header">
                      CSV Import
                    </CardHeader>
                    <CardBody>
                      <CardTitle className="mt-0">Drag/drop or click to import a list of leads</CardTitle>
                      <CardText>
                        Supported columns include: Full Name, Email, Phone, Zip Code, and Lead Source
                      </CardText>

                      <Importer
                          // chunkSize={10000} // optional, internal parsing chunk size in bytes
                          assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                          restartable={true} // optional, lets user choose to upload another file when import is complete
                          onStart={async () => {
                            const message = window.prompt("If you'd like to auto send a text message to all users in this CSV, input the message now.  Leave blank if you do not wish to message these leads.", "");
                            await this.updateTextMessage(message)
                          }}
                          processChunk={async (rows) => {
                            await Promise.allSettled(rows.map(async (row) => {
                              await this.createLead(row)
                            }));
                          }}
                          onComplete={({ file, fields }) => {
                            this.updateTextMessage(null)
                            console.log("finished import of file", file, "with fields", fields);
                          }}
                          onClose={() => {
                            this.updateTextMessage(null)
                            console.log("importer dismissed");
                          }}
                        >
                          <ImporterField name="name" label="Name" optional />
                          <ImporterField name="email" label="Email" optional />
                          <ImporterField name="phone_number" label="Phone Number" optional />
                          <ImporterField name="zip_code" label="Postal Code" optional />
                          <ImporterField name="lead_source" label="Lead Source" optional />
                        </Importer>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6">
                  <Card>
                    <CardHeader className="mt-0 h5 bg-transparent border-bottom text-uppercase card-header">
                      Lead Form
                    </CardHeader>
                    <CardBody>
                      <CardTitle className="mt-0">Fill out form to create an individual lead</CardTitle>
                      <CardText>
                        Supported columns include: Full Name, Email, Phone, Zip Code, and Lead Source
                      </CardText>
                      <AvForm className="needs-validation" onValidSubmit={this.handleSubmit}>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label htmlFor="name">Name</Label>
                              <AvField
                                name="name"
                                placeholder="Name"
                                type="text"
                                className="form-control"
                                validate={{ required: { value: false } }}
                                id="name"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="email">Email</Label>
                              <AvField
                                name="email"
                                placeholder="Email"
                                type="email"
                                className="form-control"
                                validate={{ required: { value: false } }}
                                id="email"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="phoneNumber">Phone Number</Label>
                              <AvField
                                name="phoneNumber"
                                placeholder="Phone Number"
                                type="tel"
                                className="form-control"
                                validate={{ required: { value: false } }}
                                id="phoneNumber"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="zipCode">Zip Code</Label>
                              <AvField
                                name="zipCode"
                                placeholder="Zip Code"
                                type="text"
                                className="form-control"
                                validate={{
                                  required: { value: false },
                                  pattern: { value: /^\d{5}(?:[-\s]\d{4})?$/ },
                                }}
                                id="zipCode"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="leadSource">Lead Source</Label>
                              <AvField
                                name="leadSource"
                                placeholder="[event_name/etc]"
                                type="text"
                                className="form-control"
                                validate={{
                                  required: { value: false },
                                }}
                                id="leadSource"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="textMessageBody">Send the user a text right now?</Label>
                              <AvField
                                name="textMessageBody"
                                placeholder="Text message body to send"
                                type="text"
                                className="form-control"
                                validate={{
                                  required: { value: false },
                                }}
                                id="textMessageBody"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button color="primary" type="submit">Submit form</Button>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      </>
    )
  }
});

decorate(Leads, {
  textMessage: observable,
  updateTextMessage: action,
});
