// @ts-nocheck
import { action, computed, observable } from "mobx"
import qs from "qs"
import { apiCall } from "../constants/apiCall"

import { Card } from "../models/Card"

export class cardsStore {
  @observable cards = new Map()

  @action clear() {
    this.cards.clear()
  }

  @action create = async (url: string, data: object, stripeId: string) => {
    const stripeResp = await this.submitToStripe(data, stripeId)
    const paymentResp = await fetch(`https://api.stripe.com/v1/payment_methods/${stripeResp.id}`, {
      method: 'GET',
      headers: {
        'Content-Type':   'application/x-www-form-urlencoded',
        'Authorization':  `Bearer ${process.env.REACT_APP_STRIPE_KEY}`,
      },
    })
    const paymentJson = await paymentResp.json()

    if (!['pass', 'unchecked'].includes(paymentJson.card.checks.address_postal_code_check)) {
      console.log("FAILED ZIP")
      return 'failed_zip_check'
    } else if (!['pass', 'unchecked'].includes(paymentJson.card.checks.cvc_check)) {
      console.log("FAILED CVC")
      return 'failed_cvc_check'
    }

    const cardData = {
      card: {
        stripe_id: paymentJson.id,
        address_city: paymentJson.billing_details.address.city,
        address_country: paymentJson.billing_details.address.country,
        address_line1: paymentJson.billing_details.address.line1,
        address_line2: paymentJson.billing_details.address.line2,
        address_zip: paymentJson.billing_details.address.postal_code,
        address_state: paymentJson.billing_details.address.state,
        name: paymentJson.billing_details.name,
        address_line1_check: paymentJson.card.checks.address_line1_check,
        address_zip_check: paymentJson.card.checks.address_postal_code_check,
        cvc_check: paymentJson.card.checks.cvc_check,
        brand: paymentJson.card.brand,
        country: paymentJson.card.country,
        exp_month: paymentJson.card.exp_month,
        exp_year: paymentJson.card.exp_year,
        fingerprint: paymentJson.card.fingerprint,
        funding: paymentJson.card.funding,
        last4: paymentJson.card.last4,
        metadata: paymentJson.metadata,
      }
    }

    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'POST',
      body: JSON.stringify(cardData),
    })

    if (response.data?.type === 'card') {
      const card = new Card(response.data)
      this.cards.set(card.id, card)
      return card
    }

    return 'error'
  }

  @action update = async(id: string, data: object) => {
    const card = this.cards.get(id)

    if (!card) {
      return 'error'
    }

    // clients/:client_id/addresses/:id OR /addresses/:id
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/cards/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      card.updateFromJson(response.data)
      return card
    }
  }

  @action destroy = async(id: string) => {
    const card = this.cards.get(id)

    if (!card) {
      // console.log('err')
      return 'error'
    }

    await apiCall(`${process.env.REACT_APP_API_URL}/cards/${id}`, {
      method: 'DELETE',
    })

    this.cards.delete(id)
    return true
  }

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.cards.clear()
      for (const cardData of response.data) {
        this.cards.set(cardData.id, new Card(cardData))
      }
      return this.cards
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/cards/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const card = new Card(response.data)
      this.cards.set(response.data?.id, card)
      return card
    }
  }

  @action submitToStripe = async (data: object) => {
    // https://api.stripe.com/v1/payment_methods/pm_123456789/attach
    const params = {
      type: 'card',
      billing_details: {
        address: {
          postal_code: data.card.postalCode,
        },
        email: data.card.email,
        name: data.card.name,
        phone: data.card.phone,
      },
      card: {
        number:       data.card.number,
        exp_month:    data.card.expMonth,
        exp_year:     data.card.expYear,
        cvc:          data.card.cvc,
      }
    }

    const response = await fetch('https://api.stripe.com/v1/payment_methods', {
      method: 'POST',
      headers: {
        'Content-Type':   'application/x-www-form-urlencoded',
        'Authorization':  `Bearer ${process.env.REACT_APP_STRIPE_KEY}`,
      },
      body: qs.stringify(params),
    }),
    payload = await response.json()

    return payload
  }

  @computed get default() {
    return Array.from(this.cards.values()).find(card => card.isDefault)
  }

  // @action newSubmitToStripe = async (data: object, stripeId: string) => {
  //   // https://api.stripe.com/v1/customers/cus_HYlmvyHWpWI2ei/sources

  //   const params = {
  //     source: {
  //       object: 'card',
  //       number:       data.card.number,
  //       exp_month:    data.card.expMonth,
  //       exp_year:     data.card.expYear,
  //       cvc:          data.card.cvc,
  //       name:         data.card.name,
  //       address_zip:  data.card.postalCode,
  //     }
  //   }

  //   const response = await fetch(`https://api.stripe.com/v1/customers/${stripeId}/sources`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type':   'application/x-www-form-urlencoded',
  //       'Authorization':  `Bearer ${process.env.REACT_APP_STRIPE_KEY}`,
  //     },
  //     body: qs.stringify(params),
  //   }),
  //   payload = await response.json()

  //   console.log(payload)
  //   return payload
  // }

}
