import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { titleize } from '../../helpers/titleize';
import { VehiclesTable } from '../../components/Tables/VehiclesTable';

export const P2PVehiclesIndex = observer(class P2PVehiclesIndex extends Component {
  filter

  get title() {
    return titleize(this.filter || 'All');
  }

  render() {
    return (
      <VehiclesTable
        filter={this.filter}
        dataUrl="/p2p"
        baseTitle="P2P Vehicles"
        title={this.title}
        addEnabled={true}
      />
    );
  }
});

decorate(P2PVehiclesIndex, {
  filter: observable,
  title: computed,
});
