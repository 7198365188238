import React, { useEffect } from 'react'
import { action, decorate, observable } from 'mobx'
// Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Card, Col, Container, Row } from 'reactstrap'
import { messagesStore } from '../../stores/messagesStore'
import { Trip } from '../../models/Trip'
import { Message } from '../../models/Message'
import RelativeTime from 'react-relative-time'

export const NewChat = ({ booking }: { booking: Trip }) => {
  const [messages, setMessages] = React.useState<Message[]>([])

  useEffect(() => {
    const messageStore = new messagesStore()
    messageStore.fetchAll(`bookings/${booking.id}/messages`).then(() => {
      setMessages(messageStore.messages)
    })
  }, [])

  return (
    <>
      <div className="">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col xs="9">
                          <h5 className="font-size-15 mb-1">
                            New Chat Feature
                          </h5>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <div className="chat-conversation p-3">
                        <ul className="list-unstyled">
                          <PerfectScrollbar style={{ height: '470px' }}>
                            {messages.map((message) => (
                              <li
                                key={`test_k${message.id}`}
                                className={
                                  message.userId === booking.userId
                                    ? 'right'
                                    : ''
                                }
                              >
                                <div className="conversation-list">
                                  <div className="ctext-wrap">
                                    <div className="conversation-name">
                                      {message.user?.name}{' '}
                                      <span
                                        style={{
                                          fontWeight: 400,
                                          fontSize: 10,
                                          color: 'gray',
                                        }}
                                      >
                                        {message.userId === booking.userId
                                          ? 'Client'
                                          : 'Driver'}
                                      </span>
                                    </div>
                                    <p>{message.body}</p>
                                    <p className="chat-time mb-0">
                                      <i className="bx bx-time-five align-middle mr-1" />{' '}
                                      <RelativeTime
                                        value={
                                          message.createdAtObj
                                            ? message.createdAtObj.toJSDate()
                                            : new Date()
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
