// @ts-nocheck
import { action, computed, observable } from "mobx"

export class InsurancePolicy {
    /**
     * unique id of this InsurancePolicy, immutable.
     */
    id = null

    @observable userId
    @observable policyNumber
    @observable provider
    @observable endDate
    @observable createdAt
    @observable updatedAt

    // @observable email = ""

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:           this.id,
        userId:       this.userId,
        policyNumber: this.policyNumber,
        provider:     this.provider,
        endDate:      this.endDate,
        createdAt:    this.createdAt,
        updatedAt:    this.updatedAt,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.userId       = data.attributes.userId || data.attributes.user_id
      this.policyNumber = data.attributes.policyNumber || data.attributes.policy_number
      this.provider     = data.attributes.provider
      this.endDate      = data.attributes.endDate || data.attributes.end_date
      this.createdAt    = data.attributes.createdAt || data.attributes.created_at
      this.updatedAt    = data.attributes.updatedAt || data.attributes.updated_at

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
