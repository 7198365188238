import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom'
import strftime from 'strftime';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { Subaccount } from '../../models/Subaccount';
import { ConfirmationModal } from "../Modals/ConfirmationModal";
export const SubaccountsTable = observer(class SubaccountsTable extends Component {
  filter

  _currentSubaccounts
  _removeSubaccountsModalOpen = false

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  nameFormatter = (cell, row) => {
    return cell ? <>
      <NavLink style={{display: "inline"}} strict={+true} to={`/client/${cell.id}`} key={`subaccount-${cell.id}`}>{ cell.name }</NavLink>
      <ClipboardLink text={ cell.id } />
    </> : <></>
  }

  createdAtFormatter = (cell, row) => {
    const time = new Date(cell);

    return (
      <div>
        { strftime('%B %d, %Y', time) }
        <br />
        { strftime('%l:%M%P', time) }
        <br />
        { strftime('(%Z)', time) }
      </div>
    );
  }

  statusFormatter = (cell, _row) => {
    switch (cell) {
      case 'active':
        return <Badge className="badge-soft-success mr-1">{ cell.toUpperCase() }</Badge>;
      default:
        return <Badge color="danger" className="mr-1">{ cell.toUpperCase() }</Badge>;
    }
  }

  _toggleRemoveSubaccountsModal = (subaccounts) => {
    this._removeSubaccountsModalOpen = !this._removeSubaccountsModalOpen
    this._currentSubaccounts = subaccounts
  }

  _removeSubaccounts = async () => {
    await Promise.allSettled(this._currentSubaccounts.map(async (subaccount) => {
      await client.delete(`/subaccounts/${subaccount.id}`);
    }))

    this._tableRef.current.fetchData()
    runInAction(() => this._removeSubaccountsModalOpen = false)
  }

  dataColumns = [
    {
      dataField: 'child',
      name: 'Name',
      priority: 1,
      formatter: this.nameFormatter
    },
    {
      dataField: 'relationship',
      name: 'Relationship',
      priority: 1,
    },
    {
      dataField: 'status',
      name: 'Status',
      priority: 1,
      formatter: this.statusFormatter,
    },
    {
      dataField: 'createdAt',
      name: 'Created',
      priority: 1,
      formatter: this.createdAtFormatter,
    },
  ]

  render() {
    return (
      <>
        <ConfirmationModal
          isOpen={ this._removeSubaccountsModalOpen }
          toggle={ this._toggleRemoveSubaccountsModal }
          header={ `Remove Subaccount(s)?` }
          body={ "Are you sure you want to remove this/these subaccount(s)?" }
          confirm={ this._removeSubaccounts }
          confirmButtonText={ 'Remove' }
          closeButtonText={ 'Cancel' }
        />

        <MyTable
          model={Subaccount}
          dataUrl={this.props.dataUrl}
          dataColumns={this.dataColumns}
          breadcrumbTitle={this.props.filter || 'All'}
          breadcrumbBaseTitle={this.props.baseTitle || 'Subaccounts'}

          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}

          embedded

          defaultLimit={this.props.defaultLimit}
          filter={this.props.filter}

          multiActions={{
            'Remove': (rows) => {
              this._toggleRemoveSubaccountsModal(rows)
            }
          }}

          ref={this._tableRef}
        />
      </>
    );
  }
});

decorate(SubaccountsTable, {
  _removeSubaccountsModalOpen: observable,
  _currentSubaccounts: observable,
  _toggleRemoveSubaccountsModal: action,
})
