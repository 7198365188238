import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation';
import { decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button, Col, CustomInput, FormGroup, Label, Modal, Row } from 'reactstrap';

export const VehicleModal = observer(class VehicleModal extends Component {
  constructor(props) {
    super(props);

    this.loadAssociationData();
  }

  loadAssociationData = async () => {
    if (this.props.client) {
      await this.props.client.fetchAddresses();
      await this.props.client.fetchInsurancePolicies();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.vehicle}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="year">Year</Label>
                  <AvField
                    name="year"
                    placeholder="Year"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    id="year"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="make">Make</Label>
                  <AvField
                    name="make"
                    placeholder="Make (BMW, Mercedes, etc)"
                    type="text"
                    className="form-control"
                    id="make"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="model">Model</Label>
                  <AvField
                    name="model"
                    placeholder="Model (X1, S500, etc)"
                    type="text"
                    className="form-control"
                    id="model"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="color">Color</Label>
                  <AvField type="select" name="color" id="color">
                    <option />
                    <option>black</option>
                    <option>white</option>
                    <option>silver</option>
                    <option>blue</option>
                    <option>grey</option>
                    <option>red</option>
                    <option>green</option>
                    <option>orange</option>
                    <option>yellow</option>
                    <option>purple</option>
                    <option>pink</option>
                    <option>other</option>
                  </AvField>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="vin">VIN</Label>
                  <AvField
                    name="vin"
                    placeholder="VIN Number"
                    type="text"
                    className="form-control"
                    id="vin"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="licensePlateNumber">License Plate Number</Label>
                  <AvField
                    name="licensePlateNumber"
                    placeholder="License Plate Number"
                    type="text"
                    className="form-control"
                    id="licensePlateNumber"
                  />
                </FormGroup>

                <FormGroup>
                  <AvInput
                    type="checkbox"
                    label="Manual Transmission"
                    id="manualTransmission"
                    name="manualTransmission"
                    tag={CustomInput}
                  />
                </FormGroup>

                <FormGroup>
                  <AvInput
                    type="checkbox"
                    label="Available for P2P"
                    id="availableForP2P"
                    name="availableForP2P"
                    tag={CustomInput}
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="hourlyRateFormatted">Hourly Rental Rate $</Label>
                  <AvField
                    name="hourlyRateFormatted"
                    placeholder="Hourly Rental Rate $"
                    validate={{ required: { value: false }, number: true }}
                    type="text"
                    className="form-control"
                    id="hourlyRateFormatted"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="dailyRateFormatted">Daily Rental Rate $</Label>
                  <AvField
                    name="dailyRateFormatted"
                    placeholder="Daily Rental Rate $"
                    validate={{ required: { value: false }, number: true }}
                    type="text"
                    className="form-control"
                    id="dailyRateFormatted"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="fuelType">Fuel Type</Label>
                  <AvField type="select" name="fuelType" id="fuelType">
                    <option />
                    <option>gasoline</option>
                    <option>diesel</option>
                    <option>biodiesel</option>
                    <option>ethanol</option>
                    <option>electric</option>
                    <option>compressed_gas</option>
                  </AvField>
                </FormGroup>

                <FormGroup>
                  <AvField type="select" name="addressId" label="Pickup Address">
                    <option />

                    { this.props.client?.addresses.map((address) => (
                      <option key={address.id} value={address.id}>
                        {
                          [address.label && `[${address.label}]`, address.line1, address.city, address.state, address.isDefault ? '(default)' : null].filter((el) => el != null).join(', ')
                        }
                      </option>
                    ))}
                  </AvField>
                </FormGroup>

                <FormGroup>
                  <AvField type="select" name="insurancePolicyId" label="Insurance Policy">
                    <option />

                    { this.props.client?.insurancePolicies.map((policy) => {
                      return (
                      <option key={policy.id} value={policy.id}>
                        { policy.provider } - { policy.policyNumber } - { policy.endDate }
                      </option>
                    )})}
                  </AvField>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(VehicleModal, {
});
