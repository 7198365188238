import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import strftime from 'strftime';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { formatters } from '../../helpers/Formatters';
import { Vehicle } from '../../models/Vehicle';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { VehicleModal } from '../Modals/VehicleModal';

export const VehiclesTable = observer(class VehiclesTable extends Component {
  filter

  _selectedVehicle

  _addModalOpen = false

  _selectedVehicles
  _confirmationModalOpen = false

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  idFormatter = (cell, row) => (
    <><a
      href={`#${row.id}`}
      key={row.id}
      onClick={
        () => {
          runInAction(() => {
            this._addModalOpen = true;
            this._selectedVehicle = row;
          });
        }
      }
    >
      {cell.split('-')[0]}
    </a><ClipboardLink text={cell} /></>
  )

  createdAtFormatter = (cell, row) => {
    const time = new Date(cell);

    return (
      <div>
        { strftime('%B %d, %Y', time) }
        <br />
        { strftime('%l:%M%P', time) }
        <br />
        { strftime('(%Z)', time) }
      </div>
    );
  }

  transFormatter = (cell, _row) => {
    return cell ? 'Manual' : 'Automatic'
  }

  insuranceFormatter = (cell, _row) => {
    if (cell) {
      return cell.id.split('-')[0]
    } else {
      return ''
    }
  }

  addressFormatter = (cell, _row) => {
    if (cell) {
      return cell.id.split('-')[0]
    } else {
      return ''
    }
  }


  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      formatter: this.idFormatter,
      priority: 1,
    },
    {
      dataField: 'year',
      name: 'Year',
      sort: true,
      priority: 1,
    },
    {
      dataField: 'make',
      name: 'Make',
      sort: true,
      priority: 1,
    },
    {
      dataField: 'model',
      name: 'Model',
      sort: true,
      priority: 1,
    },
    {
      dataField: 'color',
      name: 'Color',
      sort: true,
      priority: 2,
    },
    {
      dataField: 'licensePlateNumber',
      name: 'License Plate',
      priority: 2,
    },
    {
      dataField: 'manualTransmission',
      name: 'Transmission',
      formatter: this.transFormatter,
      priority: 2,
    },
    {
      dataField: 'availableForP2P',
      name: 'P2P',
      formatter: formatters.boolFormatter,
      priority: 2,
    },
    {
      dataField: 'hourlyRate',
      name: 'Hourly Rate',
      formatter: formatters.currencyFormatter,
      sort: true,
      priority: 3,
    },
    {
      dataField: 'dailyRate',
      name: 'Daily Rate',
      formatter: formatters.currencyFormatter,
      sort: true,
      priority: 3,
    },
    {
      dataField: 'classification',
      name: 'Category',
      sort: true,
      priority: 2,
    },
    {
      dataField: 'insurancePolicy',
      name: 'Insurance',
      formatter: this.insuranceFormatter,
      priority: 3,
    },
    {
      dataField: 'addresss',
      name: 'Address',
      formatter: this.addressFormatter,
      priority: 3,
    },
  ]

  _submitHandler = async (_e, values) => {
    const data = {
      vehicle: {
        year: values.year,
        make: values.make,
        model: values.model,
        color: values.color,
        vin: values.vin,
        license_plate_number: values.licensePlateNumber,
        manual_transmission: values.manualTransmission,
        available_for_p2p: values.availableForP2P,
        hourly_rate_cents: values.hourlyRateFormatted && values.hourlyRateFormatted * 100,
        daily_rate_cents: values.dailyRateFormatted && values.dailyRateFormatted * 100,
        insurance_policy_id: values.insurancePolicyId,
        address_id: values.addressId,
        // classification: values.classification,
        fuel_type: values.fuelType,
      },
    };

    Object.keys(data).forEach((key) => (data[key] === null) && delete data[key]);

    if (this._selectedVehicle) {
      client.makeApiCall(
        'put', `${this.props.dataUrl}/${this._selectedVehicle.id}`, data,
        'Vehicle updated successfully',
        'Unable to update vehicle',
        (response) => {
          this._selectedVehicle.updateFromJson(response.data)
          this._closeModals()
        }
      )
    } else {
      client.makeApiCall(
        'post', this.props.dataUrl, data,
        'Vehicle created successfully',
        'Unable to create vehicle',
        this._postSubmitCallback
      )
    }
  }

  _postSubmitCallback = () => {
    this._tableRef.current.fetchData()
    this._closeModals()
  }

  _closeModals = () => {
    this._addModalOpen = false
    this._confirmationModalOpen = false
  }

  _removeVehicles = async () => {
    await Promise.allSettled(this._selectedVehicles.map(async (vehicle) => {
      await client.delete(`/vehicles/${vehicle.id}/`);
    }))
    window.location.reload()
  }

  _toggleConfirmationModal = (vehicles) => {
    if (vehicles) { this._selectedVehicles = vehicles }
    this._confirmationModalOpen = !this._confirmationModalOpen
  }

  render() {
    return (
      <>
        <ConfirmationModal
          isOpen={ this._confirmationModalOpen }
          toggle={ this._toggleConfirmationModal }
          header={ 'Remove Vehicles?' }
          body={ 'Are you sure you want to remove selected vehicles?' }
          confirm={ this._removeVehicles }
          confirmButtonText={ 'Confirm' }
          closeButtonText={ 'Cancel' }
        />

        { this.props.client && <VehicleModal
            isOpen={this._addModalOpen}
            toggle={() => { runInAction(() => {
              this._addModalOpen = !this._addModalOpen;
              this._selectedVehicle = null
            }); }}
            title={this._selectedVehicle ? "Update Vehicle" : "Add New Vehicle"}
            submitHandler={this._submitHandler}
            vehicle={this._selectedVehicle}
            client={this.props.client}
          /> }

        <MyTable
          model={Vehicle}
          dataUrl={this.props.dataUrl}
          dataColumns={this.dataColumns}
          breadcrumbTitle={this.props.filter || 'All'}
          breadcrumbBaseTitle={this.props.baseTitle || 'Vehicles'}
          multiActions={{
            'Remove Vehicle': (rows) => {
              this._toggleConfirmationModal(rows)
            }
          }}
          singleActions={this.props.addEnabled && {
            'Add +': () => {
              runInAction(() => {
                this._addModalOpen = !this._addModalOpen;
              });
            }
          }}

          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}

          embedded={this.props.embedded}
          searchable
          defaultLimit={this.props.defaultLimit}
          filter={this.props.filter}
          ref={this._tableRef}
        />
      </>
    );
  }
});

decorate(VehiclesTable, {
  _addModalOpen: observable,
  _submitHandler: action,
  _selectedVehicle: observable,
  _toggleConfirmationModal: action,
  _selectedVehicles: observable,
  _confirmationModalOpen: observable,
  _closeModals: action,
});
