import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { client } from '../../helpers/client';
import { formatters } from '../../helpers/Formatters';
import { PromoCodeModal } from '../Modals/PromoCodeModal';
import { MyTable } from '../../components/MyTable/MyTable';
import { PromoCode } from '../../models/PromoCode';
import { Badge } from 'reactstrap';

export const PromoCodesTable = observer(class PromoCodesTable extends Component {
  filter

  _addModalOpen = false

  _selectedPromoCode

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  idFormatter = (cell, row) => (
    <><a
      href="#"
      key={row.id}
      onClick={
        () => {
          runInAction(() => {
            this._selectedPromoCode = row

            if (row.cashValue.cents) {
              this._selectedPromoCode.cashValueDollar = row.cashValue?.cents / 100;
            }

            if (row.monthlyRate.cents) {
              this._selectedPromoCode.monthlyRateDollars = row.monthlyRate?.cents / 100;
            }

            if (row.yearlyRate.cents) {
              this._selectedPromoCode.yearlyRateDollars = row.yearlyRate?.cents / 100;
            }

            this._addModalOpen = true;
          });
        }
      }
    >
      {cell.split('-')[0]}
    </a><ClipboardLink text={cell} /></>
  )

  usesFormatter = (cell, row) => {
    if (cell > 0) {
      return `${cell} / ${row.maxUses || 'unltd'}`
    } else {
      return 'n/a'
    }
  }

  adminFormatter = (cell, _row) => {
    return cell?.name || 'Unknown/Removed'
  }

  valueFormatter = (_cell, row) => {
    if (row.ambassador) {
      return <Badge className="mr-1 badge-soft-success"><i className='bx bx-crown mr-1' /> Ambassador</Badge>;
    } else if (row.babyAmbassador) {
      return <Badge className="mr-1 badge-soft-info"><i className='bx bx-crown mr-1' /> Baby Ambassador</Badge>;
    } else if (row.cashValue.cents > 0) {
      return <div>{row.cashValue.pretty}</div>;
    } else if (row.timeValueMinutes > 0) {
      return <div>{row.timeValueMinutes} minutes</div>
    }
  }

  moneyFormatter = (cell, row) => {
    if (row.ambassador) {
      return <></>
    } else if (row.babyAmbassador) {
      return <></>
    } else if (cell.cents > 0) {
      return <div>{cell.pretty}</div>;
    }
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'code',
      name: 'Code',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'totalUsesCount',
      name: 'Uses',
      priority: 1,
      formatter: this.usesFormatter,
      sort: true,
    },
    {
      dataField: 'admin',
      name: 'Created By',
      priority: 1,
      formatter: this.adminFormatter,
    },
    {
      dataField: 'cashValue',
      name: 'Value',
      priority: 1,
      formatter: this.valueFormatter,
    },
    {
      dataField: 'monthlyRate',
      name: 'Membership Monthly',
      priority: 1,
      formatter: this.moneyFormatter,
    },
    {
      dataField: 'yearlyRate',
      name: 'Membership Yearly',
      priority: 1,
      formatter: this.moneyFormatter,
    },
    {
      dataField: 'validAtLocalizedString',
      name: 'Valid At',
      priority: 1,
      sort: true,
      formatter: this.localizedDateFormatter
    },
    {
      dataField: 'expiresAtLocalizedString',
      name: 'Expires At',
      priority: 1,
      sort: true,
      formatter: this.localizedDateFormatter
    },
  ]

  formatTimeParamsToLocal(timeString) {
    if (!timeString) {
      return null
    }

    const hourOffset = (new Date(timeString).getTimezoneOffset() / 60)
    let hourPadded = "0" + hourOffset.toString()

    return `${timeString} -${hourPadded.substr(hourPadded.length-2)}00`
  }

  modalSubmitHandler = async (e, values) => {
    const data = {}

    if (values.code)    { data.code = values.code }
    if (values.maxUses) { data.max_uses = values.maxUses }
    if (values.timeValueMinutes) { data.time_value_minutes = values.timeValueMinutes }
    if (values.cashValueDollar) {
      data.cash_value_cents = values.cashValueDollar * 100
    }
    if (values.validAtLocalizedString) { data.valid_at = this.formatTimeParamsToLocal(values.validAtLocalizedString) }
    if (values.expiresAtLocalizedString) { data.expires_at = this.formatTimeParamsToLocal(values.expiresAtLocalizedString) }
    if (values.ambassador) { data.ambassador = true }
    if (values.babyAmbassador) { data.baby_ambassador = true }

    data.monthly_rate_cents = values.monthlyRateDollars ? values.monthlyRateDollars * 100 : 0
    data.yearly_rate_cents = values.yearlyRateDollars ? values.yearlyRateDollars * 100 : 0

    if (this._selectedPromoCode) {
      await client.makeApiCall(
        'put', `/promo_codes/${this._selectedPromoCode.id}`, { promo_code: data },
        'Promo code updated successfully',
        'Unable to update promo code',
        (response) => {
          this._selectedPromoCode.updateFromJson(response.data)
          this._closeModals()
        }
      )
    } else {
      await client.makeApiCall(
        'post', this.props.dataUrl, { promo_code: data },
        'Promo code created successfully',
        'Unable to create promo code',
        this._postSubmitCallback
      )
    }
  }

  _postSubmitCallback = () => {
    this._tableRef.current.fetchData()
    this._closeModals()
  }

  _closeModals = () => {
    this._addModalOpen = false
  }

  render() {
    return (
      <>
        <PromoCodeModal
          promoCode={this._selectedPromoCode}
          isOpen={this._addModalOpen}
          toggle={() => { runInAction(() => { this._addModalOpen = !this._addModalOpen; this._selectedPromoCode = null; }); }}
          title={`${this._selectedPromoCode ? 'Edit' : 'Add New'} Promo Code`}
          submitHandler={this.modalSubmitHandler}
        />
        <MyTable
          model={PromoCode}
          dataUrl={this.props.dataUrl}
          dataColumns={this.dataColumns}
          title={'Promo Codes'}
          breadcrumbTitle={this.props.filter || 'All'}
          breadcrumbBaseTitle={'Promo Codes'}

          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}

          embedded={this.props.embedded}
          searchable
          defaultLimit={this.props.defaultLimit}
          filter={this.props.filter}

          singleActions={{
            'Add +': () => runInAction(() => { this._addModalOpen = !this._addModalOpen; }),
          }}

          ref={this._tableRef}
        />
      </>
    );
  }
});

decorate(PromoCodesTable, {
  _addModalOpen: observable,
  addSubmitHandler: action,
  filter: observable,
  _selectedPromoCode: observable,
  _closeModals: action,
});
