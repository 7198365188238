// @ts-nocheck
import humps from 'humps'
import { action, observable } from 'mobx'
import { apiCall } from '../constants/apiCall'
import { Message } from '../models/Message'

export class messagesStore {
  @observable messages: Message[] = []

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.messages = []
      for (const messageData of response.data) {
        this.messages.push(
          new Message(humps.camelizeKeys(messageData.attributes)),
        )
      }
      return this.messages
    }
  }
}
