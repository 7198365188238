// @ts-nocheck
import { action, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { Earning } from "../models/Earning"

export class earningsStore {
  @observable earnings = new Map()

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.earnings.clear()
      for (const earningData of response.data) {
        this.earnings.set(earningData.id, new Earning(earningData))
      }
      return this.earnings
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/earnings/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const earning = new Earning(response.data)
      this.earnings.set(response.data?.id, earning)
      return earning
    }
  }
}
