import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { Rating } from '../../models/Rating';
import { RatingModal } from '../Modals/RatingModal';
import { client } from '../../helpers/client';
import { User } from '../../helpers/CurrentUser';

export const RatingsTable = observer(class RatingsTable extends Component {
  filter

  _idFormatter = (cell, row) => (
    <><a
      href="#"
      key={row.id}
      onClick={
        () => {
          runInAction(() => {
            this._selectedRating = row
            this._ratingModalOpen = true
          });
        }
      }
    >
      {cell.split('-')[0]}
    </a><ClipboardLink text={cell} /></>
  )

  _rateableFormatter = (cell, row) => {
    if (cell.type === 'Driver') {
      return (
        <NavLink strict={+true} to={`/driver/${cell.id}`}>{cell.name || 'unknown'}</NavLink>
      );
    }
    return (
      <NavLink strict={+true} to={`/client/${cell.id}`}>{cell.name || 'unknown'}</NavLink>
    );
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this._idFormatter,
    },
    {
      dataField: 'rater',
      name: 'Given By',
      priority: 1,
      formatter: this._rateableFormatter,
    },
    {
      dataField: 'ratee',
      name: 'Given To',
      priority: 1,
      formatter: this._rateableFormatter,
    },
    {
      dataField: 'value',
      name: 'Rating',
      priority: 1,
      sort: true,
    },
  ]

  _toggleModal = () => this._ratingModalOpen = !this._ratingModalOpen;

  _submitRatingHandler = async (_e, values) => {
    const data = { rating: { value: values.value } }

    if (!User.canEditRatings) {
      return
    }

    client.makeApiCall(
      'put', `/ratings/${this._selectedRating.id}`, data,
      'Rating updated successfully',
      'Unable to update rating',
      (response) => {
        this._selectedRating.updateFromJson(response.data)
        this._ratingModalOpen = false
        this._selectedRating = null
      }
    )
  }

  render() {
    return (
      <>
        <RatingModal
          isOpen={this._ratingModalOpen}
          toggle={this._toggleModal}
          title={"Edit Rating"}
          submitHandler={this._submitRatingHandler}
          rating={this._selectedRating}
        />
        <MyTable
          model={Rating}
          dataUrl={this.props.dataUrl}
          breadcrumbBaseTitle="Ratings"
          dataColumns={this.dataColumns}
          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}
          embedded
        />
      </>
    );
  }
});

decorate(RatingsTable, {
  _toggleModal: action,
  _ratingModalOpen: observable,
  _selectedRating: observable,
  _submitRatingHandler: action,
});
