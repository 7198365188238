// @ts-nocheck
import { action, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { Expense } from "../models/Expense"

export class expensesStore {
  @observable expenses = new Map()

  @action pushData = (expenseData: object) => {
    this.expenses.set(expenseData.id, new Expense(expenseData))
  }

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.expenses.clear()
      for (const expenseData of response.data) {
        this.expenses.set(expenseData.id, new Expense(expenseData))
      }
      return this.expenses
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/expenses/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const expense = new Expense(response.data)
      this.expenses.set(response.data?.id, expense)
      return expense
    }
  }
}
