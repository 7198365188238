// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Billing {
    /**
     * unique id of this Billing, immutable.
     */
    id = null

    @observable label
    @observable status
    @observable refunded
    @observable amount
    @observable tax
    @observable notes
    @observable admin
    @observable amountRefunded
    @observable createdAt
    @observable updatedAt
    @observable minutesApplied
    @observable creditsApplied
    @observable client
    @observable billableType
    @observable billableId
    @observable bookingId
    @observable adminFee
    @observable processingFee

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get editable() {
      return ['pending', 'failed'].includes(this.status)
    }

    @computed get asJson() {
      return {
        id:             this.id,
        label:          this.label,
        status:         this.status,
        refunded:       this.refunded,
        amount:         this.amount,
        tax:            this.tax,
        notes:          this.notes,
        admin:          this.admin,
        client:         this.client,
        billableType:   this.billableType,
        billableId:     this.billableId,
        bookingId:      this.bookingId,
        amountRefunded: this.amountRefunded,
        createdAt:      this.createdAt,
        updatedAt:      this.updatedAt,
        minutesApplied: this.minutesApplied,
        creditsApplied: this.creditsApplied,
        adminFee:       this.adminFee,
        processingFee:  this.processingFee
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.label          = data.attributes.label
      this.status         = data.attributes.status
      this.refunded       = data.attributes.refunded
      this.amount         = data.attributes.amount
      this.tax            = data.attributes.tax
      this.notes          = data.attributes.notes
      this.admin          = data.attributes.admin
      this.client         = data.attributes.client
      this.billableType   = data.attributes.billableType
      this.billableId     = data.attributes.billableId
      this.bookingId      = data.attributes.bookingId
      this.amountRefunded = data.attributes.amountRefunded
      this.createdAt      = data.attributes.createdAt
      this.updatedAt      = data.attributes.updatedAt
      this.minutesApplied = data.attributes.minutesApplied
      this.creditsApplied = data.attributes.creditsApplied
      this.adminFee       = data.attributes.adminFee
      this.processingFee  = data.attributes.processingFee

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
