import humanizeString from 'humanize-string';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ClientsTable } from './ClientsTable';

export const ClientsIndex = observer(class ClientsIndex extends Component {
  filter

  constructor(props) {
    super(props);

    if (!this.props.embedded) {
      switch (this.props.location.pathname) {
        case '/clients':
          this.filter = 'non_lead';
          break;
        case '/clients/awaiting-call':
          this.filter = 'awaiting-call';
          break;
        case '/clients/leads':
          this.filter = 'lead';
          break;
        default:
          this.filter = this.props.location.pathname.replace('/clients/', '');
      }
    }
  }

  render() {
    return (
      <ClientsTable
        filter={this.filter}
        dataUrl="/clients"
        baseTitle="Clients"
        title={humanizeString(this.filter || 'All')}
        addEnabled
      />
    );
  }
});

decorate(ClientsIndex, {
  filter: observable,
});
