import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ZipCodesTable } from '../../components/Tables/ZipCodesTable';

export const ZipCodesIndex = observer(class ZipCodesIndex extends Component {
  filter

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <ZipCodesTable
          dataUrl="/zip_codes"
          baseTitle="ZipCodes"
        title={this.filter || 'All'}
        addEnabled
      />
    )
  }
});

decorate(ZipCodesIndex, {
  filter: observable,
});
