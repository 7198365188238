// @ts-nocheck
import { action, computed, observable } from "mobx"

export class AdminNote {
    /**
     * unique id of this AdminNote, immutable.
     */
    id = null

    @observable createdAt
    @observable body
    @observable authorName

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:         this.id,
        createdAt:  this.createdAt,
        body:       this.body,
        authorName: this.authorName,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.createdAt  = data.attributes.createdAt
      this.body       = data.attributes.body
      this.authorName = data.attributes.authorName

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
