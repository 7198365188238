import { AvForm, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Col, FormGroup, Modal, Row } from 'reactstrap';

export const CancelSubscriptionModal = observer(class CancelSubscriptionModal extends Component {
  render() {
    return(
      <Modal
        isOpen={ this.props.isOpen }
        toggle={ this.props.toggle }
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.header }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          Are you sure you want to cancel this subscription?  You can choose to:<br/><br/>

          <AvForm className="needs-validation" onValidSubmit={this.props.confirm}>
            <Row>
              <Col>
                <FormGroup>
                  <AvRadioGroup name="cancelType" required errorMessage="Pick one!">
                    <AvRadio label="Allow client to ride out the month/year they paid for (no refund)." value="continueNoRefund" />
                    <AvRadio label="Cancel subscription now (no more booking), but DO NOT refund the rest of the billing cycle." value="endNowNoRefund" />
                    <AvRadio label="Cancel subscription now (no more bookings), but refund the remaining portion of their billing cycle." value="endNowWithRefund" />
                  </AvRadioGroup>
                </FormGroup>
              </Col>
            </Row>

            <div className="modal-footer">
              <button
                type="button"
                onClick={ this.props.toggle }
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                { this.props.closeButtonText || 'Cancel' }
              </button>

              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                { this.props.confirmButtonText || 'Submit' }
              </button>
            </div>
          </AvForm>
        </div>


      </Modal>
    )
  }
})

