import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import RelativeTime from 'react-relative-time';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { client } from '../../helpers/client';
import { formatters } from '../../helpers/Formatters';
import { BookingRestriction } from '../../models/BookingRestriction';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { EventModal } from '../Modals/EventModal';

export const EventsTable = observer(class EventsTable extends Component {
  selectedEvent

  _addModalOpen = false
  _confirmApproveModalOpen = false
  _selectedEvents
  _removeEventsModalOpen = false

  componentDidMount() {
    this._tableRef = React.createRef();
  }

  idFormatter = (cell, row) => {
    return <><a
      href={`#${row.id}`}
      key={row.id}
      onClick={
        () => {
          runInAction(() => {
            this._addModalOpen = true;
            this.selectedEvent = row;
          });
        }
      }
    >
      {cell.split('-')[0]}
    </a><ClipboardLink text={cell} /></>
  }

  createdAtFormatter = (cell, row) => {
    if (cell) {
      return (<RelativeTime value={cell} />);
    }
  }

  addressFormatter = (cell, _row) => {
  	if (cell) {
  		return (
	      <div>
	        { `${cell.label}` }
	        <br />
	        { `${cell.line1}` }
	        <br />
	        { `${cell.city}, ${cell.state} ${cell.postalCode}` }
	      </div>
	    );
  	}
  }

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
      sort: true,
    },
    {
      dataField: 'name',
      name: 'Name',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'address',
      name: 'Address',
      priority: 1,
      formatter: this.addressFormatter,
    },
    {
      dataField: 'startTime',
      name: 'Starts',
      priority: 1,
      formatter: formatters.createdAtFormatter,
      sort: true,
    },
    {
      dataField: 'endTime',
      name: 'Ends',
      priority: 1,
      formatter: formatters.createdAtFormatter,
      sort: true,
    },
    {
      dataField: 'dangerRadiusMiles',
      name: 'Danger Miles',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'errorRadiusMiles',
      name: 'Error Miles',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'dangerMessage',
      name: 'Danger Message',
      priority: 1,
    },
    {
      dataField: 'errorMessage',
      name: 'Error Message',
      priority: 1,
    },
    {
      dataField: 'createdAt',
      name: 'Created At',
      priority: 1,
      formatter: formatters.createdAtFormatter,
      sort: true,
    },
  ]

  formatTimeParamsToLocal(timeString) {
    if (!timeString) {
      return null
    }

    const hourOffset = (new Date(timeString).getTimezoneOffset() / 60)
    let hourPadded = "0" + hourOffset.toString()

    return `${timeString} -${hourPadded.substr(hourPadded.length-2)}00`
  }


  _submitEventHandler = async (_e, values) => {
    const data = {
      booking_restriction: {
        name: values.name,
        address_id: values.addressId,
        start_time: this.formatTimeParamsToLocal(values.startTimeLocalizedString),
				end_time: this.formatTimeParamsToLocal(values.endTimeLocalizedString),
				danger_radius_miles: values.dangerRadiusMiles,
				error_radius_miles: values.errorRadiusMiles,
				danger_message: values.dangerMessage,
				error_message: values.errorMessage,
        allow_round_trip: !values.roundTripError,
        allow_one_way: !values.oneWayError,
      },
    }

    if (this.selectedEvent) {
      client.makeApiCall(
        'put', `/booking_restrictions/${this.selectedEvent.id}`, data,
        'Event updated successfully',
        'Unable to update event',
        (response) => {
          this.selectedEvent.updateFromJson(response.data)
          this._closeModals()
        }
      )
    } else {
      client.makeApiCall(
        'post', `/booking_restrictions`, data,
        'Event created successfully',
        'Unable create new event',
        this._postSubmitCallback
      )
    }
  }

  _postSubmitCallback = () => {
    this._tableRef.current.fetchData()
    this._closeModals()
  }

  _closeModals = () => {
    this._addModalOpen = false
    this._confirmApproveModalOpen = false
    this._removeEventsModalOpen = false
  }

  _toggleRemoveEventsModal = (events) => {
    this._removeEventsModalOpen = !this._removeEventsModalOpen
    this._selectedEvents = events
  }

  _removeEvents = async () => {
    await Promise.allSettled(this._selectedEvents.map(async (event) => {
      await client.delete(`${this.props.dataUrl}/${event.id}`);
    }))
    window.location.reload()
  }

  render() {
    return (
      <>
        <EventModal
          isOpen={this._addModalOpen}
          toggle={() => {
            runInAction(() => {
              this._addModalOpen = !this._addModalOpen
            });
          }}
          title={this.modalTitle}
          submitHandler={this._submitEventHandler}
          event={this.selectedEvent}
        />
        <ConfirmationModal
          isOpen={ this._removeEventsModalOpen }
          toggle={ this._toggleRemoveEventsModal }
          header={ 'Remove Events?' }
          body={ "Are you sure you want to remove selected events?" }
          confirm={ this._removeEvents }
          confirmButtonText={ 'Remove' }
          closeButtonText={ 'Cancel' }
        />
        <MyTable
          model={BookingRestriction}
          dataUrl={this.props.dataUrl}
          title={'All'}
          breadcrumbBaseTitle={this.props.baseTitle || 'Events'}
          dataColumns={this.dataColumns}
          embedded={this.props.embedded}
          filter={this.props.filter}
          singleActions={this.props.addEnabled && {
            'Add +': () => runInAction(() => {
              this.selectedEvent = null;
              this._addModalOpen = !this._addModalOpen;
            })
          }}
          searchable
          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}
          multiActions={{
            'Remove Event(s)': (rows) => {
              this._toggleRemoveEventsModal(rows)
            }
          }}
          ref={this._tableRef}
        />
      </>
    );
  }
});

decorate(EventsTable, {
  selectedEvent: observable,
  totalPayouts: observable,
  fetchTotals: action,
  approveAllEarnings: action,
  _addModalOpen: observable,
  _confirmApproveModalOpen: observable,
  _confirmIssueModalOpen: observable,
  _toggleConfirmApproveModal: action,
  _toggleConfirmIssueModal: action,
  _selectedEarnings: observable,
  _toggleRemoveEventsModal: action,
	_removeEventsModalOpen: observable,
	_selectedEvents: observable,
  _closeModals: action,
});
