import React from 'react';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';

import {
  Row, Col, CardBody, Card, Container,
} from 'reactstrap';

// import images
import profileImg from '../../assets/images/profile-img.png';
import logoImg from '../../assets/images/logo.svg';

const Register = (props) => (
  <>
    <div className="home-btn d-none d-sm-block">
      <Link to="/" className="text-dark">
        <i className="fas fa-home h2" />
      </Link>
    </div>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={8} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Free Register</h5>
                      <p>Get your free Jeevz account now.</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profileImg} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to="/">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={logoImg}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                  >
                    <div className="form-group">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <AvField
                        name="username"
                        label="Username"
                        type="text"
                        required
                        placeholder="Enter username"
                      />
                    </div>
                    <div className="form-group">
                      <AvField
                        name="password"
                        label="Password"
                        type="password"
                        required
                        placeholder="Enter Password"
                      />
                    </div>

                    <div className="mt-4">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        By registering you agree to the Jeevz
                        {' '}
                        <Link to="#" className="text-primary">
                          Terms of Use
                        </Link>
                      </p>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Already have an account ?
                {' '}
                <Link
                  to="/pages-login"
                  className="font-weight-medium text-primary"
                >
                  {' '}
                  Login
                </Link>
                {' '}
              </p>
              <p>
                ©
                {' '}
                {new Date().getFullYear()}
                {' '}
                Jeevz.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Register;
