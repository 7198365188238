// @ts-nocheck
import { action, computed, observable } from "mobx"
import { apiCall } from "../constants/apiCall"
import { addressesStore } from "../stores/addressesStore"
import { expensesStore } from "../stores/expensesStore"
import { DateTime } from "luxon"

export class Trip {
    /**
     * unique id of this Trip, immutable.
     */
    id = null
    type = 'Trip'

    @observable estimatedDurationMinutes
    @observable requestedStartTime
    @observable roundTrip
    @observable notes
    @observable dispatchNotes
    @observable status
    @observable driverArrivedAt
    @observable driverEmbarkedAt
    @observable bookingStartedAt
    @observable bookingEndedAt
    @observable driverPickingUpVehicleAt
    @observable driverDroppedOffClientAt
    @observable driverReturningAt
    @observable assignedPhoneNumber
    @observable originCoordinates
    @observable clientName
    @observable city
    @observable pickupAddress
    @observable dropoffAddress
    @observable assignedDriverName
    @observable assignedDriverAvatarUrl
    @observable estimatedPrice
    @observable actualPrice
    @observable estimatedCreditsToApply = 0
    @observable createdAt
    @observable updatedAt
    @observable driverRatingValue = 0
    @observable clientRatingValue = 0
    @observable preferredDriver
    @observable tip
    @observable client
    @observable driver
    @observable vehicle
    @observable card
    @observable closedOutAt
    @observable adminDriverNotes
    @observable chargeSummary
    @observable userId
    @observable driverAssignedAt
    @observable creditsApplied
    @observable minutesApplied
    @observable airportTripType
    @observable airportCode
    @observable airline
    @observable flightNumber
    @observable driverLocation
    @observable clientLocation
    @observable vehicleTransport
    @observable bookingType
    @observable vehiclePickupNotes
    @observable p2p
    @observable actualDurationMinutes
    @observable estimatedVehiclePickupLeadTimeMinutes
    @observable couponCodeUsed
    @observable couponId
    @observable couponDiscountPercent
    @observable estimatedCouponDiscountCents

    @observable couponCodeUsed
    @observable couponId
    @observable couponDiscountPercent
    @observable estimatedDriverPriceCents
    @observable estimatedOvernighterTipCents
    @observable estimatedRidesharePriceCents
    @observable estimatedCouponDiscountCents
    @observable estimatedMinutesAppliedValueCents

    @observable newDriverLocation
    @observable newClientLocation

    @observable addressesStore
    @observable expensesStore

    @observable timeZone
    @observable dryver


    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.addressesStore = new addressesStore()
      this.expensesStore = new expensesStore()
      this.updateFromJson(data)
    }

    @computed get completed() {
      return this.status === 'completed'
    }

    @computed get failed() {
      return this.status === 'failed'
    }

    @computed get cancelled() {
      return this.status === 'cancelled'
    }

    @computed get done() {
      return [
        'completed',
        'failed',
        'cancelled',
      ].includes(this.status)
    }

    @computed get upcoming() {
      return [
        'requested',
        'dispatching',
        'confirmed',
        'driver_en_route',
        'driver_arrived',
        'in_progress',
        'driver_picking_up_vehicle'
      ].includes(this.status)
    }

    @computed get current() {
      return [
        'driver_en_route',
        'driver_arrived',
        'in_progress',
        'driver_returning',
        'driver_picking_up_vehicle',
        'driver_dropping_off_vehicle'
      ].includes(this.status)
    }

    @computed get contactable() {
      return this.current
    }

    @computed get tripStops() {
      return Array.from(this.addressesStore.addresses.values())
    }

    @computed get assigned() {
      return (!!this.assignedDriverName)
    }

    @computed get editable() {
      return !this.closedOutAt && this.status !== 'cancelled'
    }

    @computed get cancellable() {
      switch(this.status) {
        case 'requested': case 'dispatching': case 'confirmed': case 'driver_en_route': case 'driver_arrived':
          return true
        default:
          return false
      }
    }

    @computed get closeable() {
      return [
        'completed',
        'cancelled',
      ].includes(this.status) && !this.closedOutAt
    }

    @computed get cardId() {
      return this.card?.id
    }

    @computed get addressId() {
      return this.pickupAddress?.id
    }

    @computed get oneWayTrip() {
      return !this.roundTrip
    }

    @computed get dropoffAddressId() {
      return this.dropoffAddress?.id
    }

    @computed get vehicleId() {
      return this.vehicle?.id
    }

    formatDate(date) {
      const offset = date.getTimezoneOffset() / 60
      date.setTime(date.getTime() - offset * 60 * 60 * 1000)
      date.setSeconds(0, 0)
      return date.toISOString().replace(/T/, " ").replace(/:00.000Z/, "");
    }

    @computed get requestedStartTimeLocalizedString() {
      let date = new Date(this.requestedStartTime)

      if (this.bookingType === "airport" && this.airportTripType === "arriving") {
        let dateTime = DateTime.fromJSDate(date)
        dateTime = dateTime.plus({ minutes: this.estimatedVehiclePickupLeadTimeMinutes })
        date = dateTime.toJSDate()
      }

      return this.formatDate(date)
    }

    @computed get driverEmbarkedAtLocalizedString() {
      if (!this.driverEmbarkedAt) {
        return null
      }
      const date = new Date(this.driverEmbarkedAt)

      return this.formatDate(date);
    }

    @computed get driverArrivedAtLocalizedString() {
      if (!this.driverArrivedAt) {
        return null
      }
      const date = new Date(this.driverArrivedAt)

      return this.formatDate(date);
    }

    @computed get bookingStartedAtLocalizedString() {
      if (!this.bookingStartedAt) {
        return null
      }
      const date = new Date(this.bookingStartedAt)
      return this.formatDate(date);
    }

    @computed get bookingEndedAtLocalizedString() {
      if (!this.bookingEndedAt) {
        return null
      }
      const date = new Date(this.bookingEndedAt)
      return this.formatDate(date);
    }

    @computed get driverPickingUpVehicleAtLocalizedString() {
      if (!this.bookingEndedAt) {
        return null
      }
      const date = new Date(this.driverPickingUpVehicleAt)
      return this.formatDate(date);
    }

    @computed get driverDroppedOffClientAtLocalizedString() {
      if (!this.bookingEndedAt) {
        return null
      }
      const date = new Date(this.driverDroppedOffClientAt)
      return this.formatDate(date);
    }

    @computed get estimatedPriceFormatted() {
      if (this.estimatedPrice) {
        return this.estimatedPrice.cents / 100
      } else {
        return null
      }
    }


    @action addStop = async (addressData) => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}/booking_stops`, {
        method: 'POST',
        body: JSON.stringify({
          address: addressData
        }),
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action removeStop = async (addressId) => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}/booking_stops/${addressId}`, {
        method: 'DELETE',
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @computed get expenses() {
      return Array.from(this.expensesStore.expenses.values())
    }

    @action addExpense = async (formData) => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}/expenses/`, {
        method: 'POST',
        body: formData,
        contentType: 'multipart/form-data'
      })

      if (response.data?.type === 'expense') {
        this.expensesStore.pushData(response.data)
        return this
      }

      return 'error'
    }

    @action deleteExpense = async (expense) => {
      await apiCall(`${process.env.REACT_APP_API_URL}/expenses/${expense.id}`, {
        method: 'DELETE'
      })

      this.expensesStore.expenses.delete(expense.id)

      return this
    }

    @action fetchExpenses = async () => {
      await this.expensesStore.fetchAll(`/bookings/${this.id}/expenses`, {
        method: 'GET'
      })
    }

    @action refresh = async () => {
      // update self from show
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}`)

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }
    }

    @computed get tippable() {
      return (!this.tip || this.tip?.cents === 0)
    }

    @action addTip = async (tipAmount) => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}/tips`, {
        method: 'POST',
        body: JSON.stringify({
          tip: {
            amount_cents: tipAmount * 100
          }
        }),
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action addFeedback = async (feedback) => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          booking: {
            client_feedback: feedback
          }
        }),
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action rateDriver = async (rating) => {
      const method = this.driverRatingValue > 0 ? 'PUT' : 'POST'
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}/ratings`, {
        method: method,
        body: JSON.stringify({
          rating: {
            value: rating
          }
        }),
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action setDriverEnRoute = async () => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          booking: {
            status: "driver_en_route"
          }
        }),
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action setDriverArrived = async () => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          booking: {
            status: "driver_arrived"
          }
        }),
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action startTrip = async () => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          booking: {
            status: "in_progress"
          }
        }),
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action endTrip = async () => {
      const response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          booking: {
            status: "completed"
          }
        }),
      })

      if (response.data?.type === 'booking') {
        this.updateFromJson(response.data)
        return this
      }

      return 'error'
    }

    @action fetchLocations = async () => {
      let response

      if (this.driver) {
        response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}/locations?type=driver`, {
          method: 'GET',
        })

        if (response.data?.type === 'location') {
          this.newDriverLocation = {
            latitude: parseFloat(response.data.attributes.current_latitude),
            longitude: parseFloat(response.data.attributes.current_longitude),
            updatedAt: response.data.attributes.location_last_updated,
          }
        }
      }

      response = await apiCall(`${process.env.REACT_APP_API_URL}/bookings/${this.id}/locations?type=client`, {
        method: 'GET',
      })

      if (response.data?.type === 'location') {
        this.newClientLocation = {
          latitude: parseFloat(response.data.attributes.current_latitude),
          longitude: parseFloat(response.data.attributes.current_longitude),
          updatedAt: response.data.attributes.location_last_updated,
        }
      }
    }

    @computed get typeLabel() {
      switch (this.bookingType) {
      case "round_trip":
        return "Round Trip"
      case "airport":
        if (this.airportTripType === "arriving") {
          return "Airport Pickup"
        } else {
          return "Airport Dropoff"
        }
      case "one_way":
        return "One Way"
      case "scheduled_one_way":
        return "Scheduled One-Way"
      case "overnighter":
        return "Overnighter"
      case "other":
        return "Other"
      default:
        return "Unknown"
      }
    }

    @computed get asJson() {
      return {
        id:                       this.id,
        estimatedDurationMinutes: this.estimatedDurationMinutes,
        requestedStartTime:       this.requestedStartTime,
        roundTrip:                this.roundTrip,
        notes:                    this.notes,
        dispatchNotes:            this.dispatchNotes,
        status:                   this.status,
        driverArrivedAt:          this.driverArrivedAt,
        driverEmbarkedAt:         this.driverEmbarkedAt,
        bookingStartedAt:         this.bookingStartedAt,
        bookingEndedAt:           this.bookingEndedAt,
        driverPickingUpVehicleAt: this.driverPickingUpVehicleAt,
        driverDroppedOffClientAt: this.driverDroppedOffClientAt,
        driverReturningAt:        this.driverReturningAt,
        assignedPhoneNumber:      this.assignedPhoneNumber,
        originCoordinates:        this.originCoordinates,
        clientName:               this.clientName,
        city:                     this.city,
        pickupAddress:            this.pickupAddress,
        dropoffAddress:           this.dropoffAddress,
        assignedDriverName:       this.assignedDriverName,
        assignedDriverAvatarUrl:  this.assignedDriverAvatarUrl,
        estimatedPrice:           this.estimatedPrice,
        estimatedCreditsToApply:  this.estimatedCreditsToApply,
        addresses:                this.tripStops,
        createdAt:                this.createdAt,
        updatedAt:                this.updatedAt,
        driverRatingValue:        this.driverRatingValue,
        clientRatingValue:        this.clientRatingValue,
        tip:                      this.tip,
        preferredDriver:          this.preferredDriver,
        client:                   this.client,
        driver:                   this.driver,
        actualPrice:              this.actualPrice,
        closedOutAt:              this.closedOutAt,
        adminDriverNotes:         this.adminDriverNotes,
        vehicle:                  this.vehicle,
        card:                     this.card,
        chargeSummary:            this.chargeSummary,
        userId:                   this.userId,
        driverAssignedAt:         this.driverAssignedAt,
        minutesApplied:           this.minutesApplied,
        creditsApplied:           this.creditsApplied,
        airportTripType:          this.airportTripType,
        airportCode:              this.airportCode,
        airline:                  this.airline,
        flightNumber:             this.flightNumber,
        driverLocation:           this.driverLocation,
        clientLocation:           this.clientLocation,
        vehicleTransport:         this.vehicleTransport,
        bookingType:              this.bookingType,
        p2p:                      this.p2p,
        vehiclePickupNotes:       this.vehiclePickupNotes,
        actualDurationMinutes:    this.actualDurationMinutes,
        estimatedVehiclePickupLeadTimeMinutes: this.estimatedVehiclePickupLeadTimeMinutes,
        couponCodeUsed:           this.couponCodeUsed,
        couponId:                 this.couponId,
        couponDiscountPercent:    this.couponDiscountPercent,
        estimatedDriverPriceCents:     this.estimatedDriverPriceCents,
        estimatedOvernighterTipCents:  this.estimatedOvernighterTipCents,
        estimatedRidesharePriceCents:  this.estimatedRidesharePriceCents,
        estimatedCouponDiscountCents:  this.estimatedCouponDiscountCents,
        estimatedMinutesAppliedValueCents:  this.estimatedMinutesAppliedValueCents,
        timeZone: this.timeZone,
        dryver: this.dryver
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.estimatedDurationMinutes = data.attributes.estimatedDurationMinutes
      this.requestedStartTime       = data.attributes.requestedStartTime
      this.roundTrip                = data.attributes.roundTrip
      this.notes                    = data.attributes.notes
      this.dispatchNotes            = data.attributes.dispatchNotes
      this.status                   = data.attributes.status
      this.driverArrivedAt          = data.attributes.driverArrivedAt
      this.driverEmbarkedAt         = data.attributes.driverEmbarkedAt
      this.bookingStartedAt         = data.attributes.bookingStartedAt
      this.bookingEndedAt           = data.attributes.bookingEndedAt
      this.driverPickingUpVehicleAt = data.attributes.driverPickingUpVehicleAt
      this.driverDroppedOffClientAt = data.attributes.driverDroppedOffClientAt
      this.driverReturningAt        = data.attributes.driverReturningAt
      this.assignedPhoneNumber      = data.attributes.assignedPhoneNumber
      this.originCoordinates        = data.attributes.originCoordinates
      this.clientName               = data.attributes.clientName
      this.city                     = data.attributes.city
      this.pickupAddress            = data.attributes.pickupAddress
      this.dropoffAddress           = data.attributes.dropoffAddress
      this.assignedDriverName       = data.attributes.assignedDriverName
      this.assignedDriverAvatarUrl  = data.attributes.assignedDriverAvatarUrl
      this.estimatedPrice           = data.attributes.estimatedPrice
      this.estimatedCreditsToApply  = data.attributes.estimatedCreditsToApply
      this.createdAt                = data.attributes.createdAt
      this.updatedAt                = data.attributes.updatedAt
      this.driverRatingValue        = data.attributes.driverRatingValue
      this.clientRatingValue        = data.attributes.clientRatingValue
      this.tip                      = data.attributes.tip
      this.preferredDriver          = data.attributes.preferredDriver
      this.client                   = data.attributes.client
      this.driver                   = data.attributes.driver
      this.actualPrice              = data.attributes.actualPrice
      this.closedOutAt              = data.attributes.closedOutAt
      this.adminDriverNotes         = data.attributes.adminDriverNotes
      this.vehicle                  = data.attributes.vehicle
      this.card                     = data.attributes.card
      this.chargeSummary            = data.attributes.chargeSummary
      this.userId                   = data.attributes.userId
      this.driverAssignedAt         = data.attributes.driverAssignedAt
      this.minutesApplied           = data.attributes.minutesApplied
      this.creditsApplied           = data.attributes.creditsApplied
      this.airportTripType          = data.attributes.airportTripType
      this.airportCode              = data.attributes.airportCode
      this.airline                  = data.attributes.airline
      this.flightNumber             = data.attributes.flightNumber
      this.driverLocation           = data.attributes.driverLocation
      this.clientLocation           = data.attributes.clientLocation
      this.vehicleTransport         = data.attributes.vehicleTransport
      this.bookingType              = data.attributes.bookingType
      this.p2p                      = data.attributes.p2p
      this.vehiclePickupNotes       = data.attributes.vehiclePickupNotes
      this.actualDurationMinutes    = data.attributes.actualDurationMinutes
      this.estimatedVehiclePickupLeadTimeMinutes = data.attributes.estimatedVehiclePickupLeadTimeMinutes
      this.couponCodeUsed           = data.attributes.couponCodeUsed
      this.couponId                 = data.attributes.couponId
      this.couponDiscountPercent    = data.attributes.couponDiscountPercent
      this.estimatedDriverPriceCents     = data.attributes.estimatedDriverPriceCents
      this.estimatedOvernighterTipCents  = data.attributes.estimatedOvernighterTipCents
      this.estimatedRidesharePriceCents  = data.attributes.estimatedRidesharePriceCents
      this.estimatedCouponDiscountCents  = data.attributes.estimatedCouponDiscountCents
      this.estimatedMinutesAppliedValueCents  = data.attributes.estimatedMinutesAppliedValueCents
      this.timeZone = data.attributes.timeZone
      this.dryver = data.attributes.dryver

      this.addressesStore.clear()

      for (const addressData of (data.attributes.addresses?.data || [])) {
        this.addressesStore.pushData(addressData);
      }

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
