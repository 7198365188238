import { AvField, AvForm } from 'availity-reactstrap-validation';
import { action, computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import publicIp from 'public-ip';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row
} from 'reactstrap';
import { myToast } from '../Common/MyToast'

const stateAbbreviations = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
  'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
  'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
  'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
];

export const BankAccountModal = observer(class BankAccountModal extends Component {
  loading = false
  success = false
  error = false

  get buttonText() {
    if (this.loading) {
      return 'Loading...'
    } else if (this.error) {
      return 'Error'
    } else if (this.success) {
      return 'Success'
    } else {
      return 'Submit'
    }
  }

  get buttonColor() {
    if (this.loading) {
      return 'warning'
    } else if (this.error) {
      return 'danger'
    } else if (this.success) {
      return 'success'
    } else {
      return 'primary'
    }
  }

  handleSubmit = async (_e, values) => {
    this.loading = true

    const ip = await publicIp.v4();
    const formData = {
      ssn: values.ssn,
      dobDay: values.dobDay,
      dobMonth: values.dobMonth,
      dobYear: values.dobYear,
      address: values.address,
      line2: values.line2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      ipAddress: ip,
      routingNumber: values.routingNumber,
      accountNumber: values.accountNumber,
      accountName: values.accountName,
      accountType: values.accountType,
      bankName: values.bankName,
    }

    const resp = await this.props.driver.updateBankAccount(formData);

    this.loading = false

    if (resp.data?.type !== 'bank_account') {
      this.error = true

      myToast.showToast('error', resp.data.attributes.messages, 'Error')
    } else {
      this.success = true
    }

    if (this.props.submitCallback) {
      this.props.submitCallback()
    }
  }

  render() {
    return (
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              { this.props.title }<br/>
              <span style={{fontSize: '75%', color: 'grey'}}>
                Note: When adding a new account, this account will become the active account for payouts for this driver.
              </span>
            </h5>

            <button
              type="button"
              onClick={this.props.toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <AvForm className="needs-validation" onValidSubmit={this.handleSubmit}>
              <Row>
                <Col>
                  { !this.props.driver?.paylocityId && <><FormGroup>
                    <Label htmlFor="ssn">SSN</Label>
                    <AvField
                      disabled={this.loading}
                      name="ssn"
                      placeholder="SSN"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      errorMessage="9 digits"
                      validate={{
                        required: { value: true } ,
                        minLength: {value: 9},
                        maxLength: {value: 9}
                      }}
                      id="ssn"
                    />
                  </FormGroup>

                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="dobDay">DOB Day</Label>
                        <AvField
                          name="dobDay"
                          placeholder="Day"
                          type="text"
                          className="form-control"
                          errorMessage={'Please enter a valid day'}
                          validate={{
                            required: { value: true },
                            min: { value: 1 },
                            max: { value: 31 },
                          }}
                          id="dobDay"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="dobMonth">DOB Month</Label>
                        <AvField
                          name="dobMonth"
                          placeholder="Day"
                          type="text"
                          className="form-control"
                          errorMessage={'Please enter a valid month'}
                          validate={{
                            required: { value: true },
                            min: { value: 1 },
                            max: { value: 12 },
                          }}
                          id="dobMonth"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="dobYear">DOB Year</Label>
                        <AvField
                          name="dobYear"
                          placeholder="Day"
                          type="text"
                          className="form-control"
                          errorMessage={'Please enter a valid year'}
                          validate={{
                            required: { value: true },
                            min: { value: (new Date()).getFullYear() - 100 },
                            max: { value: (new Date()).getFullYear() - 18 },
                          }}
                          id="dobYear"
                        />
                      </FormGroup>
                    </Col>
                  </Row></>}

                  <FormGroup>
                    <Label htmlFor="line1">Address</Label>
                    <AvField
                      disabled={this.loading}
                      name="line1"
                      placeholder="Address"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="line1"
                    />
                  </FormGroup>

                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="city">City</Label>
                        <AvField
                          name="city"
                          placeholder="City"
                          type="text"
                          errorMessage=" Please provide a valid city"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="city"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="state"
                          label="State"
                          validate={{ required: { value: true } }}
                          id="state"
                          className="form-control"
                        >
                          {
                            stateAbbreviations.map((opt) => <option key={opt} value={opt}>{opt}</option>)
                          }
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="postalCode">Zip</Label>
                        <AvField
                          name="postalCode"
                          placeholder="Zip Code"
                          type="text"
                          errorMessage="Please provide a valid zip"
                          className="form-control"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: '^[0-9]{5}$',
                              errorMessage: 'Please provide a valid zip code',
                            },
                          }}
                          id="postalCode"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label htmlFor="accountName">Full Name or Business Name on Account</Label>
                    <AvField
                      disabled={this.loading}
                      name="accountName"
                      placeholder="Account Name"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="accountName"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="accountType">Account Type</Label>
                    <AvField
                      type="select"
                      name="accountType"
                      id="accountTypeSelect"
                      required
                      disabled={this.loading}
                  >
                      <option key={'empty'} value={null}>Select One</option>

                      <option key={'checking'} value={'C'}>Checking</option>
                      <option key={'savings'} value={'S'}>Savings</option>
                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="bankName">Bank Name</Label>
                    <AvField
                      disabled={this.loading}
                      name="bankName"
                      placeholder="Bank Name"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="bankName"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="routingNumber">Routing Number</Label>
                    <AvField
                      disabled={this.loading}
                      name="routingNumber"
                      placeholder="Routing Number"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="routingNumber"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="accountNumber">Account Number</Label>
                    <AvField
                      disabled={this.loading}
                      name="accountNumber"
                      placeholder="Account Number"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="accountNumber"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Button
                color={this.buttonColor}
                type="submit"
                disabled={this.loading}>
                  { this.loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> }
                  { this.buttonText }
              </Button>
            </AvForm>
          </div>
        </Modal>
    );
  }
});

decorate(BankAccountModal, {
  loading: observable,
  success: observable,
  error: observable,
  handleSubmit: action,
  buttonText: computed,
  buttonColor: computed,
});
