import {
  takeEvery, fork, put, all, call,
} from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

// Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postJwtLogin } from '../../../helpers/fakebackend_helper';

const jwtDecode = require('jwt-decode');

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  console.log("SAGA")
  try {
    const response = yield call(postJwtLogin, `${process.env.REACT_APP_API_URL}/session`, {
      email: user.email,
      password: user.password,
    });

    const payload = jwtDecode(response.session.jwt);

    if (payload) {
      localStorage.setItem('authUser', JSON.stringify(
        {
          ...response.session.data.attributes,
          id: response.session.data.id,
          jwt: response.session.jwt,
        },
      ));

      yield put(loginSuccess(response));
      history.push('/dashboard');
    } else {
      console.log('error');
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser');

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
  ]);
}

export default authSaga;
