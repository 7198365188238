// @ts-nocheck
// curl https://api.stripe.com/v1/accounts/acct_1G19C6EpTS2wjYtJ/external_accounts \
//   -u sk_test_YwcaWUBDh6bY3QaeZDmJ1tQr00LqavR34m: \
//   -d external_account=btok_1GxVZMEpTS2wjYtJwj25nygR


import { action, observable } from "mobx"
import qs from "qs"
import { apiCall } from "../constants/apiCall"


import { BankAccount } from "../models/BankAccount"



export class bankAccountsStore {
  @observable bankAccounts = new Map()

  @action clear() {
    this.bankAccounts.clear()
  }

  @action create = async (url: string, data: object) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })

    if (response.data?.type === 'bank_account') {
      const bankAccount = new BankAccount(response.data)
      this.bankAccounts.set(bankAccount.id, bankAccount)
      return bankAccount
    }

    return response
  }

  // @action update = async(id: string, data: object) => {
  //   const card = this.bankAccounts.get(id)

  //   if (!card) {
  //     return 'error'
  //   }

  //   // clients/:client_id/addresses/:id OR /addresses/:id
  //   const response = await apiCall(`${process.env.REACT_APP_API_URL}/bankAccounts/${id}`, {
  //     method: 'PUT',
  //     body: JSON.stringify(data),
  //   })

  //   if (response.data && response.data?.type === 'errors') {
  //     // console.log('errors')
  //     return 'error'
  //   } else {
  //     card.updateFromJson(response.data)
  //     return card
  //   }
  // }

  // @action destroy = async(id: string) => {
  //   const card = this.bankAccounts.get(id)

  //   if (!card) {
  //     // console.log('err')
  //     return 'error'
  //   }

  //   const response = await apiCall(`${process.env.REACT_APP_API_URL}/bankAccounts/${id}`, {
  //     method: 'DELETE',
  //   })

  //   this.bankAccounts.delete(id)
  //   return true
  // }

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.bankAccounts.clear()
      for (const accountData of response.data) {
        this.bankAccounts.set(accountData.id, new BankAccount(accountData))
      }
      return this.bankAccounts
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/bankAccounts/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const bankAccount = new BankAccount(response.data)
      this.bankAccounts.set(response.data?.id, bankAccount)
      return bankAccount
    }
  }

  @action submitToStripe = async (data: object, driverId: string) => {
    // https://api.stripe.com/v1/accounts/acct_1G19C6EpTS2wjYtJ/external_accounts

    const params = {
      external_account: {
        // object:       "bank_account",
        // country:    data.card.expMonth,
        // currency:     data.card.expYear,
        // cvc:          data.card.cvc,
        // account_holder_name: ,
        // account_holder_type: ,
        // routing_number: ,
        // account_number: ,
      }
    }


    // {
    //   "id": "ba_1GxVZMEpTS2wjYtJ4rFAso4V",
    //   "object": "bank_account",
    //   "account_holder_name": "Jane Austen",
    //   "account_holder_type": "individual",
    //   "bank_name": "STRIPE TEST BANK",
    //   "country": "US",
    //   "currency": "usd",
    //   "fingerprint": "1JWtPxqbdX5Gamtz",
    //   "last4": "6789",
    //   "metadata": {},
    //   "routing_number": "110000000",
    //   "status": "new",
    //   "account": "acct_1G19C6EpTS2wjYtJ"
    // }

    const response = await fetch(`https://api.stripe.com/v1/accounts/${driverId}/external_accounts`, {
      method: 'POST',
      headers: {
        'Content-Type':   'application/x-www-form-urlencoded',
        'Authorization':  `Bearer ${process.env.REACT_APP_STRIPE_KEY}`,
      },
      body: qs.stringify(params),
    }),
    payload = await response.json()

    return payload
  }

}
