import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { action, computed, decorate, observable } from 'mobx';
import { client } from '../../helpers/client';

class SubscriptionsChart extends Component {
  loaded = false

  xAxisNames = []
  econData = []
  execData = []
  elPrezData = []

  async componentDidMount() {
    await this.fetchData()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.activeTab !== this.props.activeTab) {
      await this.fetchData();
    }
  }

  get chartData() {
    return [
      { name: 'Econ', data: this.econData },
      { name: 'Exec', data: this.execData },
      { name: 'El Prez', data: this.elPrezData },
    ]
  }

  get options() {
    return {
      chart: {
        height: 359,
        type: 'bar',
        stacked: !0,
        toolbar: {
          show: 1,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: '15%',
        },
      },
      dataLabels: {
        enabled: !1,
      },
      xaxis: {
        categories: this.xAxisNames,
        // tickPlacement: 'between',
      },
      colors: ['#556ee6', '#f1b44c', '#34c38f'],
      legend: {
        position: 'bottom',
      },
      fill: {
        opacity: 1,
      },
    }
  }

  fetchData = async () => {
    this.loaded = false

    const data = await client.get(`/admin/dashboard_stats/subscription_chart_data?type=${this.props.activeTab}`);

    this.econData = data.econ
    this.execData = data.exec
    this.elPrezData = data.elPrez
    this.xAxisNames = data.xAxisNames

    this.loaded = true;
  }

  render() {
    return (
      <>
        { this.loaded && <ReactApexChart
          options={this.options}
          series={this.chartData}
          activeTab={this.props.activeTab}
          type="bar"
          height="359" />
        }
      </>
    );
  }
}

export default SubscriptionsChart;

decorate(SubscriptionsChart, {
  options: computed,
  loaded: observable,
  fetchData: action,
  chartData: computed,
  roundTripData: observable,
  rescueRideData: observable,
  scheduledOneWayData: observable,
  airportData: observable,
  overnighterData: observable,
  xAxisNames: observable,
})
