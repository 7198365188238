// @ts-nocheck
import { action, observable } from "mobx"
import { apiCall } from "../constants/apiCall"

import { Driver } from "../models/Driver"

export class driversStore {
  @observable drivers = new Map()

  @action fetchAll = async (url: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      this.drivers.clear()
      for (const driverData of response.data) {
        this.drivers.set(driverData.id, new Driver(driverData))
      }
      return this.drivers
    }
  }

  @action refresh = async (id: string) => {
    const response = await apiCall(`${process.env.REACT_APP_API_URL}/drivers/${id}`, {
      method: 'GET',
    })

    if (response.data && response.data?.type === 'errors') {
      // console.log('errors')
      return 'error'
    } else {
      const driver = new Driver(response.data)
      this.drivers.set(response.data?.id, driver)
      return driver
    }
  }
}
