import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { DriversTable } from './DriversTable';

export const DriversIndex = observer(class DriversIndex extends Component {
  filter

  constructor(props) {
    super(props);

    switch (this.props.location.pathname) {
      case '/drivers':
        this.filter = null;
        break;
      default:
        this.filter = this.props.location.pathname.replace('/drivers/', '');
    }
  }

  render() {
    return (
      <DriversTable
        filter={this.filter}
        dataUrl="/drivers"
        baseTitle="Drivers"
        title={this.filter || 'All'}
        addEnabled
      />
    );
  }
});

decorate(DriversIndex, {
  filter: observable,
});
