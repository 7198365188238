import { AvForm } from 'availity-reactstrap-validation';
import { action, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {
  Button, Col, FormGroup, Label, Modal, Row
} from 'reactstrap';
import { client } from '../../helpers/client';

export const AvatarModal = observer(class AvatarModal extends Component {
  file = null

  fileBinary

  setFile = (files) => {
    const file = files[0]

    const reader = new FileReader()

    reader.onload = () => {
      this.fileBinary = reader.result

    }
    reader.readAsArrayBuffer(file)

    Object.assign(file, {
      preview: window.URL.createObjectURL(file)
    })

    this.file = file
  }

  uploadImage = async () => {
    let formData = new FormData()
    formData.append('avatar', this.file, this.file.name)

    client.makeApiCall(
      'put', this.props.dataUrl, formData,
      'Photo updated successfully',
      'Unable to update photo',
      () => window.location.reload()
    )
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Upload Photo
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.uploadImage}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="body">Photo</Label>

                  { this.file && <div><img alt="Preview" width={300} style={{marginBottom: '20px'}} src={this.file.preview} /></div> }

                  <Dropzone
                    accept={['image/*']}
                    onDrop={acceptedFiles => {this.setFile(acceptedFiles)}}
                    maxFiles={1}
                  >
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p style={{width: '100%', height: '100px', padding: '10px', background: 'lightgrey'}}>
                            Drop photo here, or click to upload (jpg, jpeg, png)
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit" disabled={!this.fileBinary}>
              Save
            </Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(AvatarModal, {
  file: observable,
  setFile: action,
  fileBinary: observable,
});
