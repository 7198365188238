import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { action, computed, decorate, observable } from 'mobx';
import { client } from '../../helpers/client';

class TipPercentageChart extends Component {
  loaded = false

  xAxisNames = []

  tippedData

  async componentDidMount() {
    await this.fetchData()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.activeTab !== this.props.activeTab) {
      await this.fetchData();
    }
  }

  get chartData() {
    return [
      { name: 'Avg Tip Percentage', data: this.tippedData }
    ]
  }

  get options() {
    return {
      chart: {
        height: 359,
        type: 'bar',
        stacked: !0,
        toolbar: {
          show: 1,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: '15%',
        },
      },
      dataLabels: {
        enabled: !1,
      },
      xaxis: {
        categories: this.xAxisNames,
      },
      colors: ['#556ee6'],
      legend: {
        position: 'bottom',
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val, _opts) => `${val}%`,
          title: {
            formatter: () => null
          },
        },
      },
    }
  }

  fetchData = async () => {
    this.loaded = false

    const data = await client.get(`/admin/dashboard_stats/tip_amount_data?type=${this.props.activeTab}`);

    this.tippedData = data.tipped
    this.xAxisNames = data.xAxisNames

    this.loaded = true;
  }

  render() {
    return (
      <>
        { this.loaded && <ReactApexChart
          options={this.options}
          series={this.chartData}
          activeTab={this.props.activeTab}
          type="bar"
          height="359" />
        }
      </>
    );
  }
}

export default TipPercentageChart;

decorate(TipPercentageChart, {
  options: computed,
  loaded: observable,
  fetchData: action,
  chartData: computed,
  tippedData: observable,
  xAxisNames: observable,
})
