import React from 'react';
import {
  Col, Card, CardBody, Media,
} from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

const ChartMiniCard = (props) => {

  var options = {
    labels: props.labels,
    dataLabels: {
      enabled: false,
    },
    colors: ["#222761", "#7B3573", "#C14D71", "#F17A63", "#FFB759", "#F9F871"],
    legend: {
      formatter: function(val, opts) {
        return val + ": " + opts.w.globals.series[opts.seriesIndex]
      }
    },
    fill: {
      type: 'gradient',
    },
    title: {
      text: props.title
    },
  };

  const chartData = {
    type: "donut",
    series: props.series, 
    options
  }

  return(
    <>
      <Col className="px-0">
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <ReactApexChart {...chartData } className="mr-0" />
              </Media>

              <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                <span className="avatar-title">
                  <i className={`bx ${props.iconClass} font-size-24`} />
                </span>
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default ChartMiniCard;
