// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Coupon {
    /**
     * unique id of this Coupon, immutable.
     */
    id = null

    @observable adminId
    @observable code
    @observable usersEnabled
    @observable regionEnabled
    @observable admin
    @observable valuePercent
    @observable value
    @observable enabled
    @observable enabledForAirport
    @observable enabledForOneWay
    @observable enabledForOvernighter
    @observable enabledForRoundTrip
    @observable usageCount
    @observable enabledRegions

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        adminId: this.adminId,
        code: this.code,
        enabled: this.enabled,
        totalUsesCount: this.totalUsesCount,
        enabledForAirport: this.enabledForAirport,
        enabledForOneWay: this.enabledForOneWay,
        enabledForOvernighter: this.enabledForOvernighter,
        enabledForRoundTrip: this.enabledForRoundTrip,
        usersEnabled: this.usersEnabled,
        enabledRegions: this.enabledRegions,
        valuePercent: this.valuePercent,
        admin: this.admin,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false
      this.adminId          = data.attributes.adminId
      this.code             = data.attributes.code
      this.enabled          = data.attributes.enabled
      this.totalUsesCount   = data.attributes.totalUsesCount
      this.value = data.attributes.value,
      this.valuePercent = data.attributes.valuePercent
      this.enabledForRoundTrip = data.attributes.enabledForRoundTrip
      this.enabledForAirport = data.attributes.enabledForAirport
      this.enabledForOneWay = data.attributes.enabledForOneWay
      this.enabledForOvernighter = data.attributes.enabledForOvernighter
      this.usersEnabled     = data.attributes.usersEnabled
      this.enabledRegions    = data.attributes.enabledRegions
      this.admin            = data.attributes.admin
      this.autoSave = true
      // Don't know how to avoid these ones for showing the list
      this.tripsEnabled = this.groupTripsEnabled(data.attributes)
      this.valueDiscount = data.attributes.valuePercent > 0 ? data.attributes.valuePercent : data.attributes.value.cents
    }

    groupTripsEnabled(data){
      let trips = []
      if (data.enabledForAirport){
        trips.push("Airport")
      }
      if (data.enabledForRoundTrip){
        trips.push("Round Trip")
      }
      if (data.enabledForOneWay){
        trips.push("One Way Tirp")
      }
      if (data.enabledForOvernighter){
        trips.push("Over Nighter Tirp")
      }
      return trips
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
