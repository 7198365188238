import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
// Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import RelativeTime from 'react-relative-time';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, Media, Row, TabContent, TabPane } from 'reactstrap';
import { client } from '../../helpers/client';
import { AlertModal } from '../../components/Modals/AlertModal';

export const Chat = observer(class Chat extends Component {
  constructor(props) {
    super(props);
    this.loadTextMessages();
  }

  curMessage = ''

  clientChat = observable([])

  driverChat = observable([])

  bookingChat = observable([])

  messages = observable([])

  chats = observable([])

  currentChat

  _alertModalOpen = false

  async addMessage() {
    const data = {
      text_message: {
        body: this.curMessage,
        booking_id: this.props.booking.id,
      },
    };

    switch (this.currentChat.id) {
      case 'client':
        data.text_message.client_id = this.props.booking.userId;
        break;
      case 'driver':
        data.text_message.driver_id = this.props.booking.driver.id;
        break;
      default:
        this._toggleAlertModal()
    }

    const result = await client.post('/text_messages', data);
    const msg = result.data.attributes;

    this.messages.push({
      id: result.data.id, isRight: true, name: 'Dispatch', message: msg.body, time: msg.createdAt,
    });

    this.curMessage = '';
  }

  async loadTextMessages() {
    this.chats = [
      observable({
        id: 'client', status: 'online', image: this.props.booking.client.photo, name: `${this.props.booking.client.name} [Client]`, description: '', time: '', isActive: true,
      }),
    ];

    this.currentChat = this.chats[0];

    if (this.props.booking.driver) {
      this.chats.push(
        observable({
          id: 'driver', status: 'online', image: this.props.booking.driver?.photo, name: `${this.props.booking.driver.name} [Driver]`, description: '', time: '', isActive: false,
        }),
        observable({
          id: 'booking', status: 'online', image: '', name: '[Client] <-> [Driver]', description: '', time: '', isActive: false,
        }),
      );
    }

    try {
      const clientResult = await client.get(`/bookings/${this.props.booking.id}/text_messages`, { text_receivable_id: this.props.booking.userId, dispatcher: true });

      for (const msg of clientResult.data.reverse()) {
        this.clientChat.push(
          {
            id: msg.id, isRight: msg.attributes.textSendableType === 'Admin', name: msg.attributes.textSendableType === 'Admin' ? 'Dispatch' : 'Client', message: msg.attributes.body, time: msg.attributes.createdAt,
          },
        );
      }

      if (this.props.booking.driver) {
        const driverResult = await client.get(`/bookings/${this.props.booking.id}/text_messages`, { text_receivable_id: this.props.booking.driver?.id, dispatcher: true });
        const bookingResult = await client.get(`/bookings/${this.props.booking.id}/text_messages`, { driver_id: this.props.booking.driver?.id, client_id: this.props.booking.userId });

        for (const msg of driverResult.data.reverse()) {
          this.driverChat.push(
            {
              id: msg.id, isRight: msg.attributes.textSendableType === 'Admin', name: msg.attributes.textSendableType === 'Admin' ? 'Dispatch' : 'Driver', message: msg.attributes.body, time: msg.attributes.createdAt,
            },
          );
        }

        for (const msg of bookingResult.data.reverse()) {
          this.bookingChat.push(
            {
              id: msg.id, isRight: msg.attributes.textSendableType === 'Driver', name: msg.attributes.textSendableType === 'Client' ? 'Client' : 'Driver', message: msg.attributes.body, time: msg.attributes.createdAt,
            },
          );
        }
      }
    } catch {
      this.errorVisible = true;
      this.errorMessage = 'Unable to fetch message data. Please reload page.';
      return;
    }

    this.messages = this.clientChat;
  }

  openChat(id) {
    switch (id) {
      case 'client':
        this.messages = this.clientChat;
        for (const chat of this.chats) {
          if (chat.id === 'client') {
            this.currentChat = chat;
          }
          chat.isActive = chat.id === 'client';
        }
        break;
      case 'driver':
        this.messages = this.driverChat;
        for (const chat of this.chats) {
          if (chat.id === 'driver') {
            this.currentChat = chat;
          }
          chat.isActive = chat.id === 'driver';
        }
        break;
      case 'booking':
        this.messages = this.bookingChat;
        for (const chat of this.chats) {
          if (chat.id === 'booking') {
            this.currentChat = chat;
          }
          chat.isActive = chat.id === 'booking';
        }
        break;
      default:
        this.messages = this.clientChat;
    }
  }

  _toggleAlertModal = () => this._alertModalOpen = !this._alertModalOpen

  render() {
    return (
      <>
        <AlertModal
          isOpen={ this._alertModalOpen }
          toggle={ this._toggleAlertModal }
          header={ 'Error' }
          body={ 'Unable to send message' }
        />

        <div className="">
          <Container fluid>
            <Row>
              <Col lg="12">
                <div className="d-lg-flex">
                  <div className="chat-leftsidebar mr-lg-4">
                    <h4 className="mb-2 font-size-18">Chats</h4>
                    <div className="">
                      <div className="chat-leftsidebar-nav">
                        <TabContent activeTab="1">
                          <TabPane tabId="1">
                            <div>
                              <ul className="list-unstyled chat-list">
                                <PerfectScrollbar style={{ height: '410px' }}>
                                  {
                                                                  this.chats.map((chat) => (
                                                                    <li key={chat.id + chat.status} className={chat.isActive ? 'active' : ''}>
                                                                      <Link to="#" onClick={() => { this.openChat(chat.id); }}>
                                                                        <Media>
                                                                          <div className="align-self-center mr-3">
                                                                            {chat.image && <img src={chat.image} className="rounded-circle avatar-xs" alt="" />}
                                                                          </div>

                                                                          <Media className="overflow-hidden" body>
                                                                            <h5 className="text-truncate font-size-14 mb-1">{chat.name}</h5>
                                                                            <p className="text-truncate mb-0">{chat.description}</p>
                                                                          </Media>
                                                                          {/* <div className="font-size-11"><RelativeTime value={chat.time} /></div> */}
                                                                        </Media>
                                                                      </Link>
                                                                    </li>
                                                                  ))
                                                              }
                                </PerfectScrollbar>
                              </ul>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 user-chat">
                    <Card>
                      <div className="p-4 border-bottom ">
                        <Row>
                          <Col xs="9">
                            <h5 className="font-size-15 mb-1">{this.currentChat.name}</h5>

                            <p className="text-muted mb-0">
                              <i className="mdi mdi-circle text-success align-middle mr-1" />
                              online
                            </p>
                          </Col>
                        </Row>
                      </div>

                      <div>
                        <div className="chat-conversation p-3">
                          <ul className="list-unstyled">
                            <PerfectScrollbar style={{ height: '470px' }}>
                              {/* <li>
                                                          <div className="chat-day-title">
                                                              <span className="title">Today</span>
                                                          </div>
                                                      </li> */}
                              {
                                                          this.messages.map((message) => (
                                                            <li key={`test_k${message.id}`} className={message.isRight ? 'right' : ''}>
                                                              <div className="conversation-list">
                                                                <div className="ctext-wrap">
                                                                  <div className="conversation-name">{message.name}</div>
                                                                  <p>{message.message}</p>
                                                                  <p className="chat-time mb-0">
                                                                    <i className="bx bx-time-five align-middle mr-1" />
                                                                    {' '}
                                                                    <RelativeTime value={message.time} />
                                                                  </p>
                                                                </div>

                                                              </div>
                                                            </li>
                                                          ))
                                                      }
                            </PerfectScrollbar>
                          </ul>
                        </div>
                        { this.currentChat.id !== 'booking' && (
                        <div className="p-3 chat-input-section">
                          <Row>
                            <Col>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  value={this.curMessage}
                                  onChange={
                                                            (e) => {
                                                              runInAction(() => {
                                                                this.curMessage = e.target.value;
                                                              });
                                                            }
}
                                  className="form-control chat-input"
                                  placeholder="Enter Message..."
                                />
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <Button type="button" color="primary" onClick={() => { this.addMessage(); }} className="btn-rounded chat-send w-md waves-effect waves-light">
                                <span className="d-none d-sm-inline-block mr-2">Send</span>
                                {' '}
                                <i className="mdi mdi-send" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        ) }
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
});

decorate(Chat, {
  clientChat: observable,
  driverChat: observable,
  bookingChat: observable,
  messages: observable,
  curMessage: observable,
  chats: observable,
  openChat: action,
  currentChat: observable,
  _alertModalOpen: observable,
  _toggleAlertModal: action,
});
