import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { MyTable } from '../../components/MyTable/MyTable';
import { formatters } from '../../helpers/Formatters';
import { Audit } from '../../models/Audit';
export const AuditsTable = observer(class AuditsTable extends Component {
  userFormatter = (cell, row) => {
    if (cell) {
      const str = `${cell.name} (${cell.type})`

      switch(cell.type) {
        case 'Driver':
          return <a href={`/driver/${cell.id}`}>{str}</a>
        case 'Client':
          return <a href={`/client/${cell.id}`}>{str}</a>
        default:
          return str
      }
    } else {
      return ''
    }
  }

  changesFormatter = (cell, row) => {
    if (row.action === 'create') {
      return 'created new record'
    } else if (row.action === 'destroy') {
      return 'destroyed record'
    } else {
      const changes = []
      for (const key of Object.keys(cell)) {
        const change = cell[key]
        if (change) {
          let changeFrom = change[0]
          let changeTo = change[1]

          if (changeFrom && typeof(changeFrom) === "string" && changeFrom.match(new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}T/))) {
            changeFrom = formatters.createdAtFormatter(changeFrom)
          }

          if (changeTo && typeof(changeTo) === "string" && changeTo.match(new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}T/))) {
            changeTo = formatters.createdAtFormatter(changeTo)
          }

          changes.push(<>
            <div><strong>{ key }:</strong></div>
            <div> &gt; from: <i>{ changeFrom }</i></div>
            <div> &gt; to: <i>{ changeTo }</i></div>
          </>)
        }
      }
      return <>
        { changes }
      </>
    }
  }

  dataColumns = [
    {
      dataField: 'createdAt',
      name: 'Created At',
      priority: 1,
      formatter: formatters.createdAtFormatter,
      sort: true,
    },
    {
      dataField: 'user',
      name: 'User',
      priority: 1,
      formatter: this.userFormatter,
    },
    {
      dataField: 'action',
      name: 'Action',
      priority: 1,
    },
    {
      dataField: 'auditedChanges',
      name: 'Changes',
      priority: 1,
      formatter: this.changesFormatter,
    },
  ]

  render() {
    return <MyTable
      model={Audit}
      dataUrl={this.props.dataUrl}
      breadcrumbBaseTitle={this.props.baseTitle || 'Data Changes'}
      dataColumns={this.dataColumns}
      embedded
      dateFilterColumn={'createdAt'}
      dateFilterLabel={'Created At'}
      defaultLimit={this.props.defaultLimit}
      searchable
    />
  }
});

decorate(AuditsTable, {
  addModalOpen: observable,
});
