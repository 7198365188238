import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { CouponsTable } from '../../components/Tables/CouponsTable';

export const CouponsIndex = observer(class CouponsIndex extends Component {
  filter

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <CouponsTable 
          dataUrl="/coupons"
          baseTitle="Coupons"
          title={this.filter || 'All'}
      />
    )
  }
});

decorate(CouponsIndex, {
  filter: observable,
});
