// @ts-nocheck
import { action, computed, observable } from "mobx"

export class Subaccount {
    /**
     * unique id of this Subaccount, immutable.
     */
    id = null

    @observable child
    @observable relationship
    @observable status
    @observable createdAt

    /**
     * Indicates whether changes in this object
     * should be submitted to the server
     */
    autoSave = true

    /**
     * Disposer for the side effect that automatically
     * stores this object, see @dispose.
     */
    saveHandler = null

    constructor(data) {
      this.id = data?.id

      this.updateFromJson(data)
    }

    @computed get asJson() {
      return {
        id:           this.id,
        child:        this.child,
        relationship: this.relationship,
        status:       this.status,
        createdAt:    this.createdAt,
      }
    }

    /**
     * Update this object with information from the server
     */
    @action updateFromJson(data) {
      // make sure our changes aren't sent back to the server
      this.autoSave = false

      this.child        = data.attributes.child
      this.relationship = data.attributes.relationship
      this.status       = data.attributes.status
      this.createdAt    = data.attributes.createdAt

      this.autoSave = true
    }

    dispose() {
      // clean up the observer
      this.saveHandler()
    }
}
