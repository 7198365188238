import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation'
import { computed, decorate } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Button, Col, FormGroup, Label, Modal, Row } from 'reactstrap'

export const ExpenseModal = observer(
  class ExpenseModal extends Component {
    get editable() {
      return (
        !this.props.expense ||
        this.props.expense?.status === 'submitted' ||
        this.props.expense?.status === 'approved'
      )
    }

    render() {
      return (
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {this.props.title}
            </h5>

            <button
              type="button"
              onClick={this.props.toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.props.submitHandler}
              model={this.props.expense}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="amount">Amount</Label>
                    <AvField
                      disabled={!this.editable}
                      name="amount"
                      placeholder="Expense Amount $"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="amount"
                    />
                  </FormGroup>
                  <FormGroup>
                    <AvField
                      type="select"
                      name="expenseType"
                      id="expenseType"
                      validate={{ required: { value: true } }}
                    >
                      <option value={null}>Select</option>
                      <option value={'gas'}>Gas</option>
                      <option value={'food'}>Food</option>
                      <option value={'parking'}>Parking</option>
                      <option value={'jeevz-gas'}>Jeevz Topup Program</option>
                      <option value={'other'}>Other</option>
                    </AvField>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="description">Description</Label>
                    <AvField
                      name="description"
                      placeholder="Description"
                      type="textarea"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="description"
                    />
                  </FormGroup>
                  <FormGroup>
                    <AvGroup check>
                      <Label check>
                        <AvInput type="checkbox" name="bento" /> Ramp Card Used?
                      </Label>
                    </AvGroup>
                  </FormGroup>
                </Col>
              </Row>
              {this.editable && (
                <Button color="primary" type="submit">
                  Submit form
                </Button>
              )}
            </AvForm>
          </div>
        </Modal>
      )
    }
  },
)

decorate(ExpenseModal, {
  editable: computed,
})
