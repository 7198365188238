import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { User } from "../../helpers/CurrentUser"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
}) => (
  <Route
    render={(props) => {
		  if (!localStorage.getItem('authUser')) {
        // TODO: also check the session is active
		    return (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
		    );
		  }

      if (
        (props.location.pathname.match(new RegExp("^/booking")) && !User.canViewBookings) ||
        (props.location.pathname.match(new RegExp("^/client")) && !User.canViewClients) ||
        (props.location.pathname.match(new RegExp("^/driver")) && !User.canViewDrivers) ||
        (props.location.pathname.match(new RegExp("^/billing")) && !User.canViewBillings) ||
        (props.location.pathname.match(new RegExp("^/earning")) && !User.canViewEarnings) ||
        (props.location.pathname.match(new RegExp("^/promo_code")) && !User.canViewPromoCodes) ||
        (props.location.pathname.match(new RegExp("^/coupons")) && !User.canViewCoupons) ||
        (props.location.pathname.match(new RegExp("^/short_url")) && !User.canViewShortUrls) ||
        (props.location.pathname.match(new RegExp("^/marketing/lead_import")) && !User.canViewLeadImport) ||
        (props.location.pathname.match(new RegExp("^/communication/texting")) && !User.canViewTexting) ||
        (props.location.pathname.match(new RegExp("^/dispatch-settings")) && !User.canViewDispatch) ||
        (props.location.pathname.match(new RegExp("^/zip-codes")) && !User.canViewZips)
      ) {
        return (
          <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
        );
      }

		  return (
        <Layout>
          <Component {...props} />
        </Layout>
			);
    }}
		/>
);

export default withRouter(Authmiddleware);
