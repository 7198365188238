import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import { Provider } from 'react-redux';

import store from './store';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4e7874c5a8784dbc869da50f2e3d3732@o382746.ingest.sentry.io/5440821",
  ignoreErrors: ["ResizeObserver"]
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
