import { action, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import RelativeTime from 'react-relative-time'
import { NavLink } from 'react-router-dom'
import { Badge } from 'reactstrap'
import ClipboardLink from '../../components/Common/ClipboardLink'
import { ConfirmationModal } from '../../components/Modals/ConfirmationModal'
import { MyTable } from '../../components/MyTable/MyTable'
import { client } from '../../helpers/client'
import { Billing } from '../../models/Billing'
import { BillingModal } from '../Modals/BillingModal'
import { AlertModal } from '../Modals/AlertModal'
import { ManualChargeModal } from '../Modals/ManualChargeModal'
export const BillingsTable = observer(
  class BillingsTable extends Component {
    filter
    selectedBilling
    _billingData

    _addModalOpen = false
    _confirmationModalOpen = false
    _manualChargeModalOpen = false
    _alertModalOpen = false
    _alertBody

    _selectedBillings = []

    componentDidMount() {
      this._tableRef = React.createRef()
    }

    clientFormatter = (cell, _row) => {
      const url = `/client/${cell.id}`

      return (
        <NavLink strict={+true} to={url}>
          {cell.photo && (
            <img
              className="rounded-circle avatar-xs"
              src={cell.photo}
              alt=""
              style={{ marginRight: '8px' }}
            />
          )}
          {cell.name}
        </NavLink>
      )
    }

    createdByFormatter = (cell, _row) => {
      if (!cell) {
        return 'System'
      } else {
        return cell.name
      }
    }

    idFormatter = (cell, row) => (
      <>
        <a
          href="#"
          key={row.id}
          onClick={() => {
            runInAction(() => {
              row.amount = row.amount.cents / 100
              this.selectedBilling = row
              this._addModalOpen = true
            })
          }}
        >
          {cell.split('-')[0]}
        </a>
        <ClipboardLink text={cell} />
      </>
    )

    createdAtFormatter = (cell, row) => {
      if (cell) {
        return <RelativeTime value={cell} />
      }
    }

    emailFormatter = (cell, row) => {
      if (cell) {
        return <a href={`mailto:${cell}`}>{cell}</a>
      }
    }

    statusFormatter = (cell, _row) => {
      switch (cell) {
        case 'pending':
          return (
            <Badge color="warning" className="mr-1">
              Unpaid
            </Badge>
          )
        case 'charged':
        case 'paid':
          return (
            <Badge className="badge-soft-success mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        case 'cancelled':
          return (
            <Badge className="badge-soft-info mr-1">{cell.toUpperCase()}</Badge>
          )
        case 'failed':
          return (
            <Badge color="danger" className="mr-1">
              {cell.toUpperCase()}
            </Badge>
          )
        default:
          return (
            <Badge color="danger" className="mr-1">
              unknown
            </Badge>
          )
      }
    }

    amountFormatter = (cell, _row) => cell.pretty

    transferFormatter = (cell, _row) => {
      if (cell) {
        return <a href={`/transfers/${cell}`}>{cell.split('-')[0]}</a>
      }
    }

    billableTypeFormatter = (cell, _row) => {
      switch (cell) {
        case 'Tip':
          return (
            <>
              <i className="bx bxs-coin text-primary mr-2" />
              {cell}
            </>
          )
        case 'Expense':
          return (
            <>
              <i className="bx bxs-gas-pump text-primary mr-2" />
              {cell}
            </>
          )
        case 'Booking':
          return (
            <>
              <i className="bx bxs-car text-primary mr-2" />
              {cell}
            </>
          )
        case 'Subscription':
          return (
            <>
              <i className="bx bxs-calendar text-primary mr-2" />
              {cell}
            </>
          )
        case 'Rideshare':
          return (
            <>
              <i className="fab fa-uber text-primary mr-2" />
              {cell}
            </>
          )
        default:
          return <>{cell}</>
      }
    }

    dataColumns = [
      {
        dataField: 'id',
        name: 'ID',
        priority: 1,
        formatter: this.idFormatter,
        sort: true,
      },
      {
        dataField: 'client',
        name: 'Client',
        priority: 1,
        formatter: this.clientFormatter,
        sort: true,
      },
      {
        dataField: 'amount',
        name: 'Amount',
        priority: 1,
        formatter: this.amountFormatter,
        sort: true,
        sortKey: 'amount_cents',
      },
      {
        dataField: 'creditsApplied',
        name: 'Credits Applied',
        priority: 1,
        formatter: this.amountFormatter,
        sort: true,
      },
      {
        dataField: 'minutesApplied',
        name: 'Minutes Applied',
        priority: 1,
        sort: true,
      },
      {
        dataField: 'adminFee',
        name: 'Admin Fee',
        priority: 1,
        sort: false,
        formatter: this.amountFormatter,
      },
      {
        dataField: 'processingFee',
        name: 'Processing Fee',
        priority: 1,
        sort: false,
        formatter: this.amountFormatter,
      },
      {
        dataField: 'billableType',
        name: 'Type',
        priority: 1,
        formatter: this.billableTypeFormatter,
      },
      {
        dataField: 'status',
        name: 'Status',
        priority: 1,
        sort: true,
        formatter: this.statusFormatter,
      },
      {
        dataField: 'admin',
        name: 'Created By',
        priority: 1,
        formatter: this.createdByFormatter,
      },
      {
        dataField: 'createdAt',
        name: 'Created At',
        priority: 1,
        formatter: this.createdAtFormatter,
        sort: true,
      },
    ]

    submitBillingHandler = async (_e, values) => {
      const data = {
        billing: {
          amount_cents: Math.round(values.amount * 100),
          notes: values.notes,
          status: values.status,
          stripe_id: values.stripeId,
        },
      }

      Object.keys(data).forEach(
        (key) =>
          (data[key] === null || data[key].length === 0) && delete data[key],
      )

      if (this.selectedBilling) {
        client.makeApiCall(
          'put',
          `/billings/${this.selectedBilling.id}`,
          data,
          'Billing updated successfully',
          'Unable to update billing',
          (response) => {
            this.selectedBilling.updateFromJson(response.data)
            this._confirmationModalOpen = false
            this._addModalOpen = false
          },
        )
      } else {
        this._billingData = data
        this._toggleConfirmationModal()
      }
    }

    _toggleConfirmationModal = () =>
      (this._confirmationModalOpen = !this._confirmationModalOpen)
    _submitBilling = async () => {
      client.makeApiCall(
        'post',
        `/bookings/${this.props.booking.id}/billings`,
        this._billingData,
        'Billing added successfully',
        'Unable to add billing',
        this._postSubmitCallback,
      )
    }

    _postSubmitCallback = () => {
      this._tableRef.current.fetchData()

      runInAction(() => {
        this._confirmationModalOpen = false
        this._addModalOpen = false
      })
    }

    _displayManualChargeModal = (billings) => {
      if (!billings || billings.length === 0) {
        this._alertBody = 'You must select some billings first'
        this._toggleAlertModal()
      } else if (
        !billings.every(
          (billing) => billing.client.id === billings[0].client.id,
        )
      ) {
        this._alertBody = 'All selected billings must belong to the same Client'
        this._toggleAlertModal()
      } else {
        this._manualChargeModalOpen = !this._manualChargeModalOpen
        this._selectedBillings = billings
      }
    }

    _toggleAlertModal = () => (this._alertModalOpen = !this._alertModalOpen)

    render() {
      return (
        <>
          <ConfirmationModal
            isOpen={this._confirmationModalOpen}
            toggle={this._toggleConfirmationModal}
            header={'Submit Billing?'}
            body={
              'If the booking already has an associated billing (not a tip or expense), it will be removed.  Are you sure you want to proceed?'
            }
            confirm={this._submitBilling}
            confirmButtonText={'Yes'}
            closeButtonText={'No'}
          />

          <AlertModal
            isOpen={this._alertModalOpen}
            toggle={this._toggleAlertModal}
            header={'Error'}
            body={this._alertBody}
          />

          <BillingModal
            isOpen={this._addModalOpen}
            toggle={() =>
              runInAction(() => {
                this._addModalOpen = !this._addModalOpen
              })
            }
            title={`${
              this.selectedBilling?.status === 'pending' ? 'Edit' : 'View'
            } Billing`}
            submitHandler={this.submitBillingHandler}
            billing={this.selectedBilling}
          />

          {this._manualChargeModalOpen && (
            <ManualChargeModal
              isOpen={this._manualChargeModalOpen}
              toggle={() =>
                runInAction(() => {
                  this._manualChargeModalOpen = !this._manualChargeModalOpen
                })
              }
              title={'Mark Billings as Charged'}
              billings={this._selectedBillings}
            />
          )}

          <MyTable
            model={Billing}
            dataUrl={this.props.dataUrl}
            breadcrumbBaseTitle={this.props.baseTitle || 'Billings'}
            dataColumns={this.dataColumns}
            embedded={this.props.embedded}
            dateFilterColumn={'createdAt'}
            dateFilterLabel={'Created At'}
            defaultLimit={this.props.defaultLimit}
            filter={this.props.filter}
            searchable
            singleActions={
              this.props.addEnabled && {
                'Add +': () =>
                  runInAction(() => {
                    this.selectedBilling = null
                    this._addModalOpen = !this._addModalOpen
                  }),
              }
            }
            multiActions={
              this.props.filter === 'failed' && {
                'Mark As Charged': (rows) => {
                  this._displayManualChargeModal(rows)
                },
              }
            }
            ref={this.tableRef}
          />
        </>
      )
    }
  },
)

decorate(BillingsTable, {
  selectedBilling: observable,
  _addModalOpen: observable,
  _billingData: observable,
  _confirmationModalOpen: observable,
  _toggleConfirmationModal: action,
  _displayManualChargeModal: action,
  _manualChargeModalOpen: observable,
  _selectedBillings: observable,
  _alertModalOpen: observable,
  _toggleAlertModal: action,
  _alertBody: observable,
})
