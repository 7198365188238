import { action, decorate, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import RelativeTime from 'react-relative-time'
import { MyTable } from '../../components/MyTable/MyTable'
import { client } from '../../helpers/client'
import { PermanentNote } from '../../models/PermanentNote'
import { PermanentNoteModal } from '../Modals/PermanentNoteModal'

export const PermanentNotesTable = observer(
  class PermanentNotesTable extends Component {
    filter

    componentDidMount() {
      this._tableRef = React.createRef()
    }

    createdAtFormatter = (cell, row) => {
      if (cell) {
        return <RelativeTime value={cell} />
      }
    }

    _postSubmitCallback = () => {
      this._tableRef.current?.fetchData()
      runInAction(() => (this.addModalOpen = false))
    }

    _confirmDelete = (row) => () => {
      if (window.confirm('Are you sure you want to delete this note?')) {
        client.makeApiCall(
          'delete',
          `${this.props.dataUrl}/${row.id}`,
          null,
          'Note deleted successfully',
          'Unable to delete note',
          this._postSubmitCallback,
        )
      }
    }

    dataColumns = [
      {
        dataField: 'body',
        name: 'Note',
        priority: 1,
      },
      {
        dataField: 'authorName',
        name: 'Author',
        priority: 1,
      },
      {
        dataField: 'createdAt',
        name: 'Created At',
        priority: 1,
        formatter: this.createdAtFormatter,
        sort: true,
      },
      {
        dataField: '',
        name: '',
        priority: 1,
        formatter: (cell, row) => {
          return (
            <>
              <button
                title="Delete"
                type="button"
                className="fa fa-trash"
                style={{
                  cursor: 'pointer',
                  color: 'red',
                  outline: 'none',
                  border: 'none',
                }}
                onClick={this._confirmDelete(row)}
              />
            </>
          )
        },
        sort: true,
      },
    ]

    addSubmitHandler = async (e, values) => {
      client.makeApiCall(
        'post',
        this.props.dataUrl,
        { permanent_note: { body: values.body } },
        'Note added successfully',
        'Unable to add note',
        this._postSubmitCallback,
      )
    }

    render() {
      return (
        <>
          <PermanentNoteModal
            isOpen={this.addModalOpen}
            toggle={() => {
              runInAction(() => {
                this.addModalOpen = !this.addModalOpen
              })
            }}
            title="Add New Note"
            submitHandler={this.addSubmitHandler}
          />

          <MyTable
            model={PermanentNote}
            dataUrl={this.props.dataUrl}
            breadcrumbBaseTitle={
              this.props.baseTitle || 'Persistent Notes (visible to drivers!)'
            }
            dataColumns={this.dataColumns}
            embedded
            dateFilterColumn={'createdAt'}
            dateFilterLabel={'Created At'}
            defaultLimit={this.props.defaultLimit}
            singleActions={{
              'Add +': () =>
                runInAction(() => {
                  this.addModalOpen = !this.addModalOpen
                }),
            }}
            ref={this._tableRef}
          />
        </>
      )
    }
  },
)

decorate(PermanentNotesTable, {
  addModalOpen: observable,
  addSubmitHandler: action,
})
