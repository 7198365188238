import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { action, computed, decorate, observable } from 'mobx';
import { client } from '../../helpers/client';

class RidesChart extends Component {
  loaded = false

  xAxisNames = []
  roundTripData = []
  oneWayData = []
  airportData = []
  overnighterData = []

  async componentDidMount() {
    await this.fetchData()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.activeTab !== this.props.activeTab) {
      await this.fetchData();
    }
  }

  get chartData() {
    return [
      { name: 'Round Trip', data: this.roundTripData },
      { name: 'One-Way', data: this.oneWayData },
      { name: 'Airport', data: this.airportData },
      { name: 'Overnighter', data: this.overnighterData },
    ]
  }

  get options() {
    return {
      chart: {
        height: 359,
        type: 'bar',
        stacked: !0,
        toolbar: {
          show: 1,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: '15%',
        },
      },
      dataLabels: {
        enabled: !1,
      },
      xaxis: {
        categories: this.xAxisNames,
        // tickPlacement: 'between',
      },
      colors: ['#556ee6', '#f1b44c', '#34c38f', '#e83e8c', '#6f42c1'],
      legend: {
        position: 'bottom',
      },
      fill: {
        opacity: 1,
      },
    }
  }

  fetchData = async () => {
    this.loaded = false

    const data = await client.get(`/admin/dashboard_stats/ride_chart_data?type=${this.props.activeTab}`);

    this.roundTripData = data.roundTrip
    this.oneWayData = data.oneWay
    this.airportData = data.airport
    this.overnighterData = data.overnighter
    this.xAxisNames = data.xAxisNames

    this.loaded = true;
  }

  render() {
    return (
      <>
        { this.loaded && <ReactApexChart
          options={this.options}
          series={this.chartData}
          activeTab={this.props.activeTab}
          type="bar"
          height="359" />
        }
      </>
    );
  }
}

export default RidesChart;

decorate(RidesChart, {
  options: computed,
  loaded: observable,
  fetchData: action,
  chartData: computed,
  roundTripData: observable,
  oneWayData: observable,
  airportData: observable,
  overnighterData: observable,
  xAxisNames: observable,
})
