import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { action, computed, observable, decorate } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  Button, Col, FormGroup, Label, Modal, Row,
} from 'reactstrap';

export const PromoCodeModal = observer(class PromoCodeModal extends Component {
  _showMembershipRates = false

  toggleMonthlyRateFields = (e) => {
    this._showMembershipRates = e.target.checked
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { this.props.title }
          </h5>

          <button
            type="button"
            onClick={this.props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <AvForm className="needs-validation" onValidSubmit={this.props.submitHandler} model={this.props.promoCode}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="code">Code</Label>
                  <AvField
                    disabled={this.props.promoCode}
                    name="code"
                    placeholder="Code (leave blank to auto generate)"
                    type="text"
                    className="form-control"
                    id="code"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="maxUses">Max Uses</Label>
                  <AvField
                    name="maxUses"
                    placeholder="Max Uses (leave blank for unlimited)"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    id="maxUses"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="timeValueMinutes">Value in Minutes</Label>
                  <AvField
                    name="timeValueMinutes"
                    placeholder="Time Value Minutes (code must have minute or dollar value)"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    id="timeValueMinutes"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="cashValueDollar">Cash Value</Label>
                  <AvField
                    name="cashValueDollar"
                    placeholder="Cash Value in Dollars (code must have minute or dollar value)"
                    type="number"
                    onwheel="return false;"
                    className="form-control"
                    id="cashValueDollar"
                  />
                </FormGroup>

                <FormGroup>
                  <AvGroup check>
                      <Label check>
                        <AvInput type="checkbox" name="ambassador" /> Ambassador Code?
                      </Label>
                    </AvGroup>
                </FormGroup>

                <FormGroup>
                  <AvGroup check>
                      <Label check>
                        <AvInput type="checkbox" name="babyAmbassador" /> Baby Ambassador Code?
                      </Label>
                    </AvGroup>
                </FormGroup>

                <FormGroup>
                  <AvGroup check>
                    <Label check>
                      <AvInput type="checkbox" name="showMonthlyRate"
                        onClick={this.toggleMonthlyRateFields}

                      /> Modify membership pricing w/ this promo?
                    </Label>
                  </AvGroup>
                </FormGroup>

                { (this.props.promoCode?.monthlyRate.cents > 0 ||
                  this.props.promoCode?.yearlyRate.cents > 0 ||
                  this._showMembershipRates) && <>
                  <FormGroup>
                    <Label htmlFor="monthlyRateDollars">Monthly Rate</Label>
                    <AvField
                      name="monthlyRateDollars"
                      placeholder="Monthly rate in Dollars (code must have minute or dollar value)"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      id="monthlyRateDollars"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="yearlyRateDollars">Yearly Rate</Label>
                    <AvField
                      name="yearlyRateDollars"
                      placeholder="Yearly Rate in Dollars (code must have minute or dollar value)"
                      type="number"
                      onwheel="return false;"
                      className="form-control"
                      id="yearlyRateDollars"
                    />
                  </FormGroup>
                </>}

                <FormGroup>
                  <Label htmlFor="validAtLocalizedString">Valid Date</Label>
                  <AvField
                    name="validAtLocalizedString"
                    placeholder="Code Valid Date (optional - blank for immediately)"
                    type="date"
                    className="form-control"
                    id="validAtLocalizedString"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="expiresAtLocalizedString">Expires At</Label>
                  <AvField
                    name="expiresAtLocalizedString"
                    placeholder="Code Expiration Date (optional - blank for never)"
                    type="date"
                    className="form-control"
                    id="expiresAtLocalizedString"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">Submit form</Button>
          </AvForm>
        </div>
      </Modal>
    );
  }
});

decorate(PromoCodeModal, {
  _showMembershipRates: observable,
  toggleMonthlyRateFields: action,
});

