import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ClipboardLink from '../../components/Common/ClipboardLink';
import { MyTable } from '../../components/MyTable/MyTable';
import { formatters } from '../../helpers/Formatters';
import { Charge } from '../../models/Charge';
import { RefundModal } from '../Modals/RefundModal';
export const ChargesTable = observer(class ChargesTable extends Component {
  filter

  idFormatter = (cell, row) => (
    <>
      <a key={cell} onClick={
        () => {runInAction(() => {
          this.selectedCharge = new Charge(row)
        })}
      }>
        {cell.split('-')[0]}
      </a>
      <ClipboardLink text={cell} />
    </>
  )

  dataColumns = [
    {
      dataField: 'id',
      name: 'ID',
      priority: 1,
      formatter: this.idFormatter,
    },
    {
      dataField: 'stripeId',
      name: 'Stripe id',
      priority: 1,
    },
    {
      dataField: 'description',
      name: 'Description',
      priority: 1,
    },
    {
      dataField: 'createdAt',
      name: 'Created at',
      priority: 1,
      formatter: formatters.createdAtFormatter,
      sort: true,
    },
    {
      dataField: 'status',
      name: 'Status',
      priority: 1,
      sort: true,
    },
    {
      dataField: 'amount',
      name: 'Amount',
      priority: 1,
      formatter: formatters.currencyFormatter,
      sort: true,
      sortKey: 'amount_cents',
    },
    {
      dataField: 'amountRefunded',
      name: 'Refunded',
      priority: 1,
      formatter: formatters.currencyFormatter,
      sort: true,
      sortKey: 'amount_refunded_cents',
    }
  ]

  render() {
    return (
      <>
        <RefundModal
          isOpen={this.selectedCharge}
          toggle={() => {
            runInAction(() => {
              this.selectedCharge = null;
            });
          }}
          title={`Refund $${this.selectedCharge?.amountRefundable} Charge`}
          submitHandler={this.refundSubmitHandler}
          charge={this.selectedCharge}
        />

        <MyTable
          model={Charge}
          dataUrl={this.props.dataUrl}
          breadcrumbBaseTitle={this.props.baseTitle || 'Charges'}
          dataColumns={this.dataColumns}
          embedded
          dateFilterColumn={'createdAt'}
          dateFilterLabel={'Created At'}
          defaultLimit={this.props.defaultLimit}
          // ref={this.tableRef}
        />
      </>
    );
  }
});

decorate(ChargesTable, {
  selectedCharge: observable,
  refundModalOpen: observable,
  refundSubmitHandler: action,
});
